import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgFeedO = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M4.578 3.635a.941.941 0 0 0-.945.941v5.654a.945.945 0 0 0 .945.944h5.652a.943.943 0 0 0 .944-.944V4.578a.943.943 0 0 0-.944-.945H4.578v.002zm0-1.885h5.652a2.828 2.828 0 0 1 2.829 2.828v5.652a2.828 2.828 0 0 1-2.829 2.829H4.578A2.828 2.828 0 0 1 1.75 10.23V4.578A2.827 2.827 0 0 1 4.578 1.75zM17.77 3.635a.941.941 0 0 0-.944.941v5.654a.945.945 0 0 0 .944.944h5.652a.944.944 0 0 0 .945-.944V4.578a.941.941 0 0 0-.945-.945H17.77v.002zm0-1.885h5.652a2.827 2.827 0 0 1 2.828 2.828v5.652a2.828 2.828 0 0 1-2.828 2.829H17.77a2.828 2.828 0 0 1-2.829-2.829V4.578A2.828 2.828 0 0 1 17.77 1.75zM4.578 16.828a.941.941 0 0 0-.945.942v5.652c0 .521.423.945.945.945h5.652a.945.945 0 0 0 .944-.945V17.77a.942.942 0 0 0-.944-.944H4.578v.002zm0-1.886h5.652a2.828 2.828 0 0 1 2.829 2.828v5.652a2.828 2.828 0 0 1-2.829 2.828H4.578a2.828 2.828 0 0 1-2.828-2.828V17.77a2.828 2.828 0 0 1 2.828-2.829zm13.192 1.886a.942.942 0 0 0-.944.942v5.652c0 .521.424.945.944.945h5.652a.945.945 0 0 0 .945-.945V17.77a.942.942 0 0 0-.945-.944H17.77v.002zm0-1.886h5.652a2.828 2.828 0 0 1 2.828 2.828v5.652a2.828 2.828 0 0 1-2.828 2.828H17.77a2.828 2.828 0 0 1-2.829-2.828V17.77a2.828 2.828 0 0 1 2.829-2.829z" />
  </svg>
);
export default SvgFeedO;
