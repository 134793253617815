import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgCopy = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M24.938 7c.364 0 .673.128.929.383a1.265 1.265 0 0 1 .383.93v16.624a1.265 1.265 0 0 1-.383.93 1.266 1.266 0 0 1-.93.383H11.814a1.265 1.265 0 0 1-.93-.383 1.265 1.265 0 0 1-.383-.93V21H3.063a1.265 1.265 0 0 1-.93-.383 1.266 1.266 0 0 1-.383-.93V10.5c0-.364.091-.767.273-1.204.182-.438.403-.782.656-1.038l5.581-5.58c.256-.256.6-.473 1.038-.657.437-.18.84-.271 1.202-.271h5.688c.364 0 .673.128.929.383a1.265 1.265 0 0 1 .383.93v4.485C18.12 7.182 18.704 7 19.25 7h5.688zM17.5 9.912 13.412 14H17.5V9.912zm-8.75-5.25L4.662 8.75H8.75V4.662zm2.68 8.848 4.32-4.322V3.5H10.5v5.688a1.265 1.265 0 0 1-.383.929 1.266 1.266 0 0 1-.93.383H3.5v8.75h7v-3.5c0-.364.091-.767.273-1.204.182-.438.403-.782.656-1.038v.002zM24.5 24.5V8.75h-5.25v5.688a1.265 1.265 0 0 1-.383.929 1.266 1.266 0 0 1-.93.383H12.25v8.75H24.5z" />
  </svg>
);
export default SvgCopy;
