/* eslint-disable reverb-design-system/prefer-components-to-classname */
import React from 'react';
import classNames from 'classnames';
import I18n from 'i18n-js';

type Size = 'default' | 'small' | 'large' | 'fluid';

interface IProps {
  /**
   * The size of the loading bar image. Use `fluid` to fill the entire width of the container.
   */
  size?: Size;

  /**
   * Invert the bar color for use on contrasted backgrounds (such as buttons or some overlays).
  */
  invertColor?: boolean;
}

/**
 * An animated loading indicator.
 * We don't often need to display one, but when you need to show that a section is loading and can't use placeholders with our `pulse` animation,
 * center a loading bar over the given area.
 */
export function RCLoadingBars({ size = 'default', invertColor = false }: IProps) {
  const classes = classNames('rc-loading-bars', {
    'rc-loading-bars--small': size === 'small',
    'rc-loading-bars--large': size === 'large',
    'rc-loading-bars--fluid': size === 'fluid',
    'rc-loading-bars--invert-color': invertColor,
  });

  return (
    <div
      className={classes}
      role="img"
      aria-label={I18n.t('cadence.RCLoadingBars.ariaLabel')}
    >
      <div className="rc-loading-bars__inner">
        {[...Array(size === 'small' ? 3 : 5)].map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} className="rc-loading-bars__bar" />
        ))}
      </div>
    </div>
  );
}
