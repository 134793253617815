/* eslint-disable reverb-design-system/prefer-components-to-classname */
import React from 'react';
import classNames from 'classnames';

import { TagVariant } from '../types';

interface IRCTagProps {
  /**
   * The color variant, used to represent intent:
   *
   * - `default`: a standard gray tag used mainly for optional/required labels in forms.
   * - `highlight`: higher-contrast tag, use sparingly for cases where standard gray isn't apparent enough.
   * - `notification`: use this only for `NEW` callouts and counts (e.g. cart count).
   */
  variant?: TagVariant;

  /** Minimizes padding; use when space is tight (like displaying the count on something). */
  small?: boolean;

  /** The content of the tag. */
  children: React.ReactNode;
}

/**
 * A little pip to call out a status.
 * Most commonly used within our form elements to denote a field as optional or required, but available here for ad-hoc usage.
 */
export function RCTag({
  variant = 'default',
  small = false,
  children,
}: IRCTagProps) {
  const classes = classNames(
    'rc-tag',
    { 'rc-tag--highlight': variant === 'highlight' },
    { 'rc-tag--notification': variant === 'notification' },
    { 'rc-tag--success': variant === 'success' },
    { 'rc-tag--small': small },
  );

  return (
    <div className={classes}>
      {children}
    </div>
  );
}
