import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgAndroid = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10.703 6.537a.61.61 0 0 0 0-1.219.607.607 0 0 0-.594.61c0 .327.266.609.594.609Zm6.594 0a.607.607 0 0 0 .594-.61.607.607 0 0 0-.594-.609.61.61 0 0 0 0 1.219ZM4.609 9.412a1.6 1.6 0 0 1 1.594 1.593v6.72c0 .89-.703 1.609-1.594 1.609-.89 0-1.609-.72-1.609-1.61v-6.719c0-.875.719-1.593 1.61-1.593Zm16.563.297v10.406a1.71 1.71 0 0 1-1.703 1.719h-1.172v3.547c0 .89-.719 1.609-1.61 1.609-.89 0-1.609-.719-1.609-1.61v-3.546h-2.156v3.547c0 .89-.719 1.609-1.61 1.609a1.604 1.604 0 0 1-1.593-1.61l-.016-3.546H8.547a1.714 1.714 0 0 1-1.719-1.72V9.71h14.344ZM17.547 3.38c2.187 1.125 3.672 3.282 3.672 5.766H6.766c0-2.484 1.484-4.64 3.687-5.766l-1.11-2.046a.228.228 0 0 1 .079-.313c.11-.047.25-.016.312.094l1.125 2.062A7.739 7.739 0 0 1 14 2.521a7.73 7.73 0 0 1 3.14.656l1.126-2.062c.062-.11.203-.14.312-.094.11.063.14.203.078.313l-1.11 2.046ZM25 11.005v6.72c0 .89-.719 1.609-1.61 1.609a1.604 1.604 0 0 1-1.593-1.61v-6.719c0-.89.719-1.593 1.594-1.593.89 0 1.609.703 1.609 1.593Z" />
  </svg>
);
export default SvgAndroid;
