/**
 * README
 * This is an "internal" component meant to be used solely by other RC components.
 * Please don't use this directly. If it seems like you'd benefit from it please
 * reach out to #cadence for help coordinating!
 */
import React, { cloneElement, useEffect } from 'react';
import classNames from 'classnames';
import {
  arrow,
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  useClick,
  limitShift,
} from '@floating-ui/react';
// framer-motion v7+ requires React 18+ (we're on 17) so the options here VS their docs might be off until that can happen
/* eslint-disable no-restricted-imports */
import { motion, AnimatePresence } from 'framer-motion';
import mergeRefs from 'react-merge-refs';
import { FLOATING_UI_DOM_ID, PANEL_DEFAULTS } from '@reverbdotcom/cadence/constants';

type FloatingUIPositions = 'bottom' | 'bottom-start' | 'bottom-end' | 'right';

interface RCNonModalDialogBaseProps {
  id: string,
  isOpen?: boolean,
  onOpenChange?: (open: boolean) => void,
  sticky?: boolean,
  position?: FloatingUIPositions,
  anchor: JSX.Element,
  children,

  /** Overrides base functionality to suit specific purposes
   * - Guide Popover: Adds arrow and delays animate in
   * - Legacy: temporary variant that does not automatically add a click handler to support legacy instances
   */
  variant?: 'default' | 'guide-popover' | 'legacy';
  dismissOnOutsidePress?: boolean;
  focusOrder?: Array<'reference' | 'floating' | 'content'>;
  trapFocus?: boolean;
  /** Index of the focusOrder array that should receive initial focus. Defaults to 0. Use -1 to prevent initial focus. */
  initialFocus?: -1 | 0 | 1 | 2;
}

function ariaID(id) {
  return {
    label: `${id}-label`,
    description: `${id}-description`,
  };
}

function framerMotionConfig(overrides = {}) {
  return {
    // More: https://www.framer.com/docs/transition
    initial: {
      opacity: 0,
      y: '-0.5rem',
    },
    animate: {
      opacity: 1,
      y: 0,
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    transition: {
      duration: 0.25,
      ease: 'easeOut',
    },
    ...overrides,
  };
}

function arrowSide(panelPlacement) {
  // the side of the anchor that the panel sits on comes from `placement`
  const [ panelSide ] = panelPlacement.split('-');

  // the arrow sits on that opposite side, so we remap it here.
  const arrowSideMapping = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  };

  return arrowSideMapping[panelSide];
}

function arrowPositionStyles(arrowData, panelPlacement) {
  return {
    top: arrowData?.y && `${arrowData?.y}px`,
    left: `${arrowData?.x}px`,

    // override defaults above based on position
    [arrowSide(panelPlacement)]: `-${PANEL_DEFAULTS.arrowHeight}px`,
  };
}

export function RCNonModalDialogBase({
  id,
  isOpen: openProp = undefined,
  onOpenChange: setOpenProp = undefined,
  sticky = false,
  position = 'bottom-start',
  anchor,
  children,
  variant = 'default',
  dismissOnOutsidePress = true,
  focusOrder = ['content'],
  trapFocus = true,
  initialFocus = 0,
}: RCNonModalDialogBaseProps) {
  const arrowRef = React.useRef(null);
  const labelId = ariaID(id).label;
  const descriptionId = ariaID(id).description;

  const [loaded, setLoaded] = React.useState(false);
  const [openState, setOpenState] = React.useState<boolean>(openProp || false);

  const isControlled = openProp !== undefined && setOpenProp !== undefined;
  const open = isControlled ? openProp : openState;
  const setOpen = isControlled ? setOpenProp : setOpenState;

  // Await client side render
  useEffect(() => {
    setLoaded(true);
  }, []);

  function handleOpenChange(state: boolean) {
    setOpen(state);
    if (!isControlled) {
      setOpenProp?.(state);
    }
  }

  // Main config for libs "useFloating"
  const {
    x,
    y,
    refs,
    strategy,
    context,
    update,
    placement,
    middlewareData,
  } = useFloating({
    open,
    onOpenChange: handleOpenChange,
    middleware: [
      offset(PANEL_DEFAULTS.anchorOffset),
      flip(),
      shift({
        padding: {
          top: PANEL_DEFAULTS.arrowHeight,
          right: PANEL_DEFAULTS.windowEdgePadding,
          bottom: PANEL_DEFAULTS.windowEdgePadding,
          left: PANEL_DEFAULTS.windowEdgePadding,
        },
        mainAxis: true,
        crossAxis: true,
        limiter: sticky ? undefined : limitShift(),
      }),
      arrow({ element: arrowRef }),
    ],
    placement: position,
    whileElementsMounted: (r, f, u) => {
      // We only need to auto update if it's possible to do so
      let cleanup = undefined;
      if (('ResizeObserver' in window)) {
        cleanup = autoUpdate(r, f, u);
      }
      return cleanup;
    },
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useRole(context, {
      role: 'dialog', // this should be changed when this does more than a popover-like thing.
    }),
    useClick(context, {
      enabled: variant === 'legacy' ? false : true,
      toggle: true,
    }),
    useDismiss(context, {
      enabled: true,
      escapeKey: true,
      outsidePressEvent: 'mousedown',
      outsidePress: dismissOnOutsidePress,
    }),
  ]);

  // Preserve the consumer's ref
  const anchorRef = mergeRefs([refs.setReference, (anchor as any).ref]);

  const arrowCallback = React.useCallback(
    (node) => {
      arrowRef.current = node;
      update();
    },
    [update],
  );

  const classes = classNames(
    'rc-non-modal-dialog-base',
    { 'rc-non-modal-dialog-base--guide-popover': variant === 'guide-popover' },
  );

  if (anchor.type === React.Fragment) {
    // Fragments will render to the screen, but they lose their ref and the Popover UIs won't know what to anchor
    // themselves to, and the panel will end up in the top-left corner of the screen.
    throw new Error('Pass a single element to the `anchor` prop, Fragments or other arrays are not supported.');
  }

  return (
    <>
      {cloneElement(anchor, {
        'data-floating-ui-is-open': open,
        ...getReferenceProps({ ref: anchorRef, ...anchor.props }),
      })}

      {loaded &&
        <AnimatePresence>
          {open && (
            <FloatingPortal id={FLOATING_UI_DOM_ID}>
              <FloatingFocusManager
                context={context}
                modal={trapFocus}
                order={focusOrder}
                returnFocus
                initialFocus={initialFocus}
              >
                <motion.div
                  ref={refs.setFloating}
                  className={classes}
                  style={{
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0,
                  }}
                  aria-labelledby={labelId}
                  aria-describedby={descriptionId}
                  {...framerMotionConfig(variant === 'guide-popover' && { transition: { delay: 1.5 } })}
                  {...getFloatingProps()}
                >
                  {children}
                  {variant === 'guide-popover' &&
                    <div
                      className={`rc-non-modal-dialog-base__arrow rc-non-modal-dialog-base__arrow--${arrowSide(placement)}`}
                      ref={arrowCallback}
                      style={arrowPositionStyles(middlewareData?.arrow, placement)}
                    />
                  }
                </motion.div>
              </FloatingFocusManager>
            </FloatingPortal>
          )}
        </AnimatePresence>
      }
    </>
  );
}
