import classNames from 'classnames';

export const BUTTON_THEMES = ['main', 'danger', 'loud'] as const;
export const BUTTON_SIZES = ['large', 'medium', 'small', 'mini'] as const;
export const BUTTON_VARIANTS = ['filled', 'outlined', 'muted', 'transparent'] as const;

export type ButtonSize = typeof BUTTON_SIZES[number];
export type ButtonTheme = typeof BUTTON_THEMES[number];
export type ButtonVariant = typeof BUTTON_VARIANTS[number];

export interface ButtonStyling {
  /**
   * The hierarchy level of the button.
   *
   * - __Filled:__ A primary CTA; should be used sparingly for key actions like adding to cart or submitting a form.
   * - __Outlined:__ Renders an outlined button. Generally should be used alongside or below a Primary action.
   * - __Muted:__ A light grey button. Use this for minor actions like `show more` toggles or other in-page transitions.
   * - __Transparent:__ Renders no background except on hover. Use this for situations where you'd link the button to appear as a link,
   *    but you want it to take up the usual space of a button.
   */
  variant?: ButtonVariant;

  /**
   * The primary button color scheme.
   * - __Main:__ Gray. Our main theme for the majority of buttons.
   * - __Loud:__ VU-Orange. Should be used sparingly.
   * - __Danger:__ Red. Should be used for destructive actions.
   */
  theme?: ButtonTheme;

  /**
   * The size of the button. Use `small` and `mini` sparingly.
   */
  size?: ButtonSize;

  /** Sets `width: 100%` in CSS so the button always fills its available space. */
  fullWidth?: boolean;

  /** Inverts the button colors for use on dark backgrounds. */
  inverted?: boolean;
}

export const DEFAULT_SIZE = 'medium';
export const DEFAULT_VARIANT = 'muted';
export const DEFAULT_THEME = 'main';

/**
 * Function to generate className string to style any HTML element like an RCButton.
 * This is mainly for use on CoreLinks.
 *
 * @example
 * ```
 * <CoreLink
 *   to={something}
 *   className={buttonClassName({ variant: 'filled' })}
 * >
 *   I look like a button
 * </CoreLink>
 * ```
 */
export function buttonClassName({
  variant = DEFAULT_VARIANT,
  theme = DEFAULT_THEME,
  size = DEFAULT_SIZE,
  inverted,
  fullWidth,
}: ButtonStyling): string {
  return classNames(
    'rc-button',
    {
      // color and state modifiers
      'rc-button--filled': variant === 'filled',
      'rc-button--outlined': variant === 'outlined',
      'rc-button--muted': variant === 'muted',
      'rc-button--transparent': variant === 'transparent',

      // themes
      'rc-button--main': theme === 'main',
      'rc-button--danger': theme === 'danger',
      'rc-button--loud': theme === 'loud',

      // size modifiers
      'rc-button--large': size === 'large',
      'rc-button--medium': size === 'medium',
      'rc-button--small': size === 'small',
      'rc-button--mini': size === 'mini',

      // display modifiers
      'rc-button--inverted': inverted,
      'rc-button--full-width': fullWidth,
    },
  );
}
