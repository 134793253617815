import React from 'react';
import classNames from 'classnames';

import { RadioImpl } from '../RCRadioGroup/RadioImpl';
import type { IRCRadioProps } from '../RCRadioGroup/RadioImpl';

interface Props extends IRCRadioProps {
  /** Additional markup below the label/sublabel */
  children?: React.ReactNode;
}

/**
 * Internal component for rendering radio options as cards, you should never need to use this directly.
 * See RCRadioGroup
 */
export function RadioCardImpl({
  checked,
  children = undefined,
  disabled,
  id,
  label,
  name,
  onChange,
  sublabel,
  value,
  iconAlignment,
}: Props) {
  const additionalContentID = `rc-radio-card-content-${id}`;
  const validChildren = React.Children.toArray(children).filter(Boolean);
  return (
    <div className={classNames(
      'rc-radio-card',
      { 'rc-radio-card--disabled': disabled },
      { 'rc-radio-card--checked': checked },
    )}>
      <div className="rc-radio-card__main">
        <RadioImpl
          id={id}
          label={label}
          sublabel={sublabel}
          name={name}
          checked={checked}
          value={value}
          onChange={onChange}
          disabled={disabled}
          aria-describedby={additionalContentID}
          iconAlignment={iconAlignment}
        />
      </div>
      {validChildren.length > 0 && (
        <div
          id={additionalContentID}
          className="rc-radio-card__content"
        >
          {validChildren}
        </div>
      )}
    </div>
  );
}
