import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgNudgeGem = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3.25a.75.75 0 0 0-.553.243l-4 4.364a.75.75 0 0 0 .035 1.05l8 7.636a.75.75 0 0 0 1.036 0l8-7.637a.75.75 0 0 0 .035-1.05l-4-4.363A.75.75 0 0 0 14 3.25H6Zm.269 4.614H3.476L6.33 4.75H7.9L6.269 7.864Zm-.021 1H3.61l4.616 4.406-1.978-4.406ZM10 14.779 7.344 8.864h5.312L10 14.779ZM7.398 7.864l1.63-3.114h1.943l1.631 3.114H7.398Zm8.992 1h-2.638l-1.978 4.406 4.616-4.406Zm-2.659-1h2.793L13.67 4.75H12.1l1.631 3.114Z"
    />
  </svg>
);
export default SvgNudgeGem;
