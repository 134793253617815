// Additional Documentation
// Docs: https://github.com/reverbdotcom/docs/blob/master/tech/user-context-provider.md
import React from 'react';
import PropTypes from 'prop-types';

export interface Experiment {
  name: string;
  value: string;
  multivariate_experiment?: boolean;
  use_in_frontend?: boolean;
  use_in_marketplace_listings_search?: boolean;
  multi_client_experiment_context?: string;
}

const user = PropTypes.shape({
  email: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.number,
  isAdmin: PropTypes.bool,
  isActivated: PropTypes.bool,
  isBusiness: PropTypes.bool,
  preferredSeller: PropTypes.bool,
  isEligibleForSEOCache: PropTypes.bool,
  isBot: PropTypes.bool,
  loggedOut: PropTypes.bool,
  cookieId: PropTypes.string,
  requestId: PropTypes.string,
  country: PropTypes.string,
  countryCode: PropTypes.string,
  stateCode: PropTypes.string,
  shippingRegionCode: PropTypes.string,
  profileSlug: PropTypes.string,
  locale: PropTypes.string,
  fiveDigitLocale: PropTypes.string,
  gdpr: PropTypes.bool,
  deviceName: PropTypes.string,
  experiments: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    use_in_frontend: PropTypes.bool,
    multivariate_experiment: PropTypes.bool,
    use_in_marketplace_listings_search: PropTypes.bool,
    multi_client_experiment_context: PropTypes.string,
  })),
  currency: PropTypes.string,
  itemRegionOverride: PropTypes.string,
  sessionId: PropTypes.string,
  thirdPartyAdDataAllowed: PropTypes.bool,
  thirdPartyAdStorageAllowed: PropTypes.bool,
  thirdPartyAdPersonalizationAllowed: PropTypes.bool,
  thirdPartyAnalyticsStorageAllowed: PropTypes.bool,
  urlRegion: PropTypes.string,
  requireThirdPartyPrivacyConsent: PropTypes.bool,
});

export interface IUser {
  email: string;
  name: string;
  id: any;
  isAdmin: boolean;
  isActivated: boolean;
  isBusiness: boolean;
  preferredSeller: boolean;
  isEligibleForSEOCache: boolean;
  isBot: boolean;
  loggedOut: boolean;
  cookieId: string;
  requestId?: string;
  country: string;
  countryCode: string;
  stateCode: string;
  shippingRegionCode: string;
  profileSlug: string;
  locale: string;
  /**
   * Locale ID that includes at least the language code and
   * a country code when available based on the user's locale
   * settings.
   *
   * @example <caption>English speaker in the United States</caption>
   * "en-US"
   *
   * @example <caption>English speaker in the United Kingdom</caption>
   * "en-GB"
   *
   * @example <caption>Spanish speaker with a region set to "Everywhere"</caption>
   * "es"
   */
  fiveDigitLocale: string;
  gdpr: boolean;
  deviceName: string;
  deviceAppVersion?: number;
  deviceOSVersion?: string | null;
  experiments: Experiment[];
  currency: string;
  itemRegionOverride?: string;
  sessionId?: string;
  thirdPartyAdDataAllowed: boolean;
  thirdPartyAdStorageAllowed: boolean;
  thirdPartyAdPersonalizationAllowed: boolean;
  thirdPartyAnalyticsStorageAllowed: boolean;
  urlRegion?: string;
  avatarUrl: string;
  postalCode?: string;
  ghost?: boolean; // is the current user an admin logged in as another user?
  secondaryUserModeIsAvailable?: boolean;
  secondaryUserModeIsActive?: boolean;
  managedShopName?: string;
  managedShopUserAvatarUrl?: string;
  managedShopPermission?: string;
  managedShopUserId?: string;
  managedShopId?: string;
  shopId?: string | number;
  requireThirdPartyPrivacyConsent?: boolean;
  shopCountryCode?: string;
  managedShopCountryCode?: string;
  shopCurrency?: string;
  managedShopCurrency?: string;
}

export interface IUserContext {
  user?: IUser;
}

export const UserContext = React.createContext<Partial<IUser>>({});

/**
 * The UserContextProvider injects the current user into your component via React's context API.
 * Any component in the render tree (every component in this repo) can ask for that specific field
 * on the context (called user). To opt a component into using this field, you must use the
 * `withUserContext` HOC.
 *
 * class MyComponent extends React.Component {
 *  render() {
 *    return <p>{this.context.user.name}</p>
 *  }
 * }
 *
 * export default withUserContext(MyComponent);
 */
export class UserContextProvider extends React.Component<IUserContext, {}>
  implements React.ChildContextProvider<IUserContext> {

  static childContextTypes = { user };

  getChildContext() {
    return { user: this.props.user };
  }

  render() {
    return (
      <UserContext.Provider value={this.props.user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

/**
 * HOC: withUserContext adds the current user to your components' props.
 * Previously, we used a decorator, that has been deprecated.
 */
export function withUserContext<TProps extends {}>(
  Component: React.ComponentType<TProps & IUserContext>,
) {
  type IProps = TProps & Partial<IUserContext>;

  const result = class Connected extends React.Component<IProps, null> {
    static displayName = `withUserContext(${Component.displayName || Component.name})`;

    static WrappedComponent = Component;

    static contextTypes = {
      user,
    };

    render() {
      return <Component user={this.context.user} {...this.props} />;
    }
  };

  return result;
}
