import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgPreferredSeller = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={40}
    height={34}
    viewBox="0 0 40 34"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m0 11.08 1.92 6-1.92 6h7.2v-12H0ZM39.97 11l-1.93 6 1.93 6h-7.2V11h7.2Z"
      fillOpacity={0.65}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35 9.85v14.3a2.91 2.91 0 0 1-1.38 2.37l-12.26 7.14a2.9 2.9 0 0 1-2.73 0L6.36 26.52C5.58 26.06 5 25.03 5 24.15V9.85a2.96 2.96 0 0 1 1.36-2.37L18.64.34a2.9 2.9 0 0 1 2.73 0l12.27 7.14c.78.46 1.36 1.49 1.36 2.37ZM23.91 24.88c2.14-.03 3.4-1.98 3.4-1.98v-.01s.37-.6.07-1.23v-.02s-.4.88-1.4.88c-.98 0-1.78-1.52-2.24-3.28-.297-1.13-.753-1.635-1.033-1.945-.154-.17-.254-.281-.247-.405 0-.185.1-.188.285-.193.145-.004.343-.01.585-.107l.067-.026c.703-.267 3.833-1.457 3.833-4.874 0-3.55-3.78-3.69-5.47-3.69-1.68 0-10.9.3-11.25 6.67-.1 1.9 1 2.8 2.5 2.8s3.15-1.2 3.08-2.81c0 0-1.91 1.07-1.91-1.07 0-2.15 2.68-4.12 4.79-4.25 0 0-.26.58-.64 1.65-.35 1.07-3 10.55-3.4 12.55-.27 1.32.12 1.48.58 1.45.094-.008.261-.004.47 0 .81.02 2.234.053 2.29-.56.081-.975.225-1.944.43-2.9.117-.567.343-1.468.55-2.296.184-.734.354-1.41.42-1.744.08-.41.51-.2.71.07.2.27.67 1.03.6 2.43-.09 1.4-.02 4.93 2.93 4.89ZM22.48 9.1c.1-.08 1.76.5 1.56 2.4v.01c-.516 4.592-3.616 4.475-4.01 4.46a.221.221 0 0 0-.04 0c-.134 0-.004-.416.21-1.104l.08-.256c1.02-3.37 1.75-5.03 1.84-5.2a.833.833 0 0 1 .326-.29l.034-.02Z"
    />
  </svg>
);
export default SvgPreferredSeller;
