import UUID from 'uuid';
import qs from 'qs';
import type { AnalyticsQueryParams } from './index';
import type { UserContext } from '../schemas/analytics';
import { setSession, getSession } from './analytics-cookie';
import window from '../window_wrapper';

const THIRTY_MINUTES_IN_MILLIS = 1800000;

function removeAnchor(url: string) {
  if (!url) { return ''; }

  return url.split('#')[0]; // remove anchor
}

export class Session {
  _sessionID: string;

  _pageViewID: string;

  _lastPageVisited: Partial<Location>;

  _currentReferer: string;

  _location: Partial<Location>;

  _userContext: Partial<UserContext>;

  constructor(userContext, loc = window.location) {
    const lastSession = getSession();
    this._sessionID = lastSession.sessionID;
    this._location = { ...loc };
    this._userContext = userContext;
    userContext.session_id = this._sessionID;
  }

  touch(loc: Partial<Location> = window.location, doc: Partial<Document> = document) {
    if (this._lastPageVisited) {
      this._lastPageVisited = { ...this._location };
    } else {
      this._lastPageVisited = { href: doc.referrer };
    }

    this._location = { ...loc };
    this._pageViewID = UUID.v4();
    this._sessionID = this.touchSession();

    return this._sessionID;
  }

  touchSession() {
    const now = new Date().getTime();
    const { lastSeen, sessionID } = getSession();

    if (!lastSeen) {
      return this.start(now);
    }

    const age = now - lastSeen;
    if (age > THIRTY_MINUTES_IN_MILLIS) {
      return this.start(now);
    }

    this.setSessionID(sessionID, now);
    return this._sessionID;
  }

  start(now) {
    const id = UUID.v4();
    this.setSessionID(id, now);

    return id;
  }

  setSessionID(sessionID, now) {
    this._userContext.session_id = sessionID;
    setSession(sessionID, now);
  }

  setRequestID(requestID: string) {
    this._userContext.request_id = requestID;
  }

  requestId() {
    return this._userContext.request_id;
  }

  cookieId() {
    return this._userContext.cookie_id;
  }

  deviceName() {
    return this._userContext.device?.name;
  }

  url() {
    return removeAnchor(this._location.href);
  }

  query() {
    return qs.parse(this._location.search, { ignoreQueryPrefix: true }) as AnalyticsQueryParams;
  }

  ID() {
    return this._sessionID;
  }

  referer(doc: Partial<Document> = document) {
    if (!this._lastPageVisited) {
      return removeAnchor(doc.referrer);
    }

    return removeAnchor(this._lastPageVisited.href);
  }

  pageViewID() {
    return this._pageViewID;
  }
}
