import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgMicrophone = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M19.095 3.881a4.667 4.667 0 0 0-4.68 4.76L4.55 18.518l1.703 2.115 5.925-3.792.266 8.047a.677.677 0 0 0 .677.661h.731a.677.677 0 0 0 .653-.661l.194-9.66 3.333-2.134a4.666 4.666 0 0 0 3.067-8.755 4.628 4.628 0 0 0-2.005-.451V3.88zm0 1.167A3.477 3.477 0 1 1 19.122 12a3.477 3.477 0 0 1-.027-6.953z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMicrophone;
