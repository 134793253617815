import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgPencil = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="m7.672 24 1.422-1.422-3.672-3.672L4 20.328V22h2v2h1.672zm8.172-14.5c0-.23-.115-.344-.344-.344a.362.362 0 0 0-.266.11l-8.468 8.468a.362.362 0 0 0-.11.266c0 .23.115.344.344.344a.362.362 0 0 0 .266-.11l8.468-8.468a.362.362 0 0 0 .11-.266zM15 6.5l6.5 6.5-13 13H2v-6.5l13-13zM25.672 8c0 .552-.193 1.02-.578 1.406L22.5 12 16 5.5l2.594-2.578A1.857 1.857 0 0 1 20 2.328a1.94 1.94 0 0 1 1.422.594l3.672 3.656c.385.406.578.88.578 1.422z" />
  </svg>
);
export default SvgPencil;
