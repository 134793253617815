import React, { useRef } from 'react';
import { useId } from '@reach/auto-id';

import { RCFormGroup } from '../..';
import { CommonInputProps } from '../../types';
import { useMergeRefs } from '@floating-ui/react';

export type InputMode = 'email' | 'tel' | 'text' | 'search' | 'url' | 'none' | 'numeric' | 'decimal';

export interface TextInputProps extends CommonInputProps {
  /** Hint to browser about the content of the field. See https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values */
  autoComplete?: string;

  /** Called when the input element loses focus. */
  onBlur?: React.FocusEventHandler<HTMLInputElement>;

  /** Called when a key on the keyboard is pressed. */
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;

  /** Current value of the input. Passed through to the input tag. */
  value?: string;

  /**
   * This component only supports "text-like" inputs, other `<input>`s are rendered by different components.
   * See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types) for details on HTML input types
   */
  type?: 'text' | 'email' | 'number' | 'date'; // TODO: are these useful and similar enough to be variants? 'search' | 'url' |  'password' | 'tel'

  /**
   * Regex to validate input value against
   * See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/pattern) for details
  */
  pattern?: string;

  /** Text that appears when the value is empty. Passed through to the input tag. */
  placeholder?: string;

  /** Static text displayed inline before the input. Useful for currency symbols. */
  prefixText?: string;

  /** Static text displayed inline after the input.
   * Useful for "plus shipping", numerical units, or other context. Limit to a few characters at most. */
  suffixText?: string;

  /** Minimum character length for value. Passed through to the input tag. */
  minLength?: number;

  /** Maximum character length for value. Passed through to the input tag. */
  maxLength?: number;

  /** Large variant. Use this for simpler forms. */
  large?: boolean;

  /**
   * A hint for virtual keyboards about what character set might be needed (e.g. native app prompting number pad for price input).
   * See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode) for details
  */
  inputMode?: InputMode;

  /** Additional context to add to certain fields if the value + prefixText + suffixText is insufficient to understanding what the field is for */
  'aria-label'?: string;
}

/**
 * Our universal text input, with many options. If you need an input for money, email, or password input, use those components instead.
 */
export const RCTextInput = React.forwardRef<HTMLInputElement, TextInputProps>(({
  'aria-label': ariaLabel,
  autoComplete = undefined,
  disabled,
  errorText,
  helpText,
  id: compID,
  inputMode = undefined,
  label,
  large = false,
  maxLength = undefined,
  minLength = undefined,
  name,
  onBlur = undefined,
  onChange,
  onKeyPress = undefined,
  pattern = undefined,
  placeholder = undefined,
  prefixText = undefined,
  required,
  suffixText = undefined,
  tag,
  type = 'text',
  value = undefined,
}: TextInputProps, forwardedRef) => {
  const autoID = useId();
  const id = compID || autoID;
  const innerRef = useRef<HTMLInputElement>(null);

  const mergedRef = useMergeRefs([
    forwardedRef,
    innerRef,
  ]);

  // On most browsers, a focused and hovered number input will change value when
  // the user scrolls their mouse wheel. This can cause some unintentional
  // price/number changes and the `blur` prevents this from happening.
  function preventNumberScrolling(event) {
    if (type !== 'number') return null;

    return (event.target as HTMLElement).blur();
  }

  function determineDescriptiveTag() {
    const descriptiveElementIDs = [
      prefixText && `rc-text-input-${id}-prefix-text`,
      suffixText && `rc-text-input-${id}-suffix-text`,
    ];

    return descriptiveElementIDs?.join(' ').trim() || undefined;
  }

  function onTagClick() {
    innerRef?.current?.focus();
  }

  return (
    <RCFormGroup
      errorText={errorText}
      helpText={helpText}
      inputId={id}
      label={label}
      tag={tag}
      large={large}
    >
      {
        /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
        /* Click handler is a progressive enhancement only needed for mouse users */
      }
      <div
        className="rc-text-input__group"
        onClick={onTagClick}
      >
        {/* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        {prefixText &&
          <div
            className="rc-text-input__text"
            id={`rc-text-input-${id}-prefix-text`}
          >
            {prefixText}
          </div>
        }
        <input
          className="rc-text-input__input"
          autoComplete={autoComplete}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          type={type}
          pattern={pattern}
          value={value}
          onKeyPress={onKeyPress}
          minLength={minLength}
          maxLength={maxLength}
          onWheel={event => preventNumberScrolling(event)}
          inputMode={inputMode}
          aria-describedby={determineDescriptiveTag()}
          aria-label={ariaLabel}
          ref={mergedRef}
        />
        {suffixText &&
          <div
            className="rc-text-input__text"
            id={`rc-text-input-${id}-suffix-text`}
          >
            {suffixText}
          </div>
        }
      </div>
    </RCFormGroup>
  );
});

// Explicitly setting displayName to help with debugging and tracing an otherwise anonymous function.
RCTextInput.displayName = 'RCTextInput';
