import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgClockO = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M16 8.5v7a.487.487 0 0 1-.14.36.487.487 0 0 1-.36.14h-5a.487.487 0 0 1-.36-.14.487.487 0 0 1-.14-.36v-1a.49.49 0 0 1 .14-.36.487.487 0 0 1 .36-.14H14V8.5a.49.49 0 0 1 .14-.36.487.487 0 0 1 .36-.14h1a.49.49 0 0 1 .36.14.49.49 0 0 1 .14.36zm6.5 5.5c0-1.542-.38-2.963-1.14-4.266a8.473 8.473 0 0 0-3.094-3.093A8.308 8.308 0 0 0 14 5.5c-1.542 0-2.963.38-4.266 1.14a8.473 8.473 0 0 0-3.093 3.094A8.308 8.308 0 0 0 5.5 14c0 1.542.38 2.963 1.14 4.266a8.473 8.473 0 0 0 3.094 3.093A8.308 8.308 0 0 0 14 22.5c1.542 0 2.963-.38 4.266-1.14a8.473 8.473 0 0 0 3.093-3.094A8.308 8.308 0 0 0 22.5 14zm3.5 0c0 2.177-.537 4.185-1.61 6.023a11.945 11.945 0 0 1-4.367 4.368C18.185 25.464 16.177 26 14 26c-2.177 0-4.185-.537-6.023-1.61a11.945 11.945 0 0 1-4.368-4.367C2.536 18.185 2 16.177 2 14c0-2.177.536-4.185 1.61-6.023a11.946 11.946 0 0 1 4.367-4.368C9.815 2.536 11.823 2 14 2c2.177 0 4.185.536 6.023 1.61a11.945 11.945 0 0 1 4.368 4.367C25.464 9.815 26 11.823 26 14z" />
  </svg>
);
export default SvgClockO;
