import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgFacebook = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M26 14c0-6.627-5.373-12-12-12S2 7.373 2 14c0 5.99 4.388 10.954 10.125 11.854V17.47H9.078V14h3.047v-2.644c0-3.007 1.791-4.668 4.533-4.668 1.313 0 2.686.234 2.686.234v2.953H17.83c-1.491 0-1.956.925-1.956 1.874V14h3.328l-.532 3.469h-2.796v8.385C21.612 24.954 26 19.99 26 14Z" />
  </svg>
);
export default SvgFacebook;
