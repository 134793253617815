import classNames from 'classnames';
import React from 'react';

interface RCCardProps {
  /**
   * Sets `height: 100%`. Use this if you're rendering a set of `RCCard`s in a flex/grid row to ensure they all stay the same height relative to one another.
  */
  fullHeight?: boolean;
  children: React.ReactNode;
  /**
   * Pass a token name here to override the background color. Optional; defaults to our component token.
  */
  bgColorToken?: string;
  /**
   * Pass a token name here to override the hovered background color. Optional; defaults to our component token.
  */
  bgColorHoverToken?: string;
}

/**
 * RCCard is a lightweight wrapper that allows for easy implementation of a clickable card-shaped wrapper UI.
 *
 * Note that because of the way we use `CoreLink`, you need to use `RCCard.LinkWrapper` as a direct child to make the component properly linked & styled (see example source code, which uses a simple `<a>` tag but provides the correct structure).
 */
export function RCCard({
  fullHeight = false,
  children,
  bgColorToken = 'rc-color-background-card',
  bgColorHoverToken = 'rc-color-background-card-hovered',
}: RCCardProps) {
  const bgColors = {
    '--bg-color': `var(--${bgColorToken})`,
    '--bg-color-hover': `var(--${bgColorHoverToken})`,
  } as React.CSSProperties;

  return (
    <div
      className={classNames(
        'rc-card',
        {
          'rc-card--full-height': fullHeight,
        },
      )}
      style={bgColors}
    >
      {children}
    </div>
  );
}

interface RCCardLinkProps {
  children: React.ReactElement<HTMLAnchorElement>;
}

function LinkWrapper({ children }: RCCardLinkProps) {
  return React.cloneElement(children, {
    className: ['rc-card__link', children.props.className].join(' '),
  });
}

RCCard.LinkWrapper = LinkWrapper;
