import React from 'react';
import classNames from 'classnames';

import { RCTooltip } from '../RCTooltip/RCTooltip';
import { FloatingDelayGroup } from '@floating-ui/react';

interface RCSegmentedControlProps {
  /** Should be `<RCSegmentedControl.Button />` components only. */
  children: React.ReactElement<ButtonProps>[];

  /** Render at full page width. (Defaults to width of contents.) */
  fullWidth?: boolean;

  /** Use during content reloads  */
  loading?: boolean;

  /** High-contrast style. Use this when you'd like this component to stand out from a colored background; don't use it on white. */
  highContrast?: boolean;

  /**
   * These sizes are set up to replicate our RCButton sizes. Defaults to 'medium'.
  */
  size?: 'medium' | 'small';

  /**
   * Used to describe the element to screen readers.
   * Note: All controls should include either a label or be labelled by another element
  */
  'aria-label'?: string;

  /** Optionally describe this component using the id of a text element on the page */
  'aria-labelledby'?: string;
}

/**
* A button control group that always contains a single selected state.
*
* Best for controls that contain 2-3 options; keep in mind this control does not wrap to a new line.
*
* You can use icons in place of text in the control, but make sure to implement the `aria` props if you do so.',
**/
export function RCSegmentedControl({
  children,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  fullWidth = false,
  loading = false,
  size = 'medium',
  highContrast = false,
}: RCSegmentedControlProps) {
  return (
    <div
      className={classNames(
        'rc-segmented-control',
        { 'rc-segmented-control--full-width': fullWidth },
        { 'rc-segmented-control--loading': loading },
        { 'rc-segmented-control--small': size === 'small' },
        { 'rc-segmented-control--high-contrast': highContrast },
      )}
      role="group"
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
    >
      <FloatingDelayGroup delay={{ open: 500, close: 100 }}>
        {children}
      </FloatingDelayGroup>
    </div>
  );
}

interface ButtonProps {
  /** Button contents. Generally text or an RCIcon. */
  children: React.ReactNode;

  /** Callback function when this button is clicked */
  onClick: () => void;

  /** Optional prop to be used when displaying only an RCIcon. Slightly increases icon size */
  iconOnly?: boolean;

  /** Identifies this as the current item, both visually and to screen readers */
  selected?: boolean,

  /** Optional aria label. Use this if the button is iconographic. */
  'aria-label'?: string;
}

function Button({
  onClick,
  children,
  iconOnly = false,
  selected = false,
  'aria-label': ariaLabel,
}: ButtonProps) {

  const button = (
    <button
      type="button"
      className={classNames(
        'rc-segmented-control__button',
        { 'rc-segmented-control__button--icon-only': iconOnly },
        { 'rc-segmented-control__button--selected': selected },
      )}
      onClick={onClick}
      aria-current={selected}
      aria-label={ariaLabel}
    >
      <span className="rc-segmented-control__button__content">
        {children}
      </span>
    </button>
  );

  if (!iconOnly || !ariaLabel) return button;

  return (
    <RCTooltip
      text={ariaLabel}
      style="buttonLabel"
      placement="bottom"
      visualOnly
    >
      {button}
    </RCTooltip>
  );
}

RCSegmentedControl.Button = Button;

function Skeleton() {
  return (
    <div
      className="rc-segmented-control--loading"
      role="group"
      aria-label="ariaLabel"
      aria-labelledby="ariaLabelledBy"
    />
  );
}

RCSegmentedControl.Skeleton = Skeleton;
