// Shipping region codes
export const EVERYWHERE_CODE = 'XX';
export const US_CON = 'US_CON';
export const US_AK = 'US_AK';
export const US_HI = 'US_HI';
export const US_PR = 'US_PR';
export const US_OTHER = 'US_OTHER';

// Country codes
export const US = 'US';
export const UK = 'GB';
export const AU = 'AU';
export const CA = 'CA';

export const COUNTRY_CODES = {
  US,
  UK,
  AU,
  CA,
};

export const AFFIRM_SUPPORTED_SHIPPING_REGIONS = [
  EVERYWHERE_CODE,
  US_CON,
  US_AK,
  US_HI,
  US_PR,
  US_OTHER,
  CA,
];

export const AFFIRM_SUPPORTED_ZERO_PERCENT_FINANCING_REGIONS = [
  EVERYWHERE_CODE,
  US_CON,
  US_AK,
  US_HI,
  US_PR,
  US_OTHER,
];

// US Con state codes
export const US_AL = 'AL';
export const US_AZ = 'AZ';
export const US_AR = 'AR';
export const US_CA = 'CA';
export const US_CO = 'CO';
export const US_CT = 'CT';
export const US_DC = 'DC';
export const US_DE = 'DE';
export const US_FL = 'FL';
export const US_GA = 'GA';
export const US_ID = 'ID';
export const US_IL = 'IL';
export const US_IN = 'IN';
export const US_IA = 'IA';
export const US_KS = 'KS';
export const US_KY = 'KY';
export const US_LA = 'LA';
export const US_ME = 'ME';
export const US_MD = 'MD';
export const US_MA = 'MA';
export const US_MI = 'MI';
export const US_MN = 'MN';
export const US_MS = 'MS';
export const US_MO = 'MO';
export const US_MT = 'MT';
export const US_NE = 'NE';
export const US_NV = 'NV';
export const US_NH = 'NH';
export const US_NJ = 'NJ';
export const US_NM = 'NM';
export const US_NY = 'NY';
export const US_NC = 'NC';
export const US_ND = 'ND';
export const US_OH = 'OH';
export const US_OK = 'OK';
export const US_OR = 'OR';
export const US_PA = 'PA';
export const US_RI = 'RI';
export const US_SC = 'SC';
export const US_SD = 'SD';
export const US_TN = 'TN';
export const US_TX = 'TX';
export const US_UT = 'UT';
export const US_VT = 'VT';
export const US_VA = 'VA';
export const US_WA = 'WA';
export const US_WV = 'WV';
export const US_WI = 'WI';
export const US_WY = 'WY';

export const SUPPORTED_REGIONAL_SHIPPING_REGION_CODES = [
  US_CON,
  CA,
];

export const REGIONAL_AREA_TYPES = {
  [US_CON]: 'state',
  [CA]: 'province',
};

export const US_CON_STATE_CODES = [
  US_AL,
  US_AZ,
  US_AR,
  US_CA,
  US_CO,
  US_CT,
  US_DC,
  US_DE,
  US_FL,
  US_GA,
  US_ID,
  US_IL,
  US_IN,
  US_IA,
  US_KS,
  US_KY,
  US_LA,
  US_ME,
  US_MD,
  US_MA,
  US_MI,
  US_MN,
  US_MS,
  US_MO,
  US_MT,
  US_NE,
  US_NV,
  US_NH,
  US_NJ,
  US_NM,
  US_NY,
  US_NC,
  US_ND,
  US_OH,
  US_OK,
  US_OR,
  US_PA,
  US_RI,
  US_SC,
  US_SD,
  US_TN,
  US_TX,
  US_UT,
  US_VT,
  US_VA,
  US_WA,
  US_WV,
  US_WI,
  US_WY,
];

export const US_CON_MIDWEST = [
  US_IL,
  US_IN,
  US_IA,
  US_KS,
  US_MI,
  US_MN,
  US_MO,
  US_NE,
  US_ND,
  US_OH,
  US_SD,
  US_WI,
];

export const US_CON_WEST = [
  US_AZ,
  US_CA,
  US_CO,
  US_ID,
  US_MT,
  US_NV,
  US_NM,
  US_OR,
  US_UT,
  US_WA,
  US_WY,
];

export const US_CON_SOUTH = [
  US_AL,
  US_AR,
  US_FL,
  US_GA,
  US_KY,
  US_LA,
  US_MD,
  US_MS,
  US_NC,
  US_OK,
  US_SC,
  US_TN,
  US_TX,
  US_VA,
  US_WV,
];

export const US_CON_NORTHEAST = [
  US_CT,
  US_DC,
  US_DE,
  US_ME,
  US_MA,
  US_NH,
  US_NJ,
  US_NY,
  US_PA,
  US_RI,
  US_VT,
];

// Canadian Provincial Codes
export const CA_AB = 'AB';
export const CA_BC = 'BC';
export const CA_MB = 'MB';
export const CA_NB = 'NB';
export const CA_NL = 'NL';
export const CA_NT = 'NT';
export const CA_NS = 'NS';
export const CA_NU = 'NU';
export const CA_ON = 'ON';
export const CA_PE = 'PE';
export const CA_QC = 'QC';
export const CA_SK = 'SK';
export const CA_YT = 'YT';

export const CANADA_PROVINCIAL_CODES = [
  CA_AB,
  CA_BC,
  CA_MB,
  CA_NB,
  CA_NL,
  CA_NT,
  CA_NS,
  CA_NU,
  CA_ON,
  CA_PE,
  CA_QC,
  CA_SK,
  CA_YT,
];

// Supported locales
export const DE = 'de';
export const EN = 'en';
export const ES = 'es';
export const FR = 'fr';
export const IT = 'it';
export const JA = 'ja';

export const NUMBER_OR_FLOAT_KEYS_REGEX = /\d|\.|\,|\s/;

export const CORE_DEFAULT_OG_IMAGE_URL =
  'https://static.reverb-assets.com/assets/homepage/open-graph-2a0cbbc49a26d873db03aae487defc6d.jpg';

export const LP_NATIVE = 'lp_native';
export const IOS = 'ios-app';
export const ANDROID = 'android-app';
export const MOBILE_PHONE = 'mobile-browser';
export const TABLET = 'tablet-browser';
export const WEB = 'web';
export const DEALS_AND_STEALS_CURATED_SET_ID = '8';
export const DEALS_AND_STEALS_CMS_PAGE_SLUG = 'deals-and-steals';

export enum CmsPageType {
  BUYING_GUIDE = 'buying_guide',
  CATEGORY = 'category',
  EMBED = 'embed',
  FEATURED = 'featured',
  GEAR = 'gear',
  LEGAL = 'legal',
  MOBILE = 'mobile',
  PRESS = 'press',
  PROMO = 'promo',
  ROOT = 'root',
  SALE = 'sale',
  SELLING = 'selling',
  SEO_LANDING_PAGE = 'seo_landing_page',
}

export enum ExperimentBucketType {
  NULL = '',
  DEFAULT = 'default',
  EXPERIMENT = 'experiment',
}

export const currencies = {
  USD: 'USD',
  CAD: 'CAD',
};

// Keep these constants synced with Core's Reverb::Recaptcha::Action module
export const GOOGLE_RECAPTCHA_ACTIONS = {
  PUBLISH_LISTING: 'publish_listing',
  SEND_MESSAGE: 'send_message',
  SIGNUP: 'signup',
  SUBMIT_OFFER: 'submit_offer',
} as const;

export type RecaptchaAction = typeof GOOGLE_RECAPTCHA_ACTIONS[keyof typeof GOOGLE_RECAPTCHA_ACTIONS];

export const GOOGLE_RECAPTCHA_TOKEN_PARAM = 'google_recaptcha_token';

export const REACT_MODAL_PORTAL_DOM_CLASS = 'modal-outlet__dialogs';

export const LISTING_STATES = {
  DRAFT: 'draft',
  ENDED: 'ended',
  EXPIRED: 'expired',
  LIVE: 'live',
  ORDERED: 'ordered',
  SELLER_UNAVAILABLE: 'seller_unavailable',
  SOLD: 'sold',
  SOLD_OUT: 'sold_out',
  SUSPENDED: 'suspended',
  UPCOMING: 'upcoming',
};

export const ELECTRIC_GUITARS_UUID = 'dfd39027-d134-4353-b9e4-57dc6be791b9';

export const PRO_AUDIO_UUID = 'b021203f-1ed8-476c-a8fc-32d4e3b0ef9e';
export const INTERFACES_UUID = 'dfb72735-b7a3-4c8f-9b14-0d1dd904af1e';
export const MICROPHONES_UUID = '0f2bbf76-3225-44d5-8a5b-c540cc1fd058';

export const KEYBOARDS_AND_SYNTHS_UUID = 'd002db05-ab63-4c79-999c-d49bbe8d7739';
export const ARRANGER_KEYBOARDS_UUID = 'f7693392-603e-4e47-bf83-dbe0f586f350';

export const ADMINISTRATOR_PERMISSION_LEVEL = 'administrator';

export const PROTECTION_PLANS_ARTICLE_ID = '31546591472403';
