import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgNudgeSaleTag = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 8.85a2.1 2.1 0 1 0 0-4.2 2.1 2.1 0 0 0 0 4.2ZM8 6.75a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m17.589 9.643-6.82-6.82A2.81 2.81 0 0 0 8.742 2l-3.917.056a2.81 2.81 0 0 0-2.769 2.77L2 8.741a2.81 2.81 0 0 0 .823 2.027l6.82 6.82a1.405 1.405 0 0 0 1.986 0l5.96-5.96a1.405 1.405 0 0 0 0-1.986Zm-7.88-5.76 6.752 6.753-5.825 5.825-6.752-6.753a1.31 1.31 0 0 1-.384-.944l.056-3.917a1.31 1.31 0 0 1 1.29-1.29L8.765 3.5a1.31 1.31 0 0 1 .944.384Z"
    />
  </svg>
);
export default SvgNudgeSaleTag;
