import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgComments = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M22 12c0 1.448-.49 2.787-1.469 4.016-.979 1.229-2.315 2.2-4.008 2.914C14.831 19.643 12.99 20 11 20c-.896 0-1.813-.083-2.75-.25a13.141 13.141 0 0 1-4.344 2 13.12 13.12 0 0 1-1.344.25h-.046a.488.488 0 0 1-.32-.125.516.516 0 0 1-.18-.328.32.32 0 0 1-.016-.102c0-.036.003-.07.008-.101a.33.33 0 0 1 .031-.094l.04-.078.054-.086.062-.078.07-.078.063-.07c.052-.063.172-.193.36-.391.187-.198.322-.352.406-.461.083-.11.2-.26.351-.453.151-.193.282-.393.39-.602a8.25 8.25 0 0 0 .321-.687c-1.291-.75-2.307-1.672-3.047-2.766C.37 14.406 0 13.24 0 12c0-1.448.49-2.786 1.469-4.016.979-1.229 2.315-2.2 4.008-2.914C7.169 4.357 9.01 4 11 4c1.99 0 3.83.357 5.523 1.07 1.693.714 3.03 1.685 4.008 2.914C21.511 9.214 22 10.552 22 12zm6 4c0 1.25-.37 2.42-1.11 3.508-.739 1.088-1.755 2.008-3.046 2.758.104.25.21.479.32.687.11.209.24.409.39.602.152.192.269.343.352.453.084.11.219.263.407.46a10.94 10.94 0 0 1 .421.462l.07.078a.676.676 0 0 1 .063.078.706.706 0 0 1 .055.086l.039.078.031.094.008.101-.016.102a.58.58 0 0 1-.203.344.47.47 0 0 1-.343.109 13.12 13.12 0 0 1-1.344-.25 13.141 13.141 0 0 1-4.344-2c-.938.167-1.854.25-2.75.25-2.823 0-5.281-.688-7.375-2.063.604.042 1.063.063 1.375.063 1.677 0 3.287-.234 4.828-.703 1.542-.469 2.917-1.14 4.125-2.016 1.302-.958 2.302-2.062 3-3.312a8.02 8.02 0 0 0 .688-6.344c1.343.74 2.406 1.667 3.187 2.781C27.61 13.521 28 14.72 28 16z" />
  </svg>
);
export default SvgComments;
