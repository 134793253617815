import I18n from 'i18n-js';
import moment from 'moment';

// Explicitly import moment locales so they're included in the bundle, even when ignoring in webpack
import 'moment/locale/de';
import 'moment/locale/en-au';
import 'moment/locale/en-ca';
import 'moment/locale/en-gb';
import 'moment/locale/en-ie';
import 'moment/locale/en-il';
import 'moment/locale/en-nz';
import 'moment/locale/es';
import 'moment/locale/es-us';
import 'moment/locale/fr';
import 'moment/locale/fr-ca';
import 'moment/locale/ja';

// Set moment locale back to I18n default, as importing changes the global locale
moment.locale(I18n.defaultLocale);

function isInvalidDate(val) {
  return !val || new Date(val).toString() === 'Invalid Date';
}

export function formatInternationalizedSeconds(seconds, format = 'L') {
  const t = new Date(1970, 0, 1);
  t.setSeconds(seconds);
  return formatInternationalizedDate(t, format);
}

export function formatInternationalizedDate(date, format = 'L') {
  return moment(date).format(format);
}

export function getDate(dateString: any): string {
  if (isInvalidDate(dateString)) {
    return I18n.t('cms.invalidDate');
  }

  return moment(dateString).format('MMMM D, gggg');
}

export function getDateAndTime(dateString: any): string {
  if (isInvalidDate(dateString)) {
    return I18n.t('cms.invalidDate');
  }

  return moment(dateString).format('LLL');
}

export function getDateAndTimeFromSeconds(seconds: number): string {
  return moment.unix(seconds).format('LLL');
}

export function getMillisecondsRemaining(seconds: number): number {
  // getTime returns milliseconds
  const end = new Date(seconds * 1000).getTime();
  const now = new Date().getTime();
  return (end - now);
}

export function getDaysSincePublished(seconds: number): number {
  if (!seconds) return null;
  return moment().diff(moment.unix(seconds), 'days');
}
