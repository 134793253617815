import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgHandshake = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10.595 7.513c-1.007.403-2.09.835-3.11.602a9.03 9.03 0 0 1-3.71-1.83c-.737-.37-.88-.25-1.033.135L.467 11.454c-.313.671-.308.912.195 1.159l3.371 1.677c.829.437 1.594.984 2.275 1.629 1.757 1.549 4.79 4.447 6.315 5.905.408.39.708.676.847.807a1.44 1.44 0 0 0 1.613-.269 1.72 1.72 0 0 0 .371-.94 1.568 1.568 0 0 0 1.726-.368c.285-.315.4-.755.307-1.175a1.363 1.363 0 0 0 1.871-.101c.278-.348.421-.79.404-1.242.605.242 1.217.197 1.667-.29.398-.556.345-1.103-.1-1.62l-4.149-4.312c-.533-.437-1.19-.54-1.629 0-.764.814-1.63 1.422-2.675 1.755-.865.237-2.233-.022-2.822-.72-.562-.545-.707-1.7-.245-2.339 0 0 3.336-3.198 3.53-3.433.194-.235-1.499-.553-2.482-.168l-.262.104z" />
    <path d="M15.87 6.9a1.468 1.468 0 0 0-.722.309l-4.381 4.346c-.145.39-.11.714.12 1.057.23.343.542.498.943.492.564.118 1.143-.139 1.607-.492 0 0 .179-.05 1.711-1.207.806-.703 2.124-.638 2.853.15l4.281 4.544c.105.122.362.217.518.217.158 0 .296-.095.4-.217l4.45-3.487a.67.67 0 0 0 .303-.442.688.688 0 0 0-.11-.53l-2.419-5.354c-.193-.37-.71-.118-.856 0a16.335 16.335 0 0 1-3.08 1.678 5.52 5.52 0 0 1-3.227-.336c-.36-.122-.532-.194-.743-.282-.197-.081-.426-.177-.87-.339a1.468 1.468 0 0 0-.778-.107z" />
  </svg>
);
export default SvgHandshake;
