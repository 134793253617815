import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useId } from '@reach/auto-id';
// framer-motion v7+ requires React 18+ (we're on 17) so the options here VS their docs might be off until that can happen
/* eslint-disable no-restricted-imports */
import { motion, AnimatePresence, useAnimationControls } from 'framer-motion';
import I18n from 'i18n-js';
import {
  useFloating,
  useInteractions,
  FloatingFocusManager,
  FloatingPortal,
  useDismiss,
  useTransitionStatus,
} from '@floating-ui/react';

import { ToastCloseType } from '../types';
import { TOAST_DOM_ID } from '../../constants';
import { RCCloseButton, RCIcon } from '../';
import { HeartFilledIcon, HeartEmptyIcon, CheckCircleIcon, ExclamationCircleIcon } from '../../icons/react';
import { preferDarkTheme } from '../helpers/themeOverrides';

type ToastThemes = 'plain' | 'watch-add' | 'watch-remove' | 'success' | 'error';

interface RCToastProps {
  /**
   * Controls if the Toast should display.
   *
   * Keep in mind that Toast will self-close after 10 seconds unless the user is hovering over it.
   * */
  isOpen: boolean,

  /**
   * This is auto-fired after a short interval unless the user is hovering over it.
  */
  onClose: (closeType: ToastCloseType) => void,

  /**
   * The toast message. Keep it short (1-4 words) to minimize text wrapping on mobile, especially for non-English languages.
  */
  text: string,

  /**
   * Our themes dictate the color & icon of a given toast;
   * if you need a new theme, the Cadence team can help (or simply sign off on the new theme you make).
  */
  theme?: ToastThemes,

  /** A CoreLink element without any styles. Default link text should use 'commons.RCToast.linkDefault' ("View").
   * Custom text should be very short (1-2 words max) for the best UI/UX balance.
   */
  link?: React.AnchorHTMLAttributes<HTMLAnchorElement>,

  /** Value in rems greater than 1. Adjust toast placement to avoid covering important UI elements. */
  mobileOffset?: number,

  /** How long should the toast stay on the screen? This value only applies to toasts _without_ a `link` present.
   * If a `link` is present, we have a fixed delay to help users "find" that link in a consistent amount of time.
   * Short = 2.5 seconds, medium = 5 seconds (default), long = 10 seconds (default when link is present)
   */
  delayInterval?: 'short' | 'medium' | 'long';
}

const DELAY_TIME_MS = {
  short: 2000,
  medium: 5000,
  long: 10000,
};

export function RCToast({
  text,
  isOpen,
  onClose,
  theme = 'plain',
  link = undefined,
  mobileOffset = undefined,
  delayInterval = 'medium',
}: RCToastProps) {
  const [hasAttention, setHasAttention] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const delayDuration = link ? DELAY_TIME_MS.long : DELAY_TIME_MS[delayInterval];
  const BUFFERED_CLOSE_DELAY = 1000;
  const id = useId();
  const open = isOpen; // Needed to avoid Window open() function collision
  const {
    context,
    refs,
  } = useFloating({
    open,
    onOpenChange: handleDismiss,
  });

  const { getFloatingProps } = useInteractions([
    useDismiss(context, {
      enabled: true,
      escapeKey: true,
      outsidePress: false,
    }),
  ]);

  const { isMounted, status } = useTransitionStatus(context);
  const animation = useAnimationControls();

  useEffect(() => {
    let timer;

    if (!isOpen) return;

    if (hasAttention) {
      clearTimeout(timer);
    }

    if (!hasInteracted) {
      timer = setTimeout(() => handleTimeout(), delayDuration);
    }

    if (hasInteracted) {
      timer = setTimeout(() => handleTimeout(), BUFFERED_CLOSE_DELAY);
    }

    return () => clearTimeout(timer);
  }, [isOpen, hasAttention, hasInteracted]);

  async function handleTimeout() {
    const transitionDistance = mobileOffset ? mobileOffset + 10 : 10;
    if (hasAttention) return;
    setHasInteracted(false);
    // Animate out - slide down and off screen
    await animation.start({ y: `${transitionDistance}rem`, transition: { duration: 0.3 } });
    cleanupAndClose('timeout');
  }

  function cleanupAndClose(closeType: ToastCloseType) {
    setHasAttention(false);
    setHasInteracted(false);
    onClose(closeType);
  }

  async function handleDrag(_, info) {
    const { x, y } = info.offset;

    if (x > 100 || x < -100) {
      // Animate out - fade out and slide along x-axis
      await animation.start({ x: x + x, opacity: 0, transition: { duration: 0.2 } });
      cleanupAndClose('swipe');
    } else if (y > 30) {
      // Animate out - fade out and slide along y-axis
      await animation.start({ y: y + y, opacity: 0, transition: { duration: 0.2 } });
      cleanupAndClose('swipe');
    }
  }

  async function handleDismiss() {
    // Animate out - fade out in place with a small scale effect
    await animation.start({ opacity: 0, scale: 0.9, transition: { duration: 0.2 } });
    cleanupAndClose('dismiss');
  }

  function onAttentionLeave() {
    setHasAttention(false);
  }

  function onHasAttention() {
    if (hasAttention) return;
    setHasAttention(true);
    setHasInteracted(true);
  }

  function isDialog() {
    return !!link;
  }

  const themeIcon = {
    'plain': null,
    'watch-add': HeartFilledIcon,
    'watch-remove': HeartEmptyIcon,
    'success': CheckCircleIcon,
    'error': ExclamationCircleIcon,
  };

  function toastIcon() {
    const icon = themeIcon[theme];
    if (!icon) return null;

    const iconClasses = classNames(
      'rc-toast__icon', {
        'rc-toast__icon--watch-add': theme === 'watch-add',
        'rc-toast__icon--watch-remove': theme === 'watch-remove',
        'rc-toast__icon--success': theme === 'success',
        'rc-toast__icon--error': theme === 'error',
      },
    );

    return (
      <div className={iconClasses}>
        <RCIcon inline svgComponent={icon} />
      </div>
    );
  }

  const mobileOffsetStyle = { '--mobile-offset': `${mobileOffset}rem` } as React.CSSProperties;

  function innerContents() {
    return (
      <div
        className="rc-toast-wrapper"
        style={mobileOffset ? mobileOffsetStyle : {}}
        data-status={status} // used for entry animation handling
      >
        <motion.div
          drag
          dragDirectionLock
          dragConstraints={{ top: 0 }}
          dragElastic={{ top: 0.1, right: 1, bottom: 1, left: 1 }}
          dragSnapToOrigin={true}
          onDragEnd={handleDrag}
          ref={refs.setFloating}
          key="toast"
          className="rc-toast"
          animate={animation}
          onMouseOver={() => onHasAttention()}
          onMouseLeave={() => onAttentionLeave()}
          role={isDialog() ? 'dialog' : 'status'}
          {...preferDarkTheme()}
          {...getFloatingProps()}
        >
          <div className="rc-toast__content">
            {toastIcon()}
            <div className="rc-toast__text" id={id}>
              {text}
            </div>
          </div>
          {isDialog() && link}
          <div className="rc-toast__close-button">
            <RCCloseButton
              onClick={() => handleDismiss()}
              aria-label={I18n.t('cadence.RCToast.closeDialog')}
            />
          </div>
        </motion.div>
      </div>
    );
  }

  return (
    <AnimatePresence>
      {isMounted && (
        <FloatingPortal id={TOAST_DOM_ID}>
          {isDialog() ? (
            <FloatingFocusManager
              context={context}
              order={['content']}
              returnFocus={false} // Fixes repositioning scroll bug after dismissal: https://reverb.atlassian.net/browse/HF-752
              modal
            >
              {innerContents()}
            </FloatingFocusManager>
          ) : (
            <>
              {innerContents()}
            </>
          )}
        </FloatingPortal>
      )}
    </AnimatePresence>
  );
}
