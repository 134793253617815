import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgArrowRight = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M25 15c0 .563-.193 1.037-.578 1.422L14.25 26.594a2 2 0 0 1-1.422.578c-.531 0-1-.193-1.406-.578l-1.172-1.172A1.94 1.94 0 0 1 9.656 24c0-.552.198-1.026.594-1.422L14.828 18h-11c-.542 0-.982-.195-1.32-.586C2.169 17.024 2 16.552 2 16v-2c0-.552.17-1.023.508-1.414.338-.39.778-.586 1.32-.586h11L10.25 7.406A1.857 1.857 0 0 1 9.656 6c0-.563.198-1.031.594-1.406l1.172-1.172a1.922 1.922 0 0 1 1.406-.594 1.94 1.94 0 0 1 1.422.594l10.172 10.172c.385.364.578.833.578 1.406z" />
  </svg>
);
export default SvgArrowRight;
