import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgFileTextO = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M24.938 5.938c.291.291.541.687.75 1.187.208.5.312.958.312 1.375v18c0 .417-.146.77-.438 1.063A1.446 1.446 0 0 1 24.5 28h-21c-.417 0-.77-.146-1.063-.438A1.447 1.447 0 0 1 2 26.5v-25c0-.417.146-.77.438-1.063A1.447 1.447 0 0 1 3.5 0h14c.417 0 .875.104 1.375.313.5.208.896.458 1.188.75l4.875 4.875zM18 2.124V8h5.875c-.104-.302-.219-.516-.344-.64l-4.89-4.891c-.125-.125-.339-.24-.641-.344zM24 26V10h-6.5c-.417 0-.77-.146-1.063-.438A1.446 1.446 0 0 1 16 8.5V2H4v24h20zM8 12.5a.49.49 0 0 1 .14-.36.487.487 0 0 1 .36-.14h11a.49.49 0 0 1 .36.14.49.49 0 0 1 .14.36v1a.487.487 0 0 1-.14.36.487.487 0 0 1-.36.14h-11a.487.487 0 0 1-.36-.14.487.487 0 0 1-.14-.36v-1zM19.5 16a.49.49 0 0 1 .36.14.49.49 0 0 1 .14.36v1a.487.487 0 0 1-.14.36.487.487 0 0 1-.36.14h-11a.487.487 0 0 1-.36-.14.487.487 0 0 1-.14-.36v-1a.49.49 0 0 1 .14-.36.487.487 0 0 1 .36-.14h11zm0 4a.49.49 0 0 1 .36.14.49.49 0 0 1 .14.36v1a.487.487 0 0 1-.14.36.487.487 0 0 1-.36.14h-11a.487.487 0 0 1-.36-.14.487.487 0 0 1-.14-.36v-1a.49.49 0 0 1 .14-.36.487.487 0 0 1 .36-.14h11z" />
  </svg>
);
export default SvgFileTextO;
