import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgProtectionShield = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17.083 9.637c.09-.716-.179-1.164-.447-1.432-.358-.358-.806-.537-.895-.537-.09 0-.09 0-.18.09h-.089c-.09.089-.179.089-.268.268-.09.09-.627 1.342-1.432 3.848 0 .09-.09.18-.09.269-.179.537-.268.716-.09.895 0 .09.09.09.18.09h.179c.626-.09 2.774-.359 3.132-3.491Z" />
    <path d="m23.527 4.446-8.77-3.312a1.746 1.746 0 0 0-1.343 0l-8.86 3.312C3.747 4.714 3.3 5.52 3.3 6.326v7.696c0 3.312 1.7 6.534 5.012 9.487 1.432 1.253 3.043 2.417 4.833 3.312.269.179.537.179.895.179.269 0 .627-.09.895-.179 1.7-.895 3.312-2.059 4.744-3.312 3.311-2.953 5.012-6.086 5.012-9.487V6.325c.09-.805-.448-1.521-1.164-1.88ZM19.5 17.96c-.537.358-1.611.985-2.685.985-.537 0-.985-.18-1.343-.537-.716-.716-.716-2.059-.716-2.775.09-.984-.268-1.521-.537-1.88-.09-.178-.358-.268-.537-.268-.179 0-.358.09-.358.358-.09.269-.179.806-.358 1.343-.179.626-.358 1.163-.447 1.61-.18.806-.269 1.522-.358 2.149 0 0 0 .179-1.074.179h-.897c-.089 0-.178 0-.178-.09 0 0-.09-.179 0-.716.268-1.432 2.327-8.234 2.595-9.04.269-.715.806-1.52.806-1.52h-.09c-.895 0-1.969.357-2.864.984-.895.626-1.432 1.521-1.432 2.327 0 .358.09 1.163.895 1.163.18 0 .358 0 .537-.09a1.73 1.73 0 0 1-.447.806 2.445 2.445 0 0 1-1.7.716 2.2 2.2 0 0 1-1.343-.447c-.269-.269-.448-.716-.358-1.343.09-1.88 1.342-3.222 3.759-3.938 1.969-.626 4.027-.716 4.564-.716.716 0 1.7 0 2.506.358.985.358 1.432 1.074 1.432 2.059 0 2.774-3.49 3.49-3.67 3.759-.089.179.09.268.27.447.178.18.536.537.715 1.343.18.716.806 2.506 1.88 2.506.537 0 .895-.09 1.163-.269.627-.268.895.18.269.537Z" />
  </svg>
);
export default SvgProtectionShield;
