import React from 'react';

import type { FormattedString } from '../types';
import {
  buttonClassName,
  ButtonStyling,
  DEFAULT_VARIANT,
  DEFAULT_THEME,
  DEFAULT_SIZE,
} from './styling';

/* eslint-disable react/require-default-props */

export interface RCButtonProps extends ButtonStyling {
  /** The button text is defined via the component's `children` to allow for rich text formatting if necessary,
   * but typically a button should be a simple 1-3 word [title cased](https://en.wikipedia.org/wiki/Title_case) string. (Essentially, capitalize everything except articles, e.g. "Add to Cart".)
   *
   * Our buttons __do not wrap__ by default; keep that in mind when designing for
   * non-EN languages or small areas. If you need to wrap button text, use the `word-wrap-break-word` CSS utility class.
  */
  children: FormattedString;

  /** The click handler passed to the button. */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;

  /**
   * Fades the button visually and applies the `disabled` attribute. Keep in mind that if a button is disabled
   * without a way for the user to enable it, it may make more sense to simply not render the button.
   *
   * Either way, if a the button is disabled, an `RCTooltip` explaining _why_ is often a good UX add.
   */
  disabled?: boolean;

  /** Appends `type="submit"` to the button for use inside forms. */
  isSubmit?: boolean;

  /** Passes through to the HTML tag. Should be used when wrapping an icon or something without visible text. */
  title?: string;

  /** Passes through to the HTML tag. Useful for when ARIA attributes need to refer to a button's ID. */
  id?: string;

  /** Passes through to the HTML tag. */
  'aria-disabled'?: boolean;

  /** Passes through to the HTML tag. */
  form?: string;
}

/**
 * It's a button! RCButton renders a `<button>` tag and should be used for button actions whenever possible.
 *
 * A future version of button components will render more tag types; if you need an `<input type="submit">` or `<a>` tag, use our `button` styles.
 */
export const RCButton = React.forwardRef<HTMLButtonElement, RCButtonProps>(({
  variant = DEFAULT_VARIANT,
  theme = DEFAULT_THEME,
  size = DEFAULT_SIZE,
  fullWidth,
  inverted = false,
  children,
  isSubmit,
  onClick,
  disabled,
  title,
  id,
  ...passthroughProps
}: RCButtonProps, ref) => {
  return (
    <button
      className={buttonClassName({
        variant: variant,
        theme: theme,
        size: size,
        inverted: inverted,
        fullWidth: fullWidth,
      })}
      type={isSubmit ? 'submit' : 'button'}
      onClick={onClick}
      disabled={disabled}
      title={title}
      id={id}
      ref={ref}
      {...passthroughProps}
    >
      {children}
    </button>
  );
});

// Explicitly setting displayName to help with debugging and tracing an otherwise anonymous function.
RCButton.displayName = 'RCButton';
