import { debounce } from 'lodash';

const DEFAULT_TIMEOUT_MS = 2000;

export type Endpoint = 'events' | 'gauges';

const CONFIG = {
  apiKey: null,
  host: null,
  timeout: DEFAULT_TIMEOUT_MS,
  local: false,
  sentryClient: null,
};

const QUEUED_EVENTS = [];
const QUEUED_GAUGES = [];
const ERROR_INFO_KEY = 'errorInfo';

let send = () => { };

function removeEmptyAndExtractErrorData(obj, levels = 10) {
  if (levels === 0) {
    return obj;
  }

  Object.keys(obj).forEach((key) => {
    if (obj[key] && obj[key] instanceof Error) {
      const { message, stack } = obj[key];
      obj[ERROR_INFO_KEY] = { message, stack };
    } else if (obj[key] && typeof obj[key] === 'object') {
      removeEmptyAndExtractErrorData(obj[key], levels - 1);
    } else if (!obj[key]) {
      delete obj[key];
    }
  });

  return obj;
}

function postEvents() {
  if (QUEUED_EVENTS.length === 0) { return; }

  if (CONFIG.local) {
    const errors = QUEUED_EVENTS.filter((ev) => (ev.level === 'error'));
    const events = QUEUED_EVENTS.filter((ev) => (ev.level !== 'error'));

    if (events.length) {
      console.groupCollapsed(`[event-api] (${events.length})`);
      events.map((ev) => {
        console.log(ev.name, removeEmptyAndExtractErrorData(ev));
      });
      console.groupEnd();
    }

    errors.map((ev) => {
      const cleaned = removeEmptyAndExtractErrorData(ev);
      console.group(cleaned.name);
      console.error(cleaned.name, cleaned);
      console.error(cleaned?.error);
      console.groupEnd();
    });

    QUEUED_EVENTS.length = 0;
    return;
  }

  try {
    if (!navigator.sendBeacon) { return; }

    navigator.sendBeacon(
      `${CONFIG.host}/v1/events?cid=${CONFIG.apiKey}`,
      JSON.stringify(QUEUED_EVENTS.map(ev => removeEmptyAndExtractErrorData(ev))),
    );
  } finally {
    // Clear the queue even if there's an exception serializing an event
    QUEUED_EVENTS.length = 0;
  }
}

function postGauges() {
  if (QUEUED_GAUGES.length === 0) { return; }

  if (CONFIG.local) {
    console.groupCollapsed(`[gauges] (${QUEUED_GAUGES.length})`);

    QUEUED_GAUGES.map((gauge) => {
      console.log({ ...gauge });
    });

    console.groupEnd();
    QUEUED_GAUGES.length = 0;
    return;
  }

  try {
    if (!navigator.sendBeacon) { return; }

    navigator.sendBeacon(
      `${CONFIG.host}/v1/gauges?cid=${CONFIG.apiKey}`,
      JSON.stringify({ gauges: QUEUED_GAUGES }),
    );
  } finally {
    // Clear the queue even if there's an exception serializing an event
    QUEUED_GAUGES.length = 0;
  }
}

const nonBufferedSend = () => {
  postEvents();
  postGauges();
};

const buildSendFunction = (buffer) => {
  if (buffer && buffer !== 0) {
    return debounce(
      nonBufferedSend,
      buffer,
    );
  }

  return nonBufferedSend;
};

function configure(opts) {
  CONFIG.apiKey = opts.apiKey;
  CONFIG.host = opts.host;
  CONFIG.timeout = opts.timeout || DEFAULT_TIMEOUT_MS;
  CONFIG.local = opts.local;
  send = buildSendFunction(opts.buffer);

  window.addEventListener(
    'beforeunload',
    nonBufferedSend,
    false,
  );
}

function post(events, endpoint: Endpoint) {
  if (endpoint === 'events') {
    Array.prototype.push.apply(QUEUED_EVENTS, events);
  }

  if (endpoint === 'gauges') {
    Array.prototype.push.apply(QUEUED_GAUGES, events);
  }

  send();
}

export default {
  post,
  configure,
};
