/* eslint-disable no-restricted-imports */
import React, { useState, useRef, useMemo } from 'react';
import {
  useFloating,
  offset,
  flip,
  shift,
  autoUpdate,
  useDismiss,
  useRole,
  useMergeRefs,
  useInteractions,
  FloatingPortal,
  useClick,
  useListNavigation,
  FloatingFocusManager,
  useTransitionStatus,
} from '@floating-ui/react';

import { FLOATING_UI_DOM_ID } from '../../constants';
import { CheckIcon } from '../../icons/react';

const DIALOG_PANEL_OFFSET = 8;
const DIALOG_WINDOW_EDGE_PADDING = 8; // The space in px a panel is padded from the edge of the window

type ContextType = {
  setOpen: (state) => void;
} | null;

const RCMenuContext = React.createContext<ContextType>(null);

const useRCMenuContext = () => {
  const context = React.useContext(RCMenuContext);

  return context;
};

interface MenuOptions {
  /** The element to which this menu will anchor. */
  anchor: JSX.Element;

  /** Optionally control the state of the menu. */
  isOpen?: boolean;

  /** Open / Close event handler. */
  onOpenChange?: (open: boolean) => void;

  /** The menu contents should be wrapped by this component. Contents should be comprised of RCMenu.Item and RCMenu.Separator subcomponents. */
  children: React.ReactNode;

  /** Initial menu placement. Defaults to 'bottom-start' (left aligned). */
  position?: 'bottom-start' | 'bottom-end';
}

/**
 * RCMenu provides a standardized menu that attaches to a toggling component & provides animations, keyboard support, and other niceties out of the box.
 */
export function RCMenu({
  anchor,
  position = 'bottom-start',
  isOpen: openProp = undefined,
  onOpenChange: setOpenProp = undefined,
  children,
}: React.PropsWithChildren<MenuOptions>) {
  const listRef = useRef<Array<HTMLElement | null>>([]);
  const [openState, setOpenState] = useState<boolean>(openProp || false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const isControlled = openProp !== undefined && setOpenProp !== undefined;
  const open = isControlled ? openProp : openState;
  const setOpen = isControlled ? setOpenProp : setOpenState;

  const {
    x,
    y,
    refs,
    strategy,
    placement,
    context,
  } = useFloating({
    placement: position,
    open: open,
    onOpenChange: setOpen,
    middleware: [
      offset(DIALOG_PANEL_OFFSET),
      flip({
        mainAxis: true,
        crossAxis: false,
      }),
      shift({
        padding: {
          top: DIALOG_WINDOW_EDGE_PADDING,
          right: DIALOG_WINDOW_EDGE_PADDING,
          bottom: DIALOG_WINDOW_EDGE_PADDING,
          left: DIALOG_WINDOW_EDGE_PADDING,
        },
      }),
    ],
    whileElementsMounted: (r, f, u) => {
      // We only need to auto update if it's possible to do so. This check also ensures
      // functionality with enzyme `mount`s in tests.
      if (('ResizeObserver' in window)) {
        return autoUpdate(r, f, u);
      }
      return;
    },
  });

  const {
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useInteractions([
    useRole(context, {
      role: 'menu',
    }),
    useDismiss(context, {
      enabled: true,
      escapeKey: true,
      outsidePressEvent: 'mousedown',
      outsidePress: true,
      referencePress: false,
    }),
    useClick(context, {
      enabled: true,
      toggle: true,
    }),
    useListNavigation(context, {
      listRef: listRef,
      activeIndex,
      onNavigate: setActiveIndex,
      virtual: false,
    }),
  ]);

  const { isMounted, status } = useTransitionStatus(context);
  const anchorRef = useMergeRefs([refs.setReference, (anchor as any).ref]);

  const validChildren = useMemo(() => {
    return React.Children.toArray(children).filter(c => {
      return React.isValidElement(c);
    });
  }, [children]);

  if (anchor.type === React.Fragment) {
    // Fragments will render to the screen, but they lose their ref and the Popover UIs won't know what to anchor
    // themselves to, and the panel will end up in the top-left corner of the screen.
    throw new Error('Pass a single element to the `anchor` prop, Fragments or other arrays are not supported.');
  }

  return (
    <RCMenuContext.Provider value={{ setOpen }}>
      {React.cloneElement(anchor, {
        ...getReferenceProps({ ref: anchorRef, ...anchor.props }),
      })}

      {isMounted && (
        <FloatingPortal id={FLOATING_UI_DOM_ID}>
          <FloatingFocusManager
            context={context}
            modal={false}
            initialFocus={-1}
            returnFocus={true}
          >
            <div
              ref={refs.setFloating}
              className="rc-menu__panel"
              data-status={status}
              data-placement={placement}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              }}
              {...getFloatingProps()}
            >
              <ul className="rc-menu__items">
                {validChildren.map((child, index) => {
                  if (!React.isValidElement(child)) return null;
                  return React.cloneElement(
                    child,
                    {
                      ...getItemProps({
                        ...child.props,
                        ref(node) {
                          listRef.current[index] = node;
                        },
                        tabIndex: activeIndex === index ? 0 : -1,
                      }),
                    },
                  );
                })}
              </ul>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </RCMenuContext.Provider>
  );
}

/* eslint-disable react/require-default-props */
interface RCMenuItemProps {
  itemRole?: 'default' | 'checkbox' | 'radio';
  selected?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  preventCloseOnClick?: boolean;
  children?: React.ReactNode;
  customElement?: boolean;
}

const RCMenuItem = React.forwardRef<HTMLButtonElement, RCMenuItemProps>(
  function RCMenuItem({
    children,
    itemRole = 'default',
    selected = false,
    disabled = false,
    onClick: passThroughOnClick = undefined,
    preventCloseOnClick = false,
    customElement = false,
    ...passThroughProps
  }: RCMenuItemProps, propRef) {
    const roleMap = {
      'default': 'menuitem',
      'checkbox': 'menuitemcheckbox',
      'radio': 'menuitemradio',
    };
    const context = useRCMenuContext();

    function handleClick() {
      if (!context) return;
      if (preventCloseOnClick) return;
      context.setOpen(false);
    }

    const element = customElement ? children : <button type="button" >{children}</button>;

    if (!React.isValidElement(element)) { return null; }

    return (
      <li className="rc-menu__item">
        {React.cloneElement(
          element,
          {
            ref: propRef,
            ...element.props,
            ...passThroughProps,
            role: roleMap[itemRole],
            disabled: undefined,
            'aria-disabled': disabled,
            'aria-checked': itemRole === 'default' ? undefined : selected,
            onClick: (e) => {
              if (disabled) {
                e.preventDefault();
                return;
              }
              element.props.onClick?.(e);
              passThroughOnClick?.(e);
              handleClick();
            },
          },
        )}
        {selected &&
          <div className="rc-menu__item__selected-icon" aria-hidden="true">
            <CheckIcon />
          </div>
        }
      </li>
    );
  },
);

const RCMenuSeparator = React.forwardRef<HTMLElement, React.HTMLProps<HTMLElement>>(
  function RCMenuSeparator() {
    return (
      <div role="separator" aria-hidden="true" className="rc-menu__separator" />
    );
  },
);

RCMenu.Item = RCMenuItem;
RCMenu.Separator = RCMenuSeparator;
