import React from 'react';
import Textarea from 'react-textarea-autosize';

import { RCFormGroup } from '../../';
import { CommonInputProps } from '../../types';

interface Props extends Omit<CommonInputProps, 'id' | 'onChange'> {
  /** Unique ID passed through the the applicable HTML tags */
  id: string;

  /** Passed through to the applicable HTML tag. */
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;

  /** called when the textarea loses focus. */
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;

  /** Current value of the input. Passed through to the `textarea` tag. */
  value: string;

  /** Text that appears when the value is empty. Passed through to the input tag. */
  placeholder?: string;

  /** Min height of the textarea, in number of rows. (This should typically be handled in CSS.) Passed through to the input tag. */
  minRows?: number;

  /** Max height of the textarea, in number of rows. (This should typically be handled in CSS.) Passed through to the input tag. */
  maxRows?: number;
}

/**
 * Styled Textarea. Uses `RCFormGroup` internally to handle label, tag, and so forth.
 */
export function RCTextArea({
  disabled,
  errorText,
  helpText,
  id,
  label,
  maxRows = undefined,
  minRows = undefined,
  name,
  onBlur = undefined,
  onChange = undefined,
  placeholder = undefined,
  required,
  tag,
  value,
}: Props) {
  return (
    <RCFormGroup
      errorText={errorText}
      helpText={helpText}
      inputId={id}
      label={label}
      tag={tag}
    >
      <Textarea
        disabled={disabled}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        value={value}
        minRows={minRows}
        maxRows={maxRows}
      />
    </RCFormGroup>
  );
}
