import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgEffects = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.413 8.937a1.594 1.594 0 1 1-3.189 0 1.594 1.594 0 0 1 3.19 0zm6.471 0a1.594 1.594 0 1 1-3.188 0 1.594 1.594 0 0 1 3.188 0zm-.995 12.281h-7.692a.956.956 0 0 1-.926-1.206l.778-4.145a1.058 1.058 0 0 1 1.042-.778h5.911a1.058 1.058 0 0 1 1.043.778l.777 4.145a.954.954 0 0 1-.933 1.206z" />
    <path
      fillRule="evenodd"
      d="M19.631 4.41H8.478a1.851 1.851 0 0 0-1.851 1.812v15.556a1.851 1.851 0 0 0 1.85 1.85h11.154a1.851 1.851 0 0 0 1.851-1.85V6.222a1.851 1.851 0 0 0-1.85-1.812zm.825 17.049a1.034 1.034 0 0 1-1.011 1.034H8.68a1.034 1.034 0 0 1-1.034-1.034V6.54A1.034 1.034 0 0 1 8.68 5.507h10.765a1.034 1.034 0 0 1 1.01 1.034V21.46z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEffects;
