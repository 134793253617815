import { find } from 'lodash';
import { recordUnknownExp } from './user_hooks';
import { IUser, Experiment } from './components/user_context_provider';
import {
  LP_NATIVE,
  IOS,
  ANDROID,
  AFFIRM_SUPPORTED_SHIPPING_REGIONS,
  MOBILE_PHONE,
  TABLET,
  WEB,
  currencies,
  US_CON,
  US,
  AFFIRM_SUPPORTED_ZERO_PERCENT_FINANCING_REGIONS,
} from './constants';

const AFFIRM_SHIPPING_REGIONS_SET = new Set(AFFIRM_SUPPORTED_SHIPPING_REGIONS);

interface ListingPartial {
  sellerId?: string;
}

type UserContextExperimentGroupsAB = 'default' | 'experiment';
type UserContextExperimentGroupsMulti = 'ctl' | 'exp1' | 'exp2' | 'exp3' | 'exp4' | 'exp5' | 'exp6' | 'exp7' | 'exp8' | 'exp9';
export type UserContextExperimentGroup = UserContextExperimentGroupsAB | UserContextExperimentGroupsMulti;

export interface UserContextExperiment extends Partial<Experiment> {
  value: UserContextExperimentGroup;
}

export function getExperimentInt(exp?: UserContextExperiment) {
  if (!exp || !exp.value) return -2;

  switch (exp.value) {
    // a/b rollouts
    case 'default':
      return 0;
    case 'experiment':
      return 1;

    // multivariate rollouts
    case 'ctl':
      return 0;
    case 'exp1':
      return 1;
    case 'exp2':
      return 2;
    case 'exp3':
      return 3;
    case 'exp4':
      return 4;
    case 'exp5':
      return 5;
    case 'exp6':
      return 6;
    case 'exp7':
      return 7;
    case 'exp8':
      return 8;
    case 'exp9':
      return 9;
    default:
      return -1;
  }
}

export function experimentInt(user: Partial<IUser>, name: string): number {
  return getExperimentInt(findExperiment(user, name) as UserContextExperiment);
}

function findExperiment(user: Partial<IUser>, name: string): Experiment {
  const experiments = user?.experiments || [];
  return find(experiments, { name } as Experiment);
}

// NOTE: If you're using a functional component, the hooks in
// commons/src/user_hooks.ts allow you to directly expose experiments
// without having to inject the user and/or use this helper
export function isExperimentEnabled(user: Partial<IUser>, name: string) {
  const experiment = findExperiment(user, name) as UserContextExperiment;
  if (!experiment) {
    recordUnknownExp(name);
  }

  return getExperimentInt(experiment) > 0;
}

export function experimentBucket(user: Partial<IUser>, name: string) {
  const experiment = findExperiment(user, name);

  return experiment && experiment.value;
}

export function inMobileApp(user: Partial<IUser>) {
  const device = user && user.deviceName;
  return device === IOS || device === ANDROID || device === LP_NATIVE;
}

export function inIOSApp(user: Partial<IUser>) {
  const device = user && user.deviceName;
  return device === IOS;
}

export function inAndroidApp(user: Partial<IUser>) {
  const device = user && user.deviceName;
  return device === ANDROID;
}

export function inMobileOrTabletBrowser(user: Partial<IUser>) {
  const device = user && user.deviceName;
  return device === MOBILE_PHONE || device === TABLET;
}

export function inMobileBrowser(user: Partial<IUser>) {
  const device = user && user.deviceName;
  return device === MOBILE_PHONE;
}

export function inWeb(user: Partial<IUser>) {
  const device = user && user.deviceName;
  return device === WEB;
}

export function isOwner(user: Partial<IUser>, listing: ListingPartial) {
  if (!user || !listing) return false;
  if (user.loggedOut) return false;

  const sellerId = listing?.sellerId?.toString();

  return sellerId === user?.id?.toString() || (!!user?.secondaryUserModeIsActive && sellerId === user.managedShopUserId);
}

export function canViewFinancingOptions(user: Partial<IUser>) {
  const { currency, shippingRegionCode } = user || {};

  return (currency === currencies.USD || currency === currencies.CAD) && AFFIRM_SHIPPING_REGIONS_SET.has(shippingRegionCode);
}

export function canViewZeroPercentFinancingPromo(user: Partial<IUser>) {
  const { currency, shippingRegionCode } = user || {};

  return currency === currencies.USD && AFFIRM_SUPPORTED_ZERO_PERCENT_FINANCING_REGIONS.includes(shippingRegionCode);
}

export function canViewListingPriceRecommendations(user: Partial<IUser>, listingCurrency?: string): boolean {
  return (
    user.shippingRegionCode === US_CON &&
    user.currency === currencies.USD &&
    listingCurrency === currencies.USD
  );
}

export function isNonUSUser(user: Partial<IUser>): boolean {
  return !!user.countryCode && user.countryCode !== US;
}

export function eligibleForOutlet(user: Partial<IUser>): boolean {
  return user.countryCode === US
      && user.currency === currencies.USD;
}
