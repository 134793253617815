import { resolveRuntimeEnv } from './resolve';
import type { DeployEnvironment, DeployEnvSettings } from './types';
import { VALID_DEPLOY_ENVS } from './types';

export { envHTML, serverEnvOverrides } from './resolve';

/*
 * The env package assumes the following envvars are defined on `process.env` in the runtime:
 * - DEPLOY_ENV
 * - TARGET
 * - COMMIT_HASH
 * - NODE_ENV
 * Currently these are set via webpack.EnvironmentPlugin in each individual build.
 *
 * Do not reference process.env values directly elsewhere in code, use the exports from this module instead!
 */

const { DEPLOY_ENV, overrides = {} } = resolveRuntimeEnv();

if (!VALID_DEPLOY_ENVS.includes(DEPLOY_ENV)) {
  throw new Error(`DEPLOY_ENV must be set to a valid environment (resolved to ${DEPLOY_ENV})`);
}
export { DEPLOY_ENV };

/**
 * Is this a production-style build? When false we may log more verbosely or perform more expensive validations
 */
export const PROD_MODE = process.env.NODE_ENV === 'production';

/**
 * Is this running in a testing context? Use this sparingly
 */
export const IS_TEST = process.env.NODE_ENV === 'test';

/**
 * Is the code being executed in a browser? When false, we are in a server environment like node where
 * browser APIs like `window`, `document`, etc are not available.
 */
export const IS_BROWSER = process.env.TARGET === 'browser';

/**
 * Git commit SHA for frontend. May fall back to timestamp in local development.
 */
export const REVISION = process.env.COMMIT_HASH || (new Date()).toISOString();

/** Auth Client ID for disco sandbox mode. Not used in production reverb-ui builds */
export const DISCO_OAUTH_CLIENT_ID = process.env.DISCO_OAUTH_CLIENT_ID || '';

/** Cookie domain for disco sandbox mode. Not used in production reverb-ui builds. */
export const COOKIE_DOMAIN = process.env.COOKIE_DOMAIN || '';

const deployEnvVars: Record<DeployEnvironment, DeployEnvSettings> = {
  production: {
    ADYEN_CLIENT_KEY: 'live_PC32TRHVYJHVXKBUFFGLPO2JYIC6O25J',
    ADYEN_ENVIRONMENT: 'live-us',
    AFFIRM_PUBLIC_API_KEY: 'LIDNUXQS82W72B8L',
    AFFIRM_GLOBAL_PUBLIC_API_KEY: "V6L7LNRQMIMZEDRB",
    AFFIRM_SCRIPT_URL: 'https://cdn1.affirm.com/js/v2/affirm.js',
    AMAZON_S3_SERVER: 's3.amazonaws.com',
    CLOUDINARY_UPLOAD_URL: 'https://api.cloudinary.com/v1_1/reverb-prod-cld/upload',
    CMS_ADMIN_URL: 'https://cms.reverb.tools',
    CORE_API_ORIGIN: 'https://api.reverb.com',
    CORE_WEB_ORIGIN: 'https://reverb.com',
    ECHIDNA_BASE_URL: 'https://echidna.reverb.tools',
    ENABLE_CRITEO: true,
    ENABLE_EPPO: true,
    ENABLE_RUM: false,
    ENABLE_SENTRY: true,
    EPPO_API_KEY: '_a0eRY0NDLzjRH1C4mGrPKfjkfFjKQWsn8S9ZgyRZOM',
    GOOGLE_PAY_ENVIRONMENT: 'PRODUCTION',
    GOOGLE_RECAPTCHA_SITE_KEY: '6Le_sfQfAAAAAJcVIkTpGSeCNl_attJZj5h8dZ72',
    MPARTICLE_ENV: 'production',
    PAYPAL_CLIENT_ID: 'AXRTXBC_z-bzxicg_SoaSGhgit6AtsBXs6gk0sZsGOdRCAytN_exHANNHMgd',
    RQL_BASE_URL: 'https://rql.reverb.com/graphql',
    GQL_GATEWAY_BASE_URL: 'https://gql.reverb.com/graphql',
    ACCERTIFY_DEVICE_JS_URL: 'https://prod.accdab.net/cdn/cs/D9J_UsLCpf3JBlvw3wNS-8b3a0o.js',
    ROKT_ACCOUNT_ID: '3266439016794701825',
    ROKT_SANDBOX: false,
  },
  sandbox: {
    ADYEN_CLIENT_KEY: 'test_47S7SOSMIFB7HJ5XU6IGOSJ77AMKV4EJ',
    ADYEN_ENVIRONMENT: 'test',
    AFFIRM_PUBLIC_API_KEY: '2ERE596TZQHPQNP4',
    AFFIRM_GLOBAL_PUBLIC_API_KEY: "EIWHFKW3HBM6C1ZS",
    AFFIRM_SCRIPT_URL: 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js',
    AMAZON_S3_SERVER: 's3.amazonaws.com',
    CLOUDINARY_UPLOAD_URL: 'https://api.cloudinary.com/v1_1/reverb-preprod-cld/upload',
    CMS_ADMIN_URL: 'https://cms-stable.reverb.tools',
    CORE_API_ORIGIN: 'https://sandbox.reverb.com',
    CORE_WEB_ORIGIN: 'https://sandbox.reverb.com',
    ECHIDNA_BASE_URL: 'https://echidna-staging.reverb.tools',
    ENABLE_CRITEO: false,
    ENABLE_EPPO: true,
    ENABLE_RUM: false,
    ENABLE_SENTRY: false,
    EPPO_API_KEY: 'PARMlM1q4KpR1I6xCziYj7rzcw-4Gv7-SRH9G7qjUTo',
    GOOGLE_PAY_ENVIRONMENT: 'TEST',
    GOOGLE_RECAPTCHA_SITE_KEY: '6LcbY0YoAAAAAGTMVe7PiEKO4YO1apk1Ziwu3bI_',
    MPARTICLE_ENV: 'staging',
    PAYPAL_CLIENT_ID: 'AflEZ3wE4dcs1IcOsbd6nQjyVOxT_7oGymUULWEK5l8NSaS-V2J-3sKTIUBJ1Td1nmS_h9OQyfGn2I5h',
    RQL_BASE_URL: 'https://sandbox.rql.reverb.com/graphql',
    GQL_GATEWAY_BASE_URL: 'https://gql.sandbox.reverb.com/graphql',
    ACCERTIFY_DEVICE_JS_URL: 'https://test.accdab.net/cdn/cs/GMGwycA1IdZx8fX0NWFLtsD3hCI.js',
    ROKT_ACCOUNT_ID: '3266439016794701825',
    ROKT_SANDBOX: true,
  },
  stable: {
    ADYEN_CLIENT_KEY: 'test_47S7SOSMIFB7HJ5XU6IGOSJ77AMKV4EJ',
    ADYEN_ENVIRONMENT: 'test',
    AFFIRM_PUBLIC_API_KEY: '2ERE596TZQHPQNP4',
    AFFIRM_GLOBAL_PUBLIC_API_KEY: "EIWHFKW3HBM6C1ZS",
    AFFIRM_SCRIPT_URL: 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js',
    AMAZON_S3_SERVER: 's3.amazonaws.com',
    CLOUDINARY_UPLOAD_URL: 'https://api.cloudinary.com/v1_1/reverb-preprod-cld/upload',
    CMS_ADMIN_URL: 'https://cms-stable.reverb.tools',
    CORE_API_ORIGIN: 'https://stable.reverb.com',
    CORE_WEB_ORIGIN: 'https://stable.reverb.com',
    ECHIDNA_BASE_URL: 'https://echidna-staging.reverb.tools',
    ENABLE_CRITEO: false,
    ENABLE_EPPO: true,
    ENABLE_RUM: false,
    ENABLE_SENTRY: false,
    EPPO_API_KEY: 'PARMlM1q4KpR1I6xCziYj7rzcw-4Gv7-SRH9G7qjUTo',
    GOOGLE_PAY_ENVIRONMENT: 'TEST',
    GOOGLE_RECAPTCHA_SITE_KEY: '6LcbY0YoAAAAAGTMVe7PiEKO4YO1apk1Ziwu3bI_',
    MPARTICLE_ENV: 'staging',
    PAYPAL_CLIENT_ID: 'AflEZ3wE4dcs1IcOsbd6nQjyVOxT_7oGymUULWEK5l8NSaS-V2J-3sKTIUBJ1Td1nmS_h9OQyfGn2I5h',
    RQL_BASE_URL: 'https://rql.int.qa.rvb.ai/graphql',
    GQL_GATEWAY_BASE_URL: 'https://rql.int.qa.rvb.ai/graphql',
    ACCERTIFY_DEVICE_JS_URL: 'https://test.accdab.net/cdn/cs/GMGwycA1IdZx8fX0NWFLtsD3hCI.js',
    ROKT_ACCOUNT_ID: '3266439016794701825',
    ROKT_SANDBOX: true,
  },
  unstable: {
    ADYEN_CLIENT_KEY: 'test_47S7SOSMIFB7HJ5XU6IGOSJ77AMKV4EJ',
    ADYEN_ENVIRONMENT: 'test',
    AFFIRM_PUBLIC_API_KEY: '2ERE596TZQHPQNP4',
    AFFIRM_GLOBAL_PUBLIC_API_KEY: "EIWHFKW3HBM6C1ZS",
    AFFIRM_SCRIPT_URL: 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js',
    AMAZON_S3_SERVER: 's3.amazonaws.com',
    CLOUDINARY_UPLOAD_URL: 'https://api.cloudinary.com/v1_1/reverb-preprod-cld/upload',
    CMS_ADMIN_URL: 'https://cms-unstable.reverb.tools',
    CORE_API_ORIGIN: 'https://unstable.reverb.com',
    CORE_WEB_ORIGIN: 'https://unstable.reverb.com',
    ECHIDNA_BASE_URL: 'https://echidna-staging.reverb.tools',
    ENABLE_CRITEO: true,
    ENABLE_EPPO: true,
    ENABLE_RUM: false,
    ENABLE_SENTRY: false,
    EPPO_API_KEY: 'PARMlM1q4KpR1I6xCziYj7rzcw-4Gv7-SRH9G7qjUTo',
    GOOGLE_PAY_ENVIRONMENT: 'TEST',
    GOOGLE_RECAPTCHA_SITE_KEY: '6LcbY0YoAAAAAGTMVe7PiEKO4YO1apk1Ziwu3bI_',
    MPARTICLE_ENV: 'staging',
    PAYPAL_CLIENT_ID: 'AflEZ3wE4dcs1IcOsbd6nQjyVOxT_7oGymUULWEK5l8NSaS-V2J-3sKTIUBJ1Td1nmS_h9OQyfGn2I5h',
    RQL_BASE_URL: 'https://rql.int.preprod.rvb.ai/graphql',
    GQL_GATEWAY_BASE_URL: 'https://graphql-gateway.int.preprod.reverb.rvb.ai/graphql',
    ACCERTIFY_DEVICE_JS_URL: 'https://test.accdab.net/cdn/cs/GMGwycA1IdZx8fX0NWFLtsD3hCI.js',
    ROKT_ACCOUNT_ID: '3266439016794701825',
    ROKT_SANDBOX: true,
  },
  tilt: {
    ADYEN_CLIENT_KEY: 'test_47S7SOSMIFB7HJ5XU6IGOSJ77AMKV4EJ',
    ADYEN_ENVIRONMENT: 'test',
    AFFIRM_PUBLIC_API_KEY: '2ERE596TZQHPQNP4',
    AFFIRM_GLOBAL_PUBLIC_API_KEY: "EIWHFKW3HBM6C1ZS",
    AFFIRM_SCRIPT_URL: 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js',
    AMAZON_S3_SERVER: 's3.localhost.localstack.cloud:4566',
    CLOUDINARY_UPLOAD_URL: 'https://api.cloudinary.com/v1_1/reverb-preprod-cld/upload',
    CMS_ADMIN_URL: 'https://cms.devo.test',
    CORE_API_ORIGIN: 'https://core.devo.test',
    CORE_WEB_ORIGIN: 'https://core.devo.test',
    ECHIDNA_BASE_URL: 'http://echidna.devo.test',
    ENABLE_CRITEO: false,
    ENABLE_EPPO: true,
    ENABLE_RUM: false,
    ENABLE_SENTRY: false,
    EPPO_API_KEY: 'PARMlM1q4KpR1I6xCziYj7rzcw-4Gv7-SRH9G7qjUTo',
    GOOGLE_PAY_ENVIRONMENT: 'TEST',
    GOOGLE_RECAPTCHA_SITE_KEY: '6LcbY0YoAAAAAGTMVe7PiEKO4YO1apk1Ziwu3bI_',
    MPARTICLE_ENV: 'staging',
    PAYPAL_CLIENT_ID: 'AflEZ3wE4dcs1IcOsbd6nQjyVOxT_7oGymUULWEK5l8NSaS-V2J-3sKTIUBJ1Td1nmS_h9OQyfGn2I5h',
    RQL_BASE_URL: 'https://rql.devo.test/graphql',
    GQL_GATEWAY_BASE_URL: 'https://graphql-gateway.devo.test/graphql',
    ACCERTIFY_DEVICE_JS_URL: 'https://test.accdab.net/cdn/cs/GMGwycA1IdZx8fX0NWFLtsD3hCI.js',
    ROKT_ACCOUNT_ID: '3266439016794701825',
    ROKT_SANDBOX: true,
  },
  tilt_production: {
    ADYEN_CLIENT_KEY: 'live_PC32TRHVYJHVXKBUFFGLPO2JYIC6O25J',
    ADYEN_ENVIRONMENT: 'live-us',
    AFFIRM_PUBLIC_API_KEY: 'LIDNUXQS82W72B8L',
    AFFIRM_GLOBAL_PUBLIC_API_KEY: "V6L7LNRQMIMZEDRB",
    AFFIRM_SCRIPT_URL: 'https://cdn1.affirm.com/js/v2/affirm.js',
    AMAZON_S3_SERVER: 's3.amazonaws.com',
    CLOUDINARY_UPLOAD_URL: 'https://api.cloudinary.com/v1_1/reverb-prod-cld/upload',
    CMS_ADMIN_URL: 'https://cms.reverb.tools',
    CORE_API_ORIGIN: 'https://api.reverb.com',
    CORE_WEB_ORIGIN: 'https://reverb.com',
    ECHIDNA_BASE_URL: 'https://echidna.reverb.tools',
    ENABLE_CRITEO: true,
    ENABLE_EPPO: true,
    ENABLE_RUM: false,
    ENABLE_SENTRY: true,
    EPPO_API_KEY: '_a0eRY0NDLzjRH1C4mGrPKfjkfFjKQWsn8S9ZgyRZOM',
    GOOGLE_PAY_ENVIRONMENT: 'PRODUCTION',
    GOOGLE_RECAPTCHA_SITE_KEY: '6Le_sfQfAAAAAJcVIkTpGSeCNl_attJZj5h8dZ72',
    MPARTICLE_ENV: 'production',
    PAYPAL_CLIENT_ID: 'AXRTXBC_z-bzxicg_SoaSGhgit6AtsBXs6gk0sZsGOdRCAytN_exHANNHMgd',
    RQL_BASE_URL: 'https://rql.reverb.com/graphql',
    GQL_GATEWAY_BASE_URL: 'https://gql.reverb.com/graphql',
    ACCERTIFY_DEVICE_JS_URL: 'https://prod.accdab.net/cdn/cs/D9J_UsLCpf3JBlvw3wNS-8b3a0o.js',
    ROKT_ACCOUNT_ID: '3266439016794701825',
    ROKT_SANDBOX: false,
  },
  integration_tests: {
    ADYEN_CLIENT_KEY: 'test_47S7SOSMIFB7HJ5XU6IGOSJ77AMKV4EJ',
    ADYEN_ENVIRONMENT: 'test',
    AFFIRM_PUBLIC_API_KEY: '2ERE596TZQHPQNP4',
    AFFIRM_GLOBAL_PUBLIC_API_KEY: "EIWHFKW3HBM6C1ZS",
    AFFIRM_SCRIPT_URL: 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js',
    AMAZON_S3_SERVER: 's3.amazonaws.com',
    CLOUDINARY_UPLOAD_URL: 'https://api.cloudinary.com/v1_1/reverb-preprod-cld/upload',
    CMS_ADMIN_URL: 'https://cms-unstable.reverb.tools',
    CORE_API_ORIGIN: 'http://localhost:4999',
    CORE_WEB_ORIGIN: 'http://localhost:4999',
    ECHIDNA_BASE_URL: 'https://echidna-staging.reverb.tools',
    ENABLE_CRITEO: true,
    ENABLE_EPPO: false,
    ENABLE_RUM: false,
    ENABLE_SENTRY: false,
    EPPO_API_KEY: 'PARMlM1q4KpR1I6xCziYj7rzcw-4Gv7-SRH9G7qjUTo',
    GOOGLE_PAY_ENVIRONMENT: 'TEST',
    GOOGLE_RECAPTCHA_SITE_KEY: '6LcbY0YoAAAAAGTMVe7PiEKO4YO1apk1Ziwu3bI_',
    MPARTICLE_ENV: 'staging',
    PAYPAL_CLIENT_ID: 'AflEZ3wE4dcs1IcOsbd6nQjyVOxT_7oGymUULWEK5l8NSaS-V2J-3sKTIUBJ1Td1nmS_h9OQyfGn2I5h',
    RQL_BASE_URL: 'http://localhost:5544/graphql',
    GQL_GATEWAY_BASE_URL: 'http://localhost:5544/graphql',
    ACCERTIFY_DEVICE_JS_URL: 'https://test.accdab.net/cdn/cs/GMGwycA1IdZx8fX0NWFLtsD3hCI.js',
    ROKT_ACCOUNT_ID: '3266439016794701825',
    ROKT_SANDBOX: true,
  },
  test: {
    ADYEN_CLIENT_KEY: 'test_47S7SOSMIFB7HJ5XU6IGOSJ77AMKV4EJ',
    ADYEN_ENVIRONMENT: 'test',
    AFFIRM_PUBLIC_API_KEY: '2ERE596TZQHPQNP4',
    AFFIRM_GLOBAL_PUBLIC_API_KEY: "EIWHFKW3HBM6C1ZS",
    AFFIRM_SCRIPT_URL: 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js',
    AMAZON_S3_SERVER: 's3.amazonaws.com',
    CLOUDINARY_UPLOAD_URL: 'https://api.cloudinary.com/v1_1/reverb-preprod-cld/upload',
    CMS_ADMIN_URL: 'https://cms.devo.test',
    CORE_API_ORIGIN: 'https://api.test.host:4000',
    CORE_WEB_ORIGIN: 'https://reverb.site:4000',
    ECHIDNA_BASE_URL: 'https://echidna.reverb.test',
    ENABLE_CRITEO: false,
    ENABLE_EPPO: false,
    ENABLE_RUM: false,
    ENABLE_SENTRY: false,
    EPPO_API_KEY: 'PARMlM1q4KpR1I6xCziYj7rzcw-4Gv7-SRH9G7qjUTo',
    GOOGLE_PAY_ENVIRONMENT: 'TEST',
    GOOGLE_RECAPTCHA_SITE_KEY: 'test_6LcbY0YoAAAAAGTMVe7PiEKO4YO1apk1Ziwu3bI_',
    MPARTICLE_ENV: null,
    PAYPAL_CLIENT_ID: 'AflEZ3wE4dcs1IcOsbd6nQjyVOxT_7oGymUULWEK5l8NSaS-V2J-3sKTIUBJ1Td1nmS_h9OQyfGn2I5h',
    RQL_BASE_URL: 'http://localhost:8181/graphql',
    GQL_GATEWAY_BASE_URL: 'https://graphql-gateway.devo.test/graphql',
    ACCERTIFY_DEVICE_JS_URL: 'https://test.accdab.net/cdn/cs/GMGwycA1IdZx8fX0NWFLtsD3hCI.js',
    ROKT_ACCOUNT_ID: '3266439016794701825',
    ROKT_SANDBOX: true,
  },
};

const configuredEnv = {
  ...deployEnvVars[DEPLOY_ENV],
  ...overrides,
};

export const {
  ADYEN_CLIENT_KEY,
  ADYEN_ENVIRONMENT,
  AFFIRM_PUBLIC_API_KEY,
  AFFIRM_SCRIPT_URL,
  AFFIRM_GLOBAL_PUBLIC_API_KEY,
  AMAZON_S3_SERVER,
  CLOUDINARY_UPLOAD_URL,
  CMS_ADMIN_URL,
  CORE_API_ORIGIN,
  CORE_WEB_ORIGIN,
  ECHIDNA_BASE_URL,
  ENABLE_CRITEO,
  ENABLE_EPPO,
  ENABLE_RUM,
  ENABLE_SENTRY,
  EPPO_API_KEY,
  GOOGLE_PAY_ENVIRONMENT,
  GOOGLE_RECAPTCHA_SITE_KEY,
  MPARTICLE_ENV,
  PAYPAL_CLIENT_ID,
  RQL_BASE_URL,
  GQL_GATEWAY_BASE_URL,
  ACCERTIFY_DEVICE_JS_URL,
  ROKT_ACCOUNT_ID,
  ROKT_SANDBOX,
} = configuredEnv;
