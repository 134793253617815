import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

import { RCIconButton } from '../';
import { CloseIcon, TimesCircleAltIcon } from '../../icons/react';

interface IProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  'aria-label'?: string;
  inverted?: boolean;
  useAltIcon?: boolean;
  showTooltip?: boolean;
}

export const RCCloseButton = React.forwardRef<HTMLButtonElement, IProps>(
  function RCCloseButton({
    onClick,
    'aria-label': ariaLabel,
    inverted = false,
    useAltIcon = false,
    showTooltip = false,
  }: IProps & React.ButtonHTMLAttributes<HTMLButtonElement>, ref) {
    return (
      <div className={classNames(
        'rc-close-button',
        { 'rc-close-button--alt': useAltIcon },
      )}>
        <RCIconButton
          label={ariaLabel || I18n.t('cadence.RCCloseButton.defaultLabel')}
          onClick={onClick}
          inverted={inverted}
          svgComponent={!useAltIcon ? CloseIcon : TimesCircleAltIcon}
          hideTooltip={!showTooltip}
          ref={ref}
        />
      </div>
    );
  },
);
