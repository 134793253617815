import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgSliders = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.874 8H25a1 1 0 1 1 0 2H14.874a4.002 4.002 0 0 1-7.748 0H3a1 1 0 0 1 0-2h4.126a4.002 4.002 0 0 1 7.748 0ZM3 18a1 1 0 1 0 0 2h12.126a4.002 4.002 0 0 0 7.748 0H25a1 1 0 1 0 0-2h-2.126a4.002 4.002 0 0 0-7.748 0H3Zm18 1a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-10-8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
    />
  </svg>
);
export default SvgSliders;
