import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgYoutubePlay = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#a)">
      <path d="m11.11 17.625 7.562-3.906-7.563-3.953v7.859zM14 4.156c1.75 0 3.44.024 5.07.07 1.63.047 2.826.097 3.586.149l1.14.063a7.423 7.423 0 0 1 .626.07c.073.015.195.039.367.07.172.031.32.073.445.125.125.052.271.12.438.203.166.084.328.185.484.305a3.713 3.713 0 0 1 .695.703c.1.13.25.435.454.914.203.48.34 1.005.414 1.578.083.667.148 1.378.195 2.133.047.755.075 1.346.086 1.774v2.75c.01 1.51-.084 3.02-.281 4.53a6.237 6.237 0 0 1-.391 1.555c-.188.464-.354.784-.5.961l-.219.266a3.719 3.719 0 0 1-.453.414c-.156.12-.318.219-.484.297-.167.078-.313.143-.438.195a2.173 2.173 0 0 1-.445.125l-.375.07a9.39 9.39 0 0 1-.617.07c-2.615.199-5.88.298-9.797.298a288.094 288.094 0 0 1-5.617-.102 122.14 122.14 0 0 1-3.133-.117l-.766-.063-.562-.062a7.442 7.442 0 0 1-.852-.156 4.8 4.8 0 0 1-.797-.328 2.988 2.988 0 0 1-1.125-.93c-.099-.13-.25-.435-.453-.914a5.95 5.95 0 0 1-.414-1.578 34.435 34.435 0 0 1-.195-2.133A50.184 50.184 0 0 1 0 15.688v-2.75c-.01-1.51.083-3.021.281-4.532a6.234 6.234 0 0 1 .39-1.554c.188-.464.355-.784.5-.961l.22-.266c.145-.156.296-.294.453-.414a3.01 3.01 0 0 1 .484-.305c.167-.083.312-.15.437-.203.125-.052.274-.094.446-.125s.294-.055.367-.07a3.48 3.48 0 0 1 .36-.047l.265-.024c2.615-.187 5.88-.28 9.797-.28z" />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h28v28H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgYoutubePlay;
