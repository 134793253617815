import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgCommentO = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14 6c-2.125 0-4.112.362-5.96 1.086-1.85.724-3.319 1.7-4.407 2.93C2.544 11.245 2 12.573 2 14c0 1.167.372 2.279 1.117 3.336s1.794 1.971 3.149 2.742l1.359.781-.422 1.5c-.25.948-.614 1.844-1.094 2.688a15.41 15.41 0 0 0 4.297-2.672l.672-.594.89.094c.72.083 1.397.125 2.032.125 2.125 0 4.112-.362 5.96-1.086 1.85-.724 3.319-1.7 4.407-2.93C25.456 16.755 26 15.427 26 14s-.544-2.755-1.633-3.984c-1.088-1.23-2.557-2.206-4.406-2.93C18.112 6.362 16.125 6 14 6zm14 8c0 1.813-.625 3.487-1.875 5.023-1.25 1.537-2.948 2.75-5.094 3.641C18.885 23.554 16.541 24 14 24c-.73 0-1.484-.042-2.266-.125-2.062 1.823-4.458 3.083-7.187 3.781-.51.146-1.104.26-1.781.344h-.079a.624.624 0 0 1-.421-.164.796.796 0 0 1-.25-.43v-.015c-.032-.042-.034-.104-.008-.188a.418.418 0 0 0 .031-.156c-.005-.021.018-.07.07-.149l.094-.14.11-.133.124-.14a231.098 231.098 0 0 1 1.023-1.133c.11-.12.272-.326.485-.618.214-.291.383-.557.508-.797s.266-.546.422-.921a9.86 9.86 0 0 0 .406-1.188c-1.635-.927-2.924-2.073-3.867-3.437C.471 17.026 0 15.563 0 14c0-1.813.625-3.487 1.875-5.023 1.25-1.537 2.948-2.75 5.094-3.641C9.115 4.446 11.459 4 14 4c2.542 0 4.885.445 7.031 1.336 2.146.89 3.844 2.104 5.094 3.64C27.375 10.514 28 12.188 28 14z" />
  </svg>
);
export default SvgCommentO;
