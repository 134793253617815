import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgNudgeBox = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.335 1.78a.75.75 0 0 0-.67 0l-7 3.5a.75.75 0 0 0-.415.67v8.167c0 .284.16.543.415.67l7 3.5a.75.75 0 0 0 .67 0l7-3.5a.75.75 0 0 0 .415-.67V5.95a.75.75 0 0 0-.415-.67l-7-3.5ZM3.75 6.843v6.81l5.75 2.874V9.48L3.75 6.844Zm6.75 2.635v7.05l5.75-2.876v-6.81L10.5 9.48Zm5.051-3.415-2.32-1.16-5.29 2.76L10 8.608l5.551-2.544ZM10 3.288l2.127 1.064L6.79 7.137 4.449 6.064 10 3.288Z"
    />
  </svg>
);
export default SvgNudgeBox;
