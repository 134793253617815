import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgTags = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={28}
    viewBox="0 0 32 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M8 7c0-.552-.195-1.023-.586-1.414A1.927 1.927 0 0 0 6 5c-.552 0-1.023.195-1.414.586C4.196 5.976 4 6.448 4 7c0 .552.195 1.023.586 1.414.39.39.862.586 1.414.586.552 0 1.023-.195 1.414-.586C7.804 8.024 8 7.552 8 7zm16.672 9c0 .552-.193 1.02-.578 1.406l-7.672 7.688c-.406.385-.88.578-1.422.578-.552 0-1.02-.193-1.406-.578L2.422 13.906c-.396-.385-.732-.911-1.008-1.578C1.138 11.662 1 11.052 1 10.5V4c0-.542.198-1.01.594-1.406A1.922 1.922 0 0 1 3 2h6.5c.552 0 1.162.138 1.828.414.667.276 1.198.612 1.594 1.008l11.172 11.156c.385.406.578.88.578 1.422zm6 0c0 .552-.193 1.02-.578 1.406l-7.672 7.688c-.406.385-.88.578-1.422.578-.375 0-.682-.073-.922-.219-.24-.146-.515-.38-.828-.703l7.344-7.344c.385-.385.578-.854.578-1.406 0-.542-.193-1.016-.578-1.422L15.422 3.422c-.396-.396-.927-.732-1.594-1.008C13.162 2.138 12.552 2 12 2h3.5c.552 0 1.162.138 1.828.414.667.276 1.198.612 1.594 1.008l11.172 11.156c.385.406.578.88.578 1.422z" />
  </svg>
);
export default SvgTags;
