/*
 * Adapted from detect-node@2.0.3: https://github.com/iliakan/detect-node/blob/6cb6c11146b0701800c0e38d13501d889972d90e/index.js
 *
 * In jsdom environments like RRE uses, `global.process` is the actual node process object while
 * `process` is a POJO that fails this check.
 * We should look into better node detection mechanisms for RRE but this works for now
 */

// eslint-disable-next-line import/no-mutable-exports
let isNode;

try {
  isNode = Object.prototype.toString.call(global.process) === '[object process]';
} catch (e) {
  isNode = false;
}

if (process.env.NODE_ENV === 'test') {
  isNode = false;
}

export default isNode;
