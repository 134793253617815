import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgChevronUp = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="m26.297 20.797-2.594 2.578a.961.961 0 0 1-.703.297.961.961 0 0 1-.703-.297L14 15.078l-8.297 8.297a.961.961 0 0 1-.703.297.961.961 0 0 1-.703-.297l-2.594-2.578a.97.97 0 0 1-.297-.711.97.97 0 0 1 .297-.711L13.297 7.797A.961.961 0 0 1 14 7.5c.27 0 .505.099.703.297l11.594 11.578a.97.97 0 0 1 .297.71.97.97 0 0 1-.297.712z" />
  </svg>
);
export default SvgChevronUp;
