//
// README - Beta Component
//
// This component is marked "beta" and will go through some iterations
// before landing at a final solution. Please reach out to the Cadence team
// if you intend to use this in the meantime and we'll help you out!
//

import classNames from 'classnames';
import React from 'react';

import { GLOBAL_BORDER_RADIUS, GLOBAL_SPACE } from '../../design-tokens/build/rc-tokens-web-typescript';
import { IRCIconProps, RCIcon } from '../RCIcon/RCIcon';

type BORDER_RADIUS_SIZES = typeof GLOBAL_BORDER_RADIUS[number];
type PADDING_SIZES = typeof GLOBAL_SPACE[number];

interface RCBoxContextProps {
  colorFamily: RCBoxProps['colorFamily'];
  variant: RCBoxProps['variant'];
}

const RCBoxContext = React.createContext<RCBoxContextProps>(null);

const useRCBoxContext = () => {
  const context = React.useContext(RCBoxContext);

  return context;
};

export interface CurveStyle {
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right',
  size?: 'auto' | 'sm' | 'md' | 'lg' | 'xl',
}

export interface RCBoxProps {
  /** The spacing token to use for padding. */
  padding?: PADDING_SIZES;
  /** The border radius token to use. */
  borderRadius?: BORDER_RADIUS_SIZES;
  /** Adds a larger border radius in a single corner. Defaults to `false`.
   *  Use `true` to include a "curve" that scales with the box's border radius ('auto') and is positioned in the default corner ('bottom-left').
   *  Optionally specify the corner or size. */
  curve?: boolean | CurveStyle;
  /** The color family used for the box's styling. Primary site colors are used by default. */
  colorFamily?: 'default' | 'gray' | 'yellow' | 'tweed-orange' | 'vu-orange' | 'teal' | 'blue' | 'kick-blue' | 'sentiment-negative';
  /** The style variant. Defaults to 'muted'. */
  variant?: 'outlined' | 'subtle' | 'subtle-outlined' | 'muted' | 'bold';
  /** Optionally override the default ('block') display style of the box. */
  display?: 'block' | 'inline-block';
}

export function RCBox({
  padding = 'rc-space-0',
  borderRadius = 'rc-border-radius-0',
  curve: curveProp = false,
  colorFamily = 'default',
  variant = 'muted',
  display = 'block',
  children,
}: React.PropsWithChildren<RCBoxProps>) {
  const curveDefaults: CurveStyle = {
    position: 'bottom-right',
    size: 'auto',
  };
  const curve = curveProp === false ? null : (curveProp === true ? curveDefaults : { ...curveDefaults, ...curveProp });

  const styleOverrides = {
    ...getBorderRadiusStyle(borderRadius),
    ...getCurveStyle(curve, borderRadius),
    ...getPaddingStyle(padding),
    ...getDisplayStyle(display),
  } as React.CSSProperties;

  return (
    <RCBoxContext.Provider value={{ colorFamily, variant }}>
      <div
        className={classNames(
          'rc-box',
          {
            [`rc-box--curve-${curve?.position}`]: curve,
            [`rc-box--${colorFamily}`]: colorFamily !== 'default',
            [`rc-box--${variant}`]: variant,
          },
        )}
        style={styleOverrides}
      >
        {children}
      </div>
    </RCBoxContext.Provider>
  );
}

interface RCBoxIconProps extends IRCIconProps {
  /** Controls whether the icon should be themed to match its parent RCBox. Defaults to `true`. */
  themed?: boolean;
}

function RCBoxIcon({
  themed = true,
  svgComponent = undefined,
  ...iconProps
}: RCBoxIconProps) {

  const context = useRCBoxContext();
  const iconTheme = themed ? context : null;
  const icon = <RCIcon svgComponent={svgComponent} {...iconProps} />;

  const styleOverrides = {
    ...getIconTheme(iconTheme),
  } as React.CSSProperties;

  if (!iconTheme) return icon;

  return (
    <div className="rc-box__icon" style={styleOverrides}>
      {icon}
    </div>
  );
}

RCBox.Icon = RCBoxIcon;

function getIconTheme(theme: RCBoxContextProps) {
  if (!theme) return {};

  const { colorFamily, variant } = theme;

  const simplifiedVariant = variant === 'subtle-outlined' ? 'subtle' : variant;
  const fallbackToken = `var(--rc-color-fill-box-${colorFamily})`; // e.g. --rc-color-fill-box-teal

  return {
    '--box-icon-color': `var(--rc-color-fill-box-${colorFamily}-${simplifiedVariant}, ${fallbackToken})`, // e.g. --rc-color-fill-box-teal-bold
  };
}

function getBorderRadiusStyle(borderRadius: RCBoxProps['borderRadius']) {
  return borderRadius === 'rc-border-radius-0' ? {} : { '--box-border-radius': `var(--${borderRadius})` };
}

function getCurveStyle(curve: CurveStyle, borderRadius: RCBoxProps['borderRadius']) {
  if (!curve) return {};
  if (borderRadius === 'rc-border-radius-full') return {};

  const curveSizeMap = {
    '0': '1.6rem',
    sm: '2.4rem',
    md: '3.2rem',
    lg: '4.8rem',
    xl: '6.4rem',
  };

  const borderRadiusSize = borderRadius.replace('rc-border-radius-', ''); // e.g. 'sm'
  const curveSize = curve.size !== 'auto' ? curve.size : borderRadiusSize;
  const curveRadius = curveSizeMap[curveSize] || `var(--${borderRadius}`;

  return { '--box-curve-radius': `${curveRadius}` };
}

function getPaddingStyle(padding: RCBoxProps['padding']) {
  return padding === 'rc-space-0' ? {} : { '--box-padding': `var(--${padding})` };
}

function getDisplayStyle(display: RCBoxProps['display']) {
  return display === 'block' ? {} : { '--box-display': `${display}` };
}
