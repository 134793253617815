import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgCrop = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M9.703 20H19v-9.297L9.703 20zM9 19.297 18.297 10H9v9.297zM27 20.5v3a.487.487 0 0 1-.14.36.487.487 0 0 1-.36.14H23v3.5a.487.487 0 0 1-.14.36.487.487 0 0 1-.36.14h-3a.487.487 0 0 1-.36-.14.487.487 0 0 1-.14-.36V24H5.5a.487.487 0 0 1-.36-.14.487.487 0 0 1-.14-.36V10H1.5a.487.487 0 0 1-.36-.14A.487.487 0 0 1 1 9.5v-3a.49.49 0 0 1 .14-.36A.487.487 0 0 1 1.5 6H5V2.5a.49.49 0 0 1 .14-.36A.487.487 0 0 1 5.5 2h3a.49.49 0 0 1 .36.14.49.49 0 0 1 .14.36V6h13.297l3.844-3.86A.522.522 0 0 1 26.5 2c.135 0 .255.047.36.14.093.105.14.225.14.36a.522.522 0 0 1-.14.36L23 6.702V20h3.5a.49.49 0 0 1 .36.14.49.49 0 0 1 .14.36z" />
  </svg>
);
export default SvgCrop;
