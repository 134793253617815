//
// README - Beta Component
//
// This component is marked "beta" and will go through some iterations
// before landing at a final solution. Please reach out to the Cadence team
// if you intend to use this in the meantime and we'll help you out!
//

import React from 'react';
import classNames from 'classnames';

import { useId } from '@floating-ui/react';

import { RCText, RCIcon } from '../';
import { RCModalBase, RCModalBaseProps } from '../internalComponents/RCModalBase/RCModalBase';
import {
  RCModalActionGroup,
  RCModalActionGroupProps,
} from '../internalComponents/RCModalActionGroup/RCModalActionGroup';


interface RCModalProps {
  /** Optionally provide a trigger to have the modal handle onClick behavior and state changes. */
  trigger?: RCModalBaseProps['trigger'];
  /** Can be used to control the state of the modal when paired with `onOpenChange`. */
  isOpen?: RCModalBaseProps['isOpen'];
  /** Can be used to control the state of the modal when paired with `isOpen`.
   *  Alternatively, can be used as a callback to track state changes.
  */
  onOpenChange?: RCModalBaseProps['onOpenChange'];
  /** The title of the modal. */
  title: string;
  /** Optionally display an icon above the title. Icon will be colored to match the theme of the primary action */
  svgComponent?: (props: React.SVGProps<SVGElement>) => React.ReactElement;
  /** Actions to be appended at the end of the modal. Uses `RCModalActionGroup`.
   *
   * Includes both `primary` and `secondary` actions by default, using default text values of `Save` and `Cancel` respectively.
   */
  actions?: RCModalActionGroupProps;
}

export function RCModalConfirmation({
  trigger = undefined,
  isOpen = undefined,
  onOpenChange = undefined,
  title = undefined,
  svgComponent = undefined,
  actions = {},
  children = undefined,
}: React.PropsWithChildren<RCModalProps>) {
  const { primary, secondary } = actions;
  const primaryAction = typeof primary === 'object' ? primary : {};
  const secondaryAction = typeof secondary === 'object' ? secondary : {};

  function shouldFocusPrimary() {
    if (primaryAction.theme === 'danger') return false;
    if (secondaryAction.initialFocus === true) return false;
    if (primaryAction.initialFocus === false) return false;
    return true;
  }

  function getInitialFocus(bool) {
    if (bool) return { initialFocus: true };
    return {};
  }
  const focusPrimary = getInitialFocus(shouldFocusPrimary());
  const focusSecondary = getInitialFocus(!shouldFocusPrimary());

  const modifiedActions = {
    primary: { ...primaryAction, ...focusPrimary },
    secondary: { ...secondaryAction, ...focusSecondary },
  } as RCModalActionGroupProps;

  const dialogId = useId();
  const titleId = `${dialogId}-title`;
  const descriptionId = `${dialogId}-description`;
  return (
    <RCModalBase
      trigger={trigger}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      aria-labelledby={titleId}
      aria-describedby={descriptionId}
      role="alertdialog"
      useDismissProps={{ outsidePress: false }}
    >
      <div className="rc-modal-confirmation">
        <div className="rc-modal-confirmation__main">
          {svgComponent &&
            <div className={classNames(
              'rc-modal-confirmation__icon',
              { [`rc-modal-confirmation__icon--${primaryAction.theme}`]: primaryAction.theme },
            )}>
              <RCIcon svgComponent={svgComponent} />
            </div>
          }
          <div className="rc-modal-confirmation__text">
            <div className="rc-modal-confirmation__title" id={titleId}>
              <RCText.Title size="600">
                {title}
              </RCText.Title>
            </div>
            <div className="rc-modal-confirmation__description" id={descriptionId}>
              <RCText>{children}</RCText>
            </div>
          </div>
        </div>
        <div className="rc-modal-confirmation__actions">
          <RCModalActionGroup {...modifiedActions} />
        </div>
      </div>
    </RCModalBase>
  );
}
