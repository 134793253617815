import React from 'react';
import classNames from 'classnames';
import { useId } from '@floating-ui/react';

import { RCNonModalDialogBase } from '../internalComponents/RCNonModalDialogBase';

type FloatingUIPositions = 'bottom' | 'bottom-start' | 'bottom-end' | 'right';

interface RCPopoverProps {
  /** Optional – Popovers manage their own state.
   * If you need to have state managed externally, use this in conjunction with onOpenChange  */
  isOpen?: boolean;

  /**
   * DEPRECATED – Use onOpenChange
   */
  onDismiss?: Function;

  /**
   * Optional – Open / Close event handler.
   * - (open = true) Can be used in place of an anchor onClick handler to track open events.
   * - (open = false) Should be used in place of onDismiss for handling close events.
   */
  onOpenChange?: (open: boolean) => void;

  /** *Desktop Only* - A formatted block of content that renders inside of the popover between the header and dismiss button*/
  children: React.ReactNode;

  /** The element to which the popover will point & anchor. */
  anchor: JSX.Element;

  /** The popover automatically positions itself,
   * but if you need to ensure it is always flush left or right (e.g. to avoid covering up key UI, use this. */
  position?: FloatingUIPositions;

  /** Decreases the opacity a bit. Use this when the contents inside the popover have some sort of async process
   * that occurs while the popover is still open. */
  loading?: boolean;

  /** "Trapping" focus keeps the focus tab order locked to contents inside the Popover.
   * When `true`, a user is required to dismiss the popover before they can tab to other elements on the page.
   * Change to `false` any time the user doesn't have a way to dismiss the popover with a keyboard.
   */
  trapFocus?: boolean;
}

/**
 * Our general Popover component. Auto-positioning and accessibility-enhanced. Use this for submenus, dropdowns, and the like.
 */
export function RCPopover({
  isOpen = undefined,
  onOpenChange = undefined,
  onDismiss = undefined,
  position = 'bottom-start',
  children,
  anchor,
  loading = false,
  trapFocus = false,
}: RCPopoverProps) {
  const id = useId();

  return (
    <RCNonModalDialogBase
      id={id}
      isOpen={isOpen}
      onOpenChange={
        onOpenChange ? onOpenChange : (
          onDismiss ? () => onDismiss() : undefined
        )
      }
      position={position}
      anchor={anchor}
      trapFocus={trapFocus}
      variant={onDismiss ? 'legacy' : 'default'}
    >
      <div
        className={classNames(
          'rc-popover',
          { 'rc-popover--loading': loading },
        )}
      >
        <div className="rc-popover__content">
          {children}
        </div>
      </div>
    </RCNonModalDialogBase>
  );
}
