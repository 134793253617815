/* eslint-disable inclusive-language/use-inclusive-words */
import accounting from 'accounting';
import I18n from 'i18n-js';

import Currency from './currency';
import { CurrencyDescription, CURRENCY_DESCRIPTION } from './currency_descriptions';
import { DE, EN, ES, FR, IT, JA } from './constants';
import { core_apimessages_Money as Money } from './gql/graphql';

export interface IMoneyConfig {
  decimalSeparator?: string;
  thousandsSeparator?: string; // AKA delimiter
  precision?: number;
  format?: string;
}

export interface AmountSymbolMoneyObject {
  amount?: string; // e.g. '100.50'
  symbol?: string; // e.g. '$', '€'
}

export interface MoneyInput {
  amountCents?: number;
  currency?: string // e.g. 'USD', 'JPY'
}

// Adapted from the 'number.currency.format' locale settings
// that our backend vendorizes from rails-i18n:
// https://github.com/reverbdotcom/rails-i18n/tree/master/rails/locale
export const LOCALE_CONFIGS: Record<string, IMoneyConfig> = Object.freeze({
  [DE]: {
    decimalSeparator: ',',
    thousandsSeparator: '.',
    precision: 2,
    format: '%v %s',
  },
  [EN]: {
    decimalSeparator: '.',
    thousandsSeparator: ',',
    precision: 2,
    format: '%s%v',
  },
  [ES]: {
    decimalSeparator: ',',
    thousandsSeparator: '.',
    precision: 2,
    format: '%v %s',
  },
  [FR]: {
    decimalSeparator: ',',
    thousandsSeparator: ' ',
    precision: 2,
    format: '%v %s',
  },
  [IT]: {
    decimalSeparator: ',',
    thousandsSeparator: '.',
    precision: 2,
    format: '%v %s',
  },
  [JA]: {
    decimalSeparator: '.',
    thousandsSeparator: ',',
    precision: 0,
    format: '%v%s',
  },
});

export function getConfig(overrides = {}): IMoneyConfig {
  const { locale = EN } = I18n;
  const formattedLocale = locale?.split('-')[0];

  return {
    ...LOCALE_CONFIGS[formattedLocale],
    ...overrides,
  };
}

export function formatAmount(money: AmountSymbolMoneyObject, overrideConfig?: IMoneyConfig): string {
  const { decimalSeparator, thousandsSeparator, precision, format } = getConfig(overrideConfig);

  return accounting.formatMoney(
    money.amount,
    {
      precision,
      format,
      decimal: decimalSeparator,
      thousand: thousandsSeparator,
      symbol: money.symbol,
    },
  );
}

export function formatAmountCents(money: MoneyInput, overrideConfig?: IMoneyConfig): string {
  return parseAmountCents(money.amountCents, money.currency, overrideConfig).display;
}

export function formatNumber(value: number): string {
  const { decimalSeparator, thousandsSeparator, precision } = getConfig();

  return accounting.formatNumber(
    value,
    {
      precision,
      decimal: decimalSeparator,
      thousand: thousandsSeparator,
    },
  );
}

export function unformatNumber(inputAmount: string, locale: string = null): number {
  const { decimalSeparator: separator } = locale ? LOCALE_CONFIGS[locale] : getConfig();
  return accounting.unformat(inputAmount, separator);
}

export function parseAmount(inputAmount: string, currency: string, overrideConfig?: IMoneyConfig): Money {
  const config = getConfig(overrideConfig);
  const separator = config.decimalSeparator;
  const parsedAmount = accounting.unformat(inputAmount, separator);
  const description = Currency.get(currency);
  const { symbol } = description;

  const amount = round(parsedAmount, description.precision).toString();

  return {
    currency,
    symbol,
    amount,
    amountCents: minorUnits(parsedAmount, description),
    display: formatAmount({ amount, symbol }, config),
  };
}

export function parseAmountCents(inputAmountCents: number, currency: string, overrideConfig?: IMoneyConfig): Money {
  const { subunitToUnit, precision, symbol } = Currency.get(currency);

  const minorUnits = round(inputAmountCents, 0);
  const majorUnits = round(minorUnits / subunitToUnit, subunitToUnit);

  const amount = majorUnits.toFixed(precision);

  return {
    currency,
    symbol,
    amount,
    amountCents: minorUnits,
    display: formatAmount({ amount, symbol }, overrideConfig),
  };
}

export function hasPeriodDecimalSeparator() {
  return getConfig().decimalSeparator === '.';
}

export function isEmptyMoneyResponse(money: MoneyInput): boolean {
  return money?.amountCents === undefined || money?.amountCents === null;
}

export function isZeroAmount(input) {
  if (!input && input !== 0) { return false; }

  return Number(input) === 0;
}

function round(value: number, precision: number) {
  return shift(Math.round(shift(value, +precision)), -precision);
}

function minorUnits(amount: number, currency: CurrencyDescription): number {
  return round(amount * currency.subunitToUnit, 0);
}

function shift(value: number, precision: number) {
  const numArray = (`${value}`).split('e');
  return +(`${numArray[0]}e${(numArray[1] ? (+numArray[1] + precision) : precision)}`);
}

// this function will prioritize the Norwegian Kroner (NOK) over the Swedish Krona (SEK) if the symbol is 'kr'
export function symbolToCurrency(symbol: string): string | undefined {
  return  Object.keys(CURRENCY_DESCRIPTION).find(currency => CURRENCY_DESCRIPTION[currency].symbol === symbol);
}
