import React, { useLayoutEffect } from 'react';

import { MOBILE_MAX_WIDTH_PX, TABLET_MAX_WIDTH_PX } from '../../constants';

type ScreenSize = 'mobile' | 'tablet';

const MEDIA_QUERIES = {
  mobile: `(max-width: ${MOBILE_MAX_WIDTH_PX}px)`,
  tablet: `(min-width: ${MOBILE_MAX_WIDTH_PX + 1}px) and (max-width: ${TABLET_MAX_WIDTH_PX}px)`,
};

/**
 *  This hook returns a boolean value based on the CSS Media Query used to represent a given screen size.
 *  Initial renders, server rendering, and when no window is detected it returns 'null'.
 *
 *  It should primarily be used for code pivots where it's impractical to use CSS directly.
 */
export function useMediaQuery(size: ScreenSize): boolean {
  const [isSize, setIsSize] = React.useState(null);

  useLayoutEffect(() => {
    const query = window?.matchMedia?.(MEDIA_QUERIES[size]);
    setIsSize(query?.matches);

    function handleChange(event: MediaQueryListEvent) {
      setIsSize(event.matches);
    }

    query?.addEventListener('change', handleChange);

    return () => {
      query?.removeEventListener('change', handleChange);
    };
  }, [size]);

  return isSize;
}
