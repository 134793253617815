import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgHeartEmpty = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m14 9.793-1.42-1.431c-.467-.472-1.158-1.093-1.952-1.586C9.816 6.272 9.053 6 8.402 6c-1.604 0-2.648.471-3.29 1.112C4.473 7.752 4 8.795 4 10.398c0 1.067.494 2.177 1.143 3.137a9.616 9.616 0 0 0 1.24 1.493.032.032 0 0 1 .003.004l.036.032 7.579 7.464 7.53-7.403.012-.012c.167-.165.739-.726 1.312-1.574.651-.965 1.145-2.074 1.145-3.14 0-1.604-.472-2.647-1.113-3.287C22.246 6.472 21.202 6 19.598 6c-.651 0-1.414.272-2.226.776-.794.493-1.485 1.114-1.952 1.586L14 9.792Zm8.933 6.758-8.344 8.203A.822.822 0 0 1 14 25a.822.822 0 0 1-.59-.246l-8.356-8.23C4.946 16.427 2 13.68 2 10.397 2 6.393 4.397 4 8.402 4c2.228 0 4.323 1.705 5.433 2.79.058.056.113.11.165.163l.165-.164C15.275 5.705 17.37 4 19.598 4 23.603 4 26 6.393 26 10.398c0 3.155-2.724 5.817-3.04 6.127a2.161 2.161 0 0 0-.027.026Z"
    />
  </svg>
);
export default SvgHeartEmpty;
