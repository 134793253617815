import React, { useState, useMemo } from 'react';
import { useId } from '@floating-ui/react';
import I18n from 'i18n-js';

import { CommonInputProps } from '../types';
import { RCComboboxBase } from '../internalComponents/RCComboboxBase/RCComboboxBase';
import { RCComboboxOption, RCComboboxOptionData } from './RCComboboxOption';
import { RCFormGroup } from '../forms/RCFormGroup/RCFormGroup';

interface RCComboboxProps extends Omit <CommonInputProps, 'onChange'> {
  /** An array of `RCComboboxOptionData` items. They each have a label, value, and an optional disabled attribute. */
  options: RCComboboxOptionData[];

  /** Callback to do something with the selected option's data. */
  onOptionSelect?: (option: SelectedOption) => void;

  /** Allows for the selected option to be unset. Use this only in cases where it's necessary for the user to be able to clear the input. */
  allowInputDeselect?: boolean;

  /** The value of the selected option. */
  value: string;

  /** Standard input placeholder. */
  placeholder?: string;
}

type SelectedOption = RCComboboxOptionData | undefined;

export function RCCombobox({
  id: componentId,
  label,
  options,
  value,
  allowInputDeselect = false,
  name,
  disabled,
  required,
  errorText,
  helpText,
  tag,
  placeholder,
  onOptionSelect,
}: RCComboboxProps) {

  const getSelectedOptionFromValue = useMemo(() => {
    if (!value) return undefined;
    const filteredOptions = options.filter(o => {
      return o.value === value;
    });
    return filteredOptions?.[0] || undefined;
  }, [value, options]);

  const labelId = useId();
  const autoId = useId();
  const id = componentId || autoId;

  const [inputValue, setInputValue] = useState<string>(getSelectedOptionFromValue?.label || '');
  const [selected, setSelected] = useState<SelectedOption>(getSelectedOptionFromValue);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const filteredOptions = useMemo(() => {
    if (inputValue === selected?.label) return options;
    return options.filter(o => {
      return o.label.toLowerCase().includes(inputValue.toLowerCase());
    });
  }, [inputValue, options, selected]);

  // Allows for automatic re-selection of selected option
  const indexOfSelectedOption = useMemo(() => {
    if (!selected) return null;
    const filteredOptionValues = filteredOptions?.map(o => {
      return o.value;
    }) || [];
    const index = filteredOptionValues.indexOf(selected.value);
    if (index >= 0) return index;
    return null;
  }, [selected, filteredOptions]);

  function handleOptionSelect(option) {
    let newSelection = option;
    if (allowInputDeselect && option.value === selected?.value) {
      newSelection = undefined;
    }
    setSelected(newSelection);
    setIsOpen(false);
    setInputValue(newSelection?.label || '');
    onOptionSelect?.(option);
  }

  function handleInputChange(val) {
    let newVal = val;
    if (allowInputDeselect && selected && value === '') {
      newVal = '';
      setSelected(undefined);
    }
    setInputValue(newVal);
  }

  function resetInput() {
    const label = selected?.label || '';
    setInputValue(label);
    return setIsOpen(false);
  }

  function handleOpenChange(open: boolean) {
    setIsOpen(open);
    if (open === false) resetInput();
  }

  return (
    <RCFormGroup
      label={label}
      inputId={id}
      labelID={labelId}
      helpText={helpText}
      errorText={errorText}
      tag={tag}
    >
      <input
        id={id}
        name={name}
        required={required}
        disabled={disabled}
        value={selected?.value || ''}
        onChange={() => {}}
        aria-hidden={true}
        type="hidden"
      />
      <RCComboboxBase
        isOpen={isOpen}
        onOpenChange={(open) => handleOpenChange(open)}
        type="select"
        labelId={labelId}
        inputValue={inputValue}
        placeholder={placeholder}
        onInputChange={(v) => handleInputChange(v)}
        disabled={disabled}
        required={required}
        indexOfSelectedOption={indexOfSelectedOption}
      >
        {filteredOptions.map(o => (
          <RCComboboxOption
            key={o.value}
            value={o.value}
            label={o.label}
            selected={selected?.value === o.value}
            disabled={o.disabled}
            onClick={() => handleOptionSelect(o)}
          />
        ))}
        {filteredOptions.length === 0 &&
          <RCComboboxOption
            value=""
            label={I18n.t('cadence.RCCombobox.noResults')}
            disabled
            key="noResults"
          />
        }
      </RCComboboxBase>
    </RCFormGroup>
  );
}
