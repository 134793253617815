import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgElectric = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M13.307 3.32a.53.53 0 0 1 .374-.154h.941a.529.529 0 0 1 .53.528v4.838c.585 1.274 1.385 1.052 2.169.834.474-.131.943-.261 1.353-.056 1.09.544 1.237 2.629.545 4.122-1.076 2.291-.21 3.604.656 4.92l.013.02a4.247 4.247 0 0 1 .778 4.145c-.413 1.719-3.633 2.66-8.89 1.213-5.966-1.633-4.683-6.619-3.111-8.913 1.402-2.048.989-3.489.674-4.588-.038-.132-.075-.26-.107-.382a21.648 21.648 0 0 0-.1-.375c-.29-1.074-.635-2.356-.149-3.6.452-1.156 1.317-.087 2.052.822.15.185.295.364.43.516.793.894 1.687.303 1.687-1.034V3.694c0-.14.056-.274.155-.374zm.584 15.011c-.788.03-1.765.065-1.765 3.291a4.883 4.883 0 0 0 3.857-.1c.156-2.94-1.12-3.244-1.929-3.197a8.113 8.113 0 0 1-.163.006zm-1.737-6.96a.5.5 0 0 1 .5-.5h2.816a.5.5 0 0 1 0 1h-2.816a.5.5 0 0 1-.5-.5zm.5 3.389a.5.5 0 0 0 0 1h2.816a.5.5 0 1 0 0-1h-2.816z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgElectric;
