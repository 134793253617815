//
// README - Beta Component
//
// This component is marked "beta" and will go through some iterations
// before landing at a final solution. Please reach out to the Cadence team
// if you intend to use this in the meantime and we'll help you out!
//

import React from 'react';

import { RCFlex, RCText, useMediaQuery, buttonClassName } from '../';
import { RCBox, RCBoxProps, CurveStyle } from '../RCBox/RCBox';
import { IRCIconProps } from '../RCIcon/RCIcon';

interface RCInfoBoxProps {
  /** The color family used for the box's styling. Defaults to 'gray' */
  theme?: Extract<RCBoxProps['colorFamily'], 'gray' | 'vu-orange' | 'tweed-orange' | 'teal' | 'blue'>
  /** Optional SVG icon to be rendered using RCIcon. */
  svgComponent?: IRCIconProps['svgComponent'];
  /** Title text using Utility 450 Bold, or your own component. */
  title?: 'string' | React.ReactNode;
  /** Box style. Defaults to 'outlined'. */
  variant?: Extract<RCBoxProps['variant'], 'outlined' | 'subtle' | 'subtle-outlined'>;
  /** A button or link element. Styles will be overridden and replaced with RCButton 'filled' styles. */
  cta?: JSX.Element;
  /** Choose whether the optional icon is colored to match the theme (true) or use the text color (false). Defaults to true. */
  themedIcon?: boolean;
  /** Optionally include a 'curve' border radius in the bottom-right corner. Defaults to 'false'. */
  curve?: boolean;
}

export function RCInfoBox({
  theme = 'gray',
  variant = 'outlined',
  svgComponent = undefined,
  themedIcon = true,
  title = undefined,
  cta = undefined,
  curve = false,
  children = undefined,
}: React.PropsWithChildren<RCInfoBoxProps>) {
  const isMobile = useMediaQuery('mobile');

  // Conditionally scales up the size of the curve when it is presumed to be taller.
  const curveSize: CurveStyle['size'] = (isMobile && (cta || (title && children))) ? 'lg' : 'auto';

  return (
    <div className="rc-info-box">
      <RCBox
        padding={isMobile ? 'rc-space-4' : 'rc-space-5'}
        borderRadius="rc-border-radius-md"
        colorFamily={theme}
        variant={variant}
        curve={curve ? { size: curveSize } : false}
      >
        <RCFlex gap={isMobile ? '3' : '4'}>
          {svgComponent &&
            <div className="rc-info-box__icon">
              <RCBox.Icon
                svgComponent={svgComponent}
                size={isMobile ? 'rc-space-7' : 'rc-space-10'}
                themed={themedIcon}
              />
            </div>
          }
          <RCFlex
            gap="4"
            direction={isMobile ? 'col' : 'row'}
            align={isMobile ? undefined : 'center'}
            justify={isMobile ? 'center' : undefined}
            grow
          >
            <RCFlex direction="col" gap="2" grow={!isMobile}>
              {title && (
                typeof title !== 'string' ? title : title !== '' ? (
                  <RCText.Utility size="450" weight="bold">{title}</RCText.Utility>
                ) : null
              )}
              {children}
            </RCFlex>
            {cta &&
              <RCFlex.Item shrink={false}>
                {React.cloneElement(cta, {
                  className: buttonClassName({ variant: 'filled' }),
                })}
              </RCFlex.Item>
            }
          </RCFlex>
        </RCFlex>
      </RCBox>
    </div>
  );
}
