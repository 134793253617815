import React from 'react';

import { RCFormGroup } from '../../';
import { FormattedString, CommonInputProps } from '../../types';
import { RadioCardImpl } from './RadioCardImpl';
import type { IRCRadioProps } from '../RCRadioGroup/RadioImpl';

export interface GroupProps extends
  Omit<CommonInputProps, 'id' | 'name' | 'onChange' | 'inputId' | 'large'>,
  Pick<IRCRadioProps, 'iconAlignment'> {
  /** Unique ID passed through the the applicable HTML tags */
  id: string;

  /** Unique ID passed through the the applicable HTML tags */
  name: string;

  /** Passed through to the applicable HTML tag. */
  onChange: React.ChangeEventHandler<HTMLInputElement>;

  /** All children must be RCRadioCardGroup.Option */
  children: React.ReactNode;

  /** Current value of the input. Passed through to the `textarea` tag. */
  value: string;
}

export interface OptionProps {
  label: FormattedString;
  /** If the sublabel contiains interactive elements, you will need to add a `z-index: 1` to the element so that it sits above the card's `::before` element */
  sublabel?: React.ReactNode;
  value: string;
  disabled?: boolean;
  /** Additional card content below label and sublabel */
  children?: React.ReactNode;
  type?: any,
}

function Option(_props: OptionProps) {
  // Non-rendering component, output is generated by parent RadioGroup
  return null;
}

/**
 * Large clickable radio list. Use this for radio-style selection of large blocks of information, like credit cards or addresses.
 */
export function RCRadioCardGroup({
  children,
  errorText,
  helpText,
  id,
  label,
  name,
  onChange,
  tag,
  value,
  iconAlignment = 'top',
}: GroupProps) {
  const checkableGroupID = `rc-radio-card-group--${id}`;
  const validChildren: React.ReactElement[] = [];

  React.Children.forEach(children, (child: OptionProps) => {
    if (React.isValidElement(child) && child.type === Option) {
      validChildren.push(child);
    }
  });

  return (
    <RCFormGroup
      errorText={errorText}
      helpText={helpText}
      inputId={id}
      label={label}
      tag={tag}
      labelID={checkableGroupID}
    >
      <div
        className="rc-radio-card-group"
        role="radiogroup"
        aria-labelledby={checkableGroupID}
      >
        {validChildren.map(({ props: option }) => (
          // Iterate over <Option> children and render actual implementations
          <RadioCardImpl
            key={option.value}
            id={`${id}--${option.value}`}
            name={name}
            label={option.label}
            sublabel={option.sublabel}
            value={option.value}
            disabled={option.disabled}
            checked={value === option.value}
            onChange={onChange}
            iconAlignment={iconAlignment}
          >
            {option.children}
          </RadioCardImpl>
        ))}
      </div>
    </RCFormGroup>
  );
}

RCRadioCardGroup.Option = Option;
