import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

import { CommonInputProps } from '../../types';
import { SearchAltIcon, CloseIcon } from '../../../icons/react';
import { RCIconButton } from '../../';

interface RCSearchInputProps extends Omit <CommonInputProps,
  'name' | 'label' | 'helpText' | 'errorText' | 'id' | 'required' | 'tag'> {

  inputId?: string;

  labelId?: string;

  /** Overrides the placeholder text displayed when no input value is present. */
  placeholder?: string;

  /** The current input value */
  value: string;

  /** Use the default border radius or fully rounded ends – primarily for featured search inputs. */
  borderRadius?: 'default' | 'full';

  /** Removes the default border.
   *
   * _Caution: This should not be used without ensuring adequate contrast between background and input._ */
  borderless?: boolean;

  /** Callback function to update input value */
  onChange: React.ChangeEventHandler<HTMLInputElement>;

  /** Callback function that is fired when the search button is pressed or the input is submitted */
  onSubmit: () => void;

  /** Callback function that fires when the clear input button is pressed */
  onClear: () => void;
}

export function RCSearchInput({
  disabled,
  inputId = undefined,
  labelId = undefined,
  placeholder = I18n.t('cadence.RCCombobox.inputPlaceholder'),
  value,
  borderRadius = 'default',
  borderless = false,
  onChange,
  onClear,
  onSubmit,
}: RCSearchInputProps) {

  function handleFormSubmit(e) {
    e.preventDefault();
    onSubmit();
  }

  return (
    <div className={classNames(
      'rc-search-input',
      { 'rc-search-input--br-full': borderRadius === 'full' },
      { 'rc-search-input--borderless': borderless },
    )}>
      <form onSubmit={handleFormSubmit}>
        <div className="rc-search-input__inner">
          <input
            className="rc-search-input__input"
            type="text"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            id={inputId}
            aria-labelledby={labelId}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
            disabled={disabled}
          />
          <div className="rc-search-input__actions">
            {value.length > 0 &&
              <div className="rc-search-input__action rc-search-input__action--clear">
                <RCIconButton
                  variant="transparent"
                  size="small"
                  svgComponent={CloseIcon}
                  label={I18n.t('cadence.RCCombobox.clearInput')}
                  onClick={onClear}
                />
              </div>
            }
            <div className="rc-search-input__action rc-search-input__action--search">
              <RCIconButton
                variant="transparent"
                size="small"
                svgComponent={SearchAltIcon}
                label={I18n.t('cadence.RCCombobox.search')}
                isSubmit
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
