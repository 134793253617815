import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgTiktok = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21.806 6.81A5.746 5.746 0 0 1 19.19 2h-4.127l-.007 16.535a3.466 3.466 0 1 1-3.462-3.599c.347.002.693.056 1.024.16v-4.212a7.57 7.57 0 0 0-1.024-.074C7.407 10.81 4 14.216 4 18.405A7.606 7.606 0 0 0 11.595 26c4.188 0 7.595-3.406 7.595-7.595v-8.386a9.814 9.814 0 0 0 5.741 1.846V7.737a5.707 5.707 0 0 1-3.125-.928Z" />
  </svg>
);
export default SvgTiktok;
