import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgBan = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M22.5 13.953a8.29 8.29 0 0 0-1.36-4.61L9.36 21.11c1.427.927 2.973 1.391 4.64 1.391a8.233 8.233 0 0 0 3.305-.68 8.643 8.643 0 0 0 2.71-1.82 8.492 8.492 0 0 0 1.813-2.727 8.427 8.427 0 0 0 .672-3.32zM6.89 18.625 18.688 6.844C17.282 5.896 15.72 5.422 14 5.422c-1.542 0-2.963.38-4.266 1.14a8.437 8.437 0 0 0-3.093 3.11A8.392 8.392 0 0 0 5.5 13.953c0 1.688.464 3.245 1.39 4.672zM26 13.953c0 1.636-.318 3.198-.953 4.688-.636 1.49-1.487 2.77-2.555 3.843s-2.344 1.927-3.828 2.563A11.736 11.736 0 0 1 14 26c-1.625 0-3.18-.318-4.664-.953-1.484-.636-2.76-1.49-3.828-2.563-1.068-1.073-1.92-2.354-2.555-3.843A11.825 11.825 0 0 1 2 13.953c0-1.635.318-3.195.953-4.68C3.59 7.79 4.44 6.51 5.508 5.438 6.576 4.364 7.852 3.51 9.336 2.874A11.73 11.73 0 0 1 14 1.922c1.625 0 3.18.318 4.664.953 1.484.635 2.76 1.49 3.828 2.563 1.068 1.072 1.92 2.351 2.555 3.835.635 1.485.953 3.045.953 4.68z" />
  </svg>
);
export default SvgBan;
