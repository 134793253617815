import React from 'react';
import I18n from 'i18n-js';
import { Listing } from '../gql/graphql';

// Only listings that are in bump ui components will have a bumpKey token,
// and only listing in bump ui components should show the BumpTag.
// ie, marketplace bump row, featured listing on CSP, and bump new/used rows on CSP
// This function also determines if a listing was shown as bumped in analytics events.
export function shouldShowBumpTag(listing: Partial<Listing>): boolean {
  return !!listing?.bumpKey?.key;
}

export default function BumpTag() {
  return (
    <div className="bump-tag">
      <svg width="20" height="16" viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
        {/* eslint-disable max-len */}
        <path d="M18.2752941,0 L1.72392157,0 C0.775686275,0 0,0.78457038 0,1.74366602 L0,14.256334 C0,15.2154296 0.775686275,16 1.72392157,16 L18.2752941,16 C19.2243137,16 20,15.2154296 20,14.256334 L20,1.74366602 C20,0.78457038 19.2243137,0 18.2752941,0 L18.2752941,0 Z M4.36,13.8501661 C4.36,14.435619 3.89098039,14.9092171 3.31294118,14.9092171 C2.73411765,14.9092171 2.26588235,14.435619 2.26588235,13.8501661 L2.26588235,9.68297883 C2.26588235,9.0983192 2.73411765,8.62472111 3.31294118,8.62472111 C3.89098039,8.62472111 4.36,9.0983192 4.36,9.68297883 L4.36,13.8501661 L4.36,13.8501661 Z M9.49568627,13.8501661 C9.49568627,14.435619 9.02745098,14.9092171 8.44941176,14.9092171 C7.87058824,14.9092171 7.40235294,14.435619 7.40235294,13.8501661 L7.40235294,9.68297883 C7.40235294,9.0983192 7.87058824,8.62472111 8.44941176,8.62472111 C9.02745098,8.62472111 9.49568627,9.0983192 9.49568627,9.68297883 L9.49568627,13.8501661 L9.49568627,13.8501661 Z M17.8101961,5.81883088 C17.4015686,6.23293173 16.7388235,6.23293173 16.3294118,5.81962418 L15.3639216,4.8446626 L15.3639216,13.8501661 C15.3639216,14.435619 14.894902,14.9092171 14.3168627,14.9092171 C13.7388235,14.9092171 13.2698039,14.435619 13.2698039,13.8501661 L13.2698039,4.8438693 L12.3090196,5.82835044 C12.105098,6.03857405 11.8345098,6.14328921 11.5639216,6.14328921 C11.2980392,6.14328921 11.0329412,6.04254053 10.8290196,5.8386633 C10.4172549,5.42773563 10.412549,4.75739997 10.8188235,4.34091923 L14.2980392,0.773464227 L17.8094118,4.32108682 C18.2188235,4.73439437 18.2188235,5.40473003 17.8101961,5.81883088 L17.8101961,5.81883088 Z" />
        {/* eslint-enable max-len */}
      </svg>
      <span>
        {I18n.t('commons.bumpsView.header')}
      </span>
    </div>
  );
}
