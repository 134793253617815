import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgReverbLogoCircle = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17.203 5.206c-.062 0-.202 0-.316.061-.122.053-.218.237-.34.473-.123.175-.955 2.021-2.145 5.95-.029.084-.044.156-.058.22a.878.878 0 0 1-.055.191c-.298.831-.359 1.138-.236 1.313l.01.011c.058.057.11.111.226.111h.289a4.165 4.165 0 0 0 3.867-2.38 8.016 8.016 0 0 0 .84-2.922 2.847 2.847 0 0 0-.656-2.197c-.534-.595-1.19-.83-1.427-.83z" />
    <path
      fillRule="evenodd"
      d="M28 14c0 7.732-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0s14 6.268 14 14zm-6.527 6.545c.656 0 1.81-.14 2.59-.639h.008c-.507 1.313-1.181 1.917-1.82 2.249-.647.332-1.969.892-3.22.892-.883 0-1.61-.297-2.135-.83-1.076-1.19-1.129-3.273-1.076-4.463.061-1.488-.35-2.38-.761-2.914-.184-.175-.481-.42-.788-.42a.525.525 0 0 0-.525.481c-.061.42-.297 1.243-.542 2.083-.073.273-.15.552-.228.826-.174.62-.34 1.215-.42 1.676a20.204 20.204 0 0 0-.542 3.325c0 .061-.053.114-.298.236a4.71 4.71 0 0 1-1.54.175H8.75a1.49 1.49 0 0 1-.223-.016c-.07-.01-.104-.014-.145.017-.052-.114-.175-.412 0-1.243a365.095 365.095 0 0 1 4.113-14.219 10.85 10.85 0 0 1 1.19-2.257 7.997 7.997 0 0 0-4.401 1.61c-1.374 1.006-2.205 2.31-2.205 3.5 0 .245.061.717.236 1.076.175.411.595.648 1.137.648.22 0 .44-.046.71-.102l.06-.012.123-.062v.114c-.061.359-.245.84-.718 1.313a3.911 3.911 0 0 1-2.738 1.128c-.945 0-1.663-.236-2.135-.77a2.836 2.836 0 0 1-.665-2.143c.183-2.975 2.205-5.11 6.125-6.659 3.097-1.015 6.309-1.068 7.262-1.068a11.9 11.9 0 0 1 3.929.587c.704.266 1.321.72 1.785 1.312.42.595.595 1.251.595 2.083a5.05 5.05 0 0 1-2.03 3.929 12.164 12.164 0 0 1-3.15 1.723c-.297.123-.534.236-.595.298-.11.118.058.285.227.454l.018.018.114.123c.358.297.84.831 1.19 2.082.212.83.532 1.627.953 2.372.596 1.015 1.252 1.487 1.96 1.487z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgReverbLogoCircle;
