import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgTimesCircleAlt = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 26c6.627 0 12-5.373 12-12S20.627 2 14 2 2 7.373 2 14s5.373 12 12 12Zm-4.472-7.528a.95.95 0 0 1 0-1.344L12.656 14l-3.128-3.128a.95.95 0 0 1 1.344-1.344L14 12.656l3.128-3.128a.95.95 0 0 1 1.344 1.344L15.343 14l3.129 3.128a.95.95 0 0 1-1.344 1.344L14 15.343l-3.128 3.129a.95.95 0 0 1-1.344 0Z"
    />
  </svg>
);
export default SvgTimesCircleAlt;
