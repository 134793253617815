//
// DO NOT EDIT -- This file is generated from a .proto file.
// Any changes should be made to the proto file and then reran
// through the compiler.
//
// Compiler: https://github.com/reverbdotcom/protoc-gen-tsd
//
// protoc -I yourschemas/ --tsd_out=/dir/for/this/file yourschemas/your.proto
//

export class SessionStart {
  user_context?: UserContext;
  referer?: string;
  url?: string;
  aid?: string;
  utm_campaign?: string;
  request_id?: string;
  id?: string;
  utm_source?: string;
  utm_medium?: string;
  gs_partner?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    referer?: string;
    url?: string;
    aid?: string;
    utm_campaign?: string;
    request_id?: string;
    id?: string;
    utm_source?: string;
    utm_medium?: string;
    gs_partner?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'SessionStart' } });
  }
}

export class ComponentInteraction {
  component_name?: string;
  interaction_type?: string;
  url?: string;
  referer?: string;
  user_context?: UserContext;
  context?: string;
  entity_id?: string;
  entity_type?: string;
  position?: number;
  page?: number;
  per_page?: number;
  total_results?: number;
  target_url?: string;
  page_view_id?: string;
  impression_uuid?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  free_text_query?: string;
  bumped?: boolean;
  $type?: { name: string };

  constructor(data: Partial<{
    component_name?: string;
    interaction_type?: string;
    url?: string;
    referer?: string;
    user_context?: UserContext;
    context?: string;
    entity_id?: string;
    entity_type?: string;
    position?: number;
    page?: number;
    per_page?: number;
    total_results?: number;
    target_url?: string;
    page_view_id?: string;
    impression_uuid?: string;
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    free_text_query?: string;
    bumped?: boolean;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ComponentInteraction' } });
  }
}

export enum ComponentInteractionInteractionType {
  CLICK = 'CLICK',
  HOVER = 'HOVER',
  SWIPE = 'SWIPE',
  FORCE_TAP = 'FORCE_TAP',
}

export class ComponentsView {
  component_name?: string;
  context?: string;
  url?: string;
  referer?: string;
  user_context?: UserContext;
  entity_ids?: string[];
  entity_type?: string;
  page_view_id?: string;
  impression_uuid?: string;
  algorithm_version?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    component_name?: string;
    context?: string;
    url?: string;
    referer?: string;
    user_context?: UserContext;
    entity_ids?: string[];
    entity_type?: string;
    page_view_id?: string;
    impression_uuid?: string;
    algorithm_version?: string;
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ComponentsView' } });
  }
}

export class ComparisonShoppingPageClick {
  comparison_shopping_page?: ComparisonShoppingPage;
  user_context?: UserContext;
  url?: string;
  referer?: string;
  component_name?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    comparison_shopping_page?: ComparisonShoppingPage;
    user_context?: UserContext;
    url?: string;
    referer?: string;
    component_name?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ComparisonShoppingPageClick' } });
  }
}

export class FollowAdded {
  user_context?: UserContext;
  referer?: string;
  searchable_type?: string;
  searchable_id?: string;
  search_params?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    referer?: string;
    searchable_type?: string;
    searchable_id?: string;
    search_params?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'FollowAdded' } });
  }
}

export class UserSignedIn {
  user_context?: UserContext;
  referer?: string;
  user_id?: string;
  signup?: boolean;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    referer?: string;
    user_id?: string;
    signup?: boolean;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'UserSignedIn' } });
  }
}

export class TrackingPixelEvent {
  name?: string;
  data?: Uint8Array;
  $type?: { name: string };

  constructor(data: Partial<{
    name?: string;
    data?: Uint8Array;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'TrackingPixelEvent' } });
  }
}

export class PMBView {
  user_context?: UserContext;
  persona?: string;
  listings?: Listing[];
  utm_campaign?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    persona?: string;
    listings?: Listing[];
    utm_campaign?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'PMBView' } });
  }
}

export class MarketingEmailView {
  user_context?: UserContext;
  listings?: Listing[];
  utm_campaign?: string;
  upsell_order_id?: string;
  persona?: string;
  entity_ids?: string[];
  entity_type?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    listings?: Listing[];
    utm_campaign?: string;
    upsell_order_id?: string;
    persona?: string;
    entity_ids?: string[];
    entity_type?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'MarketingEmailView' } });
  }
}

export class MailLinkClick {
  user_context?: UserContext;
  utm_campaign?: string;
  url?: string;
  source_type?: string;
  source_id?: string;
  variations?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    utm_campaign?: string;
    url?: string;
    source_type?: string;
    source_id?: string;
    variations?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'MailLinkClick' } });
  }
}

export class MessagesMailer {
  user_context?: UserContext;
  message_id?: string;
  action?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    message_id?: string;
    action?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'MessagesMailer' } });
  }
}

export class WatcherListingSoldMailer {
  user_context?: UserContext;
  action?: string;
  product_id?: string;
  order_id?: string;
  similar_product_ids?: string[];
  comparison_shopping_page_id?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    action?: string;
    product_id?: string;
    order_id?: string;
    similar_product_ids?: string[];
    comparison_shopping_page_id?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'WatcherListingSoldMailer' } });
  }
}

export class GenericMailer {
  user_context?: UserContext;
  email_type?: string;
  action?: string;
  source_type?: string;
  source_id?: string;
  utm_campaign?: string;
  variations?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    email_type?: string;
    action?: string;
    source_type?: string;
    source_id?: string;
    utm_campaign?: string;
    variations?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'GenericMailer' } });
  }
}

export class WatchAdded {
  user_context?: UserContext;
  referer?: string;
  listing_id?: string;
  watch_id?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    referer?: string;
    listing_id?: string;
    watch_id?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'WatchAdded' } });
  }
}

export class AdminAbilityAndAbilityProfileUpdated {
  ability_manager_user_id?: string;
  ability_recipient_user_id?: string;
  ad_hoc_abilities?: string[];
  ability_profiles?: string[];
  $type?: { name: string };

  constructor(data: Partial<{
    ability_manager_user_id?: string;
    ability_recipient_user_id?: string;
    ad_hoc_abilities?: string[];
    ability_profiles?: string[];
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'AdminAbilityAndAbilityProfileUpdated' } });
  }
}

export class ClickStats {
  entity_type?: string;
  entity_id?: string;
  impressions?: number;
  clicks?: number;
  bump_impressions?: number;
  bump_clicks?: number;
  $type?: { name: string };

  constructor(data: Partial<{
    entity_type?: string;
    entity_id?: string;
    impressions?: number;
    clicks?: number;
    bump_impressions?: number;
    bump_clicks?: number;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ClickStats' } });
  }
}

export class Device {
  name?: string;
  user_agent?: string;
  version?: string;
  app_version?: string;
  os_version?: string;
  manufacturer?: string;
  brand?: string;
  model?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    name?: string;
    user_agent?: string;
    version?: string;
    app_version?: string;
    os_version?: string;
    manufacturer?: string;
    brand?: string;
    model?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'Device' } });
  }
}

export class Experiment {
  name?: string;
  value?: string;
  use_in_frontend?: boolean;
  multivariate_experiment?: boolean;
  qualify_for_mobile?: boolean;
  mobile_context?: ExperimentMobileContext;
  $type?: { name: string };

  constructor(data: Partial<{
    name?: string;
    value?: string;
    use_in_frontend?: boolean;
    multivariate_experiment?: boolean;
    qualify_for_mobile?: boolean;
    mobile_context?: ExperimentMobileContext;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'Experiment' } });
  }
}

export class ExperimentMobileContext {
  value?: string;
  report_as?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    value?: string;
    report_as?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ExperimentMobileContext' } });
  }
}

export class Price {
  amount?: number;
  currency?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    amount?: number;
    currency?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'Price' } });
  }
}

export class Listing {
  id?: string;
  price?: Price;
  status?: string;
  category_uuids?: string[];
  bumped?: boolean;
  canonical_product_id?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    id?: string;
    price?: Price;
    status?: string;
    category_uuids?: string[];
    bumped?: boolean;
    canonical_product_id?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'Listing' } });
  }
}

export class DigitalListing {
  id?: string;
  price?: Price;
  status?: string;
  category_uuids?: string[];
  $type?: { name: string };

  constructor(data: Partial<{
    id?: string;
    price?: Price;
    status?: string;
    category_uuids?: string[];
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'DigitalListing' } });
  }
}

export class CanonicalProduct {
  id?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    id?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'CanonicalProduct' } });
  }
}

export class UserContext {
  id?: string;
  cookie_id?: string;
  distinct_id?: string;
  device?: Device;
  experiments?: Experiment[];
  timestamp?: string;
  admin_id?: string;
  bot?: boolean;
  locale?: string;
  currency?: string;
  shipping_region?: string;
  lat?: number;
  long?: number;
  last_google_click?: GoogleShoppingClick;
  request_id?: string;
  seller_sites_id?: string;
  reverb_app?: string;
  has_logged_in?: boolean;
  country_code?: string;
  session_id?: string;
  advertising_id?: string;
  acting_user_id?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    id?: string;
    cookie_id?: string;
    distinct_id?: string;
    device?: Device;
    experiments?: Experiment[];
    timestamp?: string;
    admin_id?: string;
    bot?: boolean;
    locale?: string;
    currency?: string;
    shipping_region?: string;
    lat?: number;
    long?: number;
    last_google_click?: GoogleShoppingClick;
    request_id?: string;
    seller_sites_id?: string;
    reverb_app?: string;
    has_logged_in?: boolean;
    country_code?: string;
    session_id?: string;
    advertising_id?: string;
    acting_user_id?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'UserContext' } });
  }
}

export class Article {
  id?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    id?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'Article' } });
  }
}

export class GoogleShoppingClick {
  product_id?: string;
  comparison_shopping_page_id?: string;
  timestamp?: string;
  origin?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    product_id?: string;
    comparison_shopping_page_id?: string;
    timestamp?: string;
    origin?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'GoogleShoppingClick' } });
  }
}

export class ComparisonShoppingPage {
  id?: string;
  lowest_price_for_new?: Price;
  lowest_price_for_used?: Price;
  used_listing_count?: number;
  new_listing_count?: number;
  $type?: { name: string };

  constructor(data: Partial<{
    id?: string;
    lowest_price_for_new?: Price;
    lowest_price_for_used?: Price;
    used_listing_count?: number;
    new_listing_count?: number;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ComparisonShoppingPage' } });
  }
}

export class AttributeChange {
  name?: string;
  previous_value?: string;
  new_value?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    name?: string;
    previous_value?: string;
    new_value?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'AttributeChange' } });
  }
}

export class Message {
  id?: string;
  conversation_id?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    id?: string;
    conversation_id?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'Message' } });
  }
}

export class MobileInteraction {
  name?: string;
  action?: string;
  type?: MobileInteractionInteractionType;
  context?: string;
  user_context?: UserContext;
  $type?: { name: string };

  constructor(data: Partial<{
    name?: string;
    action?: string;
    type?: MobileInteractionInteractionType;
    context?: string;
    user_context?: UserContext;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'MobileInteraction' } });
  }
}

export enum MobileInteractionInteractionType {
  TAP = 'TAP',
  DRAG = 'DRAG',
  FORCE_TAP = 'FORCE_TAP',
}

export class Error {
  error?: string;
  user_context?: UserContext;
  $type?: { name: string };

  constructor(data: Partial<{
    error?: string;
    user_context?: UserContext;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'Error' } });
  }
}

export class EntityUpdated {
  name?: string;
  id?: string;
  timestamp?: string;
  action?: EntityUpdatedAction;
  $type?: { name: string };

  constructor(data: Partial<{
    name?: string;
    id?: string;
    timestamp?: string;
    action?: EntityUpdatedAction;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'EntityUpdated' } });
  }
}

export enum EntityUpdatedAction {
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
}

export class OrderUpdated {
  user_context?: UserContext;
  id?: string;
  status?: string;
  listing?: Listing;
  buyer_id?: string;
  created_at?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    id?: string;
    status?: string;
    listing?: Listing;
    buyer_id?: string;
    created_at?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'OrderUpdated' } });
  }
}

export enum OrderUpdatedStatus {
  UNPAID = 'UNPAID',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PAID = 'PAID',
  SHIPPED = 'SHIPPED',
  PICKED_UP = 'PICKED_UP',
  RECEIVED = 'RECEIVED',
  PRESUMED_RECEIVED = 'PRESUMED_RECEIVED',
  CANCELLED = 'CANCELLED',
  REFUNDED = 'REFUNDED',
  LAYAWAY_FORFEITED = 'LAYAWAY_FORFEITED',
}

export class OrderPaid {
  user_context?: UserContext;
  id?: string;
  listing_id?: string;
  buyer_id?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    id?: string;
    listing_id?: string;
    buyer_id?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'OrderPaid' } });
  }
}

export class NegotiationUpdated {
  user_context?: UserContext;
  status?: string;
  id?: string;
  last_offer_id?: string;
  listing?: Listing;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    status?: string;
    id?: string;
    last_offer_id?: string;
    listing?: Listing;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'NegotiationUpdated' } });
  }
}

export enum NegotiationUpdatedStatus {
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  EXPIRED = 'EXPIRED',
}

export class NegotiationStarted {
  user_context?: UserContext;
  id?: string;
  buyer_initiated?: boolean;
  offer_id?: string;
  buyer_id?: string;
  listing_ids?: string[];
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    id?: string;
    buyer_initiated?: boolean;
    offer_id?: string;
    buyer_id?: string;
    listing_ids?: string[];
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'NegotiationStarted' } });
  }
}

export class ConversationUpdated {
  user_context?: UserContext;
  id?: string;
  initial_recipient_id?: string;
  initial_author_id?: string;
  listing?: Listing;
  last_message_id?: string;
  request_id?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    id?: string;
    initial_recipient_id?: string;
    initial_author_id?: string;
    listing?: Listing;
    last_message_id?: string;
    request_id?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ConversationUpdated' } });
  }
}

export class UserUpdated {
  user_context?: UserContext;
  id?: string;
  status?: string;
  newsletter_opt_in?: boolean;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    id?: string;
    status?: string;
    newsletter_opt_in?: boolean;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'UserUpdated' } });
  }
}

export enum UserUpdatedUserStatus {
  IN_GOOD_STANDING = 'IN_GOOD_STANDING',
  UNDER_REVIEW = 'UNDER_REVIEW',
  SUSPENDED = 'SUSPENDED',
  SUSPENDED_OUTSTANDING_INVOICE = 'SUSPENDED_OUTSTANDING_INVOICE',
  LOCKED_VACATION_MODE = 'LOCKED_VACATION_MODE',
}

export class ListingMatched {
  user_context?: UserContext;
  listing_id?: string;
  canonical_product_id?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    listing_id?: string;
    canonical_product_id?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ListingMatched' } });
  }
}

export class ListingUpdated {
  user_context?: UserContext;
  listing?: Listing;
  changes?: AttributeChange[];
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    listing?: Listing;
    changes?: AttributeChange[];
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ListingUpdated' } });
  }
}

export class CanonicalProductUpdated {
  user_context?: UserContext;
  canonical_product?: CanonicalProduct;
  changes?: AttributeChange[];
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    canonical_product?: CanonicalProduct;
    changes?: AttributeChange[];
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'CanonicalProductUpdated' } });
  }
}

export class MessageUpdated {
  user_context?: UserContext;
  message?: Message;
  changes?: AttributeChange[];
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    message?: Message;
    changes?: AttributeChange[];
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'MessageUpdated' } });
  }
}

export class ShippingLabelUpdated {
  user_context?: UserContext;
  id?: string;
  status?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    id?: string;
    status?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ShippingLabelUpdated' } });
  }
}

export class FollowUpdated {
  user_context?: UserContext;
  id?: string;
  saved_search_id?: string;
  destroyed?: boolean;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    id?: string;
    saved_search_id?: string;
    destroyed?: boolean;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'FollowUpdated' } });
  }
}

export class BumpsReserved {
  user_context?: UserContext;
  amount?: number;
  price?: Price;
  listing?: Listing;
  budget_type?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    amount?: number;
    price?: Price;
    listing?: Listing;
    budget_type?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'BumpsReserved' } });
  }
}

export class AddedToCart {
  user_context?: UserContext;
  listing?: Listing;
  other_listings?: Listing[];
  referer?: string;
  aid?: string;
  cart_item_uuid?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    listing?: Listing;
    other_listings?: Listing[];
    referer?: string;
    aid?: string;
    cart_item_uuid?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'AddedToCart' } });
  }
}

export class RemovedFromCart {
  user_context?: UserContext;
  listing?: Listing;
  cart_item_uuid?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    listing?: Listing;
    cart_item_uuid?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'RemovedFromCart' } });
  }
}

export class DiscountCodeApplied {
  user_context?: UserContext;
  discount_code?: string;
  success?: boolean;
  referer?: string;
  error?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    discount_code?: string;
    success?: boolean;
    referer?: string;
    error?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'DiscountCodeApplied' } });
  }
}

export class ShopEmptied {
  shop_id?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    shop_id?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ShopEmptied' } });
  }
}

export class Event {
  message_name?: string;
  payload?: Uint8Array;
  level?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    message_name?: string;
    payload?: Uint8Array;
    level?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'Event' } });
  }
}

export class EventRequest {
  events?: Event[];
  $type?: { name: string };

  constructor(data: Partial<{
    events?: Event[];
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'EventRequest' } });
  }
}

export class AttributedLanding {
  landing_id?: string;
  landing_time?: string;
  user_id?: string;
  aid?: string;
  referer?: string;
  url?: string;
  utm_campaign?: string;
  cookie_id?: string;
  user_shipping_region?: string;
  landing_device?: string;
  landing_bucket?: string;
  ordered_at?: string;
  product_id?: string;
  order_id?: string;
  order_device?: string;
  registered_at?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    landing_id?: string;
    landing_time?: string;
    user_id?: string;
    aid?: string;
    referer?: string;
    url?: string;
    utm_campaign?: string;
    cookie_id?: string;
    user_shipping_region?: string;
    landing_device?: string;
    landing_bucket?: string;
    ordered_at?: string;
    product_id?: string;
    order_id?: string;
    order_device?: string;
    registered_at?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'AttributedLanding' } });
  }
}

export class UserSignedUp {
  newsletter_opt_in?: boolean;
  user_id?: string;
  registration_source?: string;
  referer?: string;
  aid?: string;
  utm_campaign?: string;
  url?: string;
  country_code?: string;
  source_product_uuid?: string;
  user_context?: UserContext;
  $type?: { name: string };

  constructor(data: Partial<{
    newsletter_opt_in?: boolean;
    user_id?: string;
    registration_source?: string;
    referer?: string;
    aid?: string;
    utm_campaign?: string;
    url?: string;
    country_code?: string;
    source_product_uuid?: string;
    user_context?: UserContext;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'UserSignedUp' } });
  }
}

export class ContestSignUp {
  user_context?: UserContext;
  referer?: string;
  aid?: string;
  utm_campaign?: string;
  question_response?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    referer?: string;
    aid?: string;
    utm_campaign?: string;
    question_response?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ContestSignUp' } });
  }
}

export class MailListSignUp {
  user_context?: UserContext;
  email?: string;
  referer?: string;
  list_id?: string;
  country_code?: string;
  source?: string;
  source_product_uuid?: string;
  user_id?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    email?: string;
    referer?: string;
    list_id?: string;
    country_code?: string;
    source?: string;
    source_product_uuid?: string;
    user_id?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'MailListSignUp' } });
  }
}

export class ComparisonShoppingPageView {
  listings?: Listing[];
  canonical_product_ids?: string[];
  lowest_price_for_new?: Price;
  lowest_price_for_used?: Price;
  user_context?: UserContext;
  request_id?: string;
  buy_button_listing_id?: string;
  buy_button_listing_type?: string;
  comparison_shopping_page_id?: string;
  buy_button_reason?: string;
  price_guide_id?: string;
  condition?: string;
  referer?: string;
  url?: string;
  utm_campaign?: string;
  utm_source?: string;
  utm_medium?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    listings?: Listing[];
    canonical_product_ids?: string[];
    lowest_price_for_new?: Price;
    lowest_price_for_used?: Price;
    user_context?: UserContext;
    request_id?: string;
    buy_button_listing_id?: string;
    buy_button_listing_type?: string;
    comparison_shopping_page_id?: string;
    buy_button_reason?: string;
    price_guide_id?: string;
    condition?: string;
    referer?: string;
    url?: string;
    utm_campaign?: string;
    utm_source?: string;
    utm_medium?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ComparisonShoppingPageView' } });
  }
}

export class ListingsView {
  listings?: Listing[];
  query?: string;
  total_results?: number;
  user_context?: UserContext;
  view_type?: string;
  curated_link_ids?: string[];
  curated_set_ids?: string[];
  bumped_listings?: Listing[];
  featured_listing?: Listing;
  request_id?: string;
  bump_algorithm?: string;
  url?: string;
  referer?: string;
  utm_campaign?: string;
  aid?: string;
  result_count?: number;
  version?: number;
  response_time?: number;
  fallback_strategy?: string;
  fallback_listings?: Listing[];
  total_fallback_listings?: number;
  $type?: { name: string };

  constructor(data: Partial<{
    listings?: Listing[];
    query?: string;
    total_results?: number;
    user_context?: UserContext;
    view_type?: string;
    curated_link_ids?: string[];
    curated_set_ids?: string[];
    bumped_listings?: Listing[];
    featured_listing?: Listing;
    request_id?: string;
    bump_algorithm?: string;
    url?: string;
    referer?: string;
    utm_campaign?: string;
    aid?: string;
    result_count?: number;
    version?: number;
    response_time?: number;
    fallback_strategy?: string;
    fallback_listings?: Listing[];
    total_fallback_listings?: number;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ListingsView' } });
  }
}

export enum ListingsViewViewType {
  SEARCHING = 'SEARCHING',
  BROWSING = 'BROWSING',
  HOMEPAGE = 'HOMEPAGE',
  BRAND = 'BRAND',
  CURATED_SEARCH = 'CURATED_SEARCH',
  SHOP = 'SHOP',
  CURATED_SET = 'CURATED_SET',
}

export class ListingView {
  user_context?: UserContext;
  listing?: Listing;
  request_id?: string;
  url?: string;
  referer?: string;
  aid?: string;
  utm_campaign?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    listing?: Listing;
    request_id?: string;
    url?: string;
    referer?: string;
    aid?: string;
    utm_campaign?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ListingView' } });
  }
}

export class ListingViewRelatedContent {
  user_context?: UserContext;
  listing_view_listing_id?: string;
  type?: string;
  url?: string;
  referer?: string;
  listings?: Listing[];
  articles?: Article[];
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    listing_view_listing_id?: string;
    type?: string;
    url?: string;
    referer?: string;
    listings?: Listing[];
    articles?: Article[];
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ListingViewRelatedContent' } });
  }
}

export class DigitalListingView {
  user_context?: UserContext;
  digital_listing?: DigitalListing;
  request_id?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    digital_listing?: DigitalListing;
    request_id?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'DigitalListingView' } });
  }
}

export class DigitalListingDownload {
  user_context?: UserContext;
  digital_listing_id?: string;
  digital_variant_id?: string;
  request_id?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    digital_listing_id?: string;
    digital_variant_id?: string;
    request_id?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'DigitalListingDownload' } });
  }
}

export class CuratedSetView {
  curated_set_id?: string;
  user_context?: UserContext;
  listings?: Listing[];
  request_id?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    curated_set_id?: string;
    user_context?: UserContext;
    listings?: Listing[];
    request_id?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'CuratedSetView' } });
  }
}

export class SaleView {
  sale_id?: string;
  user_context?: UserContext;
  listings?: Listing[];
  request_id?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    sale_id?: string;
    user_context?: UserContext;
    listings?: Listing[];
    request_id?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'SaleView' } });
  }
}

export class CartView {
  user_context?: UserContext;
  listings?: Listing[];
  request_id?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    listings?: Listing[];
    request_id?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'CartView' } });
  }
}

export class ArticleView {
  user_context?: UserContext;
  article_id?: string;
  request_id?: string;
  referer?: string;
  url?: string;
  aid?: string;
  utm_campaign?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    article_id?: string;
    request_id?: string;
    referer?: string;
    url?: string;
    aid?: string;
    utm_campaign?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ArticleView' } });
  }
}

export class ArticleViewRelatedContent {
  user_context?: UserContext;
  article_view_article_id?: string;
  type?: string;
  url?: string;
  referer?: string;
  articles?: Article[];
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    article_view_article_id?: string;
    type?: string;
    url?: string;
    referer?: string;
    articles?: Article[];
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ArticleViewRelatedContent' } });
  }
}

export enum ArticleViewRelatedContentRelatedContentType {
  ARTICLES = 'ARTICLES',
}

export class PageView {
  user_context?: UserContext;
  referer?: string;
  url?: string;
  aid?: string;
  utm_campaign?: string;
  controller?: string;
  action?: string;
  remote_address?: string;
  request_id?: string;
  params?: string;
  method?: string;
  view_runtime?: number;
  db_runtime?: number;
  status?: string;
  duration?: number;
  custom_headers?: string;
  oauth_app_id?: string;
  dev_team?: string;
  page_experiments?: string;
  id?: string;
  sku?: string;
  params_id?: string;
  thread_id?: string;
  utm_source?: string;
  utm_medium?: string;
  url_region?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    referer?: string;
    url?: string;
    aid?: string;
    utm_campaign?: string;
    controller?: string;
    action?: string;
    remote_address?: string;
    request_id?: string;
    params?: string;
    method?: string;
    view_runtime?: number;
    db_runtime?: number;
    status?: string;
    duration?: number;
    custom_headers?: string;
    oauth_app_id?: string;
    dev_team?: string;
    page_experiments?: string;
    id?: string;
    sku?: string;
    params_id?: string;
    thread_id?: string;
    utm_source?: string;
    utm_medium?: string;
    url_region?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'PageView' } });
  }
}

export class CheckoutView {
  user_context?: UserContext;
  payment_method?: string;
  step_name?: string;
  referer?: string;
  checkout_id?: string;
  checkout_uuid?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    payment_method?: string;
    step_name?: string;
    referer?: string;
    checkout_id?: string;
    checkout_uuid?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'CheckoutView' } });
  }
}

export enum CheckoutViewStepName {
  CART = 'CART',
  SHIPPING_REGISTRATION = 'SHIPPING_REGISTRATION',
  BILLING = 'BILLING',
  PLACE_ORDER = 'PLACE_ORDER',
  CONFIRMATION = 'CONFIRMATION',
  ASYNC_PAYMENT_IN_PROGRESS = 'ASYNC_PAYMENT_IN_PROGRESS',
  ASYNC_PAYPAL_TOKEN_FETCH_IN_PROGRESS = 'ASYNC_PAYPAL_TOKEN_FETCH_IN_PROGRESS',
}

export class ComparisonShoppingPagesView {
  comparison_shopping_pages?: ComparisonShoppingPage[];
  user_context?: UserContext;
  url?: string;
  referer?: string;
  component_name?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    comparison_shopping_pages?: ComparisonShoppingPage[];
    user_context?: UserContext;
    url?: string;
    referer?: string;
    component_name?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ComparisonShoppingPagesView' } });
  }
}

export class ScreenView {
  user_context?: UserContext;
  referer?: string;
  screen_name?: string;
  context?: string;
  $type?: { name: string };

  constructor(data: Partial<{
    user_context?: UserContext;
    referer?: string;
    screen_name?: string;
    context?: string;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'ScreenView' } });
  }
}

export class BrowserFingerprint {
  hash?: string;
  lied_languages?: boolean;
  lied_resolution?: boolean;
  lied_os?: boolean;
  lied_browser?: boolean;
  plugins?: number;
  resolution?: string;
  session?: boolean;
  localstorage?: boolean;
  $type?: { name: string };

  constructor(data: Partial<{
    hash?: string;
    lied_languages?: boolean;
    lied_resolution?: boolean;
    lied_os?: boolean;
    lied_browser?: boolean;
    plugins?: number;
    resolution?: string;
    session?: boolean;
    localstorage?: boolean;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'BrowserFingerprint' } });
  }
}

export class JsLoad {
  app?: string;
  user_context?: UserContext;
  fingerprint?: BrowserFingerprint;
  $type?: { name: string };

  constructor(data: Partial<{
    app?: string;
    user_context?: UserContext;
    fingerprint?: BrowserFingerprint;
  }>) {
    Object.assign(this, data);
    Object.defineProperty(this, '$type', { value: { name: 'JsLoad' } });
  }
}

export enum MailerAction {
  SENT = 'SENT',
  OPENED = 'OPENED',
}

export enum CollectionView {
  HYDRA = 'HYDRA',
  SALE = 'SALE',
  CURATED_SET = 'CURATED_SET',
  FEED = 'FEED',
  HOMEPAGE = 'HOMEPAGE',
  PRICE_GUIDE = 'PRICE_GUIDE',
  SEARCH = 'SEARCH',
}

export enum ListingStatus {
  DRAFT = 'DRAFT',
  LIVE = 'LIVE',
  DRAFTS = 'DRAFTS',
  SOLD_OUT = 'SOLD_OUT',
  ORDERED = 'ORDERED',
  NOT_DRAFTS = 'NOT_DRAFTS',
  SUSPENDED = 'SUSPENDED',
  VACATION = 'VACATION',
  ENDED = 'ENDED',
}

export enum DeviceName {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  TOUCH = 'TOUCH',
  WEB = 'WEB',
}

export enum GoogleClickOrigin {
  REVERB = 'REVERB',
  SELLERSITES = 'SELLERSITES',
}

