import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgEyeSlash = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M8.672 20.36C5.922 19.124 3.625 17.015 2 14.5c1.484-2.297 3.516-4.266 5.953-5.516A6.945 6.945 0 0 0 7 12.5a6.995 6.995 0 0 0 2.89 5.656L8.673 20.36ZM14.75 8.5a.76.76 0 0 1-.75.75 3.258 3.258 0 0 0-3.25 3.25.76.76 0 0 1-.75.75.76.76 0 0 1-.75-.75c0-2.61 2.14-4.75 4.75-4.75a.76.76 0 0 1 .75.75Zm5.672-2.984a.493.493 0 0 0-.25-.422C19.844 4.906 18.375 4 18.094 4a.499.499 0 0 0-.438.25l-.843 1.516A15.447 15.447 0 0 0 14 5.5C8.234 5.5 3.406 8.672.312 13.422a2.016 2.016 0 0 0 0 2.156c1.797 2.813 4.344 5.156 7.375 6.531-.156.266-.687 1.11-.687 1.36 0 .187.094.343.25.437C7.578 24.094 9.063 25 9.344 25a.518.518 0 0 0 .437-.25l.766-1.39c3.297-5.891 6.562-11.813 9.86-17.704.015-.031.015-.11.015-.14ZM21 12.5c0-.438-.047-.875-.125-1.313L16.5 19.032A6.991 6.991 0 0 0 21 12.5Zm7 2c0-.406-.11-.734-.313-1.078-1.218-2.016-3.171-3.969-5.109-5.266l-.984 1.75c1.765 1.219 3.25 2.797 4.406 4.594-2.438 3.781-6.297 6.547-10.844 6.938L14 23.5c4.703 0 8.922-2.14 11.984-5.656.61-.703 1.22-1.469 1.703-2.266.204-.344.313-.672.313-1.078Z" />
  </svg>
);
export default SvgEyeSlash;
