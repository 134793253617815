//
// README - Beta Component
//
// This component is marked "beta" and will go through some iterations
// before landing at a final solution. Please reach out to the Cadence team
// if you intend to use this in the meantime and we'll help you out!
//

import React, { useRef } from 'react';
import classNames from 'classnames';
import { useId } from '@floating-ui/react';

import { RCText } from '..';
import { RCModalBase, RCModalBaseProps } from '../internalComponents/RCModalBase/RCModalBase';
import { RCModalActionGroup, RCModalActionGroupProps } from '../internalComponents/RCModalActionGroup/RCModalActionGroup';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';

type ElementRef = React.MutableRefObject<HTMLElement | null>;

interface RCModalProps {
  /** Optionally provide a trigger to have the modal handle onClick behavior and state changes. */
  trigger?: RCModalBaseProps['trigger'];
  /** Can be used to control the state of the modal when paired with `onOpenChange`. */
  isOpen?: RCModalBaseProps['isOpen'];
  /** Can be used to control the state of the modal when paired with `isOpen`.
   *  Alternatively, can be used as a callback to track state changes.
  */
  onOpenChange?: RCModalBaseProps['onOpenChange'];
  /** The title of the modal to be rendered in the header. */
  title: string;
  /** Actions to be appended at the end of the modal, below the scrollable content area. Uses `RCModalActionGroup` */
  actions?: RCModalActionGroupProps;
  /** Allows you to control where the focus ring is placed when opening the modal (with keyboard).
   *  Defaults to the modal's `'body'` content.
   *  Use `'close'` if your modal's content is lengthy and the only interactive content appears below the fold.
   *
   *  Note: for setting initial focus on an action, use `actions` instead.
   */
  initialFocus?: 'body' | 'close' | ElementRef;
}

export function RCModal({
  trigger = undefined,
  isOpen = undefined,
  onOpenChange = undefined,
  title = undefined,
  actions = undefined,
  initialFocus = 'body',
  children = undefined,
}: React.PropsWithChildren<RCModalProps>) {
  const closeButtonRef = useRef(null);

  const focus = React.useMemo(() => {
    if (initialFocus === 'close') return closeButtonRef;
    if (initialFocus === 'body') return 0;
    return initialFocus;
  }, [initialFocus, closeButtonRef]);

  const dialogId = useId();
  const titleId = `${dialogId}-title`;
  return (
    <RCModalBase
      trigger={trigger}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      aria-labelledby={titleId}
      initialFocus={focus}
      role="dialog"
    >
      <div className="rc-modal">
        <div className="rc-modal__header" id={titleId}>
          <RCText.Title size="600">
            {title}
          </RCText.Title>
        </div>
        <RCModalBody>{children}</RCModalBody>
        {actions &&
          <div className="rc-modal__footer">
            <RCModalActionGroup {...actions} />
          </div>
        }
        <RCModalBase.CloseButton ref={closeButtonRef} />
      </div>
    </RCModalBase>
  );
}

function RCModalBody({ children }) {
  const observerTop = useIntersectionObserver();
  const observerBottom = useIntersectionObserver();

  return (
    <div
      className={classNames(
        'rc-modal__body',
        { 'rc-modal__body--shadow-top': observerTop?.entry && !observerTop?.entry?.isIntersecting },
        { 'rc-modal__body--shadow-bottom': observerBottom?.entry && !observerBottom?.entry?.isIntersecting },
      )}
    >
      <div className="rc-modal__body__content">
        <div ref={observerTop.setTrackedRef} data-observer />
        {children}
        <div ref={observerBottom.setTrackedRef} data-observer />
      </div>
    </div>
  );
}
