import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';

export const DEVICE_INFO_HEADER = 'X-Reverb-Device-Info';

export function reverbDeviceInfoProperties(user) {
  return {
    platform: user.deviceName,
    app_version: user.deviceAppVersion,
    platform_version: user.deviceOSVersion,
  };
}

export function reverbDeviceInfoHeader(user: Partial<IUser>) {
  return {
    [DEVICE_INFO_HEADER]: JSON.stringify(reverbDeviceInfoProperties(user)),
  };
}
