import classNames from 'classnames';
import React from 'react';
import { times } from 'lodash';

interface RCSkeletonProps {
  shape?: 'text' | 'circle' | 'square';
  width?: string;
  height?: string;
  count?: number;
  display?: 'block' | 'inline-block';
}

export function RCSkeleton({
  shape = 'text',
  width = '100%',
  height = '1em',
  count = 1,
  display = 'inline-block',
}: RCSkeletonProps) {
  return (
    <span
      aria-live="polite"
      aria-busy="true"
    >
      {times(count, (index) => (
        <div key={index}>
          <span
            className={classNames(
              'rc-skeleton',
              {
                'rc-skeleton--circle': shape === 'circle',
                'rc-skeleton--square': shape === 'square',
              },
            )}
            style={{
              display: display,
              width: width,
              height: shape !== 'text' ? 'auto' : height,
            }}
          />
          {index + 1 < count ? (<br />) : null}
        </div>
      ))}
    </span>
  );
}
