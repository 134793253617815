import Client, { Endpoint } from './client';
import * as AnalyticsCookie from './analytics-cookie';
import { Session } from './session';
import * as events from '../schemas/analytics';
import { trackPageViewForLocation, trackGenericPageView } from './mparticle_tracker';
import { captureException, getCurrentScope, setUser, setTags } from '@sentry/browser';

interface Gauge {
  name: string;
  value: number;
  tags?: string[];
  sample?: number;
}

interface SentryErrorReporter {
  captureException: typeof captureException;
  getCurrentScope: typeof getCurrentScope;
  setUser: typeof setUser;
  setTags: typeof setTags;
}

export interface Config {
  host: string;
  timeout?: number;
  apiKey: string;
  buffer?: number;
  local?: boolean;
  enabled?: boolean;
  sentryClient?: SentryErrorReporter;
  domain?: string;
  userContext?: events.UserContext;
}

const ERROR = 'error';
const INFO = 'info';
let ENABLED = true;

let sentryClient: SentryErrorReporter = null;

const userContext: Partial<events.UserContext> = {
  cookie_id: AnalyticsCookie.trackingID(),
};

const session = new Session(userContext);

function buildEvent(name, data = {}, jsError?, level?) {
  let eventName = name;
  let props;

  if (name.$type) {
    eventName = name.$type.name;
    props = name;
  } else {
    props = data;
  }

  const eventData = { ...props };
  delete eventData.$type;

  // Remove experiments from normal elog tracking as they are no longer used
  // The user_context itself is shared in a few places, so making this change
  // here and not in the indentify call will restore this functionality
  //
  // https://github.com/reverbdotcom/frontend/pull/5100/files
  eventData.user_context = { ...userContext, experiments: [] };

  return {
    level,
    name: eventName,
    data: eventData,
    error: jsError,
  };
}

export function configure(opts: Config) {
  if (Object.hasOwnProperty.call(opts, 'enabled')) {
    ENABLED = opts.enabled;
  }

  if (opts.sentryClient) {
    sentryClient = opts.sentryClient;
  }

  Client.configure({
    host: opts.host,
    timeout: opts.timeout,
    apiKey: opts.apiKey,
    buffer: opts.buffer,
    local: opts.local,
  });

  AnalyticsCookie.configure(opts);

  // Refresh tracking ID since we may not have been able to persist
  // the cookie on the default domain
  userContext.cookie_id = AnalyticsCookie.trackingID();

  if (opts.userContext?.request_id) {
    session.setRequestID(opts.userContext.request_id);
  }
}

export function identify(user: events.UserContext) {
  if (sentryClient) {
    sentryClient.setUser({
      id: user.id,
    });

    sentryClient.setTags(sessionTags());
  }

  const normalizedUserAttributes = { ...user, experiments: transformExperiments(user) };
  Object.assign(userContext, normalizedUserAttributes);
}

function transformExperiments(user) {
  if (!user.experiments) return [];

  return user.experiments.map((exp) => {
    return (({ name, value }) => ({ name, value }))(exp);
  });
}

export function post(event, endpoint: Endpoint) {
  if (ENABLED !== true) { return; }
  Client.post([event], endpoint);
}

export type AnalyticsQueryParams = {
  utm_source?: string;
  utm_campaign?: string;
  utm_medium?: string;
  gs_partner?: string;
};

export function pageView(loc: Partial<Location> = window.location, doc: Partial<Document> = document) {
  session.touch(loc, doc);

  trackGenericPageView();
  trackPageViewForLocation(loc, userContext);
}

export function currentSession(): Session {
  return session;
}

export function error(name: string, data = {}, jsError: string | Error = null, report = false) {
  const event = buildEvent(name, data, jsError, ERROR);

  if (!jsError) {
    // eslint-disable-next-line no-param-reassign
    jsError = name;
  }

  if (!(jsError as Error).message) {
    // If we just have a string instead of an error, wrap in an error so we get a stack trace
    // eslint-disable-next-line no-param-reassign
    jsError = new Error(jsError as string);
  }

  if (sentryClient && report) {
    sentryClient.getCurrentScope().setFingerprint(['{{ default }}', name]);
    sentryClient.getCurrentScope().setExtras(data);
    sentryClient.getCurrentScope().setTags(sessionTags());

    sentryClient.captureException(jsError);
  }

  // ensure that errors are reported to the console
  console.error(jsError);
  post(event, 'events');
}

/**
 * [Sentry tags](https://docs.sentry.io/platforms/javascript/enriching-events/tags/)
 * for a given user session.
 */
function sessionTags() {
  return {
    session_id: session.ID(),
    request_id: session.requestId(),
    context_id: session.cookieId(),
  };
}

export function info(name: string, data: Record<string, any> = {}) {
  const event = buildEvent(name, data, null, INFO);
  post(event, 'events');
}

export function gauge(gauge: Gauge) {
  post(gauge, 'gauges');
}

export const elog = {
  info,
  error,
};

export { events };
