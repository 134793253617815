import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgChevronDown = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M26.297 12.625 14.703 24.203A.961.961 0 0 1 14 24.5a.961.961 0 0 1-.703-.297L1.703 12.625a.97.97 0 0 1-.297-.71.97.97 0 0 1 .297-.712l2.594-2.578A.961.961 0 0 1 5 8.328c.27 0 .505.1.703.297L14 16.922l8.297-8.297A.961.961 0 0 1 23 8.328c.27 0 .505.1.703.297l2.594 2.578a.97.97 0 0 1 .297.711.97.97 0 0 1-.297.711z" />
  </svg>
);
export default SvgChevronDown;
