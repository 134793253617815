import React from 'react';
import classNames from 'classnames';

import { CheckIcon, MinusIcon } from '../../../icons/react';
import { RCCheckableLabel, labelID, sublabelID, errorID } from './RCCheckableLabel';
import { CommonInputProps } from '../../types';
import { LinkElement } from '../../helpers/linkElement';
import { RCIcon } from '../../';

export interface IRCCheckableProps extends Omit<CommonInputProps, 'helpText' | 'variant' | 'tag'> {
  /** Passed through to the input tag. */
  checked?: boolean | 'mixed';

  /**
   * Represents a third 'mixed' state; represents a subset of children being checked.
   * Reference the <a href="https://www.w3.org/WAI/ARIA/apg/patterns/checkbox/examples/checkbox-mixed/">W3 example</a>.
  */
  triState?: boolean;

  /**
   * Click handler exclusively for the button rendered by a triState RCCheckbox.
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;

  /** Additional line of text below main label; sentence-case without ending punctuation. Not rendered if `variant` is set to `switch`. */
  sublabel?: React.ReactNode;

  /**
   * `linkElement` is Cadence's way to support CoreLink. Pass in a linkable element and in component's internals will determine how to render the `children` prop passed.
   * */
  linkElement?: (children: React.ReactNode) => React.ReactElement | undefined;

  /**
   * For mixed-state checkboxes. Use this prop to pass in a list of input IDs representing the child checkboxes.
   */
  ariaControls?: string;

  /**
   * Boolean to enable a hover effect of an offwhite box padding the checkbox. Used in lists, never when a checkbox is alone.
   */
  paddedHover?: boolean;
}

function triStateCheckedValue(checked) {
  if (checked === 'mixed') return checked;
  return checked ? 'true' : 'false';
}

/** A styled checkbox element. Never use a default `<input type="checkbox">`, always use this. */
export function RCCheckbox({
  checked,
  triState,
  disabled,
  errorText,
  paddedHover,
  id,
  label,
  name,
  onChange,
  onClick,
  required,
  linkElement,
  ariaControls,
  sublabel = undefined,
}: IRCCheckableProps) {
  const classes = classNames(
    'rc-checkbox',
    { 'rc-checkbox--checked': checked },
    { 'rc-checkbox--disabled': disabled },
    { 'rc-checkbox--padded-hover': paddedHover },
  );

  const describedBy = [
    sublabel && sublabelID(id),
    errorText && errorID(id),
  ].filter(Boolean).join(' ');

  if (triState) {
    const triStateSpanClasses = classNames(
      'rc-checkbox',
      { 'rc-checkbox--padded-hover': paddedHover },
    );

    const buttonClassNames = classNames(
      'rc-checkbox',
      { 'rc-checkbox--checked': checked },
      { 'rc-checkbox--disabled': disabled },
    );
    return (
      <span className={triStateSpanClasses}>
        <button
          className={buttonClassNames}
          aria-checked={triStateCheckedValue(checked)}
          aria-controls={ariaControls}
          role="checkbox"
          tabIndex={0}
          onClick={onClick}
          type="button"
        >
          <span className="rc-checkbox__box">
            <RCIcon svgComponent={checked === 'mixed' ? MinusIcon : CheckIcon} />
          </span>
          <RCCheckableLabel
            label={label}
            sublabel={sublabel}
            errorText={errorText}
            renderAsDiv
          />
        </button>
      </span>
    );
  }

  if (linkElement) {
    return (
      <LinkElement
        styleName={classes}
        linkElement={linkElement}
        tabIndex={-1}
      >
        <input
          type="checkbox"
          checked={checked as boolean}
          disabled={disabled}
          id={id}
          onChange={onChange}
          className="rc-checkbox__input"
          readOnly
          aria-labelledby={labelID(id)}
          aria-describedby={describedBy}
        />
        <span aria-hidden className="rc-checkbox__box">
          <RCIcon svgComponent={CheckIcon} />
        </span>
        <RCCheckableLabel
          sublabel={sublabel}
          label={label}
          errorText={errorText}
          htmlFor={id}
          inputId={id}
        />
      </LinkElement>
    );
  }

  return (
    <span
      className={classes}
    >
      <input
        className="rc-checkbox__input"
        type="checkbox"
        name={name}
        id={id}
        checked={checked as boolean}
        onChange={onChange}
        disabled={disabled}
        required={required}
        aria-describedby={describedBy}
      />
      <span className="rc-checkbox__box">
        <RCIcon svgComponent={CheckIcon} />
      </span>
      <RCCheckableLabel
        htmlFor={id}
        sublabel={sublabel}
        label={label}
        errorText={errorText}
        inputId={id}
      />
    </span>
  );
}
