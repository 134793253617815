import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgTradeIn = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M14.004 4.088c-3.536 0-6.81.854-9.25 2.314-2.41 1.44-4.241 3.641-4.241 6.357 0 2.715 1.832 4.916 4.241 6.357 2.276 1.361 5.278 2.196 8.542 2.302l-1.718 2.185a1.197 1.197 0 0 0 1.88 1.48l3.984-5.066a1.197 1.197 0 0 0-.201-1.68l-4.634-3.644a1.197 1.197 0 0 0-1.48 1.881l2.362 1.857c-2.876-.079-5.404-.809-7.2-1.883-1.931-1.155-2.785-2.542-2.785-3.79 0-1.246.854-2.633 2.786-3.79 1.9-1.135 4.625-1.888 7.714-1.888 3.09 0 5.813.752 7.715 1.889 1.932 1.156 2.785 2.543 2.785 3.79 0 1.12-.68 2.343-2.228 3.429a1.496 1.496 0 1 0 1.717 2.45c2.025-1.42 3.503-3.448 3.503-5.88 0-2.715-1.832-4.915-4.241-6.356-2.44-1.46-5.714-2.314-9.25-2.314Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTradeIn;
