import React from 'react';
import { RCSkeleton, RCImage, RCText } from '../';

interface LinkElementProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children?: React.ReactNode;
}

interface ButtonElementProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
}

type WrapperElement = LinkElementProps | ButtonElementProps;

interface RCProductCardProps {
  title: string;

  /** Uses the Eyebrow text style. Appears between the image and title. */
  eyebrow: string;

  /** Url string to be passed to RCImage. */
  imageUrl: string;

  /**
   * A passed link or button that wraps the card content (e.g. <CoreLink> or <button>).
   *
   * Example: `wrapper={{ type: 'link', element={(children) => <CoreLink to="...">{children}</CoreLink> }}`
  */
  wrapper?: {
    type: 'link' | 'button';
    element: (children: React.ReactNode) => React.ReactElement<WrapperElement>;
  }
}

/**
 * A card component for representing "Products".
 *
 * This component accepts child content and renders it at the bottom of the card.
 * Use this area for nudges, CTAs, etc.
 */
export function RCProductCard({
  title,
  eyebrow,
  imageUrl,
  wrapper = undefined,
  children = undefined,
}: React.PropsWithChildren<RCProductCardProps>) {

  function WrapperElement({ children: wrapperChildren, ...passthroughProps }) {
    if (!wrapper) {
      return <>{children}</>;
    }
    const { type, element } = wrapper;
    return React.cloneElement(
      element(wrapperChildren),
      {
        className: `rc-product-card__wrapper rc-product-card__wrapper--${type}`,
        ...passthroughProps,
      },
    );
  }

  return (
    <div
      className="rc-product-card"
      data-rc-image-overlay-trigger
    >
      <WrapperElement>
        <div className="rc-product-card__image-wrapper">
          <RCImage
            src={imageUrl}
            overlay="offwhiteWithHover"
            borderRadius="md"
          />
        </div>
        <div className="rc-product-card__content">
          <div>
            <div className="rc-product-card__eyebrow">
              <RCText.Eyebrow maxLines={1}>{eyebrow}</RCText.Eyebrow>
            </div>
            <div
              className="rc-product-card__title"
              title={title}
            >
              {title}
            </div>
          </div>
          <div className="rc-product-card__footer">
            {children}
          </div>
        </div>
      </WrapperElement>
    </div>
  );
}

function Skeleton() {
  return (
    <div className="rc-product-card">
      <div className="rc-product-card__image-wrapper">
        <RCSkeleton width="100%" height="100%" />
      </div>
      <div className="rc-product-card__content">
        <div>
          <div className="text-eyebrow-300 mb-1">
            <RCSkeleton width="25%" />
          </div>
          <div className="rc-product-card__title">
            <RCSkeleton width="75%" />
          </div>
        </div>
      </div>
    </div>
  );
}

RCProductCard.Skeleton = Skeleton;
