import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgCaretDown = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M22 11c0 .27-.099.505-.297.703l-7 7A.961.961 0 0 1 14 19a.961.961 0 0 1-.703-.297l-7-7A.961.961 0 0 1 6 11c0-.27.099-.505.297-.703A.961.961 0 0 1 7 10h14c.27 0 .505.099.703.297A.961.961 0 0 1 22 11z" />
  </svg>
);
export default SvgCaretDown;
