import React, { useRef, useState, useMemo } from 'react';
import {
  FloatingFocusManager,
  FloatingPortal,
  autoUpdate,
  offset,
  size,
  flip,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useMergeRefs,
  useRole,
} from '@floating-ui/react';
import I18n from 'i18n-js';
/* eslint-disable no-restricted-imports */
import { motion, AnimatePresence } from 'framer-motion';

import { CommonInputProps } from '../../types';
import { FLOATING_UI_DOM_ID, PANEL_DEFAULTS } from '../../../constants';
import { defaultConfig } from '../../helpers/framer-motion-config';

import { SearchAltIcon, CloseIcon, AngleDownIcon, AngleUpIcon } from '../../../icons/react';
import { RCIconButton, RCLoadingBars } from '../../';

const OPEN = true;

interface RCComboboxBaseProps extends Omit <CommonInputProps,
  'name' | 'label' | 'helpText' | 'errorText' | 'id'> {
  /** Controls panel state */
  isOpen: boolean;

  /** Callback function to handle changes in open state */
  onOpenChange: (open: boolean) => void;

  /** Sets the visual display of the input to represent a 'select' or 'search' field */
  type?: 'select' | 'search';

  inputId?: string;

  labelId?: string;

  inputValue: string;

  /** Overrides the placeholder text displayed when no input value is present. */
  placeholder?: string;

  /** Callback function that returns the input string currently in the input field. */
  onInputChange?: (value: string) => void;

  /** Callback function that fires when Search button is pressed. When paired with `preventAutoFocus = true`, also fires when the enter key is pressed and no child is currently focused. */
  onSubmit?: (event) => void;

  /** Displays a loading indicator. Use when fetching option data. */
  isLoading?: boolean;

  /** Allows for automatic focus of selected option */
  indexOfSelectedOption?: number | null;

  /** Prevents the default behavior of auto focusing the first child when typing in the input. Can be used to allow the enter key to trigger `onSubmit` when no child is focused. */
  preventAutoFocus?: boolean;

  children?: React.ReactNode;
}

export function RCComboboxBase({
  isOpen,
  disabled,
  inputId,
  labelId,
  placeholder = I18n.t('cadence.RCCombobox.inputPlaceholder'),
  inputValue = '',
  isLoading = false,
  onInputChange = undefined,
  onOpenChange,
  onSubmit = undefined,
  required,
  indexOfSelectedOption = null,
  type = 'search',
  preventAutoFocus = false,
  children,
}: RCComboboxBaseProps) {

  const wrapperEl = useRef<HTMLElement | null>(null);
  const listRef = useRef<Array<HTMLElement | null>>([]);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const validChildren = useMemo(() => {
    return React.Children.toArray(children).filter(c => {
      return React.isValidElement(c);
    });
  }, [children]);

  const hasValidChildren = validChildren.length > 0;

  // Main config for libs "useFloating"
  const {
    x,
    y,
    strategy,
    context,
    refs,
  } = useFloating({
    open: isOpen,
    onOpenChange,
    middleware: [
      offset(PANEL_DEFAULTS.anchorOffset),
      flip({
        mainAxis: true,
        crossAxis: false, // do not use left / right positioning
      }),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            minWidth: `${rects.reference.width}px`,
          });
        },
      }),
    ],
    placement: 'bottom-start',
    whileElementsMounted: (r, f, u) => {
      // We only need to auto update if it's possible to do so. This check also ensures
      // functionality with enzyme `mount`s in tests.
      if (('ResizeObserver' in window)) {
        return autoUpdate(r, f, u);
      }
      return;
    },
  });

  const {
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useInteractions([
    useRole(context, {
      role: 'listbox',
    }),
    useDismiss(context, {
      enabled: true,
      escapeKey: true,
      outsidePressEvent: 'mousedown',
      outsidePress: (event) => {
        const target = event.target as Node;
        if (!target) return true;
        return !wrapperEl?.current?.contains(target);
      },
    }),
    useListNavigation(context, {
      listRef,
      activeIndex,
      selectedIndex: indexOfSelectedOption,
      onNavigate: setActiveIndex,
      virtual: true,
    }),
  ]);

  function handleChildSelect(child) {
    if (!child) return;
    if (child.props?.disabled) return;
    child.props?.onClick();
  }

  function handleKeydown(event) {
    const { key } = event;
    if (!isOpen && key === 'Backspace' && inputValue) {
      onOpenChange(OPEN);
    }
    // alphanumeric values and space bar (e.g. 'a', '1', ' ')
    if (!isOpen && key?.length === 1) {
      onOpenChange(OPEN);
    }
    if (!isOpen && key === 'Enter') {
      event.preventDefault();
      return onSubmit?.(event);
    }
    if (isOpen && key === 'Enter') {
      event.preventDefault();
      if (activeIndex === null) {
        return onSubmit?.(event);
      } else {
        handleChildSelect(validChildren?.[activeIndex]);
      }
    }
  }

  function handleInputChange(e) {
    const { value } = e.target;
    onInputChange?.(value);
    setActiveIndex(preventAutoFocus ? null : 0);
  }

  function shouldShowPanel() {
    if (!isOpen) return false;
    if (!hasValidChildren) return false;
    return true;
  }

  function renderInputActions() {
    const inputRef = refs.domReference?.current as HTMLInputElement;
    return (
      /* Click handler is a progressive enhancement only needed for mouse users */
      /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
      <div
        className="rc-combobox-base__actions"
        onClick={() => {
          if (!isOpen && hasValidChildren) onOpenChange(OPEN);
          return inputRef?.focus();
        }}
      >
        {isLoading &&
          <div className="rc-combobox-base__action rc-combobox-base__action--loading">
            <RCLoadingBars size="small" />
          </div>
        }
        {type === 'select' &&
          <div className="rc-combobox-base__action rc-combobox-base__action--select">
            <RCIconButton
              variant="transparent"
              size="small"
              svgComponent={isOpen ? AngleUpIcon : AngleDownIcon}
              label={isOpen ? I18n.t('cadence.shared.close') : I18n.t('cadence.shared.open')}
              onClick={() => onOpenChange(!isOpen)}
              disabled={disabled}
              hideTooltip={disabled}
            />
          </div>
        }
        {type === 'search' && inputValue.length > 0 &&
          <div className="rc-combobox-base__action rc-combobox-base__action--clear">
            <RCIconButton
              variant="transparent"
              size="small"
              svgComponent={CloseIcon}
              label={I18n.t('cadence.RCCombobox.clearInput')}
              onClick={() => onInputChange?.('')}
              disabled={disabled}
              hideTooltip={disabled}
            />
          </div>
        }
        {type === 'search' &&
          <div className="rc-combobox-base__action rc-combobox-base__action--search">
            <RCIconButton
              variant="transparent"
              size="small"
              svgComponent={SearchAltIcon}
              label={I18n.t('cadence.RCCombobox.search')}
              onClick={(e) => onSubmit?.(e)}
              disabled={disabled}
              hideTooltip={disabled}
            />
          </div>
        }
      </div>
    );
  }

  const wrapperRef = useMergeRefs([wrapperEl, refs.setPositionReference]);
  // Loading a motion.div server side breaks everything, so this defers loading it until the next render cycle
  const [loaded, setIsLoaded] = React.useState(false);
  React.useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      <div
        className="rc-combobox-base"
        ref={wrapperRef}
      >
        <input
          id={inputId}
          aria-labelledby={labelId}
          className="rc-combobox-base__input"
          type="text"
          placeholder={placeholder}
          autoComplete="off"
          value={inputValue}
          aria-autocomplete="list"
          required={required}
          disabled={disabled}
          onChange={handleInputChange}
          {...getReferenceProps({
            ref: refs.setReference,
            onMouseDown() {
              if (!isOpen) onOpenChange(OPEN);
            },
            onKeyDown(event) {
              handleKeydown(event);
            },
          })}
        />
        {renderInputActions()}
      </div>
      <FloatingPortal
        id={FLOATING_UI_DOM_ID}
      >
        <AnimatePresence>
          {shouldShowPanel() && (
            <FloatingFocusManager
              context={context}
              initialFocus={0}
              order={['reference']}
              closeOnFocusOut={false}
            >
              {loaded && <motion.div
                ref={refs.setFloating}
                className="rc-combobox-base__panel"
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                }}
                {...defaultConfig({
                  initial: {
                    opacity: 0,
                    y: 0,
                  },
                })}
                {...getFloatingProps()}
              >
                <ul className="rc-combobox-base__options">
                  {validChildren.map((child, index) => {
                    if (!React.isValidElement(child)) return null;
                    return React.cloneElement(
                      child,
                      {
                        ...getItemProps({
                          ref(node) {
                            listRef.current[index] = node;
                          },
                          onClick() {
                            handleChildSelect(child);
                          },
                          ...{ 'data-active': activeIndex === index },
                        }),
                      },
                    );
                  })}
                </ul>
              </motion.div>}
            </FloatingFocusManager>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </>
  );
}
