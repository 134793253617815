import React from 'react';
import classNames from 'classnames';

interface RCListingGridProps {
  /** Children should all be `RCListingGrid.Item` instances. */
  children: React.ReactNode;

  /** This affects grid logic and may be deprecated once we roll a sidebar forward permanently. For now, if you're using an RCListingGrid inside an RCSiteWrapper with a sidebar, set it to true. */
  withSidebar?: boolean;

  /** This overrides our tiled grid & renders a single row instead. If you're using this, make sure to put it inside an `OverflowingRow`. */
  singleRow?: boolean | 'tabletAndDesktop'

  /** This will apply a pulse animation to the container; use if needed. */
  loading?: boolean;
}

/**
* Our main tiling component. Use this for rows or grids of listings. All children should be `RCListingGrid.Item` instances, but those are just empty boxes; you can render grids of listing cards, sell cards, whatever you need.
*/
export function RCListingGrid({
  singleRow = false,
  withSidebar = false,
  loading = false,
  children,
}: RCListingGridProps) {
  return (
    <ul className={classNames(
      'rc-listing-grid',
      { 'rc-listing-grid--loading': loading },
      { 'rc-listing-grid--with-sidebar': withSidebar },
      {
        'rc-listing-grid--single-row': singleRow === true,
        'rc-listing-grid--single-row-t-and-d': singleRow === 'tabletAndDesktop',
      },
    )}>
      {children}
    </ul>
  );
}

interface GridItemProps {
  colSpan?: 'one' | 'all',
  children: React.ReactNode;
  hideOn?: 'desktop' | 'tablet' | 'mobile' | 'tabletAndDesktop' | 'tabletAndMobile';
}

function ListingGridItem({
  colSpan = 'one',
  children,
  hideOn = undefined,
}: GridItemProps) {
  return (
    <li className={classNames(
      'rc-listing-grid__item',
      { 'rc-listing-grid__item--span-all': colSpan === 'all' },
      {
        'rc-listing-grid__item--hide-d': hideOn === 'desktop',
        'rc-listing-grid__item--hide-t': hideOn === 'tablet',
        'rc-listing-grid__item--hide-m': hideOn === 'mobile',
        'rc-listing-grid__item--hide-t-and-d': hideOn === 'tabletAndDesktop',
        'rc-listing-grid__item--hide-t-and-m': hideOn === 'tabletAndMobile',
      },
    )}>
      {children}
    </li>
  );
}

RCListingGrid.Item = ListingGridItem;
