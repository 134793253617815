import classNames from 'classnames';
import React from 'react';
import { useMediaQuery } from '../';
import { RCDrawer, RCDrawerProps } from '../RCDrawer/RCDrawer';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';

interface RCSiteWrapperProps {
  /** Any/all content that should be wrapped */
  children: React.ReactNode;

  /** Narrow width here is only for a few components used in legacy one-column CMS pages, you should probably ignore it. */
  width?: 'default' | 'narrow';

  /** This will apply a pulse animation to the <b>main column only</b>; use if needed. */
  loading?: boolean;

  /** Use the provided `RCSiteWrapper.Sidebar` component set here – see the "with sidebar" story. */
  sidebar?: React.ReactNode;

  /** You might end up needing to nest this inside a component that already contains wrapper logic. If so, set this to true to avoid doubling left & right gutters. */
  insideWrapper?: boolean;
}

/**
 * This is our main site wrapper. Oftentimes you will find yourself using it with no props set just to provide a width & gutter for your content.
 *
 * However, it also supports a sidebar that becomes toggleable on tablet & narrower widths; see the examples & comments below for how to take advantage of this.
 */
export function RCSiteWrapper({
  children,
  width = 'default',
  loading = false,
  insideWrapper = false,
  sidebar = undefined,
}: RCSiteWrapperProps) {

  return (
    <div className={classNames(
      'rc-site-wrapper',
      {
        'rc-site-wrapper--narrow': width === 'narrow',
        'rc-site-wrapper--loading': loading,
        'rc-site-wrapper--with-sidebar': !!sidebar,
        'rc-site-wrapper--inside-wrapper': insideWrapper,
      },
    )}>
      {sidebar ? (
        <>
          {sidebar}
          <div className="rc-site-wrapper__main-column">
            {children}
          </div>
        </>
      ) : children
      }
    </div>
  );
}

interface SiteWrapperSidebarProps extends RCDrawerProps {
  sticky?: boolean;
  children: React.ReactNode;
}

export function RCSiteWrapperSidebar({
  sticky = false,
  children,
  ...drawerProps
}: SiteWrapperSidebarProps) {
  const isMobile = useMediaQuery('mobile');
  const isTablet = useMediaQuery('tablet');
  const isTabletOrMobile = isTablet || isMobile;

  const observerTop = useIntersectionObserver();
  const observerBottom = useIntersectionObserver();

  if (isTabletOrMobile) {
    return (
      <RCDrawer {...drawerProps}>
        {children}
      </RCDrawer>
    );
  }

  return (
    <div
      className={classNames(
        'rc-site-wrapper__sidebar',
        { 'rc-site-wrapper__sidebar--sticky': sticky },
        { 'rc-site-wrapper__sidebar--shadow-top': observerTop?.entry && !observerTop.entry.isIntersecting },
        { 'rc-site-wrapper__sidebar--shadow-bottom': observerBottom?.entry && !observerBottom.entry.isIntersecting },
      )}
    >
      <div className="rc-site-wrapper__sidebar__content">
        {sticky && <div ref={observerTop?.setTrackedRef} data-observer />}
        {children}
        {sticky && <div ref={observerBottom?.setTrackedRef} data-observer />}
      </div>
    </div>
  );
}

RCSiteWrapper.Sidebar = RCSiteWrapperSidebar;
