import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgMinus = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M25 12v2c0 .278-.146.514-.438.708-.291.195-.645.292-1.062.292h-19c-.417 0-.77-.097-1.063-.292C3.147 14.514 3 14.278 3 14v-2c0-.278.146-.514.438-.708.291-.195.645-.292 1.062-.292h19c.417 0 .77.097 1.063.292.291.194.437.43.437.708Z" />
  </svg>
);
export default SvgMinus;
