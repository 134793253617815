import React from 'react';

const VALID_THEMES = ['light', 'dark', 'system'] as const;

export type Theme = typeof VALID_THEMES[number];
export const DEFAULT_THEME: Theme = 'light';

export interface RCThemeContextProps {
  theme: Theme;
  updateTheme?: (newTheme: Theme) => void;
}

export const RCThemeContext = React.createContext<RCThemeContextProps>({
  theme: null,
  updateTheme: () => {},
});

export function isValidTheme(theme: string) {
  return VALID_THEMES.includes(theme as any);
}
