import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgStorefront = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M8.724 10.645c-.219 1.343-1.69 2.382-3.474 2.382-1.936 0-3.5-1.218-3.5-2.721 0-.236.038-.464.11-.681L4.2 3.5h5.6l-1.076 7.145zM14 13.027c-1.932 0-3.5-1.218-3.5-2.721 0-.06.004-.114.005-.17L11.2 3.5h5.6l.693 6.641c.002.053.007.109.007.165 0 1.503-1.568 2.721-3.5 2.721zm12.142-3.402c.07.217.108.444.108.68 0 1.504-1.566 2.722-3.5 2.722-1.79 0-3.265-1.045-3.474-2.392L18.2 3.5h5.6l2.341 6.125zM7 13.605v5.645h14v-5.646a5.211 5.211 0 0 0 1.75.298v8.848A1.75 1.75 0 0 1 21 24.5H7a1.75 1.75 0 0 1-1.75-1.75v-8.848c.616 0 1.21-.105 1.75-.297z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgStorefront;
