import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgTrashO = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11 11.5v9a.487.487 0 0 1-.14.36.487.487 0 0 1-.36.14h-1a.487.487 0 0 1-.36-.14.487.487 0 0 1-.14-.36v-9a.49.49 0 0 1 .14-.36.487.487 0 0 1 .36-.14h1a.49.49 0 0 1 .36.14.49.49 0 0 1 .14.36zm4 0v9a.487.487 0 0 1-.14.36.487.487 0 0 1-.36.14h-1a.487.487 0 0 1-.36-.14.487.487 0 0 1-.14-.36v-9a.49.49 0 0 1 .14-.36.487.487 0 0 1 .36-.14h1a.49.49 0 0 1 .36.14.49.49 0 0 1 .14.36zm4 0v9a.487.487 0 0 1-.14.36.487.487 0 0 1-.36.14h-1a.487.487 0 0 1-.36-.14.487.487 0 0 1-.14-.36v-9a.49.49 0 0 1 .14-.36.487.487 0 0 1 .36-.14h1a.49.49 0 0 1 .36.14.49.49 0 0 1 .14.36zm2 11.313V8H7v14.813c0 .229.036.44.11.632.072.193.148.334.226.422.078.089.133.133.164.133h13c.031 0 .086-.044.164-.133.078-.088.154-.229.227-.422.073-.192.109-.403.109-.633zM10.5 6h7l-.75-1.828A.441.441 0 0 0 16.484 4h-4.953a.441.441 0 0 0-.265.172L10.5 6zm14.5.5v1a.487.487 0 0 1-.14.36.487.487 0 0 1-.36.14H23v14.813c0 .864-.245 1.611-.734 2.242-.49.63-1.078.945-1.766.945h-13c-.688 0-1.276-.305-1.766-.914-.49-.61-.734-1.346-.734-2.211V8H3.5a.487.487 0 0 1-.36-.14A.487.487 0 0 1 3 7.5v-1a.49.49 0 0 1 .14-.36A.487.487 0 0 1 3.5 6h4.828l1.094-2.61c.156-.385.437-.713.844-.984.406-.27.817-.406 1.234-.406h5c.417 0 .828.135 1.234.406.407.271.688.6.844.985L19.672 6H24.5a.49.49 0 0 1 .36.14.49.49 0 0 1 .14.36z" />
  </svg>
);
export default SvgTrashO;
