import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgThumbsUp = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M5 21a.961.961 0 0 0-.297-.703A.961.961 0 0 0 4 20a.954.954 0 0 0-.71.297A.974.974 0 0 0 3 21a.96.96 0 0 0 .29.71A.96.96 0 0 0 4 22a.97.97 0 0 0 .703-.29A.953.953 0 0 0 5 21zm2.5-8v10c0 .27-.099.505-.297.703A.961.961 0 0 1 6.5 24H2a.961.961 0 0 1-.703-.297A.961.961 0 0 1 1 23V13c0-.27.099-.505.297-.703A.961.961 0 0 1 2 12h4.5c.27 0 .505.099.703.297A.961.961 0 0 1 7.5 13zM26 13c0 .896-.287 1.672-.86 2.328.157.459.235.854.235 1.188a3.243 3.243 0 0 1-.672 2.14c.177.584.177 1.193 0 1.828-.156.594-.437 1.084-.844 1.47.094 1.166-.161 2.108-.765 2.827-.667.792-1.693 1.198-3.078 1.219H18a11.6 11.6 0 0 1-2.25-.242 20.045 20.045 0 0 1-1.898-.453 84.534 84.534 0 0 1-1.883-.617C10.688 24.24 9.865 24.01 9.5 24a1.034 1.034 0 0 1-.703-.305A.936.936 0 0 1 8.5 23V12.984c0-.26.094-.487.281-.68.188-.192.412-.299.672-.32.25-.02.646-.328 1.188-.921a28.603 28.603 0 0 0 1.578-1.891c.708-.906 1.234-1.531 1.578-1.875.187-.188.349-.438.484-.75.136-.313.227-.565.274-.758.047-.193.117-.508.21-.945.073-.407.139-.724.196-.953.057-.23.159-.5.305-.813.146-.312.322-.573.53-.781A.961.961 0 0 1 16.5 2c.48 0 .909.055 1.29.164.38.11.692.245.937.406.244.162.453.373.625.633.171.26.296.495.375.703.078.209.14.469.187.781.047.313.073.547.078.704.005.156.008.359.008.609 0 .396-.05.792-.148 1.188a6.334 6.334 0 0 1-.297.937 12.843 12.843 0 0 1-.586 1.156 2.682 2.682 0 0 0-.297.719H23c.813 0 1.516.297 2.11.89.593.594.89 1.297.89 2.11z" />
  </svg>
);
export default SvgThumbsUp;
