export const FLOATING_UI_DOM_ID = 'floating-ui-portal';
export const TOAST_DOM_ID = 'toast-portal';
export const MODAL_DOM_ID = 'modal-portal';

// Default space / size values for fLoating panels
export const PANEL_DEFAULTS = {
  anchorOffset: 8,
  arrowHeight: 8, // This match the $arrow-width param on the dialogs.arrow scss mixin
  arrowHeightSmall: 6,
  windowEdgePadding: 8,
};

export const MOBILE_MAX_WIDTH_PX = 640;
export const TABLET_MAX_WIDTH_PX = 960;
