import I18n from 'i18n-js';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import {
  reverb_config_cache_ExchangeRate,
  core_apimessages_Money as Money,
} from './gql/graphql';
import {
  parseAmount,
  parseAmountCents,
  getConfig,
  IMoneyConfig,
} from './money';

function getExchangeRate(
  fromCurrency: string,
  toCurrency: string,
  rates: reverb_config_cache_ExchangeRate[],
): number {
  if (fromCurrency === toCurrency) { return 1.0; }

  const rate = rates.find(rate => rate.to === toCurrency && rate.from === fromCurrency);

  if (!rate) {
    throw (
      new Error(
        I18n.t('commons.currency.unsupportedCurrencyConversion', { fromCurrency, toCurrency }),
      )
    );
  }

  return rate.rate;
}

export function convertMoney(
  from: { amountCents?: number; currency?: string },
  toCurrency: string,
  rates: reverb_config_cache_ExchangeRate[],
  config?: IMoneyConfig,
): Money {
  const fromCurrency = from.currency;
  const rate = getExchangeRate(fromCurrency, toCurrency, rates);

  const amountCents = from.amountCents * rate;
  return parseAmountCents(amountCents, toCurrency, config);
}

export function convertCurrency(
  fromCurrency: string,
  toCurrency: string,
  rates: reverb_config_cache_ExchangeRate[],
  amount: string,
  config?: IMoneyConfig,
): Money {
  config = config || getConfig();
  const fromMoney = parseAmount(amount, fromCurrency, config);
  return convertMoney(fromMoney, toCurrency, rates);
}

export const EXCHANGE_RATES_QUERY = gql`
  query Core_Exchange_Rates {
    exchangeRates(input: { inverseReverseRates: true }) {
      rates {
        from
        to
        rate
      }
    }
  }
`;
