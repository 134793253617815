import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgChevronRight = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21.297 13.703 9.703 25.297a.961.961 0 0 1-.703.297.961.961 0 0 1-.703-.297l-2.594-2.594A.961.961 0 0 1 5.406 22c0-.27.1-.505.297-.703L14 13 5.703 4.703A.961.961 0 0 1 5.406 4c0-.27.1-.505.297-.703L8.297.703A.961.961 0 0 1 9 .406c.27 0 .505.1.703.297l11.594 11.594a.961.961 0 0 1 .297.703c0 .27-.1.505-.297.703z" />
  </svg>
);
export default SvgChevronRight;
