import { Theme } from '../';

const LIGHT_THEME: Theme = 'light';
const DARK_THEME: Theme = 'dark';

export function preferLightTheme(enabled = true) {
  if (!enabled) return {};
  return { 'data-theme': LIGHT_THEME };
}

export function preferDarkTheme(enabled = true) {
  if (!enabled) return {};
  return { 'data-theme': DARK_THEME };
}
