import { ComponentClass } from 'react';
import RouterHistory from './cms/lib/router_history';
import {
  IndexRoute as OriginalIndexRoute,
  IndexRouteProps,
  Route as OriginalRoute,
  RouteProps,
  RouterState,
  match,
} from 'react-router';
import { PageViewEvent } from './elog/mparticle_types';
import { Location } from 'history';

let routes;

// Re-export `Route` with more specific types for route props
export const Route: ComponentClass<RouteProps<IRouteSettings>> = OriginalRoute;
export const IndexRoute: ComponentClass<IndexRouteProps<IRouteSettings>> = OriginalIndexRoute;

export function registerRoutes(r) {
  routes = r;
}

type BuildPageViewEvent = (routerState: RouterState) => PageViewEvent;

export interface IRouteSettings {
  clientSide?: boolean;
  pageViewEvent?: PageViewEvent | BuildPageViewEvent;
  [key: string]: any;
}

export function matchRoutes(location: Partial<Location>): Promise<RouterState> {
  return new Promise((resolve, err) => {
    if (!routes) {
      resolve(null);
    }

    match(
      {
        routes,
        location,
        history: RouterHistory,
      },
      (error, _redirectLocation, renderProps) => {
        if (error) {
          err(error);
        }
        resolve(renderProps);
      },
    );
  });
}

export async function getRouteSettings(location): Promise<IRouteSettings[]> {
  const state = await matchRoutes(location);
  if (!state) return [];

  return state.routes
    .map(route => route.props)
    .filter(props => !!props);
}
