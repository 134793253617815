import React from 'react';
import { RCAvatar, RCStarRating } from '../';
import type { RCStarRatingProps } from '../RCStarRating/RCStarRating';
import { LinkElement, LinkElementFunction } from '../helpers/linkElement';

export interface RCShopInfoProps {
  /** The URL for the avatar */
  avatarURL?: string;

  /** The name of the shop */
  name: string;

  /** The shop's location in the world */
  location?: string;

  /** The shop's rating - See [RCStarRating](/?path=/docs/design-system-rcstarrating--default) for details */
  rating?: Omit<RCStarRatingProps, 'largeStars'>;
  /**
   * `linkElement` is Cadence's way to support CoreLink.
   * Pass in a linkable element and in component's internals will determine how to render the `children` prop passed.
   *
   * Example:
   *
   * ```tsx
   * <RCListingRowCard
   *  title="Foo"
   *  linkElement={(children) => (
   *    <CoreLink
   *      to="/where/ever/you/want/"
   *      target="_blank"
   *    >
   *      {children}
   *    </CoreLink>
   *  )}
   *  ...
   * />
   * ```
   */
  linkElement?: LinkElementFunction | undefined ;
}

export function RCShopInfo({
  avatarURL = undefined,
  name = '',
  location = undefined,
  rating = undefined,
  linkElement = undefined,
}: RCShopInfoProps) {
  return (
    <div className="rc-shop-info">
      {avatarURL && (
        <LinkElement styleName="rc-shop-info__avatar" linkElement={linkElement}>
          <RCAvatar
            userName={name}
            thumbnailURL={avatarURL}
          />
        </LinkElement>
      )}
      <div className="rc-shop-info__content">
        <LinkElement styleName="rc-shop-info__name" linkElement={linkElement}>
          {name}
        </LinkElement>
        {location && (
          <div className="rc-shop-info__location">
            {location}
          </div>
        )}
        {(rating && rating?.rating > 0) && (
          <div className="rc-shop-info__rating">
            <RCStarRating {...rating} />
          </div>
        )}
      </div>
    </div>
  );
}
