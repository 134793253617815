import classNames from 'classnames';
import React from 'react';
import legacyDefaultAvatar from '../../images/avatars/default.jpg';
import shopDefaultAvatar from '../../images/avatars/shop-brand-default.png';

export interface RCAvatarProps {
  /** URL for the user's thumbnail */
  thumbnailURL?: string;

  /** User's name. Will be applied via aria-label for a11y. Optionally used for initial when thumbnailFallback is `initial` (remove the thumbnailURL value to see this in action). */
  userName: string;

  /** Control the type of fallback used – default user silhouette, default shop icon, or the user's initial */
  thumbnailFallback?: 'user' | 'shop' | 'initial';
}

/**
 * A very simple component that styles an accessibility-friendly circular avatar image.
 *
 * `RCAvatar` is always the width of its container; use the container's size to scale the avatar to your needs.
 */

export function RCAvatar({
  thumbnailURL = undefined,
  userName,
  thumbnailFallback = 'user',
}: RCAvatarProps) {
  const userInitial = userName.charAt(0);
  const [avatarHeight, setAvatarHeight] = React.useState(0);
  const avatarRef = React.useRef(null);
  const AVATAR_PADDING_PERCENTAGE = 0.2;

  function handleWindowResize(ref) {
    if (!ref.current) return;
    setAvatarHeight(ref.current.clientWidth);
  }

  React.useEffect(() => {
    if (!thumbnailURL) {
      handleWindowResize(avatarRef);

      window.addEventListener('resize', () => handleWindowResize(avatarRef));
    }

    return () => {
      window.removeEventListener('resize', () => handleWindowResize(avatarRef));
    };
  }, [thumbnailURL]);

  const initialStyle = {
    fontSize: avatarHeight - (avatarHeight * (AVATAR_PADDING_PERCENTAGE * 2)),
  };

  function ThumbnailImage({ src }) {
    return (
      <img
        className="rc-avatar__thumbnail"
        src={src}
        alt=""
        aria-hidden="true"
      />
    );
  }

  return (
    <div
      className={classNames(
        'rc-avatar',
        {
          'rc-avatar--initials': !thumbnailURL && thumbnailFallback === 'initial',
        },
      )}
      aria-label={userName}
      ref={avatarRef}
    >
      {thumbnailURL ? (
        <ThumbnailImage src={thumbnailURL} />
      ) : (
        thumbnailFallback === 'initial' ? (
          <span
            className="rc-avatar__initial"
            aria-hidden="true"
            style={initialStyle}
          >
            {userInitial}
          </span>
        ) : (
          <ThumbnailImage src={
            thumbnailFallback === 'user' ?
              legacyDefaultAvatar :
              shopDefaultAvatar
          } />
        )
      )}
    </div>
  );
}
