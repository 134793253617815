import window from '../window_wrapper';
import {
  EventType,
  ProductActionType,
} from './mparticle_types';
import { IS_TEST, MPARTICLE_ENV } from '@reverbdotcom/env';

const apiKeys = {
  staging: 'f3271dc18ed0c44fa4458d8c17150793',
  production: '5508ba7c7050a64b8d6b10d1d2123901',
};

export interface Product {
  Name: string;
  Sku: string | number;
  Price: string | number;
  Quantity: number;
  Brand: string;
  Variant: string | null;
  Category: string | null;
  Position: number | null;
  CouponCode: string | null;
  TotalAmount: number;
  Attributes: Record<string, any>;
}

export interface Impression {
  Name: string;
}

export interface User {
  getCart(): Cart;
  getUserIdentities(): { userIdentities: UserIdentities };
  /**
   * Set multiple user attributes
   * @method setUserAttributes
   * @param {Object} user attribute object with keys of the attribute type, and value of the attribute value
   */
  setUserAttributes(attributes: PlainObject): void;
  getMPID(): string;
}

export interface Cart {
  [k: string]: any;
  add(product: Product, logEvent: boolean): void;
}

export interface UserIdentities {
  other?: string;
  customerid?: string;
  facebook?: string;
  twitter?: string;
  google?: string;
  microsoft?: string;
  yahoo?: string;
  email?: string;
  facebookcustomaudienceid?: string;
  other1?: string;
  other2?: string;
  other3?: string;
  other4?: string;
}

export interface Config {
  logLevel?: string;
  isDevelopmentMode?: boolean;
  identifyRequest?: IdentityAPIData;
  identityCallback?: IdentityCallback;
  maxProducts?: number;
  customFlags?: PlainObject;
}

export interface IdentityAPIData {
  userIdentities?: UserIdentities;
  onUserAlias?: Function;
}

export interface IdentityCallbackResponse {
  httpCode: number;
  getUser(): User;
  body: PlainObject;
}

export type IdentityCallback = (response: IdentityCallbackResponse) => void;

export interface MParticleShape {
  config: Config;
  Identity: {
    /**
     * Initiate a login request to the mParticle server
     * @method login
     * @param {Object} identityApiData The identityApiData object as indicated
     * [here](https://github.com/mParticle/mparticle-sdk-javascript/blob/master-v2/README.md#1-customize-the-sdk)
     * @param {Function} [callback] A callback function that is called when the login request completes
     */
    login(identityApiData: IdentityAPIData, cb?: IdentityCallback): void;

    /**
     * Initiate a logout request to the mParticle server
     * @method logout
     * @param {Object} identityApiData The identityApiData object as indicated [here](https://github.com/mParticle/mparticle-sdk-javascript/blob/master-v2/README.md#1-customize-the-sdk)
     * @param {Function} [callback] A callback function that is called when the logout request completes
     */
    logout(identityApiData: IdentityAPIData, cb?: IdentityCallback): void;

    /**
     * Returns a user object with methods to interact with the current user
     * @method getCurrentUser
     * @return {Object} the current user object
     */
    getCurrentUser(): User;
  };

  /**
   * Returns a string of the user's device id
   * @method getDeviceId
   * @return {String} the device id
   */
  getDeviceId(): string;

  ready(f: () => void): void;

  /**
   * Logs an event to mParticle's servers
   * @method logEvent
   * @param {String} eventName The name of the event
   * @param {Number} [eventType] The eventType as seen
   * [here](http://docs.mparticle.com/developers/sdk/javascript/event-tracking#event-type)
   * @param {Object} [eventInfo] Attributes for the event
   * @param {Object} [customFlags] Additional customFlags
   */
  logEvent(
    eventName: string,
    eventType?: EventType,
    eventInfo?: Record<string, any>,
    customFlags?: Record<string, any>,
  ): void;

  upload(): void;

  /**
   * Logs a page view
   * @method logPageView
   * @param {String} eventName The name of the event. Defaults to 'PageView'.
   * @param {Object} [attrs] Attributes for the event
   * @param {Object} [customFlags] Custom flags for the event
   */
  logPageView(
    eventName: string,
    attrs?: Record<string, any>,
    customFlags?: Record<string, any>
  ): void;

  Store?: {
    webviewBridgeEnabled: boolean;
    isInitialized: boolean;
  };

  eCommerce: {
    /**
     * Creates a product
     * @for mParticle.eCommerce
     * @method createProduct
     * @param {String} name product name
     * @param {String} sku product sku
     * @param {Number} price product price
     * @param {Number} [quantity] product quantity. If blank, defaults to 1.
     * @param {String} [variant] product variant
     * @param {String} [category] product category
     * @param {String} [brand] product brand
     * @param {Number} [position] product position
     * @param {String} [coupon] product coupon
     * @param {Object} [attributes] product attributes
     */
    createProduct(
      title: string,
      id: string | number,
      price: string,
      quantity?: number,
      variant?: string | null,
      category?: string,
      brand?: string,
      position?: number,
      couponCode?: string,
      attributes?: PlainObject,
    ): Product;

    /**
     * Logs a product action
     * @for mParticle.eCommerce
     * @method logProductAction
     * @param {Number} productActionType product action type as found
     * [here](https://github.com/mParticle/mparticle-sdk-javascript/blob/master-v2/src/types.js#L206-L218)
     * @param {Object} product the product for which you are creating the product action
     * @param {Object} [attrs] attributes related to the product action
     * @param {Object} [customFlags] Custom flags for the event
     */
    logProductAction(
      productActionType: ProductActionType,
      product: Product | Product[],
      productActionAttributes?: Record<string, any>,
      customFlags?: Record<string, any>,
    ): void;

    /**
     * Logs a product purchase
     * @for mParticle.eCommerce
     * @method logPurchase
     * @param {Object} transactionAttributes transactionAttributes object
     * @param {Object} product the product being purchased
     * @param {Boolean} [clearCart] boolean to clear the cart after logging or not. Defaults to false
     * @param {Object} [attrs] other attributes related to the product purchase
     * @param {Object} [customFlags] Custom flags for the event
     */
    logPurchase(
      transactionAttributes: Record<string, any>,
      product: Product | Product[],
      clearCart?: boolean,
      attrs?: Record<string, any>,
      customFlags?: Record<string, any>,
    ): void;

    /**
     * Creates a product impression
     * @for mParticle.eCommerce
     * @method createImpression
     * @param {String} name impression name
     * @param {Object} product the product for which an impression is being created
     */
    createImpression(
      name: string,
      product: Product | Product[],
    ): Impression;

    /**
     * Logs a product impression
     * @for mParticle.eCommerce
     * @method logImpression
     * @param {Object} impression product impression object
     * @param {Object} attrs attributes related to the impression log
     * @param {Object} [customFlags] Custom flags for the event
     */
    logImpression(
      impression: Impression,
      attrs: Record<string, any>,
      customFlags?: Record<string, any>,
    ): void;
  };
}

export interface MParticleUserInfo {
  mpid?: string;
  device_id?: string;
  session_id?: string;
}

export function initializeSDK() {
  /* eslint-disable */
  (
    // @ts-ignore
    (function(t) {var r=document.createElement("script");r.type="text/javascript";r.async=true;r.src=("https:"==document.location.protocol?"https://jssdkcdns":"http://jssdkcdn")+".mparticle.com/js/v2/"+t+"/mparticle.js";var c=document.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c);})
  )(apiKeys[MPARTICLE_ENV]);

  /* eslint-enable */
}

function configureSDK() {
  if (IS_TEST) return;
  /* eslint-disable */
  (
    // @ts-ignore
    (function() {window.mParticle=window.mParticle||{};window.mParticle.EventType={Unknown:0,Navigation:1,Location:2,Search:3,Transaction:4,UserContent:5,UserPreference:6,Social:7,Other:8};window.mParticle.eCommerce={Cart:{}};window.mParticle.Identity={};window.mParticle.config=window.mParticle.config||{};window.mParticle.config.rq=[];window.mParticle.config.snippetVersion=2.1;window.mParticle.ready=function(t){window.mParticle.config.rq.push(t)};function e(e,o){return function(){if(o){e=o+"."+e}var t=Array.prototype.slice.call(arguments);t.unshift(e);window.mParticle.config.rq.push(t)}}var o=["endSession","logError","logEvent","logForm","logLink","logPageView","setSessionAttribute","setAppName","setAppVersion","setOptOut","setPosition","startNewSession","startTrackingLocation","stopTrackingLocation"];var n=["setCurrencyCode","logCheckout"];var i=["identify","login","logout","modify"];o.forEach(function(t){window.mParticle[t]=e(t)});n.forEach(function(t){window.mParticle.eCommerce[t]=e(t,"eCommerce")});i.forEach(function(t){window.mParticle.Identity[t]=e(t,"Identity")});})
  )();

  /* eslint-enable */
}

configureSDK();
