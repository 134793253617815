import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgExclamationCircle = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14 2c2.177 0 4.185.536 6.023 1.61a11.945 11.945 0 0 1 4.368 4.367C25.464 9.815 26 11.823 26 14c0 2.177-.537 4.185-1.61 6.023a11.945 11.945 0 0 1-4.367 4.368C18.185 25.464 16.177 26 14 26c-2.177 0-4.185-.537-6.023-1.61a11.945 11.945 0 0 1-4.368-4.367C2.536 18.185 2 16.177 2 14c0-2.177.536-4.185 1.61-6.023a11.946 11.946 0 0 1 4.367-4.368C9.815 2.536 11.823 2 14 2zm2 19.484v-2.968a.514.514 0 0 0-.14-.368.456.456 0 0 0-.344-.148h-3a.494.494 0 0 0-.36.156.494.494 0 0 0-.156.36v2.968c0 .136.052.256.156.36a.494.494 0 0 0 .36.156h3c.135 0 .25-.05.343-.148a.514.514 0 0 0 .141-.368zm-.031-5.375.281-9.703a.307.307 0 0 0-.156-.281.582.582 0 0 0-.375-.125H12.28a.582.582 0 0 0-.375.125.307.307 0 0 0-.156.281l.266 9.703c0 .104.052.196.156.274a.607.607 0 0 0 .375.117h2.89c.146 0 .269-.04.368-.117a.396.396 0 0 0 .164-.274z" />
  </svg>
);
export default SvgExclamationCircle;
