import React from 'react';
import { UserContext, IUser } from './components/user_context_provider';
import { EppoClientContext, IEppoClient } from './eppo/eppoClientContext';
import { EppoSubjectTypes, getEppoAssignment, isEppoExpEnabled } from './eppo/eppoExperimentHelpers';

enum ExpValues {
  Experiment = 'experiment',
  Default = 'default',

  // multivariate default
  Ctl = 'ctl',
}

// "Caches" any call to useExpEnabled where the experiment was not found
// in the config and was then defaulted during the render cycle. This
// allows us to discover experiments that are present on the page,
// but not yet in a user's local config
export const unknownExps = {};

// useUser will inject the current user via a React Hook
export function useUser(): Partial<IUser> {
  return React.useContext(UserContext);
}

// useSeller will inject the current user via a React Hook
// and return sellerAttributes, accounting for
// users in secondary user mode
export interface ISeller extends Partial<IUser> {
  sellerId?: string;
}

export function useSeller(): ISeller {
  const userData = React.useContext(UserContext);

  const {
    id,
    managedShopUserId,
    managedShopId,
    shopId,
    shopCurrency,
    shopCountryCode,
    managedShopCountryCode,
    managedShopCurrency,
    secondaryUserModeIsActive,
  } = userData;

  return {
    ...userData,
    ...{
      sellerId: String(secondaryUserModeIsActive ? managedShopUserId : id),
      shopId: String(secondaryUserModeIsActive ? managedShopId : shopId),
      shopCountryCode: secondaryUserModeIsActive ? managedShopCountryCode : shopCountryCode,
      shopCurrency: secondaryUserModeIsActive ? managedShopCurrency : shopCurrency,
      secondaryUserModeIsActive: !!secondaryUserModeIsActive,
    },
  };
}

// Record an unfound experiment
export function recordUnknownExp(name) {
  unknownExps[name] = ExpValues.Default;
}

// useExpName takes the name for any given experiment and returns
// the value for the current user. With this React Hook you do not
// need to wrap your component in the withUserContext() HOC
export function useExpEnabled(name: string) {
  const user = useUser();
  return inExperiment(user, name);
}

function useEppoClient(): IEppoClient {
  return React.useContext(EppoClientContext);
}

export interface IEppoExperimentArgs {
  flagKey: string;
  subjectKey?: string;
  subjectType?: EppoSubjectTypes;
}

interface IEppoMultiVariateExperimentArgs extends IEppoExperimentArgs {
  variant: string;
}

/**
useEppoEnabled is intended to be used for A/B experiments,
returning true or false.

flagKey is the experiment name defined in Eppo.
subjectKey is the unique identifier for the experiment.
subjectType describes what the unique identifier is——client_context_id, user_id, or seller_id.

subjectKey and subjectType are optional arguments that will
default to use the client_context_id (cookieId)
**/
export function useEppoExpEnabled({
  flagKey,
  subjectKey,
  subjectType,
}: IEppoExperimentArgs): boolean {
  const eppoClient = useEppoClient();
  const user = useUser();

  return isEppoExpEnabled({
    eppoClient,
    user,
    flagKey,
    subjectKey,
    subjectType,
  });
}

/**
useEppoAssignment is intended to be used for multivariate experiments
and will return the name of the assignment group.

flagKey is the experiment name defined in Eppo.
subjectKey is the unique identifier for the experiment.
subjectType describes what the unique identifier is——client_context_id, user_id, or seller_id.

subjectKey and subjectType are optional arguments that will
default to use the client_context_id (cookieId)
**/
export function useEppoAssignment({
  flagKey,
  subjectKey,
  subjectType,
}: IEppoExperimentArgs): string {
  const eppoClient = useEppoClient();
  const user = useUser();

  return getEppoAssignment({
    eppoClient,
    user,
    flagKey,
    subjectKey,
    subjectType,
  });
}

export function useEppoVariantEnabled({
  flagKey,
  subjectKey,
  subjectType,
  variant,
}: IEppoMultiVariateExperimentArgs): boolean {
  const eppoClient = useEppoClient();
  const user = useUser();

  const eppoAssignment = getEppoAssignment({
    eppoClient,
    user,
    flagKey,
    subjectKey,
    subjectType,
  });

  return variant == eppoAssignment;
}

export function inExperiment(user: Partial<IUser>, name: string) {
  const experiments = user.experiments ?? [];
  let val = experiments.find(x => x.name === name)?.value;
  if (!val) {
    val = ExpValues.Default;
    recordUnknownExp(name);
  }

  return val === ExpValues.Experiment;
}
