import React from 'react';
import classNames from 'classnames';
import I18n from 'i18n-js';

import { StarEmptyIcon, StarFilledIcon } from '../../icons/react';
import { RCIcon, RCFormGroup } from '../';
import { RCFormGroupProps } from '../forms/RCFormGroup/RCFormGroup';

interface IProps extends Omit<RCFormGroupProps, 'large' | 'tag' | 'helpText' | 'children'> {
  /** Submitted when the user selects a rating. Passes back an int from 1 to 5. */
  onSelect: (number) => void;

  /** Initial value; should be between 0 and 5. */
  rating: number;

  /** Unique string, used for the form attributes under the hood (this component is technically a styled `radio` list). */
  inputName: string;
}

function Star({ index, rating, onChange, inputName }) {
  const id = `${inputName}-${index}`;
  const checked = Boolean(rating && rating.toString() === index.toString());
  const altText = I18n.t('cadence.RCStarRatingSelector.altLabel', { count: index });

  return (
    <>
      <input
        className={classNames(
          'rc-star-rating-selector__input',
          { 'rc-star-rating-selector__input--checked': rating >= index },
        )}
        value={index}
        id={id}
        name={inputName}
        type="radio"
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} className="rc-star-rating-selector__star">
        <span className="rc-star-rating-selector__alt-label">
          {altText}
        </span>
        <span className="rc-star-rating-selector__icon rc-star-rating-selector__icon--filled" aria-hidden>
          <RCIcon svgComponent={StarFilledIcon} />
        </span>
        <span className="rc-star-rating-selector__icon rc-star-rating-selector__icon--empty" aria-hidden>
          <RCIcon svgComponent={StarEmptyIcon} />
        </span>
      </label>
    </>
  );
}

/**
 * Star rating selector for form inputs where the user should choose a rating.
 */
export function RCStarRatingSelector({
  onSelect,
  rating,
  inputName,
  label,
  inputId,
  errorText,
}: IProps) {
  const onChange = React.useCallback(
    (evt) => {
      onSelect(parseInt(evt.target.value, 10));
    },
    [onSelect],
  );

  const checkableGroupID = `rc-star-rating-selector--${inputId}`;

  return (
    <RCFormGroup
      label={label}
      inputId={inputId}
      errorText={errorText}
      labelID={checkableGroupID}
    >
      <div
        className="rc-star-rating-selector"
        role="radiogroup"
        aria-labelledby={checkableGroupID}
      >
        {[1, 2, 3, 4, 5].map((index) => (
          <Star
            key={index}
            index={index}
            onChange={onChange}
            rating={rating}
            inputName={inputName}
          />
        ))}
      </div>
    </RCFormGroup>
  );
}
