import React from 'react';
import classNames from 'classnames';

import { useId } from '@reach/auto-id';

import {
  RCSkeleton,
  RCNudge, RCNudgeProps,
  RCPriceBlock, RCPriceBlockProps,
  RCCloseButton,
  RCShopInfo, RCShopInfoProps,
} from '../';

import { RCText, EyebrowTextProps } from '../RCText/RCText';
import { LinkElement } from '../helpers/linkElement';
import { preferLightTheme } from '../helpers/themeOverrides';
import { ListingTitleHtmlTags } from '../types';

export interface RCListingCardProps {
  /**
   * `linkElement` is Cadence's way to support CoreLink.
   * Pass in a linkable element and in component's internals will determine how to render the `children` prop passed.
   *
   * Example:
   *
   * ```tsx
   * <RCListingCard
   *  title="Foo"
   *  linkElement={(children) => (
   *    <CoreLink
   *      to="/where/ever/you/want/"
   *      target="_blank"
   *    >
   *      {children}
   *    </CoreLink>
   *  )}
   *  ...
   * />
   * ```
   */
  linkElement?: (children: React.ReactNode) => React.ReactElement | undefined;

  /**
   * We pass in an `<ImageCarousel />` here exclusively.
   */
  thumbnailElement: React.ReactElement;

  /** The title of the listing */
  title?: string;

  /** The HTML tag of the title element. Defaults to 'div'. */
  titleHtmlTag?: keyof typeof ListingTitleHtmlTags;

  /** A visually hidden description of the listing – mostly likely the title. Must be used when not including the title prop */
  label?: string;

  /** The condition of the listing. E.g. `Condition: Brand New`. This component is not responsible for appending `Condition: ` strings */
  conditionLabel?: React.ReactElement | string;

  /** Card-level param. If true, the card will take up the full height of its parent.
   * Should be `true` when inside a listing grid where all items in a row should be the same height. */
  fullHeight?: boolean;

  /** The small bit of UI under the thumbnail. Should showcase sale info, bump info, or things like that. */
  eyebrow?: {
    text: string;
    color?: EyebrowTextProps['color'];
  }

  /** A human-readable string that shows the listing price */
  price?: RCPriceBlockProps;

  /** The watch element that gets rendered on top of the thumbnail in the upper-right corner of the card */
  favoriteButtonElement?: React.ReactElement;

  /** A `primary` action should be a `primary` RCButton variant. Actions passed in here should be RCButtons, or CoreLinks using RCButton styling. */
  primaryAction?: React.ReactElement;

  /** a `secondary` action should be a `secondary` RCButton. */
  secondaryAction?: React.ReactElement;

  /** hide action buttons on mobile screen width */
  hideActionsOnMobile?: boolean;

  /** Structured interface - See RCNudge for details */
  nudges?: RCNudgeProps[];

  /** Pass in non-UI-rendering HTML content here to help with SEO. Will be injected as the first DOM item of the card */
  metaElement?: React.ReactElement;

  /** Render a remove button to the right of the title area */
  removeButton?: {
    onClick: () => void;
    label: string;
  },

  /** When present, renders a single line of text (that truncates via ellipsis) under the listing title */
  shopLocation?: string;

  /** When present, renders information about the shop. */
  shopInfo?: RCShopInfoProps;

  /** When present, renders a violator on the lower left corner of the image.
   * Note: thumbnailElement must be present for `violator` to also appear. */
  violator?: React.ReactElement;

  /** When present, prevents whitespace from collapsing to maintain horizontal content alignment across neighboring cards */
  maintainWhitespace?: boolean;

  /** Pass admin controls here; we pass in TileCurationView. This should be an unwrapped set of buttons. */
  adminControls?: React.ReactNode;
}

export function RCListingCard({
  linkElement = undefined,
  thumbnailElement,
  eyebrow = undefined,
  title = undefined,
  titleHtmlTag = 'div',
  label = undefined,
  conditionLabel = undefined,
  price = undefined,
  favoriteButtonElement = undefined,
  primaryAction = undefined,
  secondaryAction = undefined,
  nudges = undefined,
  fullHeight = true,
  metaElement = undefined,
  removeButton = undefined,
  shopLocation = undefined,
  shopInfo = undefined,
  maintainWhitespace = false,
  hideActionsOnMobile = false,
  adminControls = undefined,
  violator = undefined,
}: RCListingCardProps) {

  const id = useId();
  const labelId = `${id}-label`;
  const TitleTag = titleHtmlTag as React.ElementType;

  return (
    <div
      aria-labelledby={labelId}
      className={classNames(
        'rc-listing-card',
        {
          'rc-listing-card--full-height': fullHeight,
          'rc-listing-card--single-action': (!!primaryAction && !secondaryAction) || (!primaryAction && !!secondaryAction),
          'rc-listing-card--whitespace': maintainWhitespace,
          'rc-listing-card--hide-actions-on-mobile': hideActionsOnMobile,
        },
      )}
    >
      {metaElement && (
        <div className="rc-listing-card__meta">
          {metaElement}
        </div>
      )}
      <div className="rc-listing-card__inner">
        <div className="rc-listing-card__thumbnail" {...preferLightTheme()}>
          {thumbnailElement}
          {violator &&
            <div className="rc-listing-card__violator">
              {violator}
            </div>
          }
        </div>
        {!title && label && (
          <LinkElement
            id={labelId}
            styleName="rc-listing-card__label"
            linkElement={linkElement}
          >
            <span className="visually-hidden">{label}</span>
          </LinkElement>
        )}
        {(title || conditionLabel || eyebrow) && (
          <div className="rc-listing-card__header">
            {(title || conditionLabel) && (
              <div className="rc-listing-card__titleblock">
                {(maintainWhitespace || eyebrow) && (
                  <div className="rc-listing-card__eyebrow">
                    {eyebrow &&
                      <RCText.Eyebrow color={eyebrow.color}>
                        {eyebrow.text}
                      </RCText.Eyebrow>
                    }
                  </div>
                )}
                {title && (
                  <LinkElement
                    id={labelId}
                    styleName="rc-listing-card__title-link"
                    linkElement={linkElement}
                  >
                    <TitleTag className="rc-listing-card__title">
                      {title}
                    </TitleTag>
                  </LinkElement>
                )}
                {conditionLabel && (
                  <div className="rc-listing-card__condition">
                    {conditionLabel}
                  </div>
                )}
              </div>
            )}
            {!!removeButton?.onClick && (
              <div className="rc-listing-card__title__remove-button">
                <RCCloseButton
                  aria-label={removeButton.label}
                  onClick={removeButton.onClick}
                  useAltIcon
                  showTooltip
                />
              </div>
            )}
          </div>
        )}
        {shopLocation && (
          <div className="rc-listing-card__shop-location">
            {shopLocation}
          </div>
        )}
        {price && (
          <div className="rc-listing-card__price">
            <RCPriceBlock
              {...price}
              fixedHeight={maintainWhitespace}
            />
          </div>
        )}
        {(primaryAction || secondaryAction) && (
          <div className="rc-listing-card__actions">
            {primaryAction && (
              <div className="rc-listing-card__action rc-listing-card__action--primary">
                {primaryAction}
              </div>
            )}
            {secondaryAction && (
              <div className="rc-listing-card__action rc-listing-card__action--secondary">
                {secondaryAction}
              </div>
            )}
          </div>
        )}
        {shopInfo && (
          <div className="rc-listing-card__shop-info">
            <RCShopInfo {...shopInfo} />
          </div>
        )}
        {!!nudges && (
          <div className="rc-listing-card__nudge-block">
            {nudges.map(nudge => (
              <div
                key={nudge.label}
                className="rc-listing-card__nudge"
              >
                <RCNudge {...nudge} />
              </div>
            ))}
          </div>
        )}
        {favoriteButtonElement && (
          <div className="rc-listing-card__favorite-button" {...preferLightTheme()}>
            {favoriteButtonElement}
          </div>
        )}
      </div>
      {!!adminControls && (
        <div className="rc-listing-card__admin-controls">
          {adminControls}
        </div>
      )}
    </div>
  );
}

function Skeleton() {
  return (
    <div className="rc-listing-card">
      <div className="rc-listing-card__inner">
        <div className="rc-listing-card__thumbnail">
          <RCSkeleton
            shape="square"
            display="block"
          />
        </div>
        <div className="rc-listing-card__eyebrow"/>
        <div className="rc-listing-card__titleblock">
          <RCSkeleton count={2} />
          <div className="rc-listing-card__condition">
            <RCSkeleton width="13rem" />
          </div>
        </div>
        <div className="rc-listing-card__price">
          <RCSkeleton width="10rem" height="3.6rem" display="block" />
        </div>
      </div>
    </div>
  );
}

RCListingCard.Skeleton = Skeleton;
