/**
 * README
 * This is an "internal" component meant to be used solely by other RC components.
 * Please don't use this directly. If it seems like you'd benefit from it please
 * reach out to #cadence for help coordinating!
 */

import React, { useState } from 'react';
import classNames from 'classnames';

import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  UseDismissProps,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStatus,
} from '@floating-ui/react';

import { RCCloseButton } from '../../';
import { MODAL_DOM_ID } from '../../../constants';

type ElementRef = React.MutableRefObject<HTMLElement | null>;

type ContextType = {
  setOpen: (state) => void;
  setFocusRef: (ref: ElementRef) => void;
} | null;

const RCModalBaseContext = React.createContext<ContextType>(null);

export const useRCModalBaseContext = () => React.useContext(RCModalBaseContext);

export interface RCModalBaseProps {
  trigger?: JSX.Element;
  isOpen?: boolean;
  onOpenChange?: (state: boolean) => void;
  'aria-labelledby': string;
  'aria-describedby'?: string;
  initialFocus?: 0 | ElementRef;
  role?: 'dialog' | 'alertdialog';
  position?: 'center' | 'bottom' | 'left';
  displayStyle?: 'panel' | 'drawer';
  useDismissProps?: UseDismissProps;
  isModal?: boolean;
  keepMounted?: boolean;
}

/** _Note: This is an **internal** component. Please reach out to #cadence before using._  */
export function RCModalBase({
  trigger = undefined,
  isOpen: openProp = undefined,
  onOpenChange: setOpenProp = undefined,
  'aria-labelledby': ariaLabelledby = undefined,
  'aria-describedby': ariaDescribedby = undefined,
  initialFocus = 0,
  role = 'dialog',
  position = 'center',
  displayStyle = 'panel',
  useDismissProps = {},
  isModal = true,
  keepMounted = false,
  children,
}: React.PropsWithChildren<RCModalBaseProps>) {
  const [openState, setOpenState] = useState<boolean>(openProp || false);
  const [focusRef, setFocusRef] = useState<ElementRef>(initialFocus === 0 ? null : initialFocus);
  const [shouldReturnFocus, setShouldReturnFocus] = useState<boolean>(true);

  const isControlled = openProp !== undefined && setOpenProp !== undefined;
  const open = isControlled ? openProp : openState;
  const setOpen = isControlled ? setOpenProp : setOpenState;

  const focus = React.useMemo(() => {
    if (focusRef !== null) return focusRef;
    if (initialFocus) return initialFocus;
    return 0;
  }, [initialFocus, focusRef]);

  // Necessary to prevent Safari from focusing the <body> element on modal close when modal is externally controlled.
  // In other browsers, the trigger element receives focus on initial click and will receive focus again on modal close.
  // In all browsers, focus should return to the passed trigger if present.
  React.useEffect(() => {
    if (open) {
      const validActiveElement = document.activeElement !== document.body && document.activeElement !== null;
      setShouldReturnFocus(!!trigger || validActiveElement);
    }
  }, [open, trigger]);

  const {
    refs,
    context,
  } = useFloating({
    open: open,
    onOpenChange: setOpen,
  });

  const {
    getReferenceProps,
    getFloatingProps,
  } = useInteractions([
    useRole(context, {
      role: role,
    }),
    useDismiss(context, useDismissProps),
    useClick(context, {
      enabled: true,
    }),
  ]);

  const { isMounted, status } = useTransitionStatus(context);
  const showOverlay = keepMounted ? isModal && status !== 'unmounted' : isModal && isMounted;
  const triggerRef = useMergeRefs([refs.setReference, (trigger as any)?.ref]);
  const child = React.Children.only(children);

  if (!React.isValidElement(child)) return null;

  return (
    <RCModalBaseContext.Provider value={{ setOpen, setFocusRef }}>
      {trigger && React.cloneElement(trigger, {
        ...getReferenceProps({ ref: triggerRef }),
      })}
      {(isMounted || keepMounted) &&
        <FloatingPortal id={MODAL_DOM_ID}>
          <div
            className="rc-modal-base"
            data-status={status}
          >
            {showOverlay &&
              <FloatingOverlay
                className="rc-modal-base__overlay"
                lockScroll
              />
            }
            <FloatingFocusManager
              context={context}
              order={['content']}
              initialFocus={focus}
              returnFocus={shouldReturnFocus}
              modal={isModal}
              closeOnFocusOut={false}
              disabled={!isMounted}
            >
              <div className="rc-modal-base__wrapper">
                <div
                  className={classNames(
                    'rc-modal-base__content',
                    { 'rc-modal-base__content--bottom': position === 'bottom' },
                    { 'rc-modal-base__content--left': position === 'left' },
                    { 'rc-modal-base__content--drawer': displayStyle === 'drawer' },
                  )}
                >
                  {React.cloneElement(child, {
                    ...getFloatingProps({
                      ref: refs.setFloating,
                      'aria-labelledby': ariaLabelledby,
                      'aria-describedby': ariaDescribedby,
                    }),
                  })}
                </div>
              </div>
            </FloatingFocusManager>
          </div>
        </FloatingPortal>
      }
    </RCModalBaseContext.Provider>
  );
}

const CloseButton = React.forwardRef<HTMLButtonElement>(
  function CloseButton(_props, ref) {
    const { setOpen } = useRCModalBaseContext();
    return (
      <div className="rc-modal-base__close-button">
        <RCCloseButton
          onClick={() => setOpen?.(false)}
          ref={ref}
        />
      </div>
    );
  },
);

RCModalBase.CloseButton = CloseButton;
