import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgWindows = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11.656 15.719V25.89L1 24.42v-8.7h10.656zm0-11.61v10.297H1V5.578L11.656 4.11zM27 15.72V28l-14.172-1.953V15.719H27zM27 2v12.406H12.828V3.953L27 2z" />
  </svg>
);
export default SvgWindows;
