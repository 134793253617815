import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgBellO = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11.77 12.11v.003c-.05.282-.173.967-1 .877-.56-.05-.82-.44-.76-1.16-.03-.9.36-1.77 1.18-2.6A3.9 3.9 0 0 1 14.05 8c.63 0 .95.3.95.88 0 .6-.32.89-.95.9-.6 0-1.1.25-1.55.72-.616.653-.683 1.219-.722 1.543l-.008.067z" />
    <path
      fillRule="evenodd"
      d="M13 2v1.05C8.505 3.498 5 6.933 5 12v7.65c0 .36-.08 2.35-.53 2.35H4c-.67 0-1 .33-1 1 0 .67.33 1 1 1h6.515c-.01.095-.015.195-.015.3 0 1.77 1.68 3.3 3.5 3.3s3.5-1.53 3.5-3.3c0-.105-.006-.205-.019-.3H24c.67 0 1-.33 1-1 0-.67-.33-1-1-1h-.45c-.45 0-.55-1.99-.55-2.35V12c0-5.074-3.497-8.512-8-8.952V2c0-.67-.33-1-1-1-.67 0-1 .33-1 1zm8.5 20c-.44 0-.5-1.54-.5-2v-8c0-4.07-3.17-7-7-7a6.9 6.9 0 0 0-7 7v8c0 .03-.06 2-.5 2h15zm-5.755 2h-3.49c.061.894.64 1.59 1.745 1.59v.01c1.104 0 1.683-.713 1.745-1.6z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBellO;
