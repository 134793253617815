import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgCarbonOffset = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={60}
    height={60}
    viewBox="0 0 17 17"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M7.97.4C3.83.4.47 3.76.47 7.9s3.36 7.5 7.5 7.5c4.14 0 7.5-3.36 7.5-7.5S12.11.4 7.97.4zm2.48 10.78c-.22.22-.58.22-.8 0L8.57 10.1v2.81H7.43v-2.88l-1.15 1.15c-.11.11-.26.17-.4.17s-.29-.06-.4-.17a.567.567 0 0 1 0-.8l1.95-1.95v-.89l-.92.92c-.22.22-.58.22-.8 0a.567.567 0 0 1 0-.8l1.72-1.72v-.93l-.46.46c-.22.22-.58.22-.8 0a.567.567 0 0 1 0-.8l1.82-1.82 1.82 1.82c.22.22.22.58 0 .8-.11.11-.26.17-.4.17s-.29-.06-.4-.17l-.44-.45V6l1.66 1.66c.22.22.22.58 0 .8-.11.11-.26.17-.4.17s-.29-.06-.4-.17l-.86-.86v.9l1.88 1.88c.22.22.22.58 0 .8z" />
  </svg>
);
export default SvgCarbonOffset;
