import Cookies from 'js-cookie';
import qs from 'qs';
import window from './window_wrapper';

const AID_LAST_COOKIE = 'affiliate_id_last_v2';
const GROWSUMO_COOKIE = 'growsumo_partner_key_v2';

const UTM_CAMPAIGN = 'UTM Campaign';
const UTM_SOURCE = 'UTM Source';
const UTM_MEDIUM = 'UTM Medium';
const UTM_TERM = 'UTM Term';
const UTM_CONTENT = 'UTM Content';
const AID = 'AID';
const GS_PARTNER = 'GrowSumo Partner';

const BLANK_VALUE = '(none)';

export interface AttributionData {
  [UTM_CAMPAIGN]?: string;
  [UTM_SOURCE]?: string;
  [UTM_MEDIUM]?: string;
  [UTM_TERM]?: string;
  [UTM_CONTENT]?: string;
  [AID]?: string;
  [GS_PARTNER]?: string;
}

function normalize(value) {
  if (Array.isArray(value)) {
    return normalize(value[0]);
  }

  return value || BLANK_VALUE;
}

export function getAttribution(): AttributionData {
  const { utm_campaign, utm_source, utm_medium, utm_term, utm_content } = getUtmQueryData();

  const data: AttributionData = {};

  if (utm_campaign || utm_source || utm_medium || utm_term || utm_content) {
    Object.assign(data, {
      [UTM_CAMPAIGN]: normalize(utm_campaign),
      [UTM_SOURCE]: normalize(utm_source),
      [UTM_MEDIUM]: normalize(utm_medium),
      [UTM_TERM]: normalize(utm_term),
      [UTM_CONTENT]: normalize(utm_content),
    });
  }

  const aid = Cookies.get(AID_LAST_COOKIE);
  if (aid) {
    Object.assign(data, {
      [AID]: aid,
      [GS_PARTNER]: Cookies.get(GROWSUMO_COOKIE) || BLANK_VALUE,
    });
  }

  return data;
}

export function getUtmQueryData() {
  const query = qs.parse(window.location.search.replace(/^\?/, ''));
  const { utm_campaign, utm_source, utm_medium, utm_term, utm_content } = query;
  return {
    utm_campaign,
    utm_source,
    utm_medium,
    utm_term,
    utm_content,
  };
}
