import {
  Commons_MParticle_ListingQuery,
  Commons_MParticle_OrderBundlesQuery,
} from '../gql/graphql';

type Commons_MParticle_ListingQuery_Listing = Commons_MParticle_ListingQuery['listings'][0];
type Commons_MParticle_OrderBundlesQuery_OrderBundle = Commons_MParticle_OrderBundlesQuery['orderBundle'];

type MPEvent<T extends MParticleEventName, K extends keyof AllEventProps> =
  {
    eventName: T;
    eventType?: EventType;
    listing?: Commons_MParticle_ListingQuery_Listing;
    experiments?: string;
    componentName?: string;
    currentView?: string;
  } &
  { [P in K]: AllEventProps[P] };

export enum MParticleEventName {
  AddToCart = 'Add to cart',
  Purchase = 'Purchase',
  ProductAction = 'Product Action',
  SiteSearch = 'Searched Site',
  ClickedOfferButton = 'Clicked Offer Button',
  ClickedUpdateOfferButton = 'Clicked Update Offer Button',
  SubmittedOffer = 'Submitted Offer',
  InteractedWithOfferFlow = 'Interacted With Offer Flow',
  ConditionalOfferAcceptanceQualifyingEvent = 'Conditional Offer Acceptance Qualifying Event',
  ReachedImmediateOfferPaymentReview = 'Reached Immediate Offer Payment Review',
  ViewedSignupSignin = 'Viewed User Sign Up/In',
  ClickedCSPCard = 'Clicked CSP Card',
  ViewedSimilarCspsRow = 'Viewed Similar Csps Row',
  ClickedListingCard = 'Clicked Listing Card',
  AddToWishlist = 'Added to Watch List',
  RemoveFromWishlist = 'Removed from Watch List',
  LoggedOutWatch = 'Logged Out Watch',
  ListingImpression = 'Listing Impression',
  ComponentView = 'Component View',
  ProductViewAction = 'Product View Action',
  CheckoutViewAction = 'Checkout View Action',
  CheckoutClickEvent = 'Checkout Click Event',
  GenericPageView = 'Generic Page View',
  ViewedBumpSlider = 'Viewed Bump Slider',
  AdjustBumpRate = 'Adjust Bump Rate',
  ClickedSearchSuggestion = 'Clicked Search Suggestion',
  ViewedBumpDisplay = 'Viewed Bump Display',
  ViewedCSPComponent = 'Viewed CSP Component',
  ViewedFeaturedListing = 'Viewed Featured Listing',
  ClickedCombineAndSaveViewAll = 'Clicked Combine and Save View All',
  CmsExperimentQualifyingEvent = 'CMS Experiment Qualifying Event',
  ClickedAccordion = 'Clicked Toggled Content',
  ClickedPagination = 'Clicked Pagination',
  ClickedCompareOtherSellersLink = 'Clicked Compare Other Sellers Link',
  OpenedPrivacySettingsModal = 'Opened Privacy Settings Modal',
  ClickedComponent = 'Clicked Component',
  ClickedSearchFilter = 'Clicked Search Filter',
  ClickedClearSiteSearch = 'Clicked Clear Site Search',
  ClickedSellYoursOnCsp = 'Clicked Sell Yours on CSP',
  ClickedSearchInput = 'Clicked Search Input',
  ClickedDealsFilterChip = 'Clicked Deals Filter Chip',
  ClickedSearchFilterChip = 'Clicked Search Filter Chip',
  ClickedRemoveFromCart = 'Clicked Remove From Cart',
  RemoveFromCart = 'Remove From Cart',
  SelectedCartItemQuantity = 'Selected Cart Item Quantity',
  UnmatchedGearCollectionItem = 'Unmatched Gear Collection Item',
  GearCollectionStartOver = 'Gear Collection Start Over',
  ViewedGearCollectionForm = 'Viewed Gear Collection Form',
  ViewedGearCollectionItemShowPage = 'View Gear Collection Item Show Page',
  ClickedGearCollectionItemListForSale = 'Clicked Gear Collection Item List For Sale',
  ClickedDeleteGearCollectionItem = 'Clicked Delete Gear Collection Item',
  ApplePayOffered = 'Apple Pay Offered',
  GooglePayOffered = 'Google Pay Offered',
  ClickedApplePayButton = 'Clicked Apple Pay Button',
  ClickedAppleSigninButton = 'Clicked Apple Signin Button',
  ViewedThirdPartyAuthButtons = 'Viewed Third Party Auth Buttons',
  ClickedGoogleSigninButton = 'Clicked Google Signin Button',
  AdImpression = 'Ad Impression',
  AdClick = 'Ad Click',
  AdObserved = 'Ad Observed',
  ClickedKeepShoppingLink = 'Clicked Keep Shopping Link',
  ViewedKeepShoppingLink = 'Viewed Keep Shopping Link',
  ClickedReverbShippingGuideInsideShippingPrompt = 'Clicked Reverb Shipping Guide Inside Shipping Prompt',
  ClickedReverbShippingBoxTitleInsideShippingPrompt = 'Clicked Reverb Shipping Box Title Inside Shipping Prompt',
  ClickedReverbShippingBoxImageInsideShippingPrompt = 'Clicked Reverb Shipping Box Image Inside Shipping Prompt',
  DismissedShippingPromptModal = 'Dismissed Shipping Prompt Modal',
  ViewedProductFamilyPage = 'Viewed Product Family Page',
  ClickedI2ShopPoliciesModal = 'Clicked Item2 Shop Policies Modal',
  ChangedGlobalSubscriptionStatus = 'Changed Global Subscription Status',
  ChangedSubscriptionGroupStatus = 'Changed Subscription Group Status',
  ConversationFlagMessageSubmit = 'Submitted Conversation Flag Message',
  TriggeredOptInEmail = 'Triggered Opt-in Email',
  DismissedSecondaryNagBanner = 'Dismissed Secondary Nag Banner',
  SiteBanner = 'Clicked Site Banner',
  ClickedMosaicTile = 'Clicked Mosaic Tile',
  PlaidLink = 'Plaid Link',
  SelectedPayoutMethod = 'Selected Payout Method',
  ClickedCancelBankAccountChangesLink = 'Clicked Cancel Bank Account Changes Link',
  ViewedPaymentSettings = 'Viewed Payment Settings',
  MarketplaceGridAdQE = 'Marketplace_grid_ad qualifying event',
  ClickedMarketingCallout = 'Clicked Marketing Callout',
  EndedListingPageAdView = 'Ended Listing Page Ad View',
  ViewedListingAcceptedPaymentMethods = 'Viewed Listing Accepted Payment Methods',
  ClickedSubqueryRowSeeMoreLink = 'Clicked Subquery Row See More Link',
  UpdatedInternationalSettings = 'Updated International Settings',
  InternationalSettingsReverted = 'International Settings Reverted',
  ViewedInternationalSettingsConfirmation = 'Viewed International Settings Confirmation',
  ClickedListViewToggle = 'Clicked List View Toggle',
  ClickedLinkList = 'Clicked Link List',
  ClickedCategory = 'Clicked Category',
  ClickedBrand = 'Clicked Brand',
  ClickedSellYoursV4 = 'Clicked Sell Yours V4',
  ClickedSellFormPricingModalLink = 'Clicked Sell Form Pricing Modal Link',
  ClickedUserNotification = 'Clicked User Notification',
  ClickedBuyItNowButton = 'Clicked Buy It Now Button',
  ClickedBuyItNowPaymentMethod = 'Clicked Buy It Now Payment Method',
  ClickedExpressPaypalButton = 'Clicked Express Paypal Button',
  ClickedPaypalPayLaterButton = 'Clicked Paypal Pay Later Button',
  ClickedTrait = 'Clicked Trait',
  EligibleForEmailPopUp = 'Eligible for Email Pop Up',
  DisplayedEmailPopUp = 'Displayed Email Pop Up',
  AdaChatButtonClicked = 'Ada Chat Button Clicked',
  AdaChatOpenSuccess = 'Ada Chat Open Success',
  AdaChatAnswerServed = 'Ada Chat Answer Served',
  AdaChatConversationManuallyEnded = 'Ada Chat Conversation Manually Ended',
  ClickedRecommendedShopCard = 'Clicked Recommended Shop Card',
  ClickedAppCalloutButton = 'Clicked App Callout Button',
  ViewedTopProductsPage = 'Viewed Top Products Page',
  ViewedRecentlyViewedCspsRow = 'Viewed Recently Viewed CSPs Row',
  RedirectedToCSPFromEndedListing = 'Redirected To CSP From Ended Listing',
  ViewedEndedListing = 'Viewed Ended Listing',
  ClickedGetPriceEstimateButtonInsidePriceGuideToolCard = 'Clicked Get Price Estimate Button Inside Price Guide Tool Card',
  ClickedBackButtonToPriceGuideToolIndex = 'Clicked Back Button To Price Guide Tool Index',
  ClickedPriceGearButtonInPriceGuideTool = 'Clicked Price Gear Button in Price Guide Tool',
  ClickedSellYoursButtonFromPriceGuideTool = 'Clicked Sell Yours Button From Price Guide Tool',
  ClickedViewLiveListingsButtonFromPriceGuideTool = 'Clicked View Live Listings Button From Price Guide Tool',
  ClickedAddToGearCollectionFromPriceGuideTool = 'Clicked Add to Gear Collection From Price Guide Tool',
  ClickedTrendingSearchesTile = 'Clicked Trending Searches Tile on Homepage',
  ClickedTrendingSearchPill = 'Clicked Trending Search Pill on Homepage',
  ClickedSiteBannerControl = 'Clicked Site Banner Control on Homepage',
  ClickedRelatedSearchSuggestion = 'Clicked Related Search Suggestion',
  ClickedCspTilesCta = 'Clicked CSP Tiles CTA',
  ClickedPriceGuideToolTransactionGraphDateRangeButton = 'Clicked Price Guide Tool Transaction Graph Date Range Button',
  ClickedHowToDateYourGearFromPriceGuideTool = 'Clicked How To Date Your Gear From Price Guide Tool',
  ClickedHowGearChangesOverTimeFromPriceGuideTool = 'Clicked How Gear Changes Over Time From Price Guide Tool',
  ClickedVintageGuitarFAQFromPriceGuideTool = 'Clicked Vintage Guitar FAQ From Price Guide Tool',
  ClickedBackButtonToPriceGuideToolIndexWhenNoEstimate = 'Clicked Back Button to Price Guide Tool Index when No Estimate',
  ClickedViewLiveListingsFromPriceGuideToolShowWhenNoEstimate = 'Clicked View Live Listings from Price Guide Tool Show when NoEstimate',
  SellFormInteraction = 'Sell Form Interaction',
  ClickedPriceRecommendationFilter = 'Clicked Price Recommendation Filter',
  ViewedWatchCallout = 'Viewed Watch Callout',
  ClickedJumplink = 'Clicked Jumplink on CSP Page',
  ClickedLinkToPriceGuideToolFromCSPPriceGuideModule = 'Clicked Link To Price Guide Tool from CSP Price Guide Module',
  CSPPriceGuideModuleRendered = 'CSP Price Guide Module Rendered',
  ClickedDateRangeButtonOnCSPPriceGuideGraph = 'Clicked Date Range Button on CSP Price Guide Graph',
  ClickedPreviousOnCSPPriceGuideTransactionTable = 'Clicked Previous on CSP Price Guide Transaction Table',
  ClickedNextOnCSPPriceGuideTransactionTable = 'Clicked Next on CSP Price Guide Transaction Table',
  CSPPriceGuideModuleObserved = 'CSP Price Guide Module Observed',
  BrowserAutofill = 'Browser Autofilled Input',
  ClickedManualAddressEntry = 'Clicked Manual Address Entry',
  SelectedAutocompleteAddress = 'Selected Autocomplete Address',
  FilteringOnCSPPriceGuideModule = 'Filtering on CSP Price Guide Module',
  ViewedFilteredCSPPriceGuideModule = 'Viewed Filtered CSP Price Guide Module',
  ClickedFavoritesHubShare = 'Clicked Favorites Hub Share',
  ClickedAddFavoriteShop = 'Clicked Add Favorite - Shop',
  ClickedRemoveFavoriteShop = 'Clicked Remove Favorite - Shop',
  ClickedAddFavoriteSearch = 'Clicked Add Favorite - Search',
  ClickedRemoveFavoriteSearch = 'Clicked Remove Favorite - Search',
  EditedFavoriteGearPopup = 'Edited Favorites Gear Popup',
  ClickedToastMessage = 'Clicked Toast Message',
  ProductReviewAction = 'Product Review Action',
  ViewedOfferCallout = 'Viewed Offer Callout',
  ClickedItemImageThumbnail = 'Clicked Item Image Thumbnail',
  ClickedListingsCollectionThumbnail = 'Clicked Listings Collection Thumbnail',
  SelectedSellFormLayout = 'Selected Sell Form Layout',
  ViewedSellerVerificationLandingPage = 'Viewed Seller Verification Landing Page',
  ViewedSellerVerificationAcceptedPage = 'Viewed Seller Verification Accepted Page',
  ViewedSellerVerificationPendingReviewScreen = 'Viewed Seller Verification Pending Review Screen',
  ClickedSellerVerificationContinueButton = 'Clicked Seller Verification Continue Button',
  ClickedSellerVerificationBackButton = 'Clicked Seller Verification Back Button',
  ClickedSellerVerificationShoptypePageContinueButton = 'Clicked Seller Verification Shoptype Page Continue Button',
  ClickedSellerVerificationShoptypePageBackButton = 'Clicked Seller Verification Shoptype Page Back Button',
  ClickedSellerVerificationVerifiedPageCTAButton = 'Clicked Seller Verification Verified Page CTA Button',
  ClickedSellerVerificationVerifiedPagePublishListingButton = 'Clicked Seller Verification Verified Page Publish Listing Button',
  ViewedSellerVerificationShopTypeSelector = 'Viewed Seller Verification Shop Type Selector',
  ClickedSellerVerificationPendingPageBackButton = 'Clicked Seller Verification Pending Page Back Button',
  ViewedSellerVerificationPendingReviewLandingPage = 'Viewed Seller Verification Pending Review Landing Page',
  SubmittedSellerVerificationShopType = 'Submitted Seller Verification Shop Type',
  ViewedSellerVerificationTruliooEmbed = 'Viewed Seller Verification Trulioo Embed',
  ClosedSellerVerificationWhyModal = 'Closed Seller Verification Why Modal',
  ClickedSellerVerificationWhyModal = 'Clicked Seller Verification Why Modal',
  ClickedPrivacyPolicyLinkSellerVerificationModal = 'Clicked Privacy Policy Link Seller Verification Modal',
  ClickedHelpCenterLinkSellerVerificationModal = 'Clicked Help Center Link Seller Verification Modal',
  ClickedShopWebsiteLink = 'Clicked Shop Website Link',
  ClickedShopListingsTab = 'Clicked Shop Listings Tab',
  ClickedShopFeedbackTab = 'Clicked Shop Feedback Tab',
  ClickedShopInfoTab = 'Clicked Shop Info Tab',
  ApprovedExpressPaypalPayment = 'Approved Express Paypal Payment',
  PercentOffFilterChipClicked = 'Percent Off Filter Chip Clicked',
  UpdatedListingPrice = 'Updated Listing Price',
  ClickedAffirmZeroPercentFinancingButton = 'Clicked Affirm Zero Percent Financing Button',
  EnabledZeroPercentFinancing = 'Enabled Zero Percent Financing',
  DisabledZeroPercentFinancing = 'Disabled Zero Percent Financing',
  SellFormSelectedRootCategory = 'Sell Form Selected Root Category',
  ClickedHomepageMarketingCallout = 'Clicked Homepage Marketing Callout',
  ClickedSearchRecommendationTile = 'Clicked Search Recommendation on Homepage',
  RemovedInputChipFromExposedSidebarGridView = 'Removed Input Chip From Exposed Sidebar Grid View',
  OpenCollapsedFilterSection = 'Opened Collapsed Filter Section',
  ClosedCollapsedFilterSection = 'Closed Collapsed Filter Section',
  PredictedTimeToSellQualifyingEvent = 'Predicted Time to Sell Qualifying Event',
  PublishingListings = 'PublishingListings',
  DeletingListings = 'DeletingListings',
  ExportingListings = 'ExportingListings',
  ClickedLinkToSellFormSearch = 'Clicked Link to Sell Form Search',
  ClickedOpenSidebarMwebButton = 'Clicked Open Sidebar Mweb Button',
  ClickedClearFiltersButton = 'Clicked Clear Filters Button',
  ClickedShowResultsButton = 'Clicked Show Results Button',
  ClickedCloseSidebarButton = 'Clicked Close Sidebar Button',
  ClickedBreadcrumb = 'ClickedBreadcrumb',
  ClickedSellerListingsFilter = 'Clicked Seller Listings Filter',
  TooltipOpened = 'TooltipOpened',
  SubmittedSellerListingsSearch = 'Submitted Seller Listings Search',
  SubmittedSellerPriceRangeFilter = 'Submitted Seller Price Range Filter',
  SelectedSellerListingsSort = 'Selected Seller Listings Sort',
  ClickedValueProp = 'Clicked Logged Out Homepage Value Prop',
  ClickedPrivacySettingConsentButton = 'Clicked Privacy Setting Consent Button',
  ClickedSellerFilterChip = 'Clicked Seller Filter Chip',
  SellSearchRowsQualifyingEvent = 'Sell Search Rows Qualifying Event',
  ClickedSellerListingsCollapseAll = 'Clicked Seller Listings Collapse All',
  ClickedStandalonePayPalButton = 'ClickedStandalonePayPalButton',
  ApprovedStandalonePaypalPayment = 'ApprovedStandalonePaypalPayment',
  ClickedStandalonePayPalHostedFieldsSubmit = 'ClickedStandalonePayPalHostedFieldsSubmit',
  ClickedStandalonePayPalCardFieldsSubmit = 'ClickedStandalonePayPalCardFieldsSubmit',
  ApprovedPayPalHostedFieldsPayment = 'ApprovedPayPalHostedFieldsPayment',
  SellSearchAutocompleteFreeSearch = 'Sell Search Free Text',
  SellSearchAutocompleteBrandSearch = 'Sell Search Brand Selected',
  SellSearchAutocompleteCSPMatch = 'Sell Search CSP Match',
  SellerBulkCreatingDirectOffers = 'Seller Bulk Creating Direct Offers',
  SellerBulkDeletingDirectOffers = 'Seller Bulk Deleting Direct Offers',
  SellerBulkCreatingBump = 'Seller Bulk Creating Bump',
  SellerBulkDeletingBump = 'Seller Bulk Deleting Bump',
  SellerBulkCreatingZeroPercentAffirm = 'Seller Bulk Creating Zero Percent Affirm',
  SellerBulkDeletingZeroPercentAffirm = 'Seller Bulk Deleting Zero Percent Affirm',
  SellerBulkCreatingSaleMemberships = 'Seller Bulk Creating Sale Memberships',
  SellerBulkDeletingSaleMemberships = 'Seller Bulk Deleting Sale Memberships',
  ProtectionPlanQualifyingEvent = 'Protection Plan Qualifying Event',
  ClickedAddedProtectionPlan = 'Clicked Added Protection Plan',
  ClickedRemovedProtectionPlan = 'Clicked Removed Protection Plan',
  OpenedProtectionPlanModal = 'Opened Protection Plan Modal',
  QualifyingEventForSellerRecommendations = 'Qualifying Event for Seller Recommendations',
  SearchedOutletHub = 'Searched Outlet Hub',
  ToggleSellerRecommendations = 'Toggle Seller Recommendations',
  AboveAvgPricedListingsNagQualifyingEvent = 'Above Avg Priced Listings Nag Qualifying Event',
  ClickedSellerHub = 'Clicked Seller Hub',
  ThirdPartyAdsPaymentPageQualifyingEvent = 'Third Party Ads Payment Page Qualifying Event',
  ViewedGearCollectionHomepage = 'Viewed Gear Collection Homepage',
}

export enum MParticlePageName {
  BrowsePage = 'Browse Page',
  Cart = 'Checkout - Cart',
  Shipping = 'Checkout - Shipping',
  Payment = 'Checkout - Payment',
  ReviewOrder = 'Checkout - Review Order',
  ChangeShipping = 'Checkout - Change Shipping',
  ChangePayment = 'Checkout - Change Payment',
  Authorization = 'Checkout - Authorization',
  PaymentInProgress = 'Checkout - Payment in Progress',
  RedirectReturn = 'Checkout - Redirect Return',
  SellSearch = 'Sell Flow - Search',
  SellForm = 'Sell Flow - Form',
  Homepage = 'Homepage',
  Marketplace = 'Marketplace',
  Brand = 'Marketplace - Brand',
  Shop = 'Marketplace - Shop',
  Handpicked = 'Marketplace - Handpicked',
  Sale = 'Marketplace - Sale',
  CSP = 'CSP',
  WatchList = 'My - Watch List',
  EndedWatchList = 'My - Watch List - Ended',
  PublicWatchList = 'Public - Watch List',
  PublicEndedWatchList = 'Public - Watch List - Ended',
  Feed = 'My - Feed',
  ViewedFavoritesHubPage = 'Favorites Hub Page',
  RecentlyViewed = 'My - Recently Viewed',
  CMS = 'CMS',
  Page = 'Static Page',
  NewsHomepage = 'News - Homepage',
  NewsArticle = 'News - Article',
  Holiday = 'Holiday Sale',
  MobileBump = 'Mobile Bump',
  CreateShippingProfileForm = 'New Shipping Profile Form',
  SellerDashboard = 'Seller Dashboard',
  RedeemPromoCode = 'Redeem Promotional Code',
  RedeemGiftCard = 'Redeem Gift Card',
  RecommendedListings = 'Recommended Listings',
  EmailPreferences = 'Email Preferences',
  GetPaid = 'Get Paid',
  ShopPolicies = 'Shop Policies',
  ShopOnboarding = 'Shop Onboarding',
  ShopTaxId = 'Shop Tax ID',
  SimilarListings = 'Similar Listings',
  ClickedLinkToSellFormSearch = 'Clicked Link to Sell Form Search',
  ItemPage = 'Item Page',
  OutletPage = 'Outlet Page',
}

// Custom attributes on an mparticle product
export interface ListingProps {
  certified: boolean;
  condition: string;
  conditionName: string;
  subcategories: string;
  listingId: string;
  listingType: string;
  state: string;
  cspSlug: string;
  cspId: string;
  bumpEligible: boolean;
  freeExpeditedShipping?: boolean;
  freeShipping?: boolean;
  localPickupOnly?: boolean;
  shopId: string;
  inventory: number;
  shownAsBumped?: boolean;
  localPickup?: boolean;
  returnWindowDays: number;
  itemLocation?: string;
  daysSincePublished?: number;
  paymentMethodsAvailable?: string;
  soldAsIs?: boolean;
  isOutlet?: boolean;
  protectionPlanEligible?: boolean;
}

interface AllEventProps {
  // MParticle enum for custom events https://docs.mparticle.com/developers/sdk/web/event-tracking/#custom-event-type
  eventType: EventType;
  pageName: MParticlePageName;
  experiments: string;
  signals: string;

  // General URL props
  referringUrl: string;
  currentUrl: string;
  requestId: string;
  cookieId: string;
  reverbSessionId: string;
  targetUrl: string;

  // PageView/ecomm event properties
  quantity: number;
  itemCount: number; // Number of unique items in cart/checkout. Also used for ComponentView events with Favorites (count of listings, shops, or searches depending on the current view).
  sellerCount: number; // Number of unique sellers in cart/checkout
  currencyCount: number; // Number of unique currencies in cart
  totalPrice: string;
  totalShippingRate: string;
  paymentMethod: string;
  paymentMethodsAvailable: string;
  'negotiatedOrder?': boolean;
  prefilled: boolean;

  // entity IDs
  listingId: string | number;
  listingIds: (string | number)[];
  esScores: (string | number)[];
  cpId: string | number;
  cspId: string | number;
  cspIds: string; // pipe-delimited
  gciId: string | number;
  brand: string; // brand slug, named 'brand' for consistency with ecommerce events
  category: string; // category slug, named 'category' for consistency with ecommerce events
  categories: string; // category slugs, pipe-delimited
  cspSlug: string;
  pageSlug: string;
  curatedSetSlug: string;
  shopSlug: string;
  followId: string;
  cmsPageSlug: string;
  articleSlug: string;
  productFamilySlug: string;
  bumpedListingIds: (string | number)[];
  sponsoredListingIds: (string | number)[];
  freeShippingListingIds: (string | number)[];
  returnPolicyListingIds: (string | number)[];
  conditionSlug: string;
  mosaicIds: (string | number)[];
  browsePageId: string | number;

  // Component props
  componentName: string;
  displayStyle: string;
  entityId: string;
  entityType: string;
  shownAsBumped: boolean;
  cmsComponentId: string;

  // Adhoc props tacked onto ComponentView events for specific contexts
  shopCreatedYear: string;
  shopOrdersCount: number;
  freeShippingThresholdMet: boolean;
  collectionName: string;
  hasCategoryAffinities: boolean;
  recommendedShippingRateUsdCents: number;
  viewContext: string;
  viewedEstimatedValue: boolean;
  estimatedValueAvailable: boolean;
  dateRange: string;
  totalOrders: number;
  forNewListing: boolean;
  autodirected: boolean;
  hasCanonicalProductInGearCollection: boolean;
  singlePageActive: boolean;
  freeCombinedShippingParent: boolean;
  currency: string;
  affinityProductTypeSlug: string;
  shownProductTypeSlug: string;
  shownDefaultProductType: boolean;
  financingProgramSlug: string;
  cspHasOverview: boolean;
  secondaryUserModeIsActive: boolean;
  sellerId: string;
  secondaryUserModeIsAvailable: boolean;
  hasPriceRecommendation: boolean;
  hasDirectOffersRecommendation: boolean;

  // 'virtual' props that we need to transform client-side before sending to mparticle
  orderBundle: Commons_MParticle_OrderBundlesQuery_OrderBundle;
  listing: Commons_MParticle_ListingQuery_Listing;
  listings: Commons_MParticle_ListingQuery_Listing[];

  offerAction: 'view' | 'submit';
  offerStatus: string;
  negotiationAction: string;
  role: string;
  negotiationGuidance: string;
  discountPercent: number; // whole number discount percentage (ex: 20)
  eligibleForPriceRecommendation: boolean;
  inRecommendedPriceRange: boolean;
  comparedToRecommendedMiddlePrice: string;
  recommendedPriceDropPercent: number;
  quickInput: string;
  isCounterOffer: boolean;
  isConditionalAcceptance: boolean;
  optedIntoImmediatePayment: boolean;
  firstTimeOfferer: boolean;
  combinedRateFree: boolean;
  suggestionCount: number;
  suggestionQuery: string;
  suggestionType: string;
  suggestionId: string;
  position: number;
  interactionType: string;
  checkoutItemCount: number;
  productActionType: ProductActionType;
  cmsPageType: string;
  articleCategories: string;
  subcategories: string;
  followType: string;
  listingState: string;
  listingsCount: number;
  fallbackListingsCount: number;
  cspsCount: number;
  usedInventory: number;
  newInventory: number;
  totalInventory: number;
  query: string;
  querySuggestion: string;
  page: number | string;
  totalPages: number;
  productType: string;
  make: string;
  city: string;
  region: string;
  countryCode: string;
  matched: boolean;
  editingPersisted: boolean;
  listingSeedId: string;
  orderSeedId: string;
  hasOriginalListing: boolean;
  hideListingPhotos: boolean;
  hideListingDescription: boolean;
  hasDataServicesPriceEstimate: boolean;
  hasUserEstimate: boolean;
  gearCollectionItemId: string;
  tagsCount: number;
  promotionalToken: string;
  finishCount: number;
  sort: string;
  filter: string;
  filterOption: string;
  refererSlug: string;
  conditionSlugs: string[];
  brands: string[];
  priceMin: string;
  priceMax: string;
  orderBundleId: string;
  priceDropped: boolean;
  wellPriced: boolean;
  hasAllMatchesSelected: boolean;
  exportingAllMatchesSelected: boolean;
  isOpen: boolean;

  // bump props
  bumped: boolean;
  bumpEligible: boolean;
  newBumpRatePercent: number;
  startingBumpRatePercent: number;
  bumpRecommendationPercent: number;

  // Questionable events
  source: string;
  view: string;

  // click event generics
  clickableName: string;
  clickableType: 'link' | 'button' | 'input' | 'select';
  clickableValue: string;

  // facet props
  selected: boolean;
  parent: string;
  min: string;
  max: string;

  // proximity props
  distance: string;
  postalCode: string;

  applePayAvailableForDevice: boolean;
  googlePayAvailableForDevice: boolean;

  // ads props
  adId: string;
  adUuid: string;
  adGroupUuid: string;
  adGroupName: string;
  adCampaignUuid: string;
  advertiserUuid: string;
  title: string;
  format: string;
  adZoneSlugs: string; // pipe-delimited

  // email preferences props
  globalSubscriptionState: string;
  subscriptionGroup: string;
  subscriptionGroupState: boolean;

  // site banner and homepage mosaic tile props
  isAd: boolean;
  isDefault: boolean;
  isListingDomesticToBuyer: boolean;
  isPlaid: boolean; // bank account settings props

  // homepage mosaic tile prop
  mosaicPosition: string;

  // plaid link event props
  plaidExitStatus: string;
  plaidErrorMessage: string;
  plaidLinkSessionId: string;
  plaidEventName: string;

  // payout method change event props
  payoutMethod: string;

  // international settings props
  newShippingRegionCode: string;
  newLocaleCode: string;
  newCurrency: string;
  oldShippingRegionCode: string;
  oldLocaleCode: string;
  oldCurrency: string;
  shippingRegionCode: string;
  trait: string;

  // seller location listings rows props
  similarListingsCount: number;
  cmsListingsRowsCount: number;
  isFeaturedInCSPCollection: boolean;
  featuredListingCondition: 'new' | 'used';
  featuredListingConditionSlug: string;

  // ada chat props
  initialUrl: string;
  language: string;
  lastAnswerId: string;
  lastQuestionAsked: string;

  // Recommended Shop Cards
  shopId: string | number;
  shopCount: number;

  // Custom sell form interaction events
  interactionName: 'clickedSubwayTo' | 'updatedPhotos' | 'addedShippingRegion' | 'optedOut' | 'optedIn';
  sectionName: string;
  listingCreateFlow: boolean;

  // Checkout Props
  checkoutAction: string;
  checkoutId: string;
  checkoutClickInteraction: CheckoutClickInteraction | CartClickInteraction | CreditCardWalletClickInteraction | AddressBookClickInteraction;

  // Conversations
  messageId: string;
  flagReasonType: string;
  conversationId: string;

  // Favorites
  currentPane: string; // also used on ComponentView events for Favorites components
  currentView: string; // also used on ComponentView events for Favorites components
  shareUrl: string;
  shopTitle: string;
  shopCountry: string;
  shopRating: number;
  dailyFeedEmailStatus: boolean;
  addToFeedStatus: boolean;
  favoriteType: string;
  searchQuery: string; // the `?query=<text>` value, redundant with the 'query' prop, but requested by analytics for cross-platform consistency
  searchUrl: string; // the canonical marketplace URL for a saved search

  // Reviews
  productReviewInteraction: ProductReviewInteraction;
  reviewCount: number;
  productReviewId: string;
  productReviewFlagReason: string;
  rating: string;

  // Item page image tracking
  imageCount: number;
  inModal: boolean;
  thumbnailType: ThumbnailType

  // SelectedSellFormLayout
  selectedLayout: string;

  // Seller Verification
  shopType: string;
  sessionId: string;
  flowId: string;

  // SellFormSelectedRootCategory
  rootCategoryUuid: string;
  seedId: string;
  seedType: string;

  // Browse Pages
  browsePageLevel: number;
  browsePageTitle: string;

  // RecommendationEngine
  recommendationEngineInputIds: string;
  recommendationEngineOutputIds: string;

  // Listings Management Filters
  filterName: string;
  saleId: string;
  value: string;

  // Privacy Consent Banner
  adDataConsent: boolean;
  adStorageConsent: boolean;
  adPersonalizationConsent: boolean;
  analyticsStorageConsent: boolean;

  // Seller Listing Rows
  draftsCount: number
  collectionCount: number
  recentPurchasesCount: number

  // Related Searches
  relatedSearchCount: number;
  relatedSearchLocation: 'top' | 'bottom';

  // Outlet
  isOutlet: boolean;

  // Protection Plan
  protectionPlans: string;
  protectionPlanDurationMonths: string;
  protectionPlanPrice: string;
  protectionPlanEligible: boolean;
}

// Subset of properties we add to all events
export type CommonProperties = Pick<AllEventProps, 'referringUrl' | 'currentUrl' | 'requestId' | 'cookieId' | 'reverbSessionId'>;

/* ecommerce/builtin events */
export type PageViewEvent = { pageName: MParticlePageName } & Partial<AllEventProps>;

export type ProductActionEvent =
  MPEvent<MParticleEventName.ProductAction, 'listing' | 'productActionType'>;

export type ProductViewActionEvent =
  MPEvent<MParticleEventName.ProductViewAction, 'listing' | 'displayStyle' | 'experiments'>;

export type GenericPageViewEvent = { eventName: string } & Partial<AllEventProps>;

export type MparticleListingImpressionEvent =
  MPEvent<MParticleEventName.ListingImpression, 'listingIds' | 'listings' | 'shownAsBumped' | 'componentName'>;

export type MparticleClickedAddToCartEvent =
  MPEvent<MParticleEventName.AddToCart, 'listing' | 'productActionType' | 'position' | 'componentName' | 'source' | 'currentView'>;
export type MparticleAddToWishlistEvent =
  MPEvent<MParticleEventName.AddToWishlist, 'listing' | 'productActionType' | 'position' | 'componentName' | 'source' | 'experiments'>;
export type MparticleRemoveFromWishlistEvent =
  MPEvent<MParticleEventName.RemoveFromWishlist, 'listing' | 'productActionType' | 'position' | 'componentName' | 'source' | 'experiments'>;
export type MparticleRemoveFromCartEvent =
  MPEvent<MParticleEventName.RemoveFromCart, 'listing' | 'productActionType' | 'position' | 'componentName' | 'source'>;

export type PurchaseEvent = MPEvent<MParticleEventName.Purchase, 'orderBundle'>;

export type SellFormInteraction = MPEvent<MParticleEventName.SellFormInteraction, 'interactionName' | 'sectionName' | 'listingCreateFlow'>;

export type ViewedCSPComponentEvent = MPEvent<
  MParticleEventName.ViewedCSPComponent,
  'cspIds'
  | 'componentName'
  | 'displayStyle'
  | 'cspsCount'
>;

export type ClickedComponentEvent = MPEvent<
  MParticleEventName.ClickedComponent,
  'componentName'
  | 'entityId'
  | 'entityType'
  | 'position'
  | 'displayStyle'
  | 'interactionType'
>;

export type CheckoutClickEvent = MPEvent<
  MParticleEventName.CheckoutClickEvent,
  'checkoutClickInteraction'
  | 'paymentMethod'
  | 'checkoutId'
>;

export type ProductReviewAction = MPEvent<
  MParticleEventName.ProductReviewAction,
  'productReviewInteraction'
  | 'displayStyle'
  | 'cspId'
  | 'productReviewId'
  | 'productReviewFlagReason'
  | 'query'
  | 'rating'
>;

export type ClickedShopPoliciesModalEvent = MPEvent<
  MParticleEventName.ClickedI2ShopPoliciesModal,
  'componentName'
  | 'listingId'
>;

export type CmsExperimentQualifyingEvent = MPEvent<
  MParticleEventName.CmsExperimentQualifyingEvent,
  null
>;

export type MparticleClickedCSPCardEvent = MPEvent<
  MParticleEventName.ClickedCSPCard,
  'cspId'
  | 'cspSlug'
  | 'displayStyle'
  | 'brand'
  | 'category'
  | 'position'
  | 'subcategories'
  | 'usedInventory'
  | 'newInventory'
  | 'totalInventory'
>;

export type ClickedListingCardEvent = MPEvent<
  MParticleEventName.ClickedListingCard,
  'listing'
  | 'componentName'
  | 'displayStyle'
  | 'position'
  | 'listingsCount'
  | 'city'
  | 'region'
  | 'countryCode'
  | 'isListingDomesticToBuyer'
  | 'isFeaturedInCSPCollection'
  | 'cmsComponentId'
>;

export type ClickedRecommendedShopCardEvent = MPEvent<
  MParticleEventName.ClickedRecommendedShopCard,
  'shopId'
  | 'listingId'
  | 'browsePageId'
  | 'position'
  | 'shopCount'
>;

export type ClickedAccordion = MPEvent<
  MParticleEventName.ClickedAccordion,
  'componentName'
  | 'listing'
  | 'experiments'
  | 'interactionType'
>;

export type ClickedSearchFilter = MPEvent<
  MParticleEventName.ClickedSearchFilter,
  'componentName'
  | 'displayStyle'
  | 'selected'
  | 'query'
  | 'postalCode'
  | 'distance'
>;

export type ClickedSearchFilterChip = MPEvent<
  MParticleEventName.ClickedSearchFilterChip,
  'displayStyle'
  | 'parent'
  | 'query'
>;

export type ClickedDealsFilterChip = MPEvent<
  MParticleEventName.ClickedDealsFilterChip,
  'category'
  | 'experiments'
>;

export type AdImpression = MPEvent<
  MParticleEventName.AdImpression,
  'componentName'
  | 'adId'
  | 'adUuid'
  | 'adGroupUuid'
  | 'adGroupName'
  | 'adCampaignUuid'
  | 'advertiserUuid'
  | 'format'
  | 'title'
  | 'adZoneSlugs'
>;

export type AdClick = MPEvent<
  MParticleEventName.AdClick,
  'componentName'
  | 'adId'
  | 'adUuid'
  | 'adGroupUuid'
  | 'adGroupName'
  | 'adCampaignUuid'
  | 'advertiserUuid'
  | 'format'
  | 'title'
  | 'adZoneSlugs'
>;

export type AdObserved = MPEvent<
  MParticleEventName.AdObserved,
  'componentName'
  | 'adId'
  | 'adUuid'
  | 'adGroupUuid'
  | 'adCampaignUuid'
  | 'advertiserUuid'
  | 'format'
  | 'title'
  | 'adZoneSlugs'
>;

export type ComponentView = MPEvent<
  MParticleEventName.ComponentView,
  'componentName'
  | 'entityId'
  | 'entityType'
  | 'displayStyle'
  | 'listingId'
  | 'shopCreatedYear'
  | 'shopOrdersCount'
  | 'listingsCount'
  | 'freeShippingThresholdMet'
  | 'shopSlug'
  | 'collectionName'
  | 'hasCategoryAffinities'
  | 'recommendedShippingRateUsdCents'
  | 'viewContext'
  | 'itemCount'
  | 'currentView'
  | 'currentPane'
  | 'browsePageId'
>;

export type ViewedFeaturedListing = MPEvent<
  MParticleEventName.ViewedFeaturedListing,
  'componentName'
  | 'cspId'
  | 'category'
  | 'subcategories'
  | 'newInventory'
  | 'usedInventory'
  | 'listing'
  | 'bumped'
>;

export type ViewedGearCollectionForm = MPEvent<
  MParticleEventName.ViewedGearCollectionForm,
  'matched'
  | 'editingPersisted'
  | 'listingSeedId'
  | 'orderSeedId'
  | 'gearCollectionItemId'
>;

export type ViewedGearCollectionItemShowPage = MPEvent<
  MParticleEventName.ViewedGearCollectionItemShowPage,
  'hasOriginalListing'
  | 'hideListingPhotos'
  | 'hideListingDescription'
  | 'hasDataServicesPriceEstimate'
  | 'hasUserEstimate'
  | 'gearCollectionItemId'
  | 'tagsCount'
>;

export type AdjustBumpRateInteraction = MPEvent<
  MParticleEventName.AdjustBumpRate,
  'componentName'
  | 'listing'
  | 'newBumpRatePercent'
  | 'startingBumpRatePercent'
  | 'bumpRecommendationPercent'
>;

export type ViewedBumpSliderInteraction = MPEvent<
  MParticleEventName.ViewedBumpSlider,
  'componentName'
  | 'listing'
  | 'startingBumpRatePercent'
>;

export type ViewedBumpDisplayInteraction = MPEvent<
  MParticleEventName.ViewedBumpSlider,
  'componentName'
  | 'listing'
  | 'startingBumpRatePercent'
>;

export type ClickedCombineAndSaveViewAll = MPEvent<
  MParticleEventName.ClickedCombineAndSaveViewAll,
  'shopSlug' | 'listingId'
>;

export type ClickedPagination = MPEvent<
  MParticleEventName.ClickedPagination,
  'componentName'
  | 'position'
  | 'displayStyle'
>;

export type ClickedCompareOtherSellersLink = MPEvent<
  MParticleEventName.ClickedCompareOtherSellersLink,
  'componentName'
>;

export type ClickedClearSiteSearch = MPEvent<
  MParticleEventName.ClickedClearSiteSearch,
  'query'
>;

export type ClickedSellYoursOnCsp = MPEvent<
  MParticleEventName.ClickedSellYoursOnCsp,
  'cspId'
>;

export type ClickedFavoritesHubShare = MPEvent<
  MParticleEventName.ClickedFavoritesHubShare,
  'shareUrl'
>;

export type ClickedAddFavoriteSearch = MPEvent<
  MParticleEventName.ClickedAddFavoriteSearch,
  'currentView'
  | 'searchQuery'
  | 'searchUrl'
  | 'addToFeedStatus'
  | 'dailyFeedEmailStatus'
>;

export type ClickedRemoveFavoriteSearch = MPEvent<
  MParticleEventName.ClickedRemoveFavoriteSearch,
  'currentView'
  | 'searchQuery'
  | 'searchUrl'
>;

export type ClickedRemoveFavoriteShop = MPEvent<
  MParticleEventName.ClickedRemoveFavoriteShop,
  'currentView'
  | 'shopId'
>;

export type ClickedGearCollectionItemListForSale = MPEvent<
  MParticleEventName.ClickedGearCollectionItemListForSale,
  'gciId'
>;

export type ClickedDeleteGearCollectionItem = MPEvent<
  MParticleEventName.ClickedDeleteGearCollectionItem,
  'gciId'
>;

export type ChangedSubscriptionGroupStatus = MPEvent<
  MParticleEventName.ChangedSubscriptionGroupStatus,
  'subscriptionGroup'
  | 'subscriptionGroupState'
>;

export type ConversationFlagMessageSubmit = MPEvent<
  MParticleEventName.ConversationFlagMessageSubmit,
  'messageId'
  | 'flagReasonType'
  | 'conversationId'
>;

export type ChangedGlobalSubscriptionStatus = MPEvent<
  MParticleEventName.ChangedGlobalSubscriptionStatus,
  'globalSubscriptionState'
>;

export type TriggeredOptInEmail = MPEvent<
  MParticleEventName.TriggeredOptInEmail,
  null
>;

export type PlaidLinkEvent = MPEvent<
  MParticleEventName.PlaidLink,
  'plaidExitStatus'
  | 'plaidErrorMessage'
  | 'plaidLinkSessionId'
  | 'plaidEventName'
>;

export type SelectedPayoutMethod = MPEvent<
  MParticleEventName.SelectedPayoutMethod,
  'payoutMethod'
>;

export type ViewedPaymentSettings = MPEvent<
  MParticleEventName.ViewedPaymentSettings,
  'isPlaid'
>;

export type ViewedListingAcceptedPaymentMethods = MPEvent<
  MParticleEventName.ViewedListingAcceptedPaymentMethods,
  'paymentMethodsAvailable'
>;

export type UpdatedInternationalSettings = MPEvent<
  MParticleEventName.UpdatedInternationalSettings,
  'newShippingRegionCode'
  | 'newLocaleCode'
  | 'newCurrency'
  | 'oldShippingRegionCode'
  | 'oldLocaleCode'
  | 'oldCurrency'
>;

export type ClickedLinkList = MPEvent<
  MParticleEventName.ClickedLinkList,
  'displayStyle'
  | 'targetUrl'
>;

export type ClickedCategory = MPEvent<
  MParticleEventName.ClickedCategory,
  'displayStyle'
  | 'category'
  | 'subcategories'
  | 'targetUrl'
>;

export type ClickedTrait = MPEvent<
  MParticleEventName.ClickedCategory,
  'trait'
  | 'targetUrl'
>;

export type MParticleAdaChatEnded = MPEvent<
  MParticleEventName.AdaChatConversationManuallyEnded,
  'initialUrl'
  | 'language'
  | 'lastAnswerId'
  | 'lastQuestionAsked'
>;

export type ClickedItemImageThumbnail = MPEvent<
  MParticleEventName.ClickedItemImageThumbnail,
  'position'
  | 'imageCount'
  | 'inModal'
  | 'thumbnailType'
>;

export type ClickedPrivacySettingButton = MPEvent<
  MParticleEventName.ClickedPrivacySettingConsentButton,
  'adDataConsent'
  | 'adStorageConsent'
  | 'adPersonalizationConsent'
  | 'analyticsStorageConsent'
  | 'source'
>;

export type SelectedSellFormLayout = MPEvent<MParticleEventName.SelectedSellFormLayout, 'selectedLayout'>;

export type MParticleEvent =
  ProductActionEvent
  | PurchaseEvent
  | MPEvent<
    MParticleEventName.SiteSearch,
    'position'
    | 'suggestionType'
    | 'suggestionId'
    | 'suggestionQuery'
    | 'suggestionCount'
    | 'interactionType'
    | 'targetUrl'
  >
  | MPEvent<MParticleEventName.ClickedOfferButton, 'listingId' | 'position' | 'componentName'>
  | MPEvent<MParticleEventName.SubmittedOffer, 'listingId' | 'discountPercent'>
  | MPEvent<MParticleEventName.ViewedSignupSignin, 'source' | 'view'>
  | MPEvent<MParticleEventName.ViewedSimilarCspsRow, 'componentName' | 'listingState'>
  | MparticleClickedCSPCardEvent
  | MparticleClickedAddToCartEvent
  | MparticleAddToWishlistEvent
  | MparticleRemoveFromWishlistEvent
  | MparticleRemoveFromCartEvent
  | MparticleListingImpressionEvent
  | ClickedListingCardEvent
  | ClickedRecommendedShopCardEvent
  | ViewedCSPComponentEvent
  | ComponentView
  | ViewedFeaturedListing
  | ProductViewActionEvent
  | GenericPageViewEvent
  | ClickedCombineAndSaveViewAll
  | ClickedSellYoursOnCsp
  | AdClick
  | AdImpression
  | MPEvent<MParticleEventName.ApplePayOffered, 'applePayAvailableForDevice'>
  | ClickedShopPoliciesModalEvent
  | SelectedPayoutMethod
  | PlaidLinkEvent
  | ViewedPaymentSettings
  | ClickedLinkList
  | ClickedCategory
  | ClickedTrait
  | ClickedFavoritesHubShare
  | ClickedAddFavoriteSearch
  | ClickedRemoveFavoriteSearch
  | MPEvent<MParticleEventName.SubmittedSellerVerificationShopType, 'shopType'>;

export enum CheckoutClickInteraction {
  HeaderBackToHomepage = 'Checkout - Header Back To Homepage',
  HeaderBackToCart = 'Checkout - Header Back To Cart',
  SelectPaymentMethod = 'Checkout - Select Payment Method',
  ContinueWithShipping = 'Checkout - Continue with Shipping',
  ContinueWithPayment = 'Checkout - Continue with Payment',
}

export enum ProductReviewInteraction {
  StartReview = 'Start Review',
  SubmitReview = 'Submit Review',
  VoteReview = 'Vote Review',
  FlagReview = 'Flag Review',
  SearchReviews = 'Search Reviews',
  ClearSearchQuery = 'Clear Search Query',
  FilterRating = 'Filter Rating',
}

export enum ThumbnailType {
  Image = 'Image',
  Video = 'Video',
}

export enum CartClickInteraction {
  StartCheckout = 'Cart - Start Checkout',
  OpenAuthModal = 'Cart - Open Auth Modal',
  CloseAuthModal = 'Cart - Close Auth Modal',
}

export enum CreditCardWalletClickInteraction {
  OpenModal = 'Credit Card Wallet - Open Modal',
  CloseModal = 'Credit Card Wallet - Close Modal',
  AddCard = 'Credit Card Wallet - Add Card',
  EditCard = 'Credit Card Wallet - Edit Card',
  SelectCard = 'Credit Card Wallet - Select Card',
  CreateCard = 'Credit Card Wallet - Create Card',
  UpdateCard = 'Credit Card Wallet - Update Card',
  VerifyCVV = 'Credit Card Wallet - Verify CVV',
  Cancel = 'Credit Card Wallet - Cancel',
  DeleteCard = 'Credit Card Wallet - Delete Card',
}

export enum AddressBookClickInteraction {
  OpenModal = 'Address Book - Open Modal',
  CloseModal = 'Address Book - Close Modal',
  EditAddress = 'Address Book - Edit Address',
  AddAddress = 'Address Book - Add Address',
  CancelEditAddress = 'Address Book - Cancel Edit Address',
  SaveAddress = 'Address Book - Save Address',
  SelectAddress = 'Address Book - Select Address',
  SelectDefaultAddress = 'Address Book - Select Default Address',
}

/**
 * Defined by mParticle: https://github.com/mParticle/mparticle-web-sdk/blob/master-v2/src/types.js#L206-L215
 */
export enum ProductActionType {
  AddToCart = 1,
  RemoveFromCart = 2,
  Checkout = 3,
  CheckoutOption = 4,
  Click = 5,
  ViewDetail = 6,
  Purchase = 7,
  Refund = 8,
  AddToWishlist = 9, // Amplitude: 'Added to Watch List'
  RemoveFromWishlist = 10, // Amplitude: 'Removed from Watch List'
}

/**
 * Defined by mParticle
 */
export enum EventType {
  Navigation = 1,
  Location = 2,
  Search = 3,
  Transaction = 4,
  UserContent = 5,
  UserPreference = 6,
  Social = 7,
  Other = 8,
}
