import React from 'react';
import classNames from 'classnames';
import I18n from 'i18n-js';
import { RCIcon } from '@reverbdotcom/cadence/components';
import { HeartEmptyIcon, HeartFilledIcon } from '@reverbdotcom/cadence/icons/react';

export interface RCFavoriteButtonProps {
  /** Toggles the indicator on. State is managed upstream from the button. */
  favorited?: boolean;

  /** Standard button event handler */
  onClick?: (e?: any) => void;

  /** Sets button size. Typically you won't use this; large is used for our item page, and small for our CSP header. */
  size?: 'default' | 'small' | 'large';

  /** Also rarely used (e.g. when you're the owner of the listing in question) */
  disabled?: boolean;
}

export const RCFavoriteButton = React.forwardRef<HTMLButtonElement, RCFavoriteButtonProps>(
  function RCFavoriteButton({
    favorited = false,
    onClick,
    size = 'default',
    disabled = false,
  }: RCFavoriteButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>, ref) {
    const classes = classNames(
      'rc-favorite-button',
      {
        'rc-favorite-button--favorited': favorited,
        'rc-favorite-button--large': size === 'large',
        'rc-favorite-button--small': size === 'small',
      },
    );

    return (
      <button
        ref={ref}
        className={classes}
        type="button"
        onClick={onClick}
        disabled={disabled}
        aria-label={favorited ? I18n.t('cadence.RCFavoriteButton.unwatch') : I18n.t('cadence.RCFavoriteButton.watch')}
      >
        <span className="rc-favorite-button__icon">
          <RCIcon svgComponent={favorited ? HeartFilledIcon : HeartEmptyIcon} />
        </span>
      </button>
    );
  },
);
