import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgAngleUp = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21.797 18.5a.494.494 0 0 1-.156.36l-.782.78a.494.494 0 0 1-.718 0L14 13.5l-6.14 6.14a.494.494 0 0 1-.72 0l-.78-.78a.494.494 0 0 1 0-.72l7.28-7.28a.494.494 0 0 1 .72 0l7.28 7.28a.494.494 0 0 1 .157.36z" />
  </svg>
);
export default SvgAngleUp;
