import React from 'react';
import classNames from 'classnames';

import { RCIcon } from '../../';
import { CheckIcon } from '../../../icons/react';
import { RCCheckableLabel } from '../RCCheckbox/RCCheckableLabel';
import { IRCCheckableProps } from '../RCCheckbox/RCCheckbox';

/** A switch UI built as a checkbox under the hood. Used best when saving checkable UIs async */
export function RCSwitch({
  checked,
  disabled,
  errorText,
  id,
  label,
  name,
  onChange,
  required,
  sublabel = undefined,
}: IRCCheckableProps) {
  return (
    <span className={classNames(
      'rc-switch',
      { 'rc-switch--disabled': disabled },
    )}>
      <input
        className="rc-switch__input"
        type="checkbox"
        name={name}
        id={id}
        checked={checked as boolean}
        onChange={onChange}
        disabled={disabled}
        required={required}
      />
      <span className="rc-switch__box">
        <RCIcon svgComponent={CheckIcon} />
      </span>
      <RCCheckableLabel
        htmlFor={id}
        label={label}
        sublabel={sublabel}
        errorText={errorText}
      />
    </span>
  );
}
