import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgPlus = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.25 12.25v-7a1.75 1.75 0 0 1 3.5 0v7h7a1.75 1.75 0 0 1 0 3.5h-7v7a1.75 1.75 0 0 1-3.5 0v-7h-7a1.75 1.75 0 0 1 0-3.5h7z" />
  </svg>
);
export default SvgPlus;
