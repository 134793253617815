import React, { useEffect } from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

import { RCButton } from '../../';
import { RCButtonProps } from '../../RCButton/RCButton';
import { useRCModalBaseContext } from '../RCModalBase/RCModalBase';

export interface RCModalActionProps extends Omit<RCButtonProps, 'children'> {
  /** Overrides the default action text. */
  buttonText?: string | JSX.Element;
  /** Defaults to `false`. Optionally keep a modal open when clicking this action. */
  preventCloseOnClick?: boolean;
  /** Defaults to `false`.
   * Optionally focus this action when the modal opens.
   * Note: when using a destructive `primary` action, the `secondary` action should get initial focus.
   */
  initialFocus?: boolean;
}

export interface RCModalActionGroupProps {
  /** Defaults to a `filled` button that says 'Save'.
   * Use `true` to render a button with the default properties.
   * Use an object to render a button with overrides (e.g. `{ theme: 'danger', buttonText: 'Delete' }`)
  */
  primary?: true | RCModalActionProps;
  /** Defaults to a `muted` button that says 'Cancel'.
   * Use `true` to render a button with the default properties.
   * Use an object to render a button with overrides (e.g. `{ initialFocus: true }`)
  */
  secondary?: true | RCModalActionProps;
}

export function RCModalActionGroup({
  primary = undefined,
  secondary = undefined,
}: RCModalActionGroupProps) {
  const modalContext = useRCModalBaseContext();

  const actionRef = React.useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (actionRef.current) {
      modalContext?.setFocusRef(actionRef);
    }
  }, [modalContext, actionRef]);

  const defaultActions: { [p: string]: RCModalActionProps } = {
    primary: {
      buttonText: I18n.t('cadence.RCModal.save'),
      preventCloseOnClick: false,
      initialFocus: false,
      variant: 'filled',
      id: 'rc-modal-action-primary',
    },
    secondary: {
      buttonText: I18n.t('cadence.RCModal.cancel'),
      preventCloseOnClick: false,
      initialFocus: false,
      variant: 'muted',
      id: 'rc-modal-action-secondary',
    },
  };

  function renderAction(action: true | RCModalActionProps, defaults) {
    if (action === undefined) return null;
    const props = action === true ? defaults : { ...defaults, ...action };
    const { preventCloseOnClick, initialFocus, buttonText, ...buttonProps } = props;

    return (
      <div className={classNames(
        'rc-modal-action-group__action',
        { 'rc-modal-action-group__action--full-width': props.fullWidth },
      )}>
        <RCButton
          {...buttonProps}
          onClick={(e) => {
            buttonProps.onClick?.(e);
            if (preventCloseOnClick) return;
            modalContext?.setOpen(false);
          }}
          ref={initialFocus ? actionRef : undefined}
        >
          {buttonText}
        </RCButton>
      </div>
    );
  }

  return (
    <div className="rc-modal-action-group">
      {renderAction(primary, defaultActions.primary)}
      {renderAction(secondary, defaultActions.secondary)}
    </div>
  );
}
