import { CurrencyDescription, CURRENCY_DESCRIPTION } from './currency_descriptions';

export default class Currency {
  static get(currencyCode: string): CurrencyDescription {
    return CURRENCY_DESCRIPTION[currencyCode];
  }

  static get currencyCodes() {
    return Object.keys(CURRENCY_DESCRIPTION).sort((a, b) => {
      return CURRENCY_DESCRIPTION[a].order - CURRENCY_DESCRIPTION[b].order;
    });
  }

  static isAvailableForDirectCheckoutPayout(currencyCode: string): boolean {
    return INTERNAL_PAYOUTS_SUPPORTED_CURRENCIES.indexOf(currencyCode) >= 0;
  }
}

export function supportedCurrenciesForAdyenPayouts(originCountryCode: string): string[] {
  if (originCountryCode === 'GB') {
    return ['EUR', 'GBP'];
  }

  return ['EUR'];
}

/**
 * Selling/listing supported currencies
 *
 * Some listing currencies are Payment Provider (PayPal or Reverb Payments) dependent
 */
export const LISTING_CURRENCIES = Object.freeze([
  'USD',
  'CAD',
  'EUR',
  'GBP',
  'AUD',
  'JPY',
  'NZD',
  'MXN',
]);

/**
 * Supported for payouts by Reverb directly
*/
export const INTERNAL_PAYOUTS_SUPPORTED_CURRENCIES = Object.freeze([
  'USD',
  'CAD',
]);

export const ADYEN_SUPPORTED_CURRENCIES = Object.freeze([
  'EUR',
  'GBP',
]);

export const PAYPAL_SUPPORTED_CURRENCIES = Object.freeze(LISTING_CURRENCIES);

const SETTLEMENT_TO_PRESENTMENT: { [currencyCode: string]: string[] } = {
  USD: ['USD', 'AUD', 'CAD', 'EUR', 'JPY', 'GBP'],
  CAD: ['CAD'],
  EUR: ['EUR', 'GBP', 'USD'],
  GBP: ['GBP', 'EUR', 'USD'],
};

export function reverbPaymentsPresentmentCurrency(buyerCurrencyCode: string, orderCurrencyCode: string) {
  if (canPresentInBuyerCurrency(buyerCurrencyCode, orderCurrencyCode)) {
    return buyerCurrencyCode;
  }

  return orderCurrencyCode;
}

function canPresentInBuyerCurrency(buyerCurrencyCode: string, orderCurrencyCode: string) {
  return (orderCurrencyCode in SETTLEMENT_TO_PRESENTMENT) &&
    SETTLEMENT_TO_PRESENTMENT[orderCurrencyCode].includes(buyerCurrencyCode);
}
