import { REVISION, ENABLE_SENTRY } from '@reverbdotcom/env';
import * as Sentry from '@sentry/browser';

const dsn = 'https://f52d592014294d25bb78065549a74c43@sentry.io/1270493';

// Exposing this for easier debugging in production
(window as any).DISCO_RELEASE = REVISION;

if (ENABLE_SENTRY) {
  Sentry
    .init({
      dsn,
      release: REVISION,
      allowUrls: [
        /reverb\.com/,
        /static\.reverb-assets\.com/,
      ],
      denyUrls: [
        // https://gist.github.com/impressiver/5092952
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        /v2\.zopim.com\/bin\/v\/widget\//i,
      ],
      ignoreErrors: [
        // https://github.com/SamsungInternet/support/issues/56
        'document.getElementsByClassName.ToString',
        'Blocked a frame with origin "https://reverb.com" from accessing a cross-origin frame.',
        'Blocked a frame with origin "https://reverb.com" from accessing a frame with origin "https://dis.us.criteo.com". Protocols, domains, and ports must match.',
        'Blocked a frame with origin "https://reverb.com" from accessing a frame with origin "https://www.youtube.com". Protocols, domains, and ports must match.',
        'Blocked a frame with origin "https://reverb.com" from accessing a frame with origin "https://staticxx.facebook.com". The frame being accessed set "document.domain" to "facebook.com", but the frame requesting access did not. Both must set "document.domain" to the same value to allow access.',
        'Blocked a frame with origin "https://reverb.com" from accessing a frame with origin "https://bid.g.doubleclick.net". Protocols, domains, and ports must match.',
        'Blocked a frame with origin "https://reverb.com" from accessing a frame with origin "https://gum.criteo.com". Protocols, domains, and ports must match.',
        'Blocked a frame with origin "https://reverb.com" from accessing a frame with origin',
        // https://bugs.chromium.org/p/chromium/issues/detail?id=460399
        // Specific to Safari's removeTag function
        'SyntaxError: The string did not match the expected pattern.',
        // https://sentry.io/organizations/reverb-llc/issues/1057766133/?project=1270493&query=is%3Aunresolved&statsPeriod=14d
        // https://github.com/Dash-Industry-Forum/dash.js/issues/2089
        'The expression cannot be converted to return the specified type',
        'Can\'t find variable: $zopim',
        'SecurityError',
        'No Error',
        'Microsoft Internet extensions',
        'Permission denied',
        'NS_ERROR_STORAGE_BUSY',
        'NS_ERROR_FAILURE',
        // https://sentry.io/organizations/reverb-llc/issues/1159219605/?project=1270493&referrer=slack
        'UET is not defined',

        // This will happen on bad user connections, we get this a lot, we don't need it
        // https://sentry.io/organizations/reverb-llc/issues/697127451/?project=1270493&query=is%3Aunresolved
        // We have a card to address this (e.g. prompt a reload)
        // https://trello.com/c/VkEmUYev/206-handle-chunk-loading-failures
        /Loading chunk/,

        // https://github.com/Voog/wysihtml/issues/286
        "Object doesn't support property or method 'matches'",

        // https://sentry.io/organizations/reverb-llc/issues/1210878600/?project=1270493&query=is%3Aunresolved
        // https://github.com/stephanebachelier/superapi/issues/5
        // Not sure what library caused this, but it resulted in some 150k errors in a short period
        'InvalidStateError: InvalidStateError',

        // https://github.com/webrtcHacks/adapter/issues/832
        'RTCPeerConnection',

        // https://sentry.io/organizations/reverb-llc/issues/1161385360/?project=1270493&query=is%3Aunresolved&statsPeriod=14d
        'mparticle identity error',

        // This has gotten better since tying to fix this, but still occurs
        // https://sentry.io/organizations/reverb-llc/issues/1531960153/?project=1270493&query=is%3Aunresolved
        'Not in fullscreen mode',

        // This is a known issue, we'll be addressing
        // https://trello.com/c/Z4n7FKpM/273-mparticle-tracking-on-i1-for-add-to-cart-is-broken
        "Cannot read property 'pricing' of undefined",

        // Remove ALT
        // https://trello.com/c/5c8ghm0q/274-remove-altjs
        'Invalid action reference passed in',

        'webkitExitFullScreen',
        "Cannot read property 'DOMNodeInsertedByJs' of undefined",
        'Error/C:/Users/',
        'Cannot find function getClientRects in object',
        'Criteo-ads-d ',
        "TypeError: null is not an object (evaluating 'item.obj[item.propName]')",
        'pktAnnotationHighlighter',
        // https://sentry.io/organizations/reverb-llc/issues/1154730993/?project=1270493&referrer=slack
        'movepic',
        'NS_ERROR_FILE_CORRUPTED',
        /ytsubscribe/,
        'xsca',
        'Out of memory',
        '__firefox__',
        '__gCrWeb',
        /moz-extension/,
        'px2/client.js',
        'can\'t redefine non-configurable property "userAgent"',
        'Unexpected token }',
        'illegal character',
        'Unexpected token',

        // Likely coming from WebView -- possibly Braze
        "undefined is not an object (evaluating 'webkit.messageHandlers.contextMenuMessageHandler.postMessage')",

        // Tocca Regression with Safari
        "undefined is not an object (evaluating 'e.pageX')",

        // https://sentry.io/organizations/reverb-llc/issues/1251188685/?project=1270493&query=is%3Aunresolved&sort=freq&statsPeriod=24h
        'illegal access',

        // Known UI bug reloading CSP tiles, silencing until we fix
        'amountCents',
        't is null',
        'CSP price is null',

        // One user spamming errors :-( https://sentry.io/organizations/reverb-llc/issues/1335821131/?project=1270493
        'ReferenceError: event is not defined',

        // https://sentry.io/organizations/reverb-llc/issues/1266711202/events/1c264234cf1744a4b1aeec88a02877f1/?project=1270493&query=is%3Aunresolved
        // Chrome extenision noise
        'Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target',
        /captured with keys: currentTarget, detail, isTrusted, target/,

        // Value doesn't exist in some versions of Safari
        // https://sentry.io/organizations/reverb-llc/issues/1210912278/?project=1270493&query=is%3Aunresolved&sort=freq&statsPeriod=14d
        "undefined is not an object (evaluating 'ceCurrentVideo.currentTime')",

        // https://sentry.io/organizations/reverb-llc/issues/1379931169/?project=1270493&referrer=slack
        // https://webkit.org/blog/8840/dark-mode-support-in-webkit/
        "null is not an object (evaluating 'document.head.querySelector(\"meta[name='supported-color-schemes']\").content')",

        // https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios
        // https://bugs.webkit.org/show_bug.cgi?id=184560
        'cancelled',

        // https://github.com/WICG/ResizeObserver/issues/38
        // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        'ResizeObserver loop limit exceeded',

        // https://sentry.io/organizations/reverb-llc/issues/1371315013/?project=1270493&query=is%3Aunresolved&sort=freq&statsPeriod=14d
        // https://sentry.io/organizations/reverb-llc/issues/1371313139/?project=1270493&query=is%3Aunresolved&sort=freq&statsPeriod=14d
        // https://sentry.io/organizations/reverb-llc/issues/1371313495/?project=1270493&query=is%3Aunresolved
        // https://sentry.io/organizations/reverb-llc/issues/1371347031/?project=1270493&query=is%3Aunresolved
        //
        // Three of these calls are known to fail, for unknown reasons. It is not clear that it has any impact on the user experience.
        // Known issue without ownership, going to bring the noise down.
        //
        // The calls fail with an unknown status
        // https://trello.com/c/KyR7xDP3/366-triage-failed-to-fetch-could-not-fetch-and-ensure-that-it-isnt-impacting-users
        'failed to fetch rails view - status: error, err:',
        'failed to fetch follows - status: error, err:',
        'could not fetch also viewed csps',
        'failed to check for redirect - status: error, err:',
        'Non-Error exception captured with keys',
        /Non-Error promise rejection captured with keys.*done/i,

        // https://sentry.io/organizations/reverb-llc/issues/670504143/?project=1270493&query=is%3Aignored&sort=freq&statsPeriod=14d
        'after property id',

        // This maps to 412 deadline exceeded errors. These are important, but non-actionable in Sentry
        'Invariant Violation: 6',
        /timeout of.*exceeded/,
        /Request timeout after.*milliseconds/,
        /Failed to execute.*removeChild/,
        /Cannot declare a let variable twice/,
        /const in strict mode/,
        /Network error/,
        /let is a reserved identifier/,

        // We log both 4xx and 5xx errors in the offer create form, but this one isn't very important
        /your offer was too low/,
        /waiting for the other party/,

        // We can't do much if the browser can't connect to resource
        /NetworkError when attempting to fetch resource/,

        // Number of browser extensions / quirks from 3rd party scripts
        /setIOSParameters/,
        /instantSearchSDKJSBridgeClearHighlight/,
        /SymBrowser_ModifyWindowOpenWithTarget/,
        /document.getElementById('phpbb')/,
        /GetImageTagSrcFromPointUseNewFun/,

        /Illegal invocation/,
        /Should not already be working/,
        /QuotaExceededError/,

        // Caused by users of https://github.com/Mechazawa/FuckFuckAdblock which attempts
        // to circumvent https://github.com/sitexw/FuckAdBlock which we don't used but
        // attempts to remove the above script in a fashion fitting of it's name.
        /fuckAdBlock/,
        'InvalidStateError: A mutation operation was attempted on a database that did not allow mutations.',
        'TypeError: Failed to fetch',
        /Non-Error promise rejection captured with value: Timeout/, // Generated by Google reCaptcha when the component fails to load
        /window\.cobrowseIOgetRedactedElementsBounds is not a function/, // Generated by a 3rd party screen-share software


        // ApolloError: Load failed
        //    This error is caused by failing to load a GraphQL query. It's
        //    redundant to what we report in APM errors for RQL.
        //
        // TypeError: Load failed
        //    It's unclear in sentry error context of what the root cause is
        //    for this error. It could be related to the ApolloError load failure.
        //    Though we do see many mparticle sdk forwarding calls failing as the
        //    first stack of this error in sentry error context.
        /ApolloError: Load failed/,
        /TypeError: Load failed/,
      ],
    });
}
