import { isString, mapValues } from 'lodash';

import { CMSPage, Listing } from './gql/graphql';
import { CmsPageType } from './constants';

import template from '@reverbdotcom/url-template';
import { useUser } from './user_hooks';
import { IUrl } from './url_context';
import { CORE_WEB_ORIGIN, CMS_ADMIN_URL, CORE_API_ORIGIN } from '@reverbdotcom/env';

const DEFAULT_UPLOAD_IMAGE_CDN = 'https://res.cloudinary.com/reverb-cms';

// the second argument here is temporary until we unify CDN paths between CMS and LP
export function imageUploadURL(image_key, cdn = DEFAULT_UPLOAD_IMAGE_CDN) {
  const path = template.parse('/image/upload/{image_key}').expand({ image_key });
  return `${cdn}${path}`;
}

export const YOUTUBE_IMAGE = template.parse('https://img.youtube.com/vi/{slug}/hqdefault.jpg');
export const YOUTUBE_VIDEO_STATS_API = template.parse<{
  part: string;
  id: string;
  key: string;
}>('https://youtube.googleapis.com/youtube/v3/videos{?part,id,key}');

export interface YouTubeVideoContext {
  slug: string;
  autoplay?: boolean;
}

export const YOUTUBE_VIDEO = template.parse<YouTubeVideoContext>('https://www.youtube.com/embed/{slug}{?autoplay}');
export const VIMEO_VIDEO = template.parse<{ slug: string }>('https://player.vimeo.com/video/{slug}');
const HELP_CENTER_ARTICLE = template.parse<{
  articleId: string;
  locale: string;
}>('https://help.reverb.com/hc/{locale}/articles/{articleId}');

const HELP_CENTER_SECTION = template.parse<{
  sectionId: string;
  locale: string;
}>('https://help.reverb.com/hc/{locale}/sections/{sectionId}');

/**
 * @param articleId
 * @returns Locale-aware Help Center Article URL
 */
export function useHelpCenterArticleUrl(articleId: string) {
  const { locale } = useUser();
  return HELP_CENTER_ARTICLE.expand({ locale, articleId });
}

/**
 * @param sectionId
 * @returns Locale-aware Help Center Section URL
 */
export function useHelpCenterSectionUrl(sectionId: string) {
  const { locale } = useUser();
  return HELP_CENTER_SECTION.expand({ locale, sectionId });
}

export interface ItemWithSlugRouteContext {
  id: string | number;
  slug: string;
  show_sold?: boolean;
  bk?: string; // bump key
  rollup?: boolean;
}

export const ITEM_WITH_SLUG_ROUTE =
  template.parse<ItemWithSlugRouteContext>('/item/{id}-{slug}{?show_sold,bk,rollup}');

export interface BrandRouteContext {
  brand_slug: string;
  product_type?: string;
  category?: string;
}
export const BRAND_ROUTE = template.parse<BrandRouteContext>('/brand/{brand_slug}{?product_type,category}');

export const SHOP_PATH = template.parse<{ slug: string }>('/shop/{slug}');
export const SHOP_FEEDBACK_PATH = template.parse<{ slug: string }>('/shop/{slug}/feedback');

export function cmsEditRoute(uuid: string): string {
  const path = template.parse('/pages/{uuid}/edit').expand({ uuid });
  return `${CMS_ADMIN_URL}${path}`;
}

export interface OAuthLoginContext {
  origin?: string;
}

export const APPLE_AUTH_PATH = template.parse<OAuthLoginContext>('/auth/apple/{?origin}');

export const GOOGLE_AUTH_PATH = template.parse<OAuthLoginContext>('/auth/google/{?origin}');

export const PASSWORD_RESET_PATH = '/forgot_password';

export const FACEBOOK_AUTH_PATH = '/auth/facebook';

export const TERMS_OF_USE_PATH = '/page/terms';

export const PRIVACY_POLICY_PATH = '/page/privacy';

export const GDPR_POLICY_PATH = '/page/eu-data-transfer-GDPR';

export const ADDRESS_SETTINGS_PATH = '/my/address_book';

export const CREDIT_CARD_SETTINGS_PATH = '/my/credit_cards';

export const REVERB_BUMP = '/selling/bump';

export const REVERB_OUTLET_PATH = '/outlet';

export enum CmsPrefix {
  C = 'c',
  SELLING = 'selling',
  SALE = 'sale',
  GUIDE = 'guide',
  GEAR = 'gear',
  SEO_LANDING_PAGE = 's',
  LEGAL = 'legal',
  PRESS = 'press',
  PROMO = 'promo',
  FEATURED = 'featured',
}

export const CmsPageTypeRoutes: Partial<Record<CmsPageType, CmsPrefix>> = {
  [CmsPageType.SALE]: CmsPrefix.SALE,
  [CmsPageType.BUYING_GUIDE]: CmsPrefix.GUIDE,
  [CmsPageType.CATEGORY]: CmsPrefix.C,
  [CmsPageType.SELLING]: CmsPrefix.SELLING,
  [CmsPageType.GEAR]: CmsPrefix.GEAR,
  [CmsPageType.LEGAL]: CmsPrefix.LEGAL,
  [CmsPageType.PRESS]: CmsPrefix.PRESS,
  [CmsPageType.PROMO]: CmsPrefix.PROMO,
  [CmsPageType.FEATURED]: CmsPrefix.FEATURED,
  [CmsPageType.SEO_LANDING_PAGE]: CmsPrefix.SEO_LANDING_PAGE,
};

interface CmsPagePathContext {
  prefix: string;
  slug?: string;
  childSlugs?: string[];
}

const CMS_PAGE_PATH = template.parse<CmsPagePathContext>('/{prefix}{/slug}{/childSlugs*}');
const CMS_PATH_WITH_TRAILING_SLASH = template.parse<CmsPagePathContext>('/{prefix}/{slug}/{childSlugs*}');

export function cmsPagePath(page: CMSPage) {
  if (page?.pageType === CmsPageType.ROOT) {
    return CMS_PAGE_PATH.expand({ prefix: page.slug });
  }

  const path = page?.pageType === CmsPageType.CATEGORY ? CMS_PATH_WITH_TRAILING_SLASH : CMS_PAGE_PATH;
  const fullSlug = page?.slug || '';
  const [slug, ...childSlugs] = fullSlug.split('/');
  const prefix = CmsPageTypeRoutes[page?.pageType] || CmsPrefix.C;

  return path.expand({
    slug,
    childSlugs,
    prefix,
  });
}

export function cmsPageUrl(page: CMSPage) {
  return `${CORE_WEB_ORIGIN}${cmsPagePath(page)}`;
}

export enum SupportedUrlRegion {
  AU = 'au',
  CA = 'ca',
  DE = 'de',
  ES = 'es',
  FR = 'fr',
  IE = 'ie',
  IT = 'it',
  JP = 'jp',
  MT = 'mt',
  UK = 'uk',
  EN_AT = 'en-at',
  EN_BE = 'en-be',
  EN_BG = 'en-bg',
  EN_BR = 'en-br',
  EN_CY = 'en-cy',
  EN_CZ = 'en-cz',
  EN_DK = 'en-dk',
  EN_EE = 'en-ee',
  EN_FI = 'en-fi',
  EN_FR = 'en-fr',
  EN_GR = 'en-gr',
  EN_HR = 'en-hr',
  EN_HU = 'en-hu',
  EN_LT = 'en-lt',
  EN_LU = 'en-lu',
  EN_LV = 'en-lv',
  EN_NL = 'en-nl',
  EN_NO = 'en-no',
  EN_PL = 'en-pl',
  EN_PT = 'en-pt',
  EN_RO = 'en-ro',
  EN_SE = 'en-se',
  EN_SI = 'en-si',
  EN_SK = 'en-sk',
}

export const SUPPORTED_URL_REGIONS = Object.values(SupportedUrlRegion) as string[];

// This list taken from the excluded paths on Reverb core, in order to
// bring frontend to parity with core
// https://github.com/reverbdotcom/reverb/blob/main/app/routing_filters/region_filter.rb#L19
export const EXCLUDED_URL_PATHS = [
  '/multi-checkout',
  '/admin',
  '/auth/', //auth routes are handled by a middleware (omniauth) and so dont hit this filter
  '/core_onboarding',
  '/shop_onboarding',
  '/my/selling/identity_verification',
  '/authenticate',
  '/api/auth/facebook',
  '/api/auth/apple',
  '/api/auth/google',
  '/oauth/token',
  '/api/guest_subscribe',
  '/phone-verification',
  '/signup',
  '/users/email',
  '/onward',
];

const EXCLUDED_URL_REGEX = new RegExp(`^(?:${EXCLUDED_URL_PATHS.join('|')})`);

export function regionalizePath(path: string, urlRegion: string) {
  const [preSlash, firstSegment, ...segments] = path.split('/');

  if (preSlash !== '') { return path; }

  // Special case for `/`
  if (!firstSegment && !urlRegion) { return path; }

  if (path.match(EXCLUDED_URL_REGEX)) { return path; }

  if (firstSegment && !SUPPORTED_URL_REGIONS.includes(firstSegment)) {
    segments.unshift(firstSegment);
  }

  if (urlRegion && SUPPORTED_URL_REGIONS.includes(urlRegion)) {
    segments.unshift(urlRegion);
  }

  return ['', ...segments].join('/');
}

export function newDashboardMessagesPath(listingId, recipientUuid) {
  return template.parse('/my/messages/new{?item,recipient_uuid}').expand({
    item: listingId,
    recipient_uuid: recipientUuid,
  });
}

export function prefilledListingFromCSP(cspId) {
  return template.parse('/my/selling/listings/new{?seed_id}').expand({ seed_id: cspId });
}

export function listingItemPagePath(listing: Listing): string {
  const { id, slug, bumpKey } = listing;

  return ITEM_WITH_SLUG_ROUTE.expand({ id, slug, bk: bumpKey?.key });
}

export function listingOrdersPath(listing: Listing): string {
  const { id, slug } = listing;

  return template.parse('/my/selling/orders?listing={id}-{slug}').expand({ id, slug });
}

export function listingOffersPath(listing: Listing): string {
  const { id } = listing;
  return template.parse('/my/selling/offers?product_id={id}&status=all').expand({ id });
}

export function withAPIHost(path: string): string {
  return withHost(CORE_API_ORIGIN, path);
}

export function withHost(host: string, path: string): string {
  if (host) {
    return host + path;
  }

  return path;
}

// Usage: `Paths.buyerOffer.expand({ offerId, status })`
export const Paths = mapValues({
  ...mapValues({
    apiBrandCollectionHeader: '/api/brands/{slug}/collection_header',
    apiCuratedSetCollectionHeader: '/api/curated_sets/{slug}/collection_header',
    apiCuratedSearchCollectionHeader: '/api/curated_searches/{slug}/collection_header',
    apiLandingPageCollectionHeader: '/api/landing_pages/{slug}/collection_header',
    apiCategoryCollectionHeader: '/api/categories/{categoryUuidOrSlug}/collection_header',
    apiCategoryCollectionHeaders: '/api/categories/collection_headers{?root_slug,child_slug}',
    apiProductTypeCollectionHeader: '/api/product_types/{slug}/collection_header',
  }, withAPIHost),

  autocomplete: `${CORE_WEB_ORIGIN}/autocomplete{?q}`,
  certifiedPreOwned: '/featured/certified-pre-owned',
  csp: '/p/{cspSlug}',
  marketplace: '/marketplace{?product_type,category,make,query,condition,csp_slug,trait_values,year_max,sort,referer,price_max,price_min,exclude_local_pickup_only,follow_prompt}',
  newsletterSignup: '/email_subscriptions',
  sellGear: 'sell/search',
  filteredCsp: '/p/{cspSlug}{?deals_and_steals,on_sale,free_shipping,condition,zero_percent_financing}',
  orderBundlesClaim: '/order_bundles/{orderBundleUuid}/claim',
  myFavorites: 'my/favorites',
  myFavoritesEnded: 'my/favorites/ended',
  myFavoritesSearches: 'my/favorites/searches',
  myFavoritesShops: 'my/favorites/shops',
  myFavoritesFeed: 'my/favorites/feed',
  myFavoritesWatchList: 'my/favorites/watch-list',
  myFavoritesDiscover: 'my/favorites/discover',
  privacySettingsUpdate: '/users/privacy_settings',
  shop: '/shop/{shopSlug}{?query*}',
}, (v) => template.parse(v));

export function isReferredFromGoogle(url: Partial<IUrl> | undefined) {
  return /google\./.test(url?.referer || '');
}

export function newsletterSignupPath() {
  return Paths.newsletterSignup.expand({});
}

const BRAND_URL_PATTERN = '/brand/';
const SIMILAR_ITEM_URL_PATTERN = '/similar/';
const ITEM_URL_PATTERN = '/item/';
const SHOP_URL_PATTERN = '/shop/';
const CSP_URL_PATTERN = '/p/';
const PRODUCT_FAMILY_URL_PATTERN = '/f/';
const CATEGORY_URL_PATTERN = '/c/';
const MARKETPLACE_URL_PATTERN = '/marketplace';
const FAVORITES_URL_PATTERN = '/my/favorites';
const OUTLET_URL_PATTERN = '/outlet';

export function isBrandPage(url: Partial<IUrl> | undefined) {
  return hasUrlMatch(url, BRAND_URL_PATTERN);
}

export function isSimilarItemPage(url: Partial<IUrl> | undefined) {
  return hasUrlMatch(url, SIMILAR_ITEM_URL_PATTERN);
}

export function isItemPage(url: Partial<IUrl> | undefined) {
  return hasUrlMatch(url, ITEM_URL_PATTERN);
}

export function isShopPage(url: Partial<IUrl> | undefined) {
  return hasUrlMatch(url, SHOP_URL_PATTERN);
}

export function isCSP(url: Partial<IUrl> | undefined) {
  return hasUrlMatch(url, CSP_URL_PATTERN);
}

export function isProductFamilyPage(url: Partial<IUrl> | undefined) {
  return hasUrlMatch(url, PRODUCT_FAMILY_URL_PATTERN);
}

export function isCategoryPage(url: Partial<IUrl> | undefined) {
  return hasUrlMatch(url, CATEGORY_URL_PATTERN);
}

export function isMarketplacePage(url: Partial<IUrl> | undefined) {
  return hasUrlMatch(url, MARKETPLACE_URL_PATTERN);
}

export function isOutletHub(url: Partial<IUrl> | undefined) {
  return hasUrlMatch(url, OUTLET_URL_PATTERN);
}

export function isFavoritesPage(url: Partial<IUrl> | undefined) {
  return hasUrlMatch(url, FAVORITES_URL_PATTERN);
}

export function isFavoriteSearchesPage(url: Partial<IUrl> | undefined) {
  return hasUrlMatch(url, `${FAVORITES_URL_PATTERN}/searches`);
}

export function isFavoriteShopsPage(url: Partial<IUrl> | undefined) {
  return hasUrlMatch(url, `${FAVORITES_URL_PATTERN}/shops`);
}

export function isFavoriteItemsPage(url: Partial<IUrl>) {
  return hasUrlMatch(url, FAVORITES_URL_PATTERN, 'endsWith');
}

export function isFavoriteEndedItemsPage(url: Partial<IUrl>) {
  return hasUrlMatch(url, `${FAVORITES_URL_PATTERN}/ended`);
}

export function isFavoritesHubFeedPage(url: Partial<IUrl>) {
  return hasUrlMatch(url, `${FAVORITES_URL_PATTERN}/feed`);
}

export function isFavoritesHubWatchListPage(url: Partial<IUrl>) {
  return hasUrlMatch(url, `${FAVORITES_URL_PATTERN}/watch-list`);
}

export function isHomePage(url: Partial<IUrl> | undefined) {
  const pathname = url?.pathname;
  if (!isString(pathname)) {
    return false;
  }
  const pathSegments = pathname.split('/');
  if (isRegionalURL(url)) { pathSegments.splice(1, 1); }
  pathSegments.filter(f => f);
  return !pathSegments.filter(f => f).length;
}

export function getRegionFromUrl(url: Partial<IUrl>) {
  const path = url?.pathname || '';

  return path.split('/')[1];
}

export function isRegionalURL(url: Partial<IUrl>) {
  const region = getRegionFromUrl(url);

  return SUPPORTED_URL_REGIONS.includes(region);
}

export function isUsingProximityFeatures(url: Partial<IUrl> | undefined) {
  const search = url?.search || '';
  return search.includes('proximity=true') ||
    search.includes('local_pickup=true') ||
    search.includes('sort=proximity');
}

export const WATCHED_PRODUCTS_PATH = '/watched_products';
export const ENDED_WATCHED_PRODUCTS_PATH = '/watched_products/ended';

export function publicWatchedProductsPath(profileSlug: string) {
  return `/watched_products/shared/${profileSlug}`;
}

export function publicWatchedProductsUrl(profileSlug: string) {
  return `${CORE_WEB_ORIGIN}${publicWatchedProductsPath(profileSlug)}`;
}

export function publicEndedWatchedProductsPath(profileSlug: string) {
  return `/watched_products/shared/${profileSlug}/ended`;
}

export function landingPageCollectionHeaderPath(slug) {
  return Paths.apiLandingPageCollectionHeader.expand({ slug });
}

export function productTypeCollectionHeaderPath(slug) {
  return Paths.apiProductTypeCollectionHeader.expand({ slug });
}

export function categoryCollectionHeaderPath(categoryUuidOrSlug) {
  return Paths.apiCategoryCollectionHeader.expand({ categoryUuidOrSlug });
}

export function categoryCollectionHeadersPath(rootSlug, childSlug) {
  return Paths.apiCategoryCollectionHeaders.expand({ root_slug: rootSlug, child_slug: childSlug });
}

export function brandCollectionHeaderPath(slug) {
  return Paths.apiBrandCollectionHeader.expand({ slug });
}

export function curatedSetCollectionHeaderPath(slug) {
  return Paths.apiCuratedSetCollectionHeader.expand({ slug });
}

export function curatedSearchCollectionHeaderPath(slug) {
  return Paths.apiCuratedSearchCollectionHeader.expand({ slug });
}

export function autocompletePath(query) {
  return Paths.autocomplete.expand({ q: query });
}

export function cspPath(cspSlug) {
  return Paths.csp.expand({ cspSlug });
}

function hasUrlMatch(
  url: Partial<IUrl> | undefined,
  pattern: string,
  matchType: 'includes' | 'endsWith' = 'includes',
): boolean {
  const pathname = url?.pathname;

  if (!isString(pathname)) {
    return false;
  }

  if (matchType === 'endsWith') {
    return pathname.endsWith(pattern);
  }

  return pathname.includes(pattern);
}
