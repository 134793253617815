import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgDownload = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21 21a.961.961 0 0 0-.297-.703A.961.961 0 0 0 20 20a.961.961 0 0 0-.703.297A.961.961 0 0 0 19 21c0 .27.099.505.297.703A.961.961 0 0 0 20 22c.27 0 .505-.099.703-.297A.961.961 0 0 0 21 21zm4 0a.961.961 0 0 0-.297-.703A.961.961 0 0 0 24 20a.961.961 0 0 0-.703.297A.961.961 0 0 0 23 21c0 .27.099.505.297.703A.961.961 0 0 0 24 22c.27 0 .505-.099.703-.297A.961.961 0 0 0 25 21zm2-3.5v5c0 .417-.146.77-.438 1.063A1.446 1.446 0 0 1 25.5 24h-23c-.417 0-.77-.146-1.063-.438A1.447 1.447 0 0 1 1 22.5v-5c0-.417.146-.77.438-1.063A1.446 1.446 0 0 1 2.5 16h7.266l2.109 2.125c.604.583 1.313.875 2.125.875.813 0 1.52-.292 2.125-.875L18.25 16h7.25c.417 0 .77.146 1.063.438.291.291.437.645.437 1.062zm-5.078-8.89c.177.426.104.791-.219 1.093l-7 7A.929.929 0 0 1 14 17a.929.929 0 0 1-.703-.297l-7-7c-.323-.302-.396-.667-.219-1.094C6.255 8.203 6.562 8 7 8h4V1c0-.27.099-.505.297-.703A.961.961 0 0 1 12 0h4c.27 0 .505.099.703.297A.961.961 0 0 1 17 1v7h4c.438 0 .745.203.922.61z" />
  </svg>
);
export default SvgDownload;
