import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgSearch = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M19 13c0-1.927-.685-3.576-2.055-4.945C15.575 6.685 13.927 6 12 6c-1.927 0-3.576.685-4.945 2.055C5.685 9.425 5 11.073 5 13c0 1.927.685 3.576 2.055 4.945C8.425 19.315 10.073 20 12 20c1.927 0 3.575-.685 4.945-2.055S19 14.927 19 13zm8 13a1.92 1.92 0 0 1-.594 1.406A1.922 1.922 0 0 1 25 28a1.857 1.857 0 0 1-1.406-.594l-5.36-5.343C16.37 23.354 14.292 24 12 24c-1.49 0-2.914-.29-4.273-.867-1.36-.578-2.532-1.36-3.516-2.344-.984-.984-1.766-2.156-2.344-3.516A10.808 10.808 0 0 1 1 13c0-1.49.29-2.914.867-4.273.578-1.36 1.36-2.532 2.344-3.516.984-.984 2.156-1.766 3.516-2.344A10.809 10.809 0 0 1 12 2c1.49 0 2.914.29 4.273.867 1.36.578 2.532 1.36 3.516 2.344.984.984 1.766 2.156 2.344 3.516C22.71 10.086 23 11.51 23 13c0 2.292-.646 4.37-1.938 6.234l5.36 5.36c.385.385.578.854.578 1.406z" />
  </svg>
);
export default SvgSearch;
