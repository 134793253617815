export function defaultConfig(overrides = {}) {
  return {
    // More: https://www.framer.com/docs/transition
    initial: {
      opacity: 0,
      y: '-0.5rem',
    },
    animate: {
      opacity: 1,
      y: 0,
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    transition: {
      duration: 0.25,
      ease: 'easeOut',
    },
    ...overrides,
  };
}
