import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgUserCircleO = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14 0c1.896 0 3.708.37 5.438 1.11 1.729.739 3.218 1.734 4.468 2.984s2.245 2.74 2.985 4.468A13.704 13.704 0 0 1 28 14c0 1.885-.367 3.693-1.102 5.422-.734 1.729-1.726 3.219-2.976 4.469-1.25 1.25-2.74 2.247-4.469 2.992A13.64 13.64 0 0 1 14 28c-1.906 0-3.724-.37-5.453-1.11-1.73-.739-3.216-1.736-4.461-2.992-1.245-1.255-2.237-2.744-2.977-4.468A13.63 13.63 0 0 1 0 14c0-1.896.37-3.708 1.11-5.438.739-1.729 1.734-3.218 2.984-4.468s2.74-2.245 4.468-2.985A13.704 13.704 0 0 1 14 0zm9.672 21.11C25.224 18.973 26 16.603 26 14a11.68 11.68 0 0 0-.953-4.656c-.636-1.48-1.49-2.755-2.563-3.828-1.073-1.073-2.349-1.927-3.828-2.563A11.675 11.675 0 0 0 14 2a11.68 11.68 0 0 0-4.656.953c-1.48.636-2.755 1.49-3.828 2.563-1.073 1.073-1.927 2.349-2.563 3.828A11.675 11.675 0 0 0 2 14c0 2.604.776 4.974 2.328 7.11C5.016 17.702 6.61 16 9.11 16c1.365 1.333 2.995 2 4.891 2s3.526-.667 4.89-2c2.5 0 4.094 1.703 4.782 5.11zM20 11c0-1.656-.586-3.07-1.758-4.242C17.07 5.586 15.656 5 14 5c-1.656 0-3.07.586-4.242 1.758C8.586 7.93 8 9.344 8 11c0 1.656.586 3.07 1.758 4.242C10.93 16.414 12.344 17 14 17c1.656 0 3.07-.586 4.242-1.758C19.414 14.07 20 12.656 20 11z" />
  </svg>
);
export default SvgUserCircleO;
