import React from 'react';

// TODO: Renamed to Attributes in 4.x of the JS SDKs
import { SubjectAttributes } from '@eppo/js-client-sdk-common';

// TODO: IEppoClient was removed in 4.x of the JS SDKs. Is there a better way to provide an interface that works for client and server eppo sdks?
export interface IEppoClient {
  getStringAssignment(
    flagKey: string,
    subjectKey: string,
    subjectAttributes: SubjectAttributes,
    defaultValue: string,
  ): string
}

export const fallbackEppoClient: IEppoClient = {
  getStringAssignment: (_flagKey: string, _subjectKey: string, _subjectAttributes: SubjectAttributes, defaultValue: string) => defaultValue,
};

/**
 * Work in progress! Do not use yet!
 * This will be used in AppProvider to provide an `eppoClient` to child components.
 */
export const EppoClientContext = React.createContext<IEppoClient>(fallbackEppoClient);
EppoClientContext.displayName = 'EppoClientContext';
