import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgDrums = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M22.641 8.564a9.216 9.216 0 0 1 .809 3.748 9.2 9.2 0 0 1-.218 1.984l-.109.956a8.959 8.959 0 0 1-.48 1.996l3.078 1.232-.428 1.019-3.046-1.333a8.957 8.957 0 0 1-1.911 2.556l1.107 2.588-1.019.42-.91-2.329a8.96 8.96 0 0 1-14.179-6.149l-.108-.957a9.217 9.217 0 0 1 17.414-5.73zm-1.784 8.993a8.44 8.44 0 0 1-1.309 1.326l-.999-2.335 2.308 1.01zm-8.51-4.431 4.661 1.866-1.838-4.703c-.4-.054-.816-.417-1.034-.95-.287-.7-.126-1.428.36-1.626.484-.199 1.11.207 1.396.907.23.563.172 1.145-.114 1.454l2.286 5.34 3.353 1.343a8.44 8.44 0 0 0 1.255-4.445 8.454 8.454 0 0 0-8.447-8.423 8.439 8.439 0 1 0 4.528 15.565l-1.33-3.399-5.348-2.34c-.32.247-.874.286-1.409.062-.697-.292-1.098-.92-.896-1.404.203-.483.932-.638 1.63-.346.557.233.925.682.947 1.099z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDrums;
