import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgExclamationTriangle = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#a)">
      <path d="M16 21.484v-2.968a.499.499 0 0 0-.148-.368A.48.48 0 0 0 15.5 18h-3a.48.48 0 0 0-.351.148.499.499 0 0 0-.149.368v2.968a.5.5 0 0 0 .149.368.48.48 0 0 0 .351.148h3a.48.48 0 0 0 .352-.148.499.499 0 0 0 .148-.368zm-.031-5.843.281-7.172a.344.344 0 0 0-.156-.297c-.136-.115-.26-.172-.375-.172H12.28c-.114 0-.24.057-.375.172-.104.073-.156.182-.156.328l.266 7.14c0 .105.052.19.156.258.104.068.23.102.375.102h2.89a.635.635 0 0 0 .368-.102.347.347 0 0 0 .164-.257zM15.75 1.047l12 22c.365.656.354 1.312-.031 1.969-.177.302-.42.541-.727.718A1.954 1.954 0 0 1 26 26H2c-.354 0-.685-.088-.992-.266a1.963 1.963 0 0 1-.727-.718c-.385-.657-.396-1.313-.031-1.97l12-22A1.995 1.995 0 0 1 14 0a1.994 1.994 0 0 1 1.75 1.047z" />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h28v28H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgExclamationTriangle;
