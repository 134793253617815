import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgGear = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M18 14a3.85 3.85 0 0 0-1.172-2.828A3.853 3.853 0 0 0 14 10a3.85 3.85 0 0 0-2.828 1.172A3.853 3.853 0 0 0 10 14a3.85 3.85 0 0 0 1.172 2.828A3.853 3.853 0 0 0 14 18a3.85 3.85 0 0 0 2.828-1.172A3.853 3.853 0 0 0 18 14zm8-1.703v3.469a.603.603 0 0 1-.125.359.475.475 0 0 1-.313.203l-2.89.438a10.012 10.012 0 0 1-.61 1.422c.365.52.922 1.24 1.672 2.156a.604.604 0 0 1 .157.39.521.521 0 0 1-.141.36c-.281.385-.797.948-1.547 1.687-.75.74-1.24 1.11-1.469 1.11a.763.763 0 0 1-.406-.141l-2.156-1.688a8.91 8.91 0 0 1-1.422.594c-.167 1.417-.318 2.386-.453 2.907-.073.291-.26.437-.563.437h-3.468a.59.59 0 0 1-.383-.133.463.463 0 0 1-.18-.336l-.437-2.875c-.51-.166-.98-.36-1.407-.578L7.656 23.75a.562.562 0 0 1-.39.14.539.539 0 0 1-.391-.171c-1.313-1.188-2.172-2.063-2.578-2.625a.611.611 0 0 1 .016-.719c.156-.219.421-.565.796-1.04.375-.473.657-.84.844-1.1a7.734 7.734 0 0 1-.64-1.547l-2.86-.422a.492.492 0 0 1-.328-.196.591.591 0 0 1-.125-.367v-3.469c0-.125.042-.244.125-.359a.47.47 0 0 1 .297-.203l2.906-.438c.146-.479.35-.958.61-1.437A35.566 35.566 0 0 0 4.266 7.64a.587.587 0 0 1-.157-.375c0-.105.047-.224.141-.36.27-.375.784-.935 1.54-1.68.754-.744 1.246-1.117 1.476-1.117.135 0 .27.052.406.157l2.156 1.671c.459-.24.932-.437 1.422-.593.167-1.417.318-2.386.453-2.907.073-.291.26-.437.563-.437h3.468a.59.59 0 0 1 .383.133c.11.088.17.2.18.336l.437 2.875c.51.166.98.36 1.407.578l2.218-1.672a.507.507 0 0 1 .375-.14c.136 0 .266.051.391.156 1.344 1.24 2.203 2.125 2.578 2.656.073.083.11.198.11.344a.603.603 0 0 1-.125.359c-.157.219-.422.565-.797 1.04-.375.473-.657.84-.844 1.1.27.521.484 1.032.64 1.532l2.86.437a.491.491 0 0 1 .328.196c.083.11.125.232.125.367z" />
  </svg>
);
export default SvgGear;
