import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgBarsAlt = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M26 21c0 .27-.099.505-.297.703A.961.961 0 0 1 25 22H3a.961.961 0 0 1-.703-.297A.961.961 0 0 1 2 21c0-.27.099-.505.297-.703A.961.961 0 0 1 3 20h22c.27 0 .505.099.703.297A.961.961 0 0 1 26 21Zm0-7c0 .27-.099.505-.297.703A.961.961 0 0 1 25 15H3a.961.961 0 0 1-.703-.297A.961.961 0 0 1 2 14c0-.27.099-.505.297-.703A.961.961 0 0 1 3 13h22c.27 0 .505.099.703.297A.961.961 0 0 1 26 14Zm0-7c0 .27-.099.505-.297.703A.961.961 0 0 1 25 8H3a.961.961 0 0 1-.703-.297A.961.961 0 0 1 2 7c0-.27.099-.505.297-.703A.961.961 0 0 1 3 6h22c.27 0 .505.099.703.297A.961.961 0 0 1 26 7Z" />
  </svg>
);
export default SvgBarsAlt;
