import React from 'react';
import classNames from 'classnames';

import { RCTooltip, RCNudge, RCNudgeProps } from '../';

export interface ShippingPromoProps {
  /** label should just be a simple string, but ReactNode support is here in case we want to pass in an icon */
  label: React.ReactNode;

  /** When present, the label will get a dotted underline treatment and a tooltip will be present on hover explaining the promo */
  tooltipText?: string;

  /** For the default theme, plain makes the label the default text color, and highlight makes the text green */
  type?: 'plain' | 'highlight';
}

export interface RCPriceBlockProps {
  /** The main price to display. If discountPriceDisplay is also present, this price UI will be crossed out and gray */
  priceDisplay: string;

  /** If the item has a lower price than originally listed, this number will be in the UI highlighted as the sale price */
  discountPriceDisplay?: string;

  /** E.g. "Save $40.50" or "10% Price Drop"
   * When a price has been discounted in some way, this text will appear next to the original crossed-out price
   **/
  discountPriceDescription?: string;

  /** This is the text that indicates how much it will cost to ship this item */
  shippingDisplay?: React.ReactNode;

  /** When true, the shippingDisplay UI will be crossed out */
  shippingStrike?: boolean;

  /** A nudge that is directly associated with the price, like "FREE Shipping" or "Digital Download" */
  priceNudge?: RCNudgeProps;

  /** If a shipping promotion is present, it will display adjacent to the shippingDisplay UI, and that UI will get crossed out */
  shippingPromo?: ShippingPromoProps;

  /** To help assistive technologies make sense of the pricing block.
   * This should be human-readable and contain details about price, original price, discounts and shipping costs.
   *
   * Example: `Originally $10, now $7 ($3 price drop) plus $41 shipping`
  */
  a11yPriceDescription: string;

  /** Used to maintain visual alignment across a row of content.  */
  fixedHeight?: boolean;

  /** Will make the main price red if discountPriceDisplay is provided. */
  highlightDiscountedPrice?: boolean;

  /** Makes price slightly smaller on all devices */
  smallText?: boolean;

  /** Displays a string next to the struck-through original price. Pass in a localized string. */
  originalPriceString?: string;

  /** Displays price text & discount price text on the same line rather than stacked. */
  inlineDiscountText?: boolean;
}

export function RCPriceBlock({
  priceDisplay,
  discountPriceDisplay = undefined,
  discountPriceDescription = undefined,
  shippingDisplay = undefined,
  a11yPriceDescription = '',
  shippingPromo = undefined,
  originalPriceString = undefined,
  priceNudge = undefined,
  shippingStrike = false,
  fixedHeight = false,
  highlightDiscountedPrice = false,
  smallText = false,
  inlineDiscountText = false,
}: RCPriceBlockProps) {
  return (
    <div className={classNames(
      'rc-price-block',
      {
        'rc-price-block--discounted': discountPriceDisplay,
        'rc-price-block--shipping-promo': shippingPromo,
        'rc-price-block--shipping-strike': shippingStrike,
        'rc-price-block--fixed-height': fixedHeight,
        'rc-price-block--highlighted-price': !!discountPriceDisplay && !!highlightDiscountedPrice,
        'rc-price-block--small-text': !!smallText,
        'rc-price-block--original-price': !!originalPriceString,
      },
    )}>
      <span className="visually-hidden">
        {a11yPriceDescription}
      </span>
      <div className="rc-price-block__price-wrapper" aria-hidden>
        {
          inlineDiscountText ?
            <div className="rc-price-block__discount-block-inline">
              <div className="rc-price-block__discount-block">
                <span className="rc-price-block__price">
                  {priceDisplay}
                </span>
                {(fixedHeight || discountPriceDisplay) && (
                  <span className="rc-price-block__discount-price">
                    <span className="td-line-through mr-space">
                      {discountPriceDisplay}
                    </span>
                  </span>
                )}
              </div>
            </div>
            :
            <>
              <div className="rc-price-block__discount-block">
                {(fixedHeight || discountPriceDisplay) && (
                  <div className="rc-price-block__discount-price">
                    <span className="td-line-through mr-space">
                      {discountPriceDisplay}
                    </span>
                    {originalPriceString &&
                      <span>
                        {originalPriceString}
                      </span>
                    }
                  </div>
                )}
                {discountPriceDescription && (
                  <div className="rc-price-block__discount-description">
                    {discountPriceDescription}
                  </div>
                )}
              </div>
              <div className="rc-price-block__price">
                {priceDisplay}
              </div>
            </>
        }
      </div>
      {(!priceNudge && shippingDisplay) && (
        <div className="rc-price-block__shipping" aria-hidden>
          {shippingDisplay}
        </div>
      )}
      {priceNudge && (
        <div className="rc-price-block__nudge">
          <RCNudge {...priceNudge} />
        </div>
      )}
      {shippingPromo && (
        <div
          className={classNames(
            'rc-price-block__shipping-promo',
            {
              'rc-price-block__shipping-promo--highlight': shippingPromo.type === 'highlight',
            },
          )}
        >
          {shippingPromo.tooltipText ? (
            <RCTooltip
              text={shippingPromo.tooltipText}
              type="underline"
            >
              {shippingPromo.label}
            </RCTooltip>
          ) : shippingPromo.label}
        </div>
      )}
    </div>
  );
}
