import { CSP, Category } from './gql/graphql';
import { uniqWith, isEqual, flatMap } from 'lodash';

interface CspCategoryDataForMparticle {
  category: string;
  subcategories: string;
}

export function getCategoryAttributesForMparticle(csp: CSP): CspCategoryDataForMparticle {
  const slugs = orderedSlugs(csp);

  return {
    category: slugs[0] || '',
    subcategories: slugs.join('|'),
  };
}

function orderedSlugs(csp: CSP) {
  const categories: Category[] = csp?.categories || [];
  const slugs = categories.map(c => c.slug);

  const root = categories[0]?.rootSlug;

  if (root) {
    return [root, ...slugs];
  }

  return slugs;
}

export function orderedCategories(csp: CSP): Category[] {
  if (!csp || !csp.categories) {
    return [];
  }

  const parentCategories = uniqWith(
    flatMap(
      csp.categories,
      cat => cat.parentCategories || [],
    ),
    isEqual,
  );
  return parentCategories.concat(csp.categories);
}

export function getCategory(csp: CSP): Category {
  if (csp.categories.length === 1 && !csp.categories[0].parentCategories?.length) {
    return csp.categories[0];
  }

  return csp.categories.find(c => c.parentCategories?.length)?.parentCategories[0];
}

export function getSubcategory(csp: CSP): Category {
  return csp.categories.find(c => c.parentCategories?.length);
}
