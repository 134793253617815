export interface CurrencyDescription {
  symbol: string;
  subunitToUnit: number;
  precision: number;
  order: number;
  displayCode: string;
}

export const CURRENCY_DESCRIPTION = Object.freeze<Record<string, CurrencyDescription>>({
  USD: {
    symbol: '$',
    subunitToUnit: 100,
    precision: 2,
    order: 0,
    displayCode: '',
  },
  CAD: {
    symbol: 'C$',
    subunitToUnit: 100,
    precision: 2,
    order: 1,
    displayCode: 'CAD',
  },
  AUD: {
    symbol: 'A$',
    subunitToUnit: 100,
    precision: 2,
    order: 4,
    displayCode: 'AUD',
  },
  GBP: {
    symbol: '£',
    subunitToUnit: 100,
    precision: 2,
    order: 3,
    displayCode: 'GBP',
  },
  EUR: {
    symbol: '€',
    subunitToUnit: 100,
    precision: 2,
    order: 2,
    displayCode: 'EUR',
  },
  JPY: {
    symbol: '¥',
    subunitToUnit: 1,
    precision: 0,
    order: 5,
    displayCode: 'JPY',
  },
  NZD: {
    symbol: 'NZ$',
    subunitToUnit: 100,
    precision: 2,
    order: 6,
    displayCode: 'NZD',
  },
  MXN: {
    symbol: 'MEX$',
    subunitToUnit: 100,
    precision: 2,
    order: 7,
    displayCode: 'MXN',
  },
  BRL: {
    symbol: 'R$',
    subunitToUnit: 100,
    precision: 2,
    order: 9,
    displayCode: 'BRL',
  },
  DKK: {
    symbol: 'kr.',
    subunitToUnit: 100,
    precision: 2,
    order: 10,
    displayCode: 'DKK',
  },
  HKD: {
    symbol: 'HK$',
    subunitToUnit: 100,
    precision: 2,
    order: 11,
    displayCode: 'HKD',
  },
  NOK: {
    symbol: 'kr',
    subunitToUnit: 100,
    precision: 2,
    order: 12,
    displayCode: 'NOK',
  },
  PHP: {
    symbol: '₱',
    subunitToUnit: 100,
    precision: 2,
    order: 13,
    displayCode: 'PHP',
  },
  PLN: {
    symbol: 'zł',
    subunitToUnit: 100,
    precision: 2,
    order: 14,
    displayCode: 'PLN',
  },
  SEK: {
    symbol: 'kr',
    subunitToUnit: 100,
    precision: 2,
    order: 16,
    displayCode: 'SEK',
  },
  CHF: {
    symbol: 'CHF',
    subunitToUnit: 100,
    precision: 2,
    order: 17,
    displayCode: 'CHF',
  },
});
