import React from 'react';
import classNames from 'classnames';
import I18n from 'i18n-js';

import { RCTooltip } from '../';
import { TimesCircleAltIcon, AngleDownIcon } from '../../icons/react';

/* eslint-disable react/require-default-props */
interface RCChipProps {
  /** RCChips are simpler than RCButtons and expect only a toggled state. */
  selected?: boolean;

  /** Appends a numeric indicator to the chip. Positive count values only. */
  count?: number;

  /**
   * Optionally adds an icon to indicate that this button opens a menu or popover.
   * Note: This prop is automatically applied when used with RCPopover.
  */
  'aria-haspopup'?: boolean | 'dialog' | 'menu';

  /** Standard change handler; this component doesn't track its own state. */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;

  /** We allow any markup here for overall consistency but this should likely remain a simple string */
  children: React.ReactNode;

  /**
   * These sizes are set up to replicate our RCButton sizes.
   * `mini` RCChips don't render a close icon and are intended for use in a group of selectable items, e.g. suggestions below an input field.
  */
  size?: 'small' | 'mini';

  /**
   * Reduces L/R padding; use when only rendering an icon inside.
   */
  iconOnly?: boolean;

  /**
   * Label text; shows on hover. Use this whenever RCChip is iconOnly. Keep this as concise as a text-based button would be.
   */
  'aria-label'?: string | JSX.Element | React.ReactNode;

  /** Dims button during loading */
  loading?: boolean;
}

const RCChip = React.forwardRef<HTMLButtonElement, RCChipProps>(
  function RCChip({
    selected = undefined,
    count = undefined,
    'aria-haspopup': ariaHasPopup = undefined,
    onClick = undefined,
    size = 'small',
    loading = false,
    'aria-label': ariaLabel = undefined,
    children,
    iconOnly = false,
    ...passthroughProps
  }: RCChipProps, propRef) {
    const classes = classNames(
      'rc-chip',
      {
        'rc-chip--small': size === 'small',
        'rc-chip--mini': size === 'mini',
        'rc-chip--icon-only': iconOnly,
        'rc-chip--loading': loading,
      },
    );

    function renderCount() {
      if (!count) return null;
      if (count <= 0) return null;

      return (
        <>
          <span aria-hidden id="rc-chip__count">
            {` (${count})`}
          </span>
          <span className="visually-hidden">
            {I18n.t('cadence.RCChip.applied', { count: count })}
          </span>
        </>
      );
    }

    function renderIcon() {
      if (ariaHasPopup) {
        return (
          <div className="rc-chip__caret">
            <AngleDownIcon />
          </div>
        );
      }
      if (selected) {
        return (
          <div className="rc-chip__remove">
            <TimesCircleAltIcon />
          </div>
        );
      }
      return null;
    }

    function renderButton() {
      return (
        <button
          className={classes}
          type="button"
          aria-pressed={selected}
          aria-haspopup={ariaHasPopup}
          onClick={onClick}
          ref={propRef}
          {...passthroughProps}
        >
          <span>
            {children}
            {renderCount()}
          </span>
          {renderIcon()}
        </button>
      )
    }

    if (!ariaLabel) return renderButton();
    return (
      <RCTooltip
        text={ariaLabel}
        style="buttonLabel"
        placement="bottom"
      >
        {renderButton()}
      </RCTooltip>
    );
  },
);

// Wrapper container to standardize spacing & wrapping.

interface RCChipCollectionProps {
  nowrap?: 'tablet',
  children,
}

function RCChipCollection({
  nowrap = undefined,
  children,
}: RCChipCollectionProps) {
  const classes = classNames(
    'd-flex fx-wrap gap-2',
    {
      'tablet-d-block tablet-ws-nowrap': nowrap === 'tablet',
    },
  );

  if (nowrap === 'tablet') {
    return (
      <div className={classes}>
        {
          React.Children.map(children, (child, idx) => {
            return (
              <div
                key={idx}
                className="tablet-d-inline-block tablet-mr-2"
              >
                {child}
              </div>
            );
          })
        }
      </div>
    );
  }

  return (
    <div className={classes}>
      {children}
    </div>
  );
}

// Dot notation assignments and component export

const RCChipComponents = Object.assign(
  RCChip, {
    Collection: RCChipCollection,
  },
);

export { RCChipComponents as RCChip };
