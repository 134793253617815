import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgList = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M4 20.5v3a.48.48 0 0 1-.148.352A.48.48 0 0 1 3.5 24h-3a.48.48 0 0 1-.352-.148A.48.48 0 0 1 0 23.5v-3a.48.48 0 0 1 .148-.352A.48.48 0 0 1 .5 20h3a.48.48 0 0 1 .352.148A.48.48 0 0 1 4 20.5zm0-6v3a.48.48 0 0 1-.148.352A.48.48 0 0 1 3.5 18h-3a.48.48 0 0 1-.352-.148A.48.48 0 0 1 0 17.5v-3a.48.48 0 0 1 .148-.352A.48.48 0 0 1 .5 14h3a.48.48 0 0 1 .352.148A.48.48 0 0 1 4 14.5zm0-6v3a.48.48 0 0 1-.148.352A.48.48 0 0 1 3.5 12h-3a.48.48 0 0 1-.352-.148A.48.48 0 0 1 0 11.5v-3a.48.48 0 0 1 .148-.352A.48.48 0 0 1 .5 8h3a.48.48 0 0 1 .352.148A.48.48 0 0 1 4 8.5zm24 12v3a.48.48 0 0 1-.148.352.48.48 0 0 1-.352.148h-21a.48.48 0 0 1-.352-.148A.48.48 0 0 1 6 23.5v-3a.48.48 0 0 1 .148-.352A.48.48 0 0 1 6.5 20h21a.48.48 0 0 1 .352.148.48.48 0 0 1 .148.352zM4 2.5v3a.48.48 0 0 1-.148.352A.48.48 0 0 1 3.5 6h-3a.48.48 0 0 1-.352-.148A.48.48 0 0 1 0 5.5v-3a.48.48 0 0 1 .148-.352A.48.48 0 0 1 .5 2h3a.48.48 0 0 1 .352.148A.48.48 0 0 1 4 2.5zm24 12v3a.48.48 0 0 1-.148.352.48.48 0 0 1-.352.148h-21a.48.48 0 0 1-.352-.148A.48.48 0 0 1 6 17.5v-3a.48.48 0 0 1 .148-.352A.48.48 0 0 1 6.5 14h21a.48.48 0 0 1 .352.148.48.48 0 0 1 .148.352zm0-6v3a.48.48 0 0 1-.148.352.48.48 0 0 1-.352.148h-21a.48.48 0 0 1-.352-.148A.48.48 0 0 1 6 11.5v-3a.48.48 0 0 1 .148-.352A.48.48 0 0 1 6.5 8h21a.48.48 0 0 1 .352.148A.48.48 0 0 1 28 8.5zm0-6v3a.48.48 0 0 1-.148.352A.48.48 0 0 1 27.5 6h-21a.48.48 0 0 1-.352-.148A.48.48 0 0 1 6 5.5v-3a.48.48 0 0 1 .148-.352A.48.48 0 0 1 6.5 2h21a.48.48 0 0 1 .352.148A.48.48 0 0 1 28 2.5z" />
  </svg>
);
export default SvgList;
