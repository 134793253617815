import isNode from '../../is-node';
import { useRouterHistory } from 'react-router';
import { createMemoryHistory, createHistory } from 'history';
import qs from 'qs';

// Set up a custom history to handle array params correctly, ex: ?condition[]=used
const stringifyQuery = query => qs.stringify(query, { arrayFormat: 'brackets', encodeValuesOnly: true });

export function initializeRouterHistory(pathnameDuringSsr?: string): any {
  const historyOptions = {
    stringifyQuery,
    parseQueryString: qs.parse,
    entries: null,
  };

  if (isNode && typeof pathnameDuringSsr !== 'undefined') {
    historyOptions.entries = pathnameDuringSsr;
  }

  const historyFn = isNode ? createMemoryHistory : createHistory;

  // This is not actually a React hook, but it starts with "use", so eslint thinks it is
  // eslint-disable-next-line
  return useRouterHistory<any, {}>(historyFn)(historyOptions);
}

const routerHistory = initializeRouterHistory();

export default routerHistory;
