import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgNudgeCart = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 2.857C1 2.384 1.362 2 1.809 2h2.336c.8 0 1.481.62 1.6 1.46l.278 1.969h9.359c1.02 0 1.785.987 1.587 2.047l-.96 5.142c-.15.803-.815 1.382-1.588 1.382H6.989c-.801 0-1.482-.622-1.6-1.462L4.145 3.714H1.81c-.447 0-.809-.383-.809-.857Zm5.265 4.286.723 5.143h7.433l.96-5.143H6.266Z"
    />
    <path d="M8.5 16.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM14 18a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
  </svg>
);
export default SvgNudgeCart;
