import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgFlag = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M5 4c0 .75-.333 1.323-1 1.719V25.5a.48.48 0 0 1-.148.352A.48.48 0 0 1 3.5 26h-1a.48.48 0 0 1-.352-.148A.48.48 0 0 1 2 25.5V5.719C1.333 5.323 1 4.75 1 4c0-.552.195-1.023.586-1.414C1.976 2.196 2.448 2 3 2c.552 0 1.023.195 1.414.586.39.39.586.862.586 1.414zm23 1v11.922c0 .26-.065.46-.195.601-.13.141-.336.284-.617.43-2.24 1.209-4.162 1.813-5.766 1.813a5.787 5.787 0 0 1-1.93-.344c-.65-.23-1.216-.48-1.695-.75-.48-.271-1.08-.521-1.805-.75a7.334 7.334 0 0 0-2.226-.344c-2 0-4.417.76-7.25 2.281A1.1 1.1 0 0 1 6 20a.961.961 0 0 1-.703-.297A.961.961 0 0 1 5 19V7.406c0-.333.161-.62.484-.86.22-.145.63-.369 1.235-.671C9.177 4.625 11.369 4 13.297 4c1.114 0 2.156.151 3.125.453.969.302 2.11.76 3.422 1.375.396.198.854.297 1.375.297.562 0 1.174-.11 1.836-.328a9.902 9.902 0 0 0 1.718-.734c.485-.271.943-.516 1.375-.735C26.581 4.11 26.865 4 27 4c.27 0 .505.099.703.297A.961.961 0 0 1 28 5z" />
  </svg>
);
export default SvgFlag;
