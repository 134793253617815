import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgNudgeTrustBadge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.486 8.452a1.75 1.75 0 0 0-.044 2.374l1.459 1.623c.66.735 1.789.735 2.448 0l3.209-3.571a1.75 1.75 0 0 0-.044-2.374 1.636 1.636 0 0 0-2.405.047L9.125 8.759l-.234-.26a1.636 1.636 0 0 0-2.405-.047Zm.718.696a.636.636 0 0 1 .943.019l.978 1.089 2.728-3.037a.636.636 0 0 1 .943-.02.75.75 0 0 1 .018 1.01l-3.209 3.572a.636.636 0 0 1-.96 0l-1.459-1.623a.75.75 0 0 1 .018-1.01Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.518 1.345a1.467 1.467 0 0 0-1.037 0L3.704 3.526c-.576.218-.954.77-.954 1.383V10c0 2.458 1.357 4.591 3.39 6.383a16.988 16.988 0 0 0 3.2 2.21c.415.21.905.21 1.32 0a16.99 16.99 0 0 0 3.2-2.21c2.033-1.792 3.39-3.925 3.39-6.383V4.91c0-.614-.378-1.166-.954-1.384L10.52 1.345ZM4.25 4.924 10 2.752l5.75 2.172V10c0 1.86-1.02 3.618-2.881 5.257A15.473 15.473 0 0 1 10 17.247a15.478 15.478 0 0 1-2.869-1.989C5.271 13.617 4.25 11.86 4.25 10V4.924Z"
    />
  </svg>
);
export default SvgNudgeTrustBadge;
