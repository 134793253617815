import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgTimesCircle = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M19.953 17.531a.96.96 0 0 0-.297-.703L16.828 14l2.828-2.828a.96.96 0 0 0 .297-.703.979.979 0 0 0-.297-.719L18.25 8.344a.979.979 0 0 0-.719-.297.961.961 0 0 0-.703.297L14 11.172l-2.828-2.828a.961.961 0 0 0-.703-.297.979.979 0 0 0-.719.297L8.344 9.75a.979.979 0 0 0-.297.719c0 .27.099.505.297.703L11.172 14l-2.828 2.828a.961.961 0 0 0-.297.703c0 .282.099.521.297.719l1.406 1.406a.979.979 0 0 0 .719.297.96.96 0 0 0 .703-.297L14 16.828l2.828 2.828a.96.96 0 0 0 .703.297.978.978 0 0 0 .719-.297l1.406-1.406a.979.979 0 0 0 .297-.719zM26 14c0 2.177-.537 4.185-1.61 6.023a11.945 11.945 0 0 1-4.367 4.368C18.185 25.464 16.177 26 14 26c-2.177 0-4.185-.537-6.023-1.61a11.945 11.945 0 0 1-4.368-4.367C2.536 18.185 2 16.177 2 14c0-2.177.536-4.185 1.61-6.023a11.946 11.946 0 0 1 4.367-4.368C9.815 2.536 11.823 2 14 2c2.177 0 4.185.536 6.023 1.61a11.945 11.945 0 0 1 4.368 4.367C25.464 9.815 26 11.823 26 14z" />
  </svg>
);
export default SvgTimesCircle;
