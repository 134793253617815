import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgStarFilled = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M20.634 24.996a1.33 1.33 0 0 0 1.28-1.54l-1.1-6.66 4.8-4.75a1.33 1.33 0 0 0-.74-2.27l-6.66-1-3.02-6.04a1.33 1.33 0 0 0-2.38 0l-3.02 6.03-6.66 1.01a1.33 1.33 0 0 0-.73 2.27l4.79 4.75-1.1 6.66a1.33 1.33 0 0 0 1.93 1.4l5.98-3.12 5.98 3.1c.2.1.42.16.65.15v.01z" />
  </svg>
);
export default SvgStarFilled;
