// eslint-disable-next-line
import { gql } from '@apollo/client';
import React from 'react';
import { ListingCardCPODataFragment } from '../gql/graphql';

export const ICON_HEIGHT_SMALL = 16;

export const ListingCardCPOFragment = gql`
  fragment ListingCardCPOData on Listing {
    _id
    id
    certifiedPreOwned {
      title
      badgeIconUrl
    }
  }
`;

interface Props {
  listing: ListingCardCPODataFragment;
}

export function ListingCardCPOSignal({ listing }: Props) {
  const certifiedPreOwned = listing?.certifiedPreOwned;

  if (!certifiedPreOwned) return null;

  return (
    <div className="d-flex fx-align-center gap-half">
      <svg height={ICON_HEIGHT_SMALL} width={ICON_HEIGHT_SMALL}>
        <image href={certifiedPreOwned.badgeIconUrl} height={ICON_HEIGHT_SMALL} />
      </svg>
      <span className="mt-half size-fixed-90">
        {certifiedPreOwned.title}
      </span>
    </div>
  );
}
