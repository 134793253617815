import React from 'react';
import classNames from 'classnames';
import I18n from 'i18n-js';

import { StarEmptyIcon, StarFilledIcon, StarHalfIcon } from '../../icons/react';
import { RCIcon, RCTooltip } from '../';

export interface RCStarRatingProps {
  /** A decimal rating from 0.0 to 1.0. */
  rating: number;

  /** Count of ratings. If this is `0` (not `undefined`), the component won't render. */
  count?: number;

  /** Makes it bigger. Use sparingly; right now we only use this in one spot alongside larger heading text. */
  largeStars?: boolean;

  /** Avoid rendering a tooltip. Use this if you're rendering star ratings inside a clickable element (like a listing card). */
  hideTooltip?: boolean;
}

export function getStarsCount(rating) {
  const ratingOutOf5 = Math.max(0, Math.min(rating, 1.0)) * 5.0;
  const roundedToTheNearestHalf = Math.round(ratingOutOf5 / 0.5) * 0.5;

  const fullStars = Math.floor(roundedToTheNearestHalf);
  const halfStars = roundedToTheNearestHalf > fullStars ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStars;

  return {
    fullStars,
    halfStars,
    emptyStars,
    displayRating: ratingOutOf5.toLocaleString(undefined, { maximumFractionDigits: 1 }),
  };
}

/**
 * Displays a star rating & optional count.
 *
 * If you need the star ratings to be selectable (i.e. part of a form), use `RCStarRatingSelector`.
 */
export function RCStarRating({
  rating,
  count = undefined,
  largeStars = false,
  hideTooltip = false,
}: RCStarRatingProps) {
  const classes = classNames(
    'rc-star-rating',
    { 'rc-star-rating--large': largeStars },
  );

  const {
    fullStars,
    halfStars,
    emptyStars,
    displayRating,
  } = getStarsCount(rating);

  if (count === 0) return null;

  const ratingText = I18n.t('cadence.RCStarRating.ratingText', { rating: displayRating });
  const ratingTextAria = count ?
    I18n.t('cadence.RCStarRating.ariaLabelWithCount', { rating: displayRating, count: Math.round(count) }) :
    I18n.t('cadence.RCStarRating.ariaLabel', { rating: displayRating });

  const starsContainer = (
    <div className="rc-star-rating__stars" aria-label={ratingTextAria} role="img">
      {[...Array(fullStars)].map((_, index) => (
        <RCIcon key={index} svgComponent={() => <StarFilledIcon />} />
      ))}
      {!!halfStars && (
        <RCIcon key={fullStars} svgComponent={() => <StarHalfIcon />} />
      )}
      {[...Array(emptyStars)].map((_, index) => (
        <RCIcon key={fullStars + 1 + index} svgComponent={() => <StarEmptyIcon />} />
      ))}
    </div>
  );

  return (
    <div className={classes}>
      {hideTooltip && starsContainer}
      {!hideTooltip && (
        <RCTooltip text={ratingText} type="plain">
          {starsContainer}
        </RCTooltip>
      )}
      {count !== undefined &&
        <div className="rc-star-rating__count">
          {`(${Math.round(count).toLocaleString()})`}
        </div>
      }
    </div>
  );
}
