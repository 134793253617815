import classNames from 'classnames';
import React from 'react';

import { SEMANTIC_TEXT_COLOR } from '../../design-tokens/build/rc-tokens-web-typescript';

// Note: Size / weight options and defaults need to stay in sync with `mixins/rc-text.scss`

type FontWeights = 'regular' | 'medium' | 'semibold' | 'bold';
type FontSizes = '300' | '350' | '400' | '450' | '500' | '550' | '600' | '700' | '800' | '900' | '1000' | '1200' | '1600';
type AllowedHtmlTags = 'div' | 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type VALID_TEXT_COLORS = typeof SEMANTIC_TEXT_COLOR[number];

const propOptions = {
  default: {
    htmlTags: ['div', 'span', 'p'] as const,
    sizes: ['350', '400'] as const,
    weights: ['regular', 'bold'] as const,
  },
  display: {
    htmlTags: ['div', 'h1'] as const,
    sizes: ['1600'] as const,
    weights: ['regular', 'bold'] as const,
  },
  eyebrow: {
    htmlTags: ['div', 'p', 'span'] as const,
    sizes: ['300'] as const,
    weights: ['regular', 'bold'] as const,
  },
  fine: {
    htmlTags: ['div', 'p', 'span'] as const,
    sizes: ['300'] as const,
    weights: ['regular', 'bold'] as const,
  },
  title: {
    htmlTags: ['div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const,
    sizes: ['500', '600', '700', '800', '900', '1000', '1200'] as const,
    weights: ['regular', 'bold'] as const,
  },
  utility: {
    htmlTags: ['div', 'p', 'span'] as const,
    sizes: ['350', '400', '450', '500', '550', '600'] as const,
    weights: ['regular', 'medium', 'semibold', 'bold'] as const,
  },
};

const propDefaults = {
  default: {
    size: '400',
    weight: 'regular',
  },
  display: {
    size: '1600',
    weight: 'bold',
  },
  eyebrow: {
    size: '300',
    weight: 'bold',
  },
  fine: {
    size: '300',
    weight: 'regular',
  },
  title: {
    size: '900',
    weight: 'bold',
  },
  utility: {
    size: '400',
    weight: 'regular',
  },
};

interface RCTextProps {
  /** Specifies the HTML tag used by the text element. Defaults to 'div'.
   *  Options are limited by text style.
  */
  htmlTag?: AllowedHtmlTags,
  /** Font size. Options are limited by text style. */
  size?: FontSizes,
  /** Font weight. Options are limited by text style. */
  weight?: FontWeights,
  /** Optionally, specify a text color. Inherits color by default. */
  color?: VALID_TEXT_COLORS,
  /** Optionally, pass a numeral to truncate text to the specified number of lines. */
  maxLines?: 1 | 2 | 3 | 4 | 5,
  /** Passes through to the htmlTag. */
  id?: string;
}

type DefaultTextProps = RCTextProps & {
  htmlTag?: Extract<AllowedHtmlTags, typeof propOptions.default.htmlTags[number]>;
  size?: Extract<FontSizes, typeof propOptions.default.sizes[number]>;
  weight?: Extract<FontWeights, typeof propOptions.default.weights[number]>;
};

type DisplayTextProps = RCTextProps & {
  htmlTag?: Extract<AllowedHtmlTags, typeof propOptions.display.htmlTags[number]>;
  size?: Extract<FontSizes, typeof propOptions.display.sizes[number]>;
  weight?: Extract<FontWeights, typeof propOptions.display.weights[number]>;
};

export type EyebrowTextProps = RCTextProps & {
  htmlTag?: Extract<AllowedHtmlTags, typeof propOptions.eyebrow.htmlTags[number]>;
  size?: Extract<FontSizes, typeof propOptions.eyebrow.sizes[number]>;
  weight?: Extract<FontWeights, typeof propOptions.eyebrow.weights[number]>;
};

type FineTextProps = RCTextProps & {
  htmlTag?: Extract<AllowedHtmlTags, typeof propOptions.fine.htmlTags[number]>;
  size?: Extract<FontSizes, typeof propOptions.fine.sizes[number]>;
  weight?: Extract<FontWeights, typeof propOptions.fine.weights[number]>;
};

type TitleTextProps = RCTextProps & {
  htmlTag?: Extract<AllowedHtmlTags, typeof propOptions.title.htmlTags[number]>;
  size: Extract<FontSizes, typeof propOptions.title.sizes[number]>;
  weight?: Extract<FontWeights, typeof propOptions.title.weights[number]>;
};

type UtilityTextProps = RCTextProps & {
  htmlTag?: Extract<AllowedHtmlTags, typeof propOptions.utility.htmlTags[number]>;
  size: Extract<FontSizes, typeof propOptions.utility.sizes[number]>;
  weight?: Extract<FontWeights, typeof propOptions.utility.weights[number]>;
};

/**
 *  Use this component for displaying text. Dot notation variants allow for more specific styling cases:
 *
 *  - RCText (default): Default site text styles. You probably only need this component for nested overrides.
 *  - RCText.**Display**: Extra large responsive text used for page headings. Often uses an `h1` HTML tag.
 *  - RCText.**Eyebrow**: Small and uppercase text. Often uses a `span` tag.
 *  - RCText.**Fine**: Very small text. Used for disclaimers.
 *  - RCText.**Title**: Large responsive text used for headings within a page layout. Often uses an `h1 - h6` HTML tag.
 *  - RCText.**Utility**: Flexible size and weight options with a shorter line height. Especially useful in UI compositions.
 */
export function RCText({
  htmlTag = 'div',
  size = '400',
  weight = 'regular',
  color = undefined,
  maxLines = undefined,
  id = undefined,
  children,
}: React.PropsWithChildren<DefaultTextProps>) {
  const Tag = htmlTag as React.ElementType;
  const styleOverrides = {
    ...colorOverride(color),
  } as React.CSSProperties;

  return (
    <Tag
      id={id}
      className={getClassnames('default', size, weight, maxLines)}
      style={styleOverrides}
    >
      {children}
    </Tag>
  );
}

export function RCTextDisplay({
  htmlTag = 'div',
  size = '1600',
  weight = 'bold',
  color = undefined,
  maxLines = undefined,
  id = undefined,
  children,
}: React.PropsWithChildren<DisplayTextProps>) {
  const Tag = htmlTag as React.ElementType;
  const styleOverrides = {
    ...colorOverride(color),
  } as React.CSSProperties;

  return (
    <Tag
      id={id}
      className={getClassnames('display', size, weight, maxLines)}
      style={styleOverrides}
    >
      {children}
    </Tag>
  );
}

function RCTextEyebrow({
  htmlTag = 'div',
  size = '300',
  weight = 'bold',
  color = undefined,
  maxLines = undefined,
  id = undefined,
  children,
}: React.PropsWithChildren<EyebrowTextProps>) {
  const Tag = htmlTag as React.ElementType;
  const styleOverrides = {
    ...colorOverride(color),
  } as React.CSSProperties;

  return (
    <Tag
      id={id}
      className={getClassnames('eyebrow', size, weight, maxLines)}
      style={styleOverrides}
    >
      {children}
    </Tag>
  );
}

function RCTextFine({
  htmlTag = 'div',
  size = '300',
  weight = 'regular',
  color = undefined,
  maxLines = undefined,
  id = undefined,
  children,
}: React.PropsWithChildren<FineTextProps>) {
  const Tag = htmlTag as React.ElementType;
  const styleOverrides = {
    ...colorOverride(color),
  } as React.CSSProperties;

  return (
    <Tag
      id={id}
      className={getClassnames('fine', size, weight, maxLines)}
      style={styleOverrides}
    >
      {children}
    </Tag>
  );
}

export function RCTextTitle({
  htmlTag = 'div',
  size = '900',
  weight = 'bold',
  color = undefined,
  maxLines = undefined,
  id = undefined,
  children,
}: React.PropsWithChildren<TitleTextProps>) {
  const Tag = htmlTag as React.ElementType;
  const styleOverrides = {
    ...colorOverride(color),
  } as React.CSSProperties;

  return (
    <Tag
      id={id}
      className={getClassnames('title', size, weight, maxLines)}
      style={styleOverrides}
    >
      {children}
    </Tag>
  );
}

export function RCTextUtility({
  htmlTag = 'div',
  size = '400',
  weight = 'regular',
  color = undefined,
  maxLines = undefined,
  id = undefined,
  children,
}: React.PropsWithChildren<UtilityTextProps>) {
  const Tag = htmlTag as React.ElementType;
  const styleOverrides = {
    ...colorOverride(color),
  } as React.CSSProperties;

  return (
    <Tag
      id={id}
      className={getClassnames('utility', size, weight, maxLines)}
      style={styleOverrides}
    >
      {children}
    </Tag>
  );
}

function getClassnames(variant, size, weight, maxLines) {
  return classNames(
    `text-${variant}-${size}`,
    {
      [`text-${variant}--${weight}`]: weight !== propDefaults[variant].weight,
      [`text-truncate-${maxLines}`]: maxLines !== undefined,
    },
  );
}

function colorOverride(color: VALID_TEXT_COLORS) {
  return color === undefined ? {} : { 'color': `var(--${color})` };
}

RCText.Display = RCTextDisplay;
RCText.Eyebrow = RCTextEyebrow;
RCText.Fine = RCTextFine;
RCText.Title = RCTextTitle;
RCText.Utility = RCTextUtility;


// Used by Storybook

interface validOutputProps {
  [variant: string]: {
    htmlTags: readonly string[],
    sizes: readonly string[],
    weights: readonly string[],
  }
}

const outputProps = propOptions as validOutputProps;

const combinedVariants = Object.keys(outputProps).map(variant => ({
  [variant]: {
    id: variant,
    weights: Array.prototype.reverse.call(outputProps[variant].weights),
    sizes: Array.prototype.reverse.call(outputProps[variant].sizes),
    defaultWeight: propDefaults[variant].weight,
    defaultSize: propDefaults[variant].size,
  },
}));

// data object for storybook
export const TextVariantProperties = combinedVariants.reduce((output, variant) => ({ ...output, ...variant }), {});
