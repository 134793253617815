import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

import { EyeIcon, EyeSlashIcon } from '../../../icons/react';
import { RCIcon, RCFormGroup } from '../../';
import { CommonInputProps } from '../../types';

interface Props extends Omit<CommonInputProps, 'id'> {
  /** Unique ID passed through the the applicable HTML tags */
  id: string;

  /** Hint to browser about the content of the field. See https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values */
  autoComplete?: RCPasswordAutocomplete;

  /** called when the input loses focus. */
  onBlur?: React.FocusEventHandler<HTMLInputElement>;

  /** Current value of the input */
  value: string;

  /** Text that appears when the input is empty. */
  placeholder?: string;
}

export type RCPasswordAutocomplete = 'current-password' | 'new-password';

/**
 * Specialized version of `RCFormGroup` with a password visibility toggle.
 */
export function RCPasswordInput({
  autoComplete = undefined,
  disabled,
  errorText,
  helpText,
  id,
  label,
  name,
  onBlur = undefined,
  onChange,
  placeholder = undefined,
  required,
  tag,
  value,
}: Props) {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <RCFormGroup
      errorText={errorText}
      helpText={helpText}
      inputId={id}
      label={label}
      tag={tag}
    >
      <div
        className={classNames(
          'rc-password-input',
          { 'rc-password-input--shown': showPassword },
        )}
      >
        <input
          autoComplete={autoComplete}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          type={showPassword ? 'text' : 'password'}
          value={value}
        />
        <button
          className="rc-password-input__toggle"
          onClick={() => setShowPassword(!showPassword)}
          type="button"
          title={showPassword ?
            I18n.t('cadence.RCPasswordInput.hidePassword') :
            I18n.t('cadence.RCPasswordInput.showPassword')
          }
        >
          {showPassword ?
            <RCIcon svgComponent={EyeSlashIcon} /> :
            <RCIcon svgComponent={EyeIcon} />
          }
        </button>
      </div>
    </RCFormGroup>
  );
}
