import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgCheckCircle = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M22.063 11.469a.973.973 0 0 0-.282-.719L20.36 9.344a.961.961 0 0 0-.703-.297.961.961 0 0 0-.703.297l-6.375 6.36-3.531-3.532a.961.961 0 0 0-.703-.297.961.961 0 0 0-.703.297L6.22 13.578a.973.973 0 0 0-.282.719c0 .281.094.515.282.703l5.656 5.656a.96.96 0 0 0 .703.297.98.98 0 0 0 .719-.297l8.484-8.484a.954.954 0 0 0 .282-.703zM26 14c0 2.177-.537 4.185-1.61 6.023a11.945 11.945 0 0 1-4.367 4.368C18.185 25.464 16.177 26 14 26c-2.177 0-4.185-.537-6.023-1.61a11.945 11.945 0 0 1-4.368-4.367C2.536 18.185 2 16.177 2 14c0-2.177.536-4.185 1.61-6.023a11.946 11.946 0 0 1 4.367-4.368C9.815 2.536 11.823 2 14 2c2.177 0 4.185.536 6.023 1.61a11.945 11.945 0 0 1 4.368 4.367C25.464 9.815 26 11.823 26 14z" />
  </svg>
);
export default SvgCheckCircle;
