import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgExpressTruck = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={28}
    viewBox="0 0 27 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#a)">
      <path d="M8.662 21a4.375 4.375 0 1 1 0-1.75h3.675a4.38 4.38 0 0 1 3.413-3.412V7H2.625a.875.875 0 0 1 0-1.75h14a.875.875 0 0 1 .875.875V8.75H21A5.25 5.25 0 0 1 26.25 14v6.125a.875.875 0 0 1-.875.875h-4.462a4.375 4.375 0 0 1-8.575 0H8.663zM17.5 10.5v5.338a4.38 4.38 0 0 1 3.413 3.412H24.5V14a3.5 3.5 0 0 0-3.5-3.5h-3.5zm-.875 12.25a2.625 2.625 0 1 0 0-5.25 2.625 2.625 0 0 0 0 5.25zm-12.25 0a2.625 2.625 0 1 0 0-5.25 2.625 2.625 0 0 0 0 5.25zm-3.5-14h5.25a.875.875 0 0 1 0 1.75H.875a.875.875 0 0 1 0-1.75zm1.75 3.5h5.25a.875.875 0 0 1 0 1.75h-5.25a.875.875 0 0 1 0-1.75z" />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h26.25v28H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgExpressTruck;
