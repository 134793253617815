import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgQuickResponder = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={40}
    height={34}
    viewBox="0 0 40 34"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M23.75 12.2c-1.13 0-2.18.2-3.15.57-.97.38-1.75.9-2.32 1.54a3.09 3.09 0 0 0-.87 2.1c0 .62.2 1.2.6 1.76.39.57.94 1.04 1.65 1.46l.71.41-.22.8c-.12.5-.31.97-.57 1.42.83-.35 1.59-.82 2.26-1.4l.35-.32.47.06a8.56 8.56 0 0 0 4.22-.5c.97-.39 1.75-.9 2.32-1.55.59-.65.88-1.4.88-2.14 0-.75-.29-1.46-.86-2.1a6.121 6.121 0 0 0-2.32-1.54 8.632 8.632 0 0 0-3.15-.57Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35 9.85v14.3a2.91 2.91 0 0 1-1.38 2.37l-12.26 7.14a2.9 2.9 0 0 1-2.73 0L6.36 26.52C5.58 26.06 5 25.03 5 24.15V9.85a2.96 2.96 0 0 1 1.36-2.37L18.64.34a2.9 2.9 0 0 1 2.73 0l12.27 7.14c.78.46 1.36 1.49 1.36 2.37Zm-7.53 11.16a9.549 9.549 0 0 1-4.9.64 9.09 9.09 0 0 1-4.72 2.17.39.39 0 0 1-.4-.32c-.02-.03-.02-.05 0-.1l.02-.09c0-.01 0-.05.03-.08l.1-.14.07-.07.26-.28.28-.3.26-.34.26-.41c.057-.097.113-.234.175-.383l.045-.107.048-.128c.06-.155.123-.32.162-.492a5.97 5.97 0 0 1-2.04-1.82 3.98 3.98 0 0 1-.75-2.32c0-.95.33-1.83 1-2.65a6.65 6.65 0 0 1 2.67-1.92 9.552 9.552 0 0 1 3.7-.7 9.6 9.6 0 0 1 3.71.7 6.651 6.651 0 0 1 2.69 1.92c.65.82.98 1.7 1 2.65 0 .95-.35 1.84-.99 2.65a6.65 6.65 0 0 1-2.68 1.92ZM8 12h6v1H8v-1Zm2 4h4v1h-4v-1Zm-1 3h5v1H9v-1Z"
    />
  </svg>
);
export default SvgQuickResponder;
