import React from 'react';
import classNames from 'classnames';

import { RCIcon } from '../';

interface IProps {
  /**
   * Import the SVG with `?component` in the import path, and pass it through here.
   */
  svgComponent: (props: React.SVGProps<SVGElement>) => React.ReactElement; // a specific svg

  /**
   * Text or html (must be inline only, like `<span>` or `<strong>`) to render next to the icon.
   */
  children: React.ReactNode;

  /**
   * Positioning of the icon relative to the text.
   */
  placement?: 'right' | 'left',

  /**
   * For accessibility. Leave this blank for decorative icons, but add a descriptive verb for interactive icons.
  */
  title?: string;

  /** Size of the icon. Defaults to current font size. `large` bumps the icon up to 125%. */
  size?: 'default' | 'large',
}

/**
 * Renders an `RCIcon` inline with text, spaced appropriately. Often used within an `RCButton`.
 */
export function RCTextWithIcon({
  svgComponent,
  children,
  title = '',
  placement = 'right',
  size = 'default',
}: IProps) {
  const iconClasses = classNames(
    'rc-text-with-icon__icon', {
      'rc-text-with-icon__icon--right': placement === 'right',
      'rc-text-with-icon__icon--left': placement === 'left',
      'rc-text-with-icon__icon--large': size === 'large',
    },
  );

  const icon = (
    <span className={iconClasses}>
      <RCIcon title={title} svgComponent={svgComponent} inline />
    </span>
  );
  return (
    <>
      {(placement === 'left') && icon}
      {children}
      {(placement === 'right') && icon}
    </>
  );
}
