import StringUtil from './string_util';

export function camelizeKeys(hash = {}) {
  const newHash = {};

  Object.keys(hash).forEach((key) => {
    const camelizedKey = StringUtil.underscoreToCamelCase(key);
    const value = hash[key];
    newHash[camelizedKey] = value;
  });

  return newHash;
}

export function underscoreKeys(hash = {}) {
  const newHash = {};

  Object.keys(hash).forEach((key) => {
    const underscoredKey = StringUtil.camelCaseToUnderscore(key);
    const value = hash[key];
    newHash[underscoredKey] = value;
  });

  return newHash;
}

export function rejectBlankValues(hash = {}) {
  const newHash = {};

  Object.keys(hash).forEach((key) => {
    const value = hash[key];

    if (value !== '' && value !== undefined && value !== null) {
      newHash[key] = value;
    }
  });

  return newHash;
}

export default {
  camelizeKeys,
  underscoreKeys,
  rejectBlankValues,
};
