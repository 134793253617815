export const VALID_DEPLOY_ENVS = ['production', 'unstable', 'stable', 'sandbox', 'tilt', 'test', 'integration_tests', 'tilt_production'] as const;
export type DeployEnvironment = typeof VALID_DEPLOY_ENVS[number];

export type Target = 'browser' | 'node';
export type NodeEnvValue = 'production' | 'development';

/**
 * Variables based on deploy environment. A few naming conventions:
 * - Use `_ORIGIN` suffix (not _HOST, _URL, etc) when the value is guaranteed to be
 *   scheme+hostname(+port) and nothing else.
 * - Use `_BASE_URL` when the value contains an origin but may also end with a `/path` segment
 * - These values are shared across apps, so choose names that are context-independent.
 *   e.g. WEB_ORIGIN is ambiguous since it means something different in cms-ui and reverb-ui.
 */
export interface DeployEnvSettings {
  ADYEN_CLIENT_KEY: string;
  ADYEN_ENVIRONMENT: string;
  AFFIRM_PUBLIC_API_KEY: string;
  AFFIRM_SCRIPT_URL: string;
  AFFIRM_GLOBAL_PUBLIC_API_KEY: string;
  AMAZON_S3_SERVER: string;
  CLOUDINARY_UPLOAD_URL: string;
  CMS_ADMIN_URL: string;
  /** Origin for (non-graphql) Reverb API */
  CORE_API_ORIGIN: string;
  /** Origin for Reverb website */
  CORE_WEB_ORIGIN: string;
  /** URL for Echidna, the Product Catalog team's admin UI */
  ECHIDNA_BASE_URL: string;
  ENABLE_CRITEO: boolean;
  ENABLE_EPPO: boolean;
  /** Enable DataDog's Real User Monitoring instrumentation? */
  ENABLE_RUM: boolean;
  ENABLE_SENTRY: boolean;
  EPPO_API_KEY: string;
  GOOGLE_PAY_ENVIRONMENT: 'TEST' | 'PRODUCTION';
  GOOGLE_RECAPTCHA_SITE_KEY: string;
  MPARTICLE_ENV: 'production' | 'staging';
  PAYPAL_CLIENT_ID: string;
  RQL_BASE_URL: string;
  GQL_GATEWAY_BASE_URL: string;
  ACCERTIFY_DEVICE_JS_URL: string;
  ROKT_ACCOUNT_ID: string;
  ROKT_SANDBOX: boolean;
}
