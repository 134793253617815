import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgSearchAlt = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="m10.29 20.782-4.254 4.255h.007a1.947 1.947 0 1 1-2.753-2.754l4.216-4.246A1.945 1.945 0 0 1 9.59 17.6l1.758-1.758a8.073 8.073 0 1 1 1.096 1.097l-1.719 1.727a1.944 1.944 0 0 1-.435 2.115zm2.746-14.7a6.533 6.533 0 0 0 0 9.24l-.032-.008a6.541 6.541 0 0 0 9.272.008 6.533 6.533 0 0 0-9.24-9.24z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSearchAlt;
