import React, { useContext } from 'react';
import classNames from 'classnames';

interface ColSpanProps {
  desktop: number;
  tablet?: number;
  mobile?: number;
}

interface RowSpanProps {
  desktop?: number | 'all' | 'auto';
  tablet?: number | 'auto';
  mobile?: number | 'auto';
}

interface OrderProps {
  desktop?: number;
  tablet?: number;
  mobile?: number;
}

interface SharedItemSubgridProps {
  colSpan: ColSpanProps;
  rowSpan?: RowSpanProps;
}

interface RCGridProps {
  singleRowOn?: 'all' | 'tablet' | 'mobile';
  children: React.ReactElement<ItemProps> | React.ReactElement<ItemProps>[];
  disableAndPulse?: boolean;
  flexboxItems?: boolean;
}

const COLUMN_COUNT = 12;
const GridContext = React.createContext<ColSpanProps>({
  desktop: COLUMN_COUNT,
});

export const useGridContext = () => {
  const context = useContext(GridContext);
  return context;
};

export function RCGrid({
  children,
  singleRowOn = undefined,
  disableAndPulse = false,
  flexboxItems = true,
}: RCGridProps) {

  // Infer the number of columns available to this grid instance
  // Conditionally overrides default column counts via CSSProperties
  const context = useGridContext();
  const propertyOverrides = {
    ...(context.desktop !== COLUMN_COUNT ? { '--cols-desktop': `${context.desktop}` } : {}),
    ...(context.tablet ? { '--cols-tablet': `${context.tablet}` } : {}),
    ...(context.mobile ? { '--cols-mobile': `${context.mobile}` } : {}),
  } as React.CSSProperties;

  return (
    <div
      style={propertyOverrides}
      className={classNames(
        'rc-grid',
        {
          'rc-grid--single-row-on-all': singleRowOn === 'all',
          'rc-grid--single-row-on-tablet': singleRowOn === 'tablet',
          'rc-grid--single-row-on-mobile': singleRowOn === 'mobile',
          'rc-grid--disable-and-pulse': disableAndPulse,
          'rc-grid--flexbox-items': flexboxItems,
        },
      )}
    >
      {children}
    </div>
  );
}

function Spacer({ ...props }: SharedItemSubgridProps) {
  return (
    <GridItem {...props}>
      <div data-empty-grid-item />
    </GridItem>
  );
}

interface ItemProps extends SharedItemSubgridProps {
  children: React.ReactNode;
  hideOn?: 'desktop' | 'tablet' | 'mobile' | 'tabletAndDesktop' | 'tabletAndMobile';
  order?: OrderProps;
}

export function GridItem({
  colSpan,
  rowSpan = {},
  order = {},
  children,
  hideOn = undefined,
}: ItemProps) {

  const desktopOrder = order?.desktop ? { '--item-order-d': order.desktop } : {};
  const tabletOrder = order?.tablet ? { '--item-order-t': order.tablet } : {};
  const mobileOrder = order?.mobile ? { '--item-order-m': order.mobile } : {};

  const orderPropStyles = {
    ...desktopOrder,
    ...tabletOrder,
    ...mobileOrder,
  } as React.CSSProperties;

  return (
    <GridContext.Provider value={colSpan}>
      <div
        className={classNames(
          'rc-grid-item',
          `rc-grid-item--col-d-${colSpan.desktop}`, // Desktop is the only required value here
          colSpan.tablet && `rc-grid-item--col-t-${colSpan.tablet}`,
          colSpan.mobile && `rc-grid-item--col-m-${colSpan.mobile}`,
          rowSpan.desktop && `rc-grid-item--row-d-${rowSpan.desktop}`,
          rowSpan.tablet && `rc-grid-item--row-t-${rowSpan.tablet}`,
          rowSpan.mobile && `rc-grid-item--row-m-${rowSpan.mobile}`,
          {
            'rc-grid-item--hide-d': hideOn === 'desktop',
            'rc-grid-item--hide-t': hideOn === 'tablet',
            'rc-grid-item--hide-m': hideOn === 'mobile',
            'rc-grid-item--hide-t-and-d': hideOn === 'tabletAndDesktop',
            'rc-grid-item--hide-t-and-m': hideOn === 'tabletAndMobile',
          },
        )}
        style={orderPropStyles}
      >
        {children}
      </div>
    </GridContext.Provider>
  );
}

RCGrid.Item = GridItem;
RCGrid.Spacer = Spacer;
