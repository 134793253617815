export const BRAND_NEW_CONDITION_UUIDS = Object.freeze([
  '7c3f45de-2ae0-4c81-8400-fdb6b1d74890', // brand-new
]);

export function isCoreBrandNew(conditionUuid) {
  return BRAND_NEW_CONDITION_UUIDS.indexOf(conditionUuid) > -1;
}

export const NON_FUNCTIONING = 'non-functioning';
export const POOR = 'poor';
export const FAIR = 'fair';
export const GOOD = 'good';
export const VERY_GOOD = 'very-good';
export const EXCELLENT = 'excellent';
export const MINT = 'mint';
export const MINT_INVENTORY = 'mint-inventory';
export const B_STOCK = 'b-stock';
export const BRAND_NEW = 'brand-new';
export const NEW_GROUP = 'new';
export const USED_GROUP = 'used';

export const CONDITION_GROUPS = {
  new: NEW_GROUP,
  used: USED_GROUP,
};

export const INVENTORIABLE_CONDITIONS = [
  BRAND_NEW,
  MINT_INVENTORY,
  B_STOCK,
];

export const PRICE_GUIDE_TOOL_CONDITIONS = [
  MINT,
  EXCELLENT,
  VERY_GOOD,
  GOOD,
  FAIR,
  POOR,
];

export const PGT_CSP_MODULE_CONDITIONS = [
  MINT,
  EXCELLENT,
  VERY_GOOD,
  GOOD,
];

export const GEAR_COLLECTION_CONDITIONS = [
  NON_FUNCTIONING,
  POOR,
  FAIR,
  GOOD,
  VERY_GOOD,
  EXCELLENT,
  MINT,
];

export const CATEGORY_UNDER_PRICE_CONDITIONS = [
  FAIR,
  GOOD,
  VERY_GOOD,
  EXCELLENT,
  MINT,
];

export const CONDITION_SLUGS_TO_UUID = Object.freeze({
  [NON_FUNCTIONING]: 'fbf35668-96a0-4baa-bcde-ab18d6b1b329',
  [POOR]: '6a9dfcad-600b-46c8-9e08-ce6e5057921e',
  [FAIR]: '98777886-76d0-44c8-865e-bb40e669e934',
  [GOOD]: 'f7a3f48c-972a-44c6-b01a-0cd27488d3f6',
  [VERY_GOOD]: 'ae4d9114-1bd7-4ec5-a4ba-6653af5ac84d',
  [EXCELLENT]: 'df268ad1-c462-4ba6-b6db-e007e23922ea',
  [MINT]: 'ac5b9c1e-dc78-466d-b0b3-7cf712967a48',
  [MINT_INVENTORY]: '6db7df88-293b-4017-a1c1-cdb5e599fa1a',
  [B_STOCK]: '9225283f-60c2-4413-ad18-1f5eba7a856f',
  [BRAND_NEW]: '7c3f45de-2ae0-4c81-8400-fdb6b1d74890',
});

export function conditionUuidForSlug(conditionSlug) {
  return CONDITION_SLUGS_TO_UUID[conditionSlug];
}

export function conditionSlugForUuid(conditionUuid: string) {
  return Object.keys(CONDITION_SLUGS_TO_UUID).find(slug => CONDITION_SLUGS_TO_UUID[slug] === conditionUuid);
}
