import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgEye = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M26 14.333c-2.672 4.278-6.984 7.26-12 7.26s-9.328-2.982-12-7.26c1.484-2.382 3.516-4.423 5.953-5.72A7.407 7.407 0 0 0 7 12.26c0 4.003 3.14 7.26 7 7.26s7-3.257 7-7.26c0-1.28-.328-2.544-.953-3.646 2.437 1.297 4.469 3.338 5.953 5.72ZM14.75 8.111c0 .421-.344.778-.75.778-1.781 0-3.25 1.523-3.25 3.37 0 .422-.344.778-.75.778s-.75-.356-.75-.778c0-2.706 2.14-4.926 4.75-4.926.406 0 .75.357.75.778ZM28 14.333c0-.405-.125-.777-.313-1.118C24.813 8.322 19.547 5 14 5 8.453 5 3.187 8.322.312 13.215c-.187.34-.312.713-.312 1.118 0 .405.125.778.313 1.118 2.874 4.894 8.14 8.216 13.687 8.216 5.547 0 10.813-3.306 13.688-8.216.187-.34.312-.713.312-1.118Z" />
  </svg>
);
export default SvgEye;
