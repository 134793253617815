//
// README - Beta Component
//
// This component is marked "beta" and will go through some iterations
// before landing at a final solution. Please reach out to the Cadence team
// if you intend to use this in the meantime and we'll help you out!
//

import React, { useRef } from 'react';
import classNames from 'classnames';
import { useId } from '@floating-ui/react';

import { RCText } from '..';
import { RCModalBase, RCModalBaseProps } from '../internalComponents/RCModalBase/RCModalBase';
import { RCModalActionGroup, RCModalActionGroupProps } from '../internalComponents/RCModalActionGroup/RCModalActionGroup';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';

export interface RCDrawerProps {
  /** Optionally provide a trigger to have the drawer handle onClick behavior and state changes. */
  trigger?: RCModalBaseProps['trigger'];
  /** Can be used to control the state of the modal when paired with `onOpenChange`. */
  isOpen?: RCModalBaseProps['isOpen'];
  /** Can be used to control the state of the modal when paired with `isOpen`.
   *  Alternatively, can be used as a callback to track state changes.
  */
  onOpenChange?: RCModalBaseProps['onOpenChange'];
  /** The title of the drawer to be rendered in the header. */
  title: string;
  /** Actions to be appended at the bottom of the drawer, below the scrollable content area. Uses `RCModalActionGroup` */
  actions?: RCModalActionGroupProps;
  /** Defaults to `true`. Controls whether an overlay is rendered and whether outside clicks will close the drawer.*/
  isModal?: boolean;
  /** Defaults to `true`. Controls whether the drawer stays mounted to the DOM or is removed on close.*/
  keepMounted?: boolean;
}

/**
 * a toggled panel with standardized header & actions that overlays the viewport while staying attached to one side. At mobile widths, the drawer covers the entire viewport.
 *
 * Use a drawer in place of a modal if you have long sidebar content that you'd like to display without it taking up screen real estate (this component is essentially tailor-made for filter UI).
 */
export function RCDrawer({
  trigger = undefined,
  isOpen = undefined,
  onOpenChange = undefined,
  title = undefined,
  actions = undefined,
  isModal = true,
  children = undefined,
  keepMounted = true,
}: React.PropsWithChildren<RCDrawerProps>) {
  const closeButtonRef = useRef(null);

  const dialogId = useId();
  const titleId = `${dialogId}-title`;
  return (
    <RCModalBase
      trigger={trigger}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      aria-labelledby={titleId}
      initialFocus={closeButtonRef}
      role="dialog"
      position="left"
      displayStyle="drawer"
      isModal={isModal}
      useDismissProps={isModal ? undefined : { enabled: false }}
      keepMounted={keepMounted}
    >
      <div className="rc-drawer">
        <div className="rc-drawer__header" id={titleId}>
          <RCText.Title size="600">
            {title}
          </RCText.Title>
          <RCModalBase.CloseButton ref={closeButtonRef} />
        </div>
        <RCDrawerBody>{children}</RCDrawerBody>
        {actions &&
          <div className="rc-drawer__footer">
            <RCModalActionGroup {...actions} />
          </div>
        }
      </div>
    </RCModalBase>
  );
}

function RCDrawerBody({ children }) {
  const observerTop = useIntersectionObserver();
  const observerBottom = useIntersectionObserver();

  return (
    <div
      className={classNames(
        'rc-drawer__body',
        { 'rc-drawer__body--shadow-top': observerTop?.entry && !observerTop.entry.isIntersecting },
        { 'rc-drawer__body--shadow-bottom': observerBottom?.entry && !observerBottom.entry.isIntersecting },
      )}
    >
      <div className="rc-drawer__body__content">
        <div ref={observerTop?.setTrackedRef} data-observer />
        {children}
        <div ref={observerBottom?.setTrackedRef} data-observer />
      </div>
    </div>
  );
}
