import React from 'react';

import { RCFormGroup } from '../../';
import { CommonInputProps, CustomTagProps } from '../../types';
import { RadioImpl } from './RadioImpl';

export interface GroupProps extends Omit<CommonInputProps, 'id' | 'name' | 'onChange' | 'inputId' | 'large'> {
  /** Unique ID passed through the the applicable HTML tags */
  id: string;

  /** Unique ID passed through the the applicable HTML tags */
  name: string;

  /** Passed through to the applicable HTML tag. */
  onChange: React.ChangeEventHandler<HTMLInputElement>;

  /** All children must be `RCRadioGroup.Option` instances. */
  children: React.ReactElement<OptionProps>[];

  /** Current value of the input. Passed through to the `textarea` tag. */
  value: string;

  /** When required, a required tag will display, overriding and `tag` prop set */
  required?: boolean;
}

export interface OptionProps {
  label: React.ReactNode;
  sublabel?: React.ReactNode;
  value: string;
  disabled?: boolean;
  customTag?: CustomTagProps;
}

function Option(_props: OptionProps) {
  // Non-rendering component, output is generated by parent RadioGroup
  return null;
}

/**
 * Stylized radio button list element. Use this instead of handwriting `input type="radio"` tags.
 */
export function RCRadioGroup({
  children,
  errorText,
  helpText,
  id,
  label,
  name,
  onChange,
  tag,
  value,
  required = false,
}: GroupProps) {
  const checkableGroupID = `rc-radio-group--${id}`;

  function tagType() {
    if (required) return 'required';

    return tag;
  }

  return (
    <RCFormGroup
      errorText={errorText}
      helpText={helpText}
      inputId={id}
      label={label}
      tag={tagType()}
      labelID={checkableGroupID}
    >
      <div
        className="rc-radio-group"
        role="radiogroup"
        aria-labelledby={checkableGroupID}
      >
        {children.filter(Boolean).map(({ props: option }) => (
          // Iterate over <Option> children and render actual implementations
          <RadioImpl
            key={option.value}
            id={`${id}--${option.value}`}
            name={name}
            label={option.label}
            sublabel={option.sublabel}
            value={option.value}
            disabled={option.disabled}
            checked={value === option.value}
            onChange={onChange}
            customTag={option.customTag}
            required={required}
          />
        ))}
      </div>
    </RCFormGroup>
  );
}

RCRadioGroup.Option = Option;
