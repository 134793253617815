import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgAmps = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M9.652 9.326a.926.926 0 1 1-1.85 0 .926.926 0 0 1 1.85 0zm10.345 0a.926.926 0 1 1-1.851 0 .926.926 0 0 1 1.85 0zm-4.379.925a.926.926 0 1 0 0-1.851.926.926 0 0 0 0 1.851z" />
    <path
      fillRule="evenodd"
      d="M7.739 5.701h12.32a2.139 2.139 0 0 1 2.139 2.139v12.32a2.139 2.139 0 0 1-2.139 2.139H7.739A2.139 2.139 0 0 1 5.6 20.16V7.84a2.139 2.139 0 0 1 2.14-2.139zm-.972 5.88H21V7.84a.972.972 0 0 0-.941-.972H7.739a.972.972 0 0 0-.972.972v3.741z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAmps;
