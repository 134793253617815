import React, { ChangeEventHandler } from 'react';
import classNames from 'classnames';

import { RCCheckableLabel, sublabelID } from '../RCCheckbox/RCCheckableLabel';
import type { CustomTagProps, FormattedString } from '../../types';

export interface IRCRadioProps {
  label: FormattedString;
  sublabel?: React.ReactNode;
  name: string;
  id: string;
  value: string;
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  'aria-describedby'?: string;
  iconAlignment?: 'top' | 'center';
  customTag?: CustomTagProps;
  required?: boolean;
}

/**
 * Internal-only component for rendering a radio input.
 * Use <Option> inside RCRadios when you need to render radios
 */
export function RadioImpl({
  'aria-describedby': ariaDescribedBy,
  checked,
  disabled = false,
  id,
  label,
  name,
  onChange,
  sublabel = undefined,
  value,
  iconAlignment = 'top',
  customTag = undefined,
  required = false,
}: IRCRadioProps) {
  const describedBy = [
    sublabel && sublabelID(id),
    ariaDescribedBy,
  ].filter(Boolean).join(' ');

  return (
    <span className={classNames(
      'rc-radio',
      {
        'rc-radio--disabled': disabled,
        'rc-radio--icon-center': iconAlignment === 'center',
      },
    )}>
      <input
        className="rc-radio__input"
        type="radio"
        name={name}
        id={id}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        aria-describedby={describedBy}
        required={required}
      />
      <span className="rc-radio__circle" />
      <RCCheckableLabel
        htmlFor={id}
        label={label}
        sublabel={sublabel}
        customTag={customTag}
        inputId={id}
      />
    </span>
  );
}
