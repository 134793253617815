import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgArrowLeft = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M26 14v2c0 .552-.17 1.023-.508 1.414-.338.39-.779.586-1.32.586h-11l4.578 4.594c.396.375.594.843.594 1.406s-.198 1.031-.594 1.406l-1.172 1.188a1.914 1.914 0 0 1-1.406.578 2 2 0 0 1-1.422-.578L3.578 16.406A1.914 1.914 0 0 1 3 15c0-.542.193-1.016.578-1.422L13.75 3.422a1.94 1.94 0 0 1 1.422-.594c.541 0 1.01.198 1.406.594l1.172 1.156c.396.396.594.87.594 1.422a1.94 1.94 0 0 1-.594 1.422L13.172 12h11c.541 0 .982.195 1.32.586.339.39.508.862.508 1.414z" />
  </svg>
);
export default SvgArrowLeft;
