//
// README - Beta Component
//
// This component is marked "beta" and will go through some iterations
// before landing at a final solution. Please reach out to the Cadence team
// if you intend to use this in the meantime and we'll help you out!
//

import React from 'react';
import { useId } from '@floating-ui/react';
import classNames from 'classnames';

import { RCText, RCIcon } from '../';
import { RCModalBase, RCModalBaseProps } from '../internalComponents/RCModalBase/RCModalBase';
import {
  RCModalActionGroup,
  RCModalActionGroupProps,
} from '../internalComponents/RCModalActionGroup/RCModalActionGroup';

interface RCNoticeProps {
  /** Can be used to control the state of the modal when paired with `onOpenChange`. */
  isOpen?: RCModalBaseProps['isOpen'];
  /** Can be used to control the state of the modal when paired with `isOpen`.
   *  Alternatively, can be used as a callback to track state changes.
  */
  onOpenChange?: RCModalBaseProps['onOpenChange'];
  /** The title of the notice. */
  title: string;
  /** Optionally display an icon next to the notice's content. */
  svgComponent?: (props: React.SVGProps<SVGElement>) => React.ReactElement;
  /** Actions to be appended at the end of the modal. Uses `RCModalActionGroup`. */
  actions?: RCModalActionGroupProps;
}

export function RCNotice({
  isOpen = undefined,
  onOpenChange = undefined,
  title = undefined,
  svgComponent = undefined,
  actions = undefined,
  children = undefined,
}: React.PropsWithChildren<RCNoticeProps>) {
  const dialogId = useId();
  const actionCount = actions ? (actions.primary && actions.secondary ? 2 : 1 ) : 0;

  const titleId = `${dialogId}-title`;
  const descriptionId = `${dialogId}-description`;

  return (
    <RCModalBase
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      aria-labelledby={titleId}
      aria-describedby={descriptionId}
      position="bottom"
      useDismissProps={{
        enabled: false,
      }}
      isModal={false}
    >
      <div className={classNames(
        'rc-notice',
        { 'rc-notice--row': actionCount === 1 },
      )}>
        <div className="rc-notice__main">
          {svgComponent &&
            <div className="rc-notice__icon">
              <RCIcon svgComponent={svgComponent} />
            </div>
          }
          <div className="rc-notice__text">
            <div className="rc-notice__title" id={titleId}>
              <RCText.Title size="600">
                {title}
              </RCText.Title>
            </div>
            <div className="rc-notice__description" id={descriptionId}>
              <RCText>{children}</RCText>
            </div>
          </div>
        </div>
        {actions &&
          <div className="rc-notice__actions">
            <RCModalActionGroup {...actions} />
          </div>
        }
      </div>
    </RCModalBase>
  );
}
