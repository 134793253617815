import Cookies from 'js-cookie';
import UUID from 'uuid';

const COOKIE_NAME = 'elog-analytics-2';
const DEFAULT_DOMAIN = '.reverb.com';
const FIVE_YEARS_IN_DAYS = 1825;

const CONFIGURATION = {
  domain: DEFAULT_DOMAIN,
};

export function configure(options) {
  Object.assign(CONFIGURATION, options);
}

export function trackingID() {
  const cookie = Cookies.getJSON(COOKIE_NAME);

  if (!cookie || !cookie.id) {
    return buildAndSetCookie();
  }

  return cookie.id;
}

export function getSession() {
  const cookie = Cookies.getJSON(COOKIE_NAME);
  if (!cookie) {
    return {};
  }

  return {
    sessionID: cookie.sessionID,
    lastSeen: cookie.lastSeen,
  };
}

export function setSession(ID, lastSeen) {
  Cookies.set(
    COOKIE_NAME,
    {
      lastSeen,
      id: trackingID(),
      sessionID: ID,
    },
    {
      expires: FIVE_YEARS_IN_DAYS,
      domain: CONFIGURATION.domain,
    },
  );
}

function buildAndSetCookie() {
  const cookieID = UUID.v4();

  Cookies.set(
    COOKIE_NAME,
    {
      id: cookieID,
    },
    {
      expires: FIVE_YEARS_IN_DAYS,
      domain: CONFIGURATION.domain,
    },
  );

  return cookieID;
}
