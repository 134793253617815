
import { IUser } from '../components/user_context_provider';
import { IEppoExperimentArgs } from '../user_hooks';
import { IEppoClient } from './eppoClientContext';
import { reverbDeviceInfoProperties } from '../headers/reverbDeviceInfoHeader';
import { reverbUserInfoProperties } from '../headers/reverbUserInfoHeader';

export enum EppoExperimentValues {
  Treatment = 'treatment',
  Control = 'control',
  Fallback = 'fallback',
}

export enum EppoSubjectTypes {
  CLIENT_CONTEXT_ID = 'client_context_id',
  USER_ID = 'user_id',
  SELLER_ID = 'seller_id',
}
interface IGetEppoAssignment extends IEppoExperimentArgs {
  eppoClient: IEppoClient;
  user: Partial<IUser>;
}

export function getEppoAssignment({
  eppoClient,
  user,
  flagKey,
  subjectKey,
  subjectType,
}: IGetEppoAssignment): string {
  const {
    cookieId,
    sessionId,
    shippingRegionCode,
  } = user;

  const userInfo = reverbUserInfoProperties(user);
  const deviceInfo = reverbDeviceInfoProperties(user);

  const expSubjectKey = subjectKey ?? user.cookieId;
  const expSubjectType = subjectType ?? EppoSubjectTypes.CLIENT_CONTEXT_ID;

  return eppoClient.getStringAssignment(
    flagKey,
    expSubjectKey,
    {
      subject_type: expSubjectType,
      shipping_region: shippingRegionCode,
      cookie_id: cookieId,
      reverb_session_id: sessionId,
      ...userInfo,
      ...deviceInfo,
    },
    EppoExperimentValues.Fallback,
  );
}

export function isEppoExpEnabled({
  eppoClient,
  user,
  flagKey,
  subjectKey,
  subjectType,
}: IGetEppoAssignment): boolean {
  return getEppoAssignment({
    eppoClient,
    user,
    flagKey,
    subjectKey,
    subjectType,
  }) === EppoExperimentValues.Treatment;
}
