import React from 'react';
import classNames from 'classnames';

import { RCIcon } from '../';

export interface RCNudgeProps {
  /** The icon to use for the nudge. These should be icons prefixed with a `Nudge` name (e.g. NudgeGemIcon) */
  icon: (props: React.SVGProps<SVGElement>) => React.ReactElement;

  /** The label for the nudge. Should be descriptive and make sense without the icon context for a11y. */
  label: string;

  /** Optionally hide the label and show just the icon */
  hideLabel?: boolean;
}

export function RCNudge({
  icon,
  label,
  hideLabel = false,
}: RCNudgeProps) {
  return (
    <div className={classNames(
      'rc-nudge',
      {
        'rc-nudge--hidden-label': hideLabel,
      },
    )}>
      <div
        className="rc-nudge__icon"
        aria-label={label}
      >
        <RCIcon svgComponent={icon} />
      </div>
      <div
        className="rc-nudge__icon__label"
        aria-hidden
      >
        {label}
      </div>
    </div>
  );
}
