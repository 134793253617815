import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgDesktop = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={28}
    viewBox="0 0 32 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M29 15.5v-13a.48.48 0 0 0-.148-.352A.48.48 0 0 0 28.5 2h-25a.48.48 0 0 0-.352.148A.48.48 0 0 0 3 2.5v13c0 .135.05.253.148.352A.48.48 0 0 0 3.5 16h25a.48.48 0 0 0 .352-.148A.48.48 0 0 0 29 15.5zm2-13v17a2.41 2.41 0 0 1-.734 1.766c-.49.49-1.078.734-1.766.734H20c0 .385.083.79.25 1.21.167.423.333.793.5 1.11.167.318.25.545.25.68 0 .27-.099.505-.297.703A.961.961 0 0 1 20 26h-8a.961.961 0 0 1-.703-.297A.961.961 0 0 1 11 25c0-.146.083-.375.25-.688a11.1 11.1 0 0 0 .5-1.093A3.27 3.27 0 0 0 12 22H3.5a2.407 2.407 0 0 1-1.766-.734A2.407 2.407 0 0 1 1 19.5v-17c0-.688.245-1.276.734-1.766A2.407 2.407 0 0 1 3.5 0h25a2.41 2.41 0 0 1 1.766.734c.49.49.734 1.079.734 1.766z" />
  </svg>
);
export default SvgDesktop;
