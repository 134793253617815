import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgClose = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="m15.671 13.678 8.556-8.555a1.166 1.166 0 1 0-1.665-1.634l-8.299 8.292-8.298-8.292a1.167 1.167 0 0 0-1.65 1.634l8.556 8.555-8.555 8.556a1.166 1.166 0 1 0 1.649 1.633l8.299-8.29 8.298 8.29a1.167 1.167 0 0 0 1.65-1.633l-8.54-8.556z" />
  </svg>
);
export default SvgClose;
