import React from 'react';
import classNames from 'classnames';
import { useTheme } from '../hooks/useTheme';

interface IProps {
  /** passed through to the `<img>` tag. */
  src: string;

  /** Pass a src to a dark-mode-optimized image. The user will see this image if their theme is set to `dark`. */
  darkModeSrc? : string;

  /** passed through to the `<img>` tag. */
  alt?: string;

  /** passed through to the `<img>` tag. */
  title?: string;

  /** sets `<img loading="eager"` instead of `lazy`. */
  eagerLoad?: boolean;
  onLoad?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;

  /** Width / height, e.g. an 4x3 would be 1.33. Defaults to 1 (square). Ignored if `imageBox` is `none` or `auto`. */
  aspect?: number;

  /**
   * By default, an image will render centered inside a div, sized down (but not up) to fit. You can adjust the dimensions of the div by passing an `aspect` value.
   *
   * Set to `fullWidth` for the image to always be full width (even if that requires sizing up).
   *
   * Set to `auto` to return a simple div around an `<img>` with no positioning logic (the div is required for `overlay`).
   *
   * Set to `none` to return the `<img>` tag with no `<div>`.
  **/
  imageBox?: 'default' | 'fullWidth' | 'none' | 'auto';

  /**
   * Renders the image inside of a div that contains an offwhite overlay; if set to `offwhiteWithHover`, on hover, the overlay fades away. Ignored if `imageBox` is `none`.
   *
   * This allows us to render images with white backgrounds as if they're "cut out" onto an offwhite background.
   *
   * By default, hovering over the image will trigger the hover effect here, but if a parent element has the `[data-rc-image-overlay-trigger]` attribute,
   * hovering over that element will trigger the effect. (We use this in `RCProductCard`, for example.)
   */
  overlay?: 'offwhite' | 'offwhiteWithHover';

  /**
   * Optional border radius; these values match the system-wide border radius token values.
   */
  borderRadius?: 'sm' | 'md' | 'lg' | 'xl' | 'full';
}

// lazy loading requires an estimate of the image sizes to load properly when scrolling. Most common size is 200px.
// This is NOT supposed to affect the actual size of the image.
const LAZY_LOADING_SIZE_ESTIMATE = '200';

function getPaddingStyle(aspect, imageBox) {
  if (imageBox === 'auto') return;
  return {
    style: {
      paddingBottom: `${Math.floor(100 / aspect)}%`,
    },
  };
}

/**
* You can use this component in place of a standard image tag.
*
* At its most basic level (`imageBox` set to `none`), it's simply an `<img>` tag with the lazy loading attribute set automatically.
*
* However, oftentimes you'll need an image to load within a fixed-dimension container. (This allows for non-square images to take up a predictably square space, and also prevents jittering/repaints as images load in). See the docs below for the `imageBox` and `aspect` props for this.
*
* This component also allows you to render an image with an offwhite overlay that lightens on hover, which is used for `RCListingCard` and other such places.
*/
export function RCImage({
  src,
  darkModeSrc,
  alt,
  title,
  onLoad,
  aspect = 1,
  imageBox = 'default',
  eagerLoad = false,
  overlay,
  borderRadius,
}: IProps) {
  const loadSpeed = eagerLoad ? 'eager' : 'lazy';
  const { theme } = useTheme();
  const darkMediaMap = {
    light: 'none',
    dark: 'all',
    system: '(prefers-color-scheme: dark)',
  };

  const image = (
    <img
      src={src}
      alt={alt}
      title={title}
      loading={loadSpeed}
      onLoad={onLoad}
      height={LAZY_LOADING_SIZE_ESTIMATE}
      width={LAZY_LOADING_SIZE_ESTIMATE}
    />
  );

  function imageElement() {
    if (darkModeSrc) {
      return (
        <picture>
          <source srcSet={darkModeSrc} media={darkMediaMap[theme]} />
          {image}
        </picture>
      );
    }

    return image;
  }

  // we need a containing div for imageBox or colorOverlay;
  // otherwise we return the img tag by itself
  if (imageBox === 'none') return imageElement();

  const classes = classNames(
    'rc-image',
    { 'rc-image--auto-height': imageBox === 'auto' },
    { 'rc-image--full-width': imageBox === 'fullWidth' },
    { 'rc-image--with-overlay': overlay === 'offwhite' },
    { 'rc-image--with-overlay-hover': overlay === 'offwhiteWithHover' },
    { 'rc-image-border-radius-sm': borderRadius === 'sm' },
    { 'rc-image-border-radius-md': borderRadius === 'md' },
    { 'rc-image-border-radius-lg': borderRadius === 'lg' },
    { 'rc-image-border-radius-xl': borderRadius === 'xl' },
    { 'rc-image-border-radius-full': borderRadius === 'full' },
  );

  return (
    <div
      className={classes}
      {...getPaddingStyle(aspect, imageBox)}
    >
      {imageElement()}
    </div>
  );
}
