import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';
import { RCTooltip } from '@reverbdotcom/cadence/components';
import { BRAND_NEW, MINT_INVENTORY } from '../condition_helpers';

const numBarsToShow = 8;

interface Props {
  slug: string;
  displayName: string;
  showTooltip?: boolean;
  textOnly?: boolean;
  displayAsTag?: boolean;
  isDigitalListing?: boolean;
}

export function conditionDisplayText(slug: string, displayName: string): string {
  if (slug === BRAND_NEW) return displayName;
  return I18n.t('commons.conditionDisplay.used', { condition: displayName });
}

export default class ConditionDisplay extends React.Component<Props, null> {
  get slug() {
    if (this.props.slug === MINT_INVENTORY) {
      return 'mint';
    }

    return this.props.slug;
  }

  renderBars() {
    const bars = [];
    for (let i = 0; i < numBarsToShow; i++) { bars.push(<span key={i}></span>); }
    return bars;
  }

  renderText() {
    return conditionDisplayText(this.slug, this.props.displayName);
  }

  renderLabel() {
    const label = this.props.textOnly ? this.renderText() : this.props.displayName;

    if (!this.props.showTooltip) { return label; }

    return (
      <RCTooltip
        text={I18n.t(`commons.specList.conditions.${this.props.slug}`)}
        type={this.props.textOnly ? 'underline' : 'plain'}
        placement="top"
      >
        {label}
      </RCTooltip>
    );
  }

  render() {
    if (!this.props.displayName) {
      return null;
    }

    if (this.props.isDigitalListing) {
      return null;
    }

    const classes = classNames(
      'condition-display',
      `condition-display--${this.slug}`,
      { 'condition-display--text-only': this.props.textOnly },
      { 'condition-display--tag': this.props.displayAsTag },
    );

    return (
      <div
        className={classes}
      >
        <div className="condition-display__label">
          {this.renderLabel()}
        </div>
        <div className="condition-display__bars">
          {this.renderBars()}
        </div>
      </div>
    );
  }
}
