import * as elog from '.';
import Cookies from 'js-cookie';
import isNode from '../is-node';
import { IUser } from '../components/user_context_provider';
import { experimentInt } from '../user_context_helpers';
import { pick, isEqual } from 'lodash';
import { getAttribution } from '../attribution';
import { getPaidAdData } from '../paid_ads_attribution';

import window from '../window_wrapper';
import { initializeSDK, IdentityCallbackResponse, UserIdentities, Config, IdentityAPIData, MParticleUserInfo } from './mparticle_load';
import { IS_TEST, MPARTICLE_ENV } from '@reverbdotcom/env';

const MAX_CART_ITEMS = 50;
const MAX_QUEUED_EVENTS_SIZE = 50;
const SDK_LOAD_TIMEOUT = IS_TEST ? 0 : 10000;
const MPARTICLE_DEV_MODE = MPARTICLE_ENV === 'staging';

let isTrackable = true;
let mParticleIsReady = false;
let identityIsReady = false;
const queuedEvents: Array<() => void> = [];

const IDENTITY_ATTRIBUTES = Object.freeze([
  'email',
  'customerid',
]);

const IDENTITY_ERROR = 'mparticle identity error';
// httpCode of 200 indicates a successful identity call
// httpCode of -3 indicates there was an active session, so no identity call is made,
// but the callback is still triggered
const IDENTITY_SUCCESS_CODES = Object.freeze([200, -3]);
const MPARTICLE_SDK_ERROR = 'mparticle-sdk-load-error';

const MPARTICLE_USER_INFO_COOKIE = 'reverb_mparticle_user_info';

interface ICustomUserAttributes {
  country: string;
  shippingRegionCode: string;
  locale: string;
  contextId: string;
  sessionId: string;
  currency: string;
  deviceName: string;
  stateCode: string;
}

interface IIdentityAttributes {
  email?: string;
  customerid?: string;
}

interface IUserStateAttributes {
  loggedOut: boolean;
}

type IAllUserAttributes = ICustomUserAttributes & IIdentityAttributes & IUserStateAttributes;

function identifyRequestForReverbUser(user: IAllUserAttributes): IdentityAPIData {
  if (user.loggedOut) {
    return {};
  }

  return {
    userIdentities: pick(user, IDENTITY_ATTRIBUTES),
  };
}

function buildCustomUserAttributes(user) {
  return {
    ...getAttribution(),
    ...getPaidAdData(),
    Country: user.country,
    'Shipping Region Code': user.shippingRegionCode,
    Locale: user.locale,
    Currency: user.currency,
    'Device Name': user.deviceName,
    'State Code': user.stateCode,
  };
}

export const EXPERIMENT_DELIMITER = '|';

export function formatExperimentsForEventAttributes(user: Partial<IUser>, experimentNames: string[]): string {
  const exps = experimentNames.map((expName) => {
    const i = experimentInt(user, expName);
    return `${expName}:${i}`;
  });
  return exps.join(EXPERIMENT_DELIMITER);
}

function buildUser(user: IUser): IAllUserAttributes {
  let emailOverride;
  if (user.email && MPARTICLE_DEV_MODE) {
    const emailPrefix = user.email.split('@')[0];
    emailOverride = `mailtest+${emailPrefix}@reverb.com`;
  }

  return {
    country: user.countryCode,
    email: emailOverride || user.email,
    customerid: user.id && user.id.toString(),
    locale: user.locale,
    loggedOut: user.loggedOut,
    sessionId: user.sessionId,
    shippingRegionCode: user.shippingRegionCode,
    contextId: user.cookieId,
    currency: user.currency,
    deviceName: user.deviceName,
    stateCode: user.stateCode,
  };
}

export function buildConfig(userContext): Config {
  const user = buildUser(userContext);

  return {
    isDevelopmentMode: MPARTICLE_DEV_MODE,
    identifyRequest: identifyRequestForReverbUser(user),
    identityCallback: result => identityCallback(result, user),
    maxProducts: MAX_CART_ITEMS,
  };
}

export function identityCallback(
  initialResult: IdentityCallbackResponse,
  user: IAllUserAttributes,
): void {
  if (!checkIdentitySuccess(initialResult)) {
    removeMParticleCookie();
    return;
  }

  syncLoginIfNeeded(initialResult, user)
    .then((resolvedIdentity) => {
      if (!checkIdentitySuccess(resolvedIdentity)) {
        removeMParticleCookie();
        isTrackable = false;
      } else {
        setMParticleCookie();
        setUserAttributes(resolvedIdentity, user);
      }
      setIdentityReady();
    })
    .catch((reason) => {
      removeMParticleCookie();
      elog.error(IDENTITY_ERROR, { reason }, null, false);
    });
}

function setMParticleCookie() {
  Cookies.set(MPARTICLE_USER_INFO_COOKIE, {
    mpid: window.mParticle.Store.mpid,
    session_id: window.mParticle.Store.sessionId,
    device_id: window.mParticle.Store.deviceId,
  });
}

function removeMParticleCookie() {
  Cookies.remove(MPARTICLE_USER_INFO_COOKIE);
}

export function getMParticleUserInfoCookie(): MParticleUserInfo {
  const mParticleCookie = Cookies.get(MPARTICLE_USER_INFO_COOKIE);
  const fallback = { mpid: null, session_id: null, device_id: null };

  if (!mParticleCookie) {
    return fallback;
  }

  try {
    return JSON.parse(mParticleCookie);
  } catch {
    return fallback;
  }
}

function checkIdentitySuccess(result: IdentityCallbackResponse): boolean {
  const { httpCode, body } = result;

  const success = IDENTITY_SUCCESS_CODES.indexOf(httpCode) >= 0;

  if (!success) {
    elog.info(
      IDENTITY_ERROR,
      {
        httpCode: httpCode === 0 ? 500 : httpCode,
        body,
      },
    );
  }
  return success;
}

function setUserAttributes(identityResult: IdentityCallbackResponse, user: IAllUserAttributes) {
  const mparticleUser = identityResult.getUser();
  const customUserAttributes = buildCustomUserAttributes(user);

  mparticleUser.setUserAttributes(customUserAttributes);
}

function syncLoginIfNeeded(initialResult: IdentityCallbackResponse, user: IAllUserAttributes): Promise<IdentityCallbackResponse> {
  return new Promise<IdentityCallbackResponse>((resolve, reject) => {
    const mpUser = initialResult.getUser();
    const userIdentities = mpUser?.getUserIdentities()?.userIdentities;

    if (!userIdentities) {
      reject(new Error('no mParticle user'));
    }

    if (user.loggedOut && userIdentities.customerid) {
      // we are logged out but mp has a user id cached
      window.mParticle.Identity.logout({}, resolve);
    } else if (shouldSyncLogin(user, userIdentities)) {
      // we are logged in but mp isn't up to date
      window.mParticle.Identity.login(
        identifyRequestForReverbUser(user),
        resolve,
      );
    } else {
      // mparticle is already logged in correctly
      resolve(initialResult);
    }
  });
}

function shouldSyncLogin(user: IAllUserAttributes, userIdentities: UserIdentities) {
  if (user.loggedOut) return false;

  return !isEqual(
    pick(userIdentities, IDENTITY_ATTRIBUTES),
    pick(user, IDENTITY_ATTRIBUTES),
  );
}

function runQueuedEvents() {
  if (!mParticleIsReady || !identityIsReady) { return; }
  if (!userIsTrackable()) { return; }

  while (queuedEvents.length) {
    const eventFn = queuedEvents.shift();
    eventFn();
  }
}

function setIdentityReady() {
  identityIsReady = true;
  runQueuedEvents();
}

function setMParticleReady() {
  mParticleIsReady = true;
  runQueuedEvents();
}

export function mParticleReady(cb: () => void): void {
  if (!userIsTrackable()) {
    return;
  }

  if (mParticleIsReady && identityIsReady) {
    cb();
  } else if (queuedEvents.length < MAX_QUEUED_EVENTS_SIZE) {
    queuedEvents.push(cb);
  }
}

// For testing
export function resetInitialization() {
  if (!IS_TEST) {
    throw new Error('`resetInitialization` can only be used in testing contexts');
  }

  mParticleIsReady = false;
  identityIsReady = false;
  isTrackable = true;
  hasBeenInitialized = false;
  while (queuedEvents.length) {
    queuedEvents.shift();
  }
}

export function userIsTrackable() {
  return !isNode && !!MPARTICLE_ENV && isTrackable;
}

let hasBeenInitialized = false;
export function initializeMParticleSDK() {
  if (!userIsTrackable()) return;
  if (hasBeenInitialized) return;

  hasBeenInitialized = true;

  if (!IS_TEST) {
    initializeSDK();
  }

  // log error if mParticle is not ready in 5 seconds
  setTimeout(
    () => {
      if (!window.mParticle.Store) {
        elog.info(
          MPARTICLE_SDK_ERROR,
          {},
        );
      }
    },

    SDK_LOAD_TIMEOUT,
  );

  window.mParticle.ready(() => {
    setMParticleReady();

    if (window.mParticle.Store?.webviewBridgeEnabled) {
      // We don't receive an identity callback when bridging to native, so immediately let events flow
      setIdentityReady();
    }
  });
}

export function userContextIsTrackable(userContext: Partial<IUser>) {
  if (userContext.isBot || userContext.ghost) {
    return false;
  }
  return true;
}

// Should run as early as possible (before initializeMParticleSDK) to set up
// initial identity request user data
export function configureMParticleSDK(userContext) {
  if (!userContextIsTrackable(userContext)) {
    isTrackable = false;
  }

  if (!userIsTrackable()) return;

  if (!IS_TEST) {
    const updatedConfig = { ...window.mParticle.config, ...buildConfig(userContext) };

    window.mParticle.config = updatedConfig;
  }
}
