import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgQuestionCircle = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M16 21.5v-3a.487.487 0 0 0-.14-.36.487.487 0 0 0-.36-.14h-3a.487.487 0 0 0-.36.14.487.487 0 0 0-.14.36v3a.49.49 0 0 0 .14.36.49.49 0 0 0 .36.14h3a.487.487 0 0 0 .36-.14.487.487 0 0 0 .14-.36zM20 11c0-.917-.29-1.766-.867-2.547a5.918 5.918 0 0 0-2.164-1.812A5.935 5.935 0 0 0 14.312 6c-2.53 0-4.463 1.11-5.796 3.328-.157.25-.115.469.125.656l2.062 1.563a.441.441 0 0 0 .297.094.46.46 0 0 0 .39-.188c.553-.708 1-1.187 1.344-1.437.354-.25.803-.375 1.344-.375.5 0 .945.135 1.336.406.39.27.586.578.586.922 0 .396-.104.713-.313.953-.208.24-.562.474-1.062.703-.656.292-1.258.742-1.805 1.352-.547.609-.82 1.263-.82 1.96v.563a.49.49 0 0 0 .14.36.49.49 0 0 0 .36.14h3a.487.487 0 0 0 .36-.14.487.487 0 0 0 .14-.36c0-.198.112-.456.336-.773a2.64 2.64 0 0 1 .852-.774c.333-.187.588-.336.765-.445.177-.11.417-.292.719-.547.302-.255.534-.505.695-.75.162-.245.307-.56.438-.945.13-.386.195-.808.195-1.266zm6 3c0 2.177-.537 4.185-1.61 6.023a11.945 11.945 0 0 1-4.367 4.368C18.185 25.464 16.177 26 14 26c-2.177 0-4.185-.537-6.023-1.61a11.945 11.945 0 0 1-4.368-4.367C2.536 18.185 2 16.177 2 14c0-2.177.536-4.185 1.61-6.023a11.946 11.946 0 0 1 4.367-4.368C9.815 2.536 11.823 2 14 2c2.177 0 4.185.536 6.023 1.61a11.945 11.945 0 0 1 4.368 4.367C25.464 9.815 26 11.823 26 14z" />
  </svg>
);
export default SvgQuestionCircle;
