import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgPlusCircle = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21 15v-2a.961.961 0 0 0-.297-.703A.961.961 0 0 0 20 12h-4V8a.961.961 0 0 0-.297-.703A.961.961 0 0 0 15 7h-2a.961.961 0 0 0-.703.297A.961.961 0 0 0 12 8v4H8a.961.961 0 0 0-.703.297A.961.961 0 0 0 7 13v2c0 .27.099.505.297.703A.961.961 0 0 0 8 16h4v4c0 .27.099.505.297.703A.961.961 0 0 0 13 21h2c.27 0 .505-.099.703-.297A.961.961 0 0 0 16 20v-4h4c.27 0 .505-.099.703-.297A.961.961 0 0 0 21 15zm5-1c0 2.177-.537 4.185-1.61 6.023a11.945 11.945 0 0 1-4.367 4.368C18.185 25.464 16.177 26 14 26c-2.177 0-4.185-.537-6.023-1.61a11.945 11.945 0 0 1-4.368-4.367C2.536 18.185 2 16.177 2 14c0-2.177.536-4.185 1.61-6.023a11.946 11.946 0 0 1 4.367-4.368C9.815 2.536 11.823 2 14 2c2.177 0 4.185.536 6.023 1.61a11.945 11.945 0 0 1 4.368 4.367C25.464 9.815 26 11.823 26 14z" />
  </svg>
);
export default SvgPlusCircle;
