import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgCart = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M25.52 7H7.26L6.9 3.89C6.68 2.24 5.67 1 4 1H1c-.61 0-1 .39-1 1s.39 1 1 1h3c.537 0 .626.601.704 1.133l.006.037L6.39 17a3.44 3.44 0 0 0 3.33 3h11.72a3.44 3.44 0 0 0 3.31-2.81l1.86-8.76c.07-.32-.02-.76-.23-1.02-.21-.26-.52-.41-.86-.41zM7.55 8.87l1.06 8.02c.08.56.57.99 1.15.98h12.02c.54 0 1.02-.37 1.13-.9l1.64-8.1h-17zm-.89 15.45a2.22 2.22 0 1 0 4.44 0 2.22 2.22 0 0 0-4.44 0zm12.31 0a2.22 2.22 0 1 0 4.44 0 2.22 2.22 0 0 0-4.44 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCart;
