import React, { ReactNode } from 'react';
import classNames from 'classnames';
import I18n from 'i18n-js';

import { ExclamationTriangleIcon } from '../../../icons/react';
import { RCTag, RCTextWithIcon } from '../../';
import type { CommonInputProps } from '../../types';

// `inputId` is a legacy artifact, needs to be refactored away.
export interface RCFormGroupProps extends Omit<CommonInputProps, 'onChange' | 'id' | 'disabled' | 'required' | 'name'> {
  /** Pass in your input here, e.g. `RCTextInput`. */
  children: ReactNode;

  /** Passed through to the input tag. */
  inputId: string;

  /** Large variant. Use this for simpler forms. */
  large?: boolean;

  /** Appends an ID to the <label>. Useful for aria associations or other accessibility-related tasks. */
  labelID?: string;
}

function HelpText({ children }: { children: ReactNode }) {
  return (
    <div className="rc-form-group__help-text">
      {children}
    </div>
  );
}

export function RCFormGroupAlertText({ errorText, id = undefined }: { errorText: string, id?: string }) {
  return (
    <div className="rc-form-group__error" id={id}>
      <RCTextWithIcon svgComponent={ExclamationTriangleIcon} placement="left">
        {errorText}
      </RCTextWithIcon>
    </div>
  );
}

/**
* Renders the presentational code surrounding a given input, and accepts any input as a child.
**Unless you need to use a bespoke input element**, you should probably use one of our prebuilt input components instead (e.g. `RCTextInput`).
**/

export function RCFormGroup({
  children,
  errorText,
  helpText,
  inputId,
  label,
  labelID = undefined,
  large = false,
  tag = 'none',
}: RCFormGroupProps) {
  const classes = classNames(
    'rc-form-group',
    { 'rc-form-group--with-error': !!errorText },
    { 'rc-form-group--large': large },
  );

  function ariaLabel() {
    if (tag === 'none' || tag === 'required') {
      return undefined;
    }

    return `${label} (${tag})`;
  }

  return (
    <div className={classes}>
      <div className="rc-form-group__header">
        <label
          htmlFor={inputId}
          className="rc-form-group__label"
          aria-label={ariaLabel()}
          id={labelID}
        >
          {label}
        </label>
        {tag && tag !== 'none' && (
          <div className="rc-form-group__tag">
            <RCTag variant={tag === 'recommended' ? 'highlight' : 'default'}>
              {I18n.t(`commons.formGroup.${tag}`)}
            </RCTag>
          </div>
        )}
      </div>
      {large && helpText && (
        <HelpText>{helpText}</HelpText>
      )}
      <div className="rc-form-group__fields">
        {children}
      </div>
      {!large && !!helpText && (
        <HelpText>{helpText}</HelpText>
      )}
      {errorText && <RCFormGroupAlertText errorText={errorText} />}
    </div>
  );
}
