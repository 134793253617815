import * as elog from '.';
import { omit, debounce, merge, uniqBy } from 'lodash';
import isNode from '../is-node';
import { isCoreBrandNew } from '../condition_helpers';
import { convertCurrency } from '../convert_currency';
import { Commons_MParticle_ListingQuery, CSPAdditionalTrackingFields } from '../gql/graphql';
import window from '../window_wrapper';
import { rejectBlankValues } from '../cms/lib/hash_util';
import { EN } from '../constants';
import { LOCALE_CONFIGS } from '../money';
import {
  initializeMParticleSDK,
  userIsTrackable,
  configureMParticleSDK,
  mParticleReady,
} from './mparticle';
import { PROD_MODE, MPARTICLE_ENV } from '@reverbdotcom/env';
import {
  EventType,
  MParticleEvent,
  MParticleEventName,
  PurchaseEvent,
  ProductActionEvent,
  PageViewEvent,
  CommonProperties,
  ListingProps,
  MparticleClickedAddToCartEvent,
  MparticleAddToWishlistEvent,
  MparticleRemoveFromWishlistEvent,
  MparticleRemoveFromCartEvent,
  MparticleListingImpressionEvent,
  ClickedListingCardEvent,
  ProductViewActionEvent,
  ProductActionType,
  ViewedCSPComponentEvent,
} from './mparticle_types';
import { matchRoutes } from '../route_settings';
import { getCategoryAttributesForMparticle } from '../csp_category_helper';
import { getDaysSincePublished } from '../date_utils';
import { getReturnPolicyDays } from '../listing_helpers';
import { shouldShowBumpTag } from '../components/bump_tag';

export {
  EventType,
  MParticleEventName,
  MParticlePageName,
  type PageViewEvent,
  ProductActionType,
} from './mparticle_types';

const USD_CURRENCY = 'USD';
export const CTA = 'cta';
export const LISTING_TYPE = 'Listing';
export const CLICK = 'click';

// Custom events have a default handler, they don't need one added here.
const eventHandlers: { [K in MParticleEventName]?: (e: MParticleEvent) => void } = {
  [MParticleEventName.Purchase]: trackPurchase,
  [MParticleEventName.ProductAction]: trackProductAction,
  [MParticleEventName.AddToCart]: trackCustomProductAction,
  [MParticleEventName.RemoveFromCart]: trackCustomProductAction,
  [MParticleEventName.AddToWishlist]: trackCustomProductAction,
  [MParticleEventName.RemoveFromWishlist]: trackCustomProductAction,
  [MParticleEventName.ListingImpression]: trackListingImpressions,
  [MParticleEventName.ProductViewAction]: trackProductViewAction,
};

let exchangeRates = [];
export function initExchangeRates(rates) {
  exchangeRates = rates;
}

type Commons_MParticle_ListingQuery_Listing = Commons_MParticle_ListingQuery['listings'][0];

export function normalizePrice(listing: Commons_MParticle_ListingQuery_Listing): string {
  const { currency, amount } = listing.pricing.buyerPrice;
  if (currency === USD_CURRENCY) return amount;

  try {
    return convertCurrency(
      currency,
      USD_CURRENCY,
      exchangeRates,
      amount,
      LOCALE_CONFIGS[EN],
    ).amount;
  } catch (e) {
    elog.error(
      'mparticle_tracker.convert_currency.error',
      { exchangeRates, currency },
      e,
    );
    return null;
  }
}

// title cases keys and removes null/undefined values
function formatAttributes(source: PlainObject): PlainObject {
  const out = {};
  Object.keys(source).forEach((key) => {
    const firstChar = key[0].toUpperCase();
    const rest = key.slice(1).replace(
      /\B([a-z])([A-Z])/g,
      '$1 $2',
    );

    const value = source[key];

    if (value === null || value === undefined) {
      return;
    }

    out[`${firstChar}${rest}`] = value;
  });

  return out;
}

function normalizeEvent(event: PlainObject) {
  const allProperties: CommonProperties & PlainObject = {
    referringUrl: elog.currentSession().referer(),
    currentUrl: elog.currentSession().url(),
    requestId: elog.currentSession().requestId(),
    cookieId: elog.currentSession().cookieId(),
    reverbSessionId: elog.currentSession().ID(),
    deviceName: elog.currentSession().deviceName(),
    ...event,
  };

  return formatAttributes(allProperties);
}

function trackCustomEvent(data: MParticleEvent) {
  const { eventName, eventType = EventType.Other, listing, ...attrs } = data;
  let listingAttrs = {};

  // dress up the event with product attributes if there is a listing passed in
  if (listing) {
    listingAttrs = {
      ...mParticleListingAttributes(listing),
      brand: listing.brandSlug,
      category: getRootCategorySlug(listing),
      couponCode: listingCouponCode(listing),
      listingTitle: listing.title,
      price: normalizePrice(listing),
    };
  }
  const eventInfo = normalizeEvent({ ...attrs, ...listingAttrs });

  mParticleReady(() => window.mParticle.logEvent(eventName, eventType, eventInfo));
}

function trackCustomProductAction(
  data: MparticleClickedAddToCartEvent | MparticleAddToWishlistEvent | MparticleRemoveFromWishlistEvent | MparticleRemoveFromCartEvent,
): void {
  const { listing, productActionType, position, componentName, source, experiments, currentView } = data;

  const product = createProduct(listing);
  const productActionAttributes = { position, componentName, source, experiments, currentView };
  const customFlags = {};

  mParticleReady(
    () => window.mParticle.eCommerce.logProductAction(
      productActionType,
      product,
      normalizeEvent(rejectBlankValues(productActionAttributes)),
      customFlags,
    ),
  );
}

function trackProductViewAction(
  data: ProductViewActionEvent,
): void {
  const { listing, ...customAttrs } = data;

  trackProductAction(
    {
      listing,
      productActionType: ProductActionType.ViewDetail,
      eventName: MParticleEventName.ProductAction,
    },
    omit(customAttrs, 'eventName'),
  );
}

function buildImpressionProducts(data: MparticleListingImpressionEvent) {
  const {
    listingIds,
    listings,
  } = data;

  if (listings) {
    return listings.map((listing, index) => {
      return createProduct(listing, null, null, index);
    });
  }

  return listingIds.map((id, index) => {
    const defaultPrice = '0';

    return window.mParticle.eCommerce.createProduct(
      `Product Id: ${id}`, // Name
      id, // Id
      defaultPrice, // Price
      null, // Quantity
      null, // Variant
      null, // Category
      null, // Brand
      index, // Position
      null, // CouponCode
      null, // Attributes
    );
  });
}

function trackListingImpressions(data: MparticleListingImpressionEvent): void {
  const {
    componentName,
  } = data;

  const products = buildImpressionProducts(data);

  const impression = window.mParticle.eCommerce.createImpression(
    componentName,
    products,
  );

  mParticleReady(() => window.mParticle.eCommerce.logImpression(impression, {}));
}

interface ProductActionAttributes {
  position?: number;
  componentName?: string;
  displayStyle?: string;
  source?: string;
}

export function trackProductAction(data: ProductActionEvent, productActionAttributes: ProductActionAttributes = {}): void {
  const {
    listing,
    productActionType,
  } = data;

  const product = createProduct(listing);
  const customFlags = {};

  mParticleReady(
    () => window.mParticle.eCommerce.logProductAction(
      productActionType,
      product,
      normalizeEvent(rejectBlankValues(productActionAttributes)),
      customFlags,
    ),
  );
}

function trackPurchase(data: PurchaseEvent): void {
  const { orderBundle } = data;

  const products = orderBundle.orders.nodes.map((order) => {
    return createProduct(order.listing, order.quantity, order.settlementAmountProductSubtotal.amount, null, order.protectionPlanEligible);
  });

  const orderBundleId = orderBundle.id;

  const orderBundleAttributes = {
    Revenue: orderBundle.amountProductSubtotal.amount,
    Id: orderBundleId,
    Tax: orderBundle.amountTax.amount,
    LocalPickup: orderBundle.localPickup,
  };

  const customAttributes = {
    'Checkout Item Count': orderBundle.orders.nodes.length,
    'Checkout Shop Count': uniqBy(orderBundle.orders.nodes, 'shopUuid').length,
    'Checkout Total Price': orderBundle.amountProductSubtotal.amount,
    'Payment Method': orderBundle.paymentMethod,
    'Total Shipping Rate': orderBundle.amountShipping.amount,
    'Report GMS': true,
    'Order Type': orderBundle.orderType,
    'Checkout Type': orderBundle.checkoutType,
  };

  mParticleReady(() =>
    window.mParticle.eCommerce.logPurchase(
      orderBundleAttributes,
      products,
      true, // clears cart
      normalizeEvent(customAttributes),
    ),
  );
}

export function trackCSPView(
  csps: CSPAdditionalTrackingFields.Fragment[],
  componentName: string,
  displayStyle?: string,
  experiments?: string,
): ViewedCSPComponentEvent {
  return {
    componentName,
    displayStyle,
    eventName: MParticleEventName.ViewedCSPComponent,
    cspIds: csps.map(csp => csp.id).join('|'),
    cspsCount: csps.length,
    experiments,
  };
}

export function getRootCategorySlug(listing: Commons_MParticle_ListingQuery_Listing): string {
  const root = listing.categories?.find(c => c && !c.rootSlug);
  return root?.slug || '';
}

export function compositeCategorySlug(params) {
  if (params.category) {
    return `${params.product_type}:${params.category}`;
  }

  return params.product_type;
}

function shippingAttrs(listing: Commons_MParticle_ListingQuery_Listing): Partial<ListingProps> {
  if (!listing.shipping) { return {}; }

  return {
    freeShipping: listing.shipping?.shippingPrices[0]?.rate?.amountCents === 0,
    freeExpeditedShipping: listing.shipping.freeExpeditedShipping,
    localPickup: listing.shipping.localPickup,
    localPickupOnly: !!listing.shipping.localPickupOnly,
  };
}

function mParticleListingAttributes(listing: Commons_MParticle_ListingQuery_Listing, protectionPlanEligible = false): ListingProps {
  return {
    certified: !!listing?.certifiedPreOwned,
    condition: isCoreBrandNew(listing.condition.conditionUuid) ? 'new' : 'used',
    conditionName: listing.condition.conditionSlug,
    subcategories: listing.categories?.map(c => c.slug).join('|'),
    listingId: listing.id,
    listingType: listing.listingType,
    state: listing.state,
    cspId: listing.csp?.id,
    cspSlug: listing.csp?.slug,
    bumpEligible: listing.bumpEligible,
    returnWindowDays: getReturnPolicyDays(listing),
    shownAsBumped: shouldShowBumpTag(listing),
    protectionPlanEligible,
    shopId: listing.shopId,
    inventory: listing.inventory,
    itemLocation: listing.shop?.address?.countryCode,
    daysSincePublished: getDaysSincePublished(listing.publishedAt?.seconds),
    paymentMethodsAvailable: listing.acceptedPaymentMethods?.slice(0).sort().join('|'),
    soldAsIs: listing.soldAsIs,
    isOutlet: listing.usOutlet,
    ...shippingAttrs(listing),
  };
}

function listingCouponCode(listing: Commons_MParticle_ListingQuery_Listing): string {
  const { sale } = listing;

  return (sale?.code && !sale.buyerIneligibilityReason) ? sale.code : null;
}

interface ListingClickEventProps {
  listing: Commons_MParticle_ListingQuery_Listing;
  componentName?: string;
  displayStyle?: string;
  position?: number;
  listingsCount?: number;
  city?: string;
  region?: string;
  countryCode?: string;
  isListingDomesticToBuyer?: boolean;
  isFeaturedInCSPCollection?: boolean;
  cmsComponentId?: string;
}

export function buildListingClickEvent(props: ListingClickEventProps): ClickedListingCardEvent {
  return {
    listing: props.listing,
    componentName: props.componentName,
    cmsComponentId: props.cmsComponentId,
    displayStyle: props.displayStyle,
    position: props.position,
    eventName: MParticleEventName.ClickedListingCard,
    listingsCount: props.listingsCount,
    city: props.city,
    region: props.region,
    countryCode: props.countryCode,
    isListingDomesticToBuyer: props.isListingDomesticToBuyer,
    isFeaturedInCSPCollection: props.isFeaturedInCSPCollection,
  };
}

export function buildCSPClick(
  csp: CSPAdditionalTrackingFields.Fragment,
) {
  return {
    cspSlug: csp.slug,
    cspId: csp.id,
    eventName: MParticleEventName.ClickedCSPCard,
    brand: csp.brand && csp.brand.slug,
    usedInventory: csp.inventory?.usedTotal,
    newInventory: csp.inventory?.newTotal,
    totalInventory: csp.inventory && csp.inventory.usedTotal + csp.inventory.newTotal,
    ...getCategoryAttributesForMparticle(csp),
  };
}

export function createProduct(
  listing: Commons_MParticle_ListingQuery_Listing,
  quantity: number = null,
  priceOverride: string = null,
  position: number = null,
  protectionPlanEligible = false,
) {
  const price = priceOverride || normalizePrice(listing);
  const attrs = mParticleListingAttributes(listing, protectionPlanEligible);

  return window.mParticle.eCommerce.createProduct(
    listing.title, // Name
    listing.id, // Id
    price, // Price
    quantity, // Quantity
    null, // Variant
    getRootCategorySlug(listing), // Category
    listing.brandSlug, // Brand
    position, // Position
    listingCouponCode(listing), // CouponCode
    formatAttributes(attrs), // Attributes
  );
}

// Fetches the mParticle javascript asynchonously. We want this to be invoked
// after configureMParticle() and elog have been initialized.
export function initMParticleSDK(): void {
  initializeMParticleSDK();
}

// This call should happen before initMParticleSDK() because it sets
// the mParticle config with the user data on window.mParticle
export function configureMParticle(userContext, rates = []): void {
  configureMParticleSDK(userContext);
  if (isNode || !MPARTICLE_ENV) return;
  initExchangeRates(rates);
}

export function trackPageView(data: PageViewEvent): void {
  if (!userIsTrackable()) return;

  const {
    pageName,
    listingIds,
    bumpedListingIds,
    freeShippingListingIds,
    returnPolicyListingIds,
    esScores,
    ...properties } = data;
  const props = {
    ...properties,
    listingIds: listingIds?.join('|'),
    freeShippingListingIds: freeShippingListingIds?.join('|'),
    returnPolicyListingIds: returnPolicyListingIds?.join('|'),
    esScores: esScores?.join('|'),
  };

  if (bumpedListingIds) {
    merge(props, { bumpedListingIds: bumpedListingIds?.join('|') });
  }

  if (!PROD_MODE) {
    DEBUG_EVENT_QUEUE.push({ name: 'trackPageView', data });
    delayedDebugLog();
  }

  mParticleReady(() => {
    window.mParticle.logPageView(pageName, normalizeEvent(props));
  });
}

export function trackGenericPageView() {
  trackEvent({ eventName: MParticleEventName.GenericPageView });
}

export async function trackPageViewForLocation(loc: Partial<Location>, userContext: Partial<elog.events.UserContext>) {
  const state = await matchRoutes(loc);
  if (!state || state.routes.length === 0) {
    return;
  }

  // Check most specific (last) route for the pageViewEvent prop
  const innerRoute = state.routes.pop();
  const eventProp = innerRoute.props && innerRoute.props.pageViewEvent;

  const updatedState = { ...state, innerRoute, userContext };
  if (!eventProp) return;

  const eventData = (typeof eventProp === 'function')
    ? await eventProp(updatedState)
    : eventProp;

  if (!eventData) return;
  trackPageView(eventData);
}

interface DebugEvent {
  name: 'trackEvent' | 'trackPageView',
  data: MParticleEvent | PageViewEvent,
}

const DEBUG_EVENT_QUEUE: DebugEvent[] = [];

function flushDebugLog() {
  if (!DEBUG_EVENT_QUEUE.length) {
    return;
  }

  console.groupCollapsed(`[mparticle] (${DEBUG_EVENT_QUEUE.length}) - these logs record only the events passed to mparticle_tracker before processing; check your network tab for the actual network event fired post-processing!`);
  DEBUG_EVENT_QUEUE.map(({ name, data }) => {
    console.log(name, data);
  });

  console.groupEnd();

  DEBUG_EVENT_QUEUE.length = 0;
}

const delayedDebugLog = debounce(flushDebugLog, 2000);

export function trackEvent(event: MParticleEvent): void {
  if (!userIsTrackable()) return;

  const handler = eventHandlers[event.eventName] || trackCustomEvent;

  if (!PROD_MODE) {
    DEBUG_EVENT_QUEUE.push({ name: 'trackEvent', data: event });
    delayedDebugLog();
  }

  mParticleReady(() => handler(event));
}
