import React from 'react';
import classNames from 'classnames';

import { BannerType } from '../types';
import { buttonClassName, ButtonVariant } from '../RCButton/styling';
import { RCText, RCThemeOverride } from '../';

import bannerTexture from '../../images/textures/grungy-banner-texture.png';

interface RCBannerProps {
  /** The primary message. Keep this relatively short. */
  title: string;

  /** Additional formatted text content */
  content: React.ReactNode;

  /** Optionally display a small image along with the primary banner content. Accepts an image string or an image element */
  image?: string | React.ReactNode;

  /** A button or link element. Styles will be overridden and replaced with RCButton styles */
  cta?: JSX.Element;

  /** Optionally override the default A button or link element. Defaults to "filled" */
  ctaVariant?: ButtonVariant;

  /** Base color theme of the banner. */
  theme?: BannerType;

  /** Optionally hides the background texture */
  disableTexture?: boolean;

  /** Optionally allow the banner's background to cover the entire page width. */
  fullBleed?: boolean;

  /** Optionally override the component's layout on mobile. Defaults to "banner" */
  mobileLayout?: 'banner' | 'card';
}

export function RCBanner({
  title = '',
  content,
  image = undefined,
  cta = undefined,
  theme = 'info',
  fullBleed = false,
  ctaVariant = 'filled',
  disableTexture = false,
  mobileLayout = 'banner',
}: RCBannerProps) {

  const bannerClasses = classNames(
    'rc-banner',
    {
      'rc-banner--full-bleed': fullBleed === true,
      'rc-banner--with-texture': disableTexture === false,
      'rc-banner--mobile-layout-card': mobileLayout === 'card',
      'rc-banner--error': theme === 'error',
      'rc-banner--highlight': theme === 'highlight',
      'rc-banner--info': theme === 'info',
      'rc-banner--plain': theme === 'plain',
      'rc-banner--success': theme === 'success',
      'rc-banner--warning': theme === 'warning',
    },
  );

  return (
    <div
      className={bannerClasses}
      style={disableTexture ? {} : { backgroundImage: `url(${bannerTexture})`}}
    >
      <RCThemeOverride theme="light" enabled={theme === 'warning'}>
        <div className="rc-banner__wrapper">
          {image &&
            <div className="rc-banner__image">
              {typeof image === 'string' ? <img src={image} alt="" /> : image}
            </div>
          }
          <div className="rc-banner__main">
            <div className="rc-banner__text">
              <RCText.Utility
                weight="bold"
                size="450"
              >
                {title}
              </RCText.Utility>
              <RCText>
                {content}
              </RCText>
            </div>
            {cta &&
              <div className="rc-banner__cta">
                {React.cloneElement(cta, {
                  className: buttonClassName({
                    variant: ctaVariant,
                    fullWidth: true,
                  }),
                })}
              </div>
            }
          </div>
        </div>
      </RCThemeOverride>
    </div>
  );
}
