import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgQuickShipper = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={40}
    height={34}
    viewBox="0 0 40 34"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M22.87 23.09v-6.5l-5.72-2.45v6.5l5.72 2.45ZM23.92 23.09l5.72-2.45v-6.5l-5.72 2.44v6.51ZM29.64 12.99l-6.25-2.66-6.24 2.66v.02l6.24 2.66 6.25-2.66v-.02Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35 9.85v14.3a2.91 2.91 0 0 1-1.38 2.37l-12.26 7.14a2.9 2.9 0 0 1-2.73 0L6.36 26.52C5.58 26.06 5 25.03 5 24.15V9.85a2.96 2.96 0 0 1 1.36-2.37L18.64.34a2.9 2.9 0 0 1 2.73 0l12.27 7.14c.78.46 1.36 1.49 1.36 2.37ZM8.12 12.84h6.42v1.04H8.12v-1.04Zm2.26 3.47h4.16v1.04h-4.16v-1.04Zm20.3-4v9.02l-7.29 3.1-7.28-3.1v-9.02l7.28-3.11 7.3 3.1-.01.01ZM9.34 19.44h5.21v1.04H9.34v-1.04Z"
    />
  </svg>
);
export default SvgQuickShipper;
