import React from 'react';

import { RCIcon, RCButton, RCTooltip } from '../';
import { RCButtonProps } from '../RCButton/RCButton';

/* eslint-disable react/require-default-props */
interface RCIconButtonProps extends Omit <RCButtonProps, 'children'> {
  /**
   * Import the SVG with `?component` in the import path, and pass it through here.
   */
  svgComponent: (props: React.SVGProps<SVGElement>) => React.ReactElement;

  /** A short description of the button's action, generally 1-2 words. Rendered in a tooltip and in the 'aria-label' attribute. */
  label: string;

  hideTooltip?: boolean;
}

/* eslint-enable react/require-default-props */

/**
 * Combines `RCIcon` and `RCButton` into a fully accessible component.
 * Use whenever you need a clickable icon, with the exception of a close button - we have `RCCloseButton` for that specific case.
*/
export const RCIconButton = React.forwardRef<HTMLButtonElement, RCIconButtonProps>(
  function RCIconButton({
    svgComponent,
    label,
    inverted = false,
    variant = 'transparent',
    size = 'medium',
    isSubmit = false,
    hideTooltip = false,
    ...passthroughProps
  }: RCIconButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>, ref) {

    const button = (
      <RCButton
        ref={ref}
        aria-label={label}
        inverted={inverted}
        variant={variant}
        size={size}
        isSubmit={isSubmit}
        {...passthroughProps}
      >
        <RCIcon svgComponent={svgComponent} />
      </RCButton>
    );

    if (hideTooltip) return (
      <div className="rc-icon-button">
        {button}
      </div>
    );

    return (
      <div className="rc-icon-button">
        <RCTooltip
          text={label}
          style="buttonLabel"
          placement="bottom"
          visualOnly
        >
          {button}
        </RCTooltip>
      </div>
    );
  },
);
