import * as React from 'react';

export type LinkElementFunction = (children: React.ReactNode) => React.ReactElement;

interface ILinkElementProps extends React.HTMLProps<HTMLAnchorElement> {
  children: React.ReactNode;
  styleName: string;
  id?: string;
  tabIndex?: number;
  linkElement?: LinkElementFunction | undefined;
}

/**
   * `linkElement` is Cadence's way to support CoreLink.
   * Pass in a linkable element and in component's internals will determine how to render the `children` prop passed.
   */
export function LinkElement({
  children,
  styleName,
  linkElement = undefined,
  ...passthroughProps
}: ILinkElementProps) {

  if (!children) return null;

  if (!linkElement) {
    return (
      <div className={styleName}>
        {children}
      </div>
    );
  }

  return React.cloneElement(
    linkElement(children),
    {
      className: styleName,
      ...passthroughProps,
    },
  );
}
