import classNames from 'classnames';
import React from 'react';

// Note: Property values and options need to stay in sync with `classes-utility/flex.scss`

type GapValue = '0' | 'half' | '1' | '2' | '3' | '4' | 'gutter';

interface RCFlexProps {
  /** The direction items should flow. Defaults to `row`. */
  direction?: 'row' | 'col' | 'reverse' | 'col-reverse';
  /** The spacing value added between items. Defaults to `0`.
   * Optionally, pass in an object to use different values for row and column gaps.
   */
  gap?: GapValue | {
    row: GapValue,
    col: GapValue,
  };
  /** Controls whether items can wrap to a new line when needed. */
  wrap?: boolean;
  /** Controls alignment of items along the "main axis" – horizontal when a `row` and vertical in a `col`. */
  justify?: 'start' | 'center' | 'end' | 'space-between' | 'space-around' | 'space-evenly';
  /** Controls alignment of items along the "cross-axis" – vertical in a `row` and horizontal in a `col`. */
  align?: 'stretch' | 'start' | 'center' | 'end';
  /** When this element is a child of another flex container, controls whether this item should grow to fill empty space in its container.
   *  Defaults to `false` (flex-grow: 0). Items in a container with the same grow value grow at the same rate. */
  grow?: RCFlexItemProps['grow'];
}

/**
 * A set of components allowing you to build a flex layout without writing styles or using CSS classes.
 *
 * These props correspond directly to our `fx-` prefixed utility classes.
 *
 * As you build flex layouts, make sure to use as few properties as possible to minimize unexected quirks & side effects. [The CSS-Tricks](https://css-tricks.com/snippets/css/a-guide-to-flexbox/) flex article is always useful to keep on hand.
*/
export function RCFlex({
  direction = 'row',
  gap = '2',
  wrap = false,
  align = 'stretch',
  justify = 'start',
  grow = false,
  children,
}: React.PropsWithChildren<RCFlexProps>) {
  const simpleGap = typeof gap !== 'object' ? gap : undefined;
  const rowGap = typeof gap === 'object' ? gap.row : undefined;
  const colGap = typeof gap === 'object' ? gap.col : undefined;

  return (
    <div className={classNames(
      'd-flex',
      {
        [`fx-dir-${direction}`]: direction !== 'row',
        [`fx-align-${align}`]: align !== 'stretch',
        [`fx-justify-${justify.includes('-') ? justify.split('-')[1] : justify}`]: justify !== 'start',
        [`gap-${simpleGap}`]: simpleGap,
        [`row-gap-${rowGap}`]: !simpleGap && rowGap,
        [`column-gap-${colGap}`]: !simpleGap && colGap,
        'fx-wrap': wrap,
        'fx-grow': grow,
      },
    )}>
      {children}
    </div>
  );
}

interface RCFlexItemProps {
  /** Controls whether this item should grow to fill empty space in its container. Defaults to `false` (flex-grow: 0).
   *  Items in a container with the same grow value grow at the same rate. */
  grow?: boolean;
  /** Controls whether this item should shrink when the container runs out of space. Defaults to `true` (flex-shrink: 1).
   *  Items in a container with the same shrink value will shrink at the same rate. */
  shrink?: boolean;
  /** Allows for locking this item to a fixed percentage width (or `auto`). */
  width?: 'auto' | '0' | '10' | '20' | '30' | '40' | '50' | '60' | '70' | '80' | '90' | '100';
  /** Controls the visual order of the item in the layout. By default, items have an order value of 0.
   *  Items are organized numerically and then by DOM order when numbers are identical.
   *
   * _Note: Use with caution. The visual order does not change the DOM (i.e keyboard focus) order._ */
  order?: '0' | '1' | '2' | '3' | '4' | '5';
  /** Allows this item to override the parent's alignment. */
  align?: 'stretch' | 'start' | 'center' | 'end';
}

function RCFlexItem({
  grow = false,
  shrink = undefined,
  width = undefined,
  order = '0',
  align = undefined,
  children,
}: React.PropsWithChildren<RCFlexItemProps>) {
  return (
    <div className={classNames(
      {
        'fx-grow': grow,
        'fx-noshrink': shrink === false,
        'fx-shrink': shrink,
        [`fx-width-${width}`]: width,
        [`fx-order-${order}`]: order !== '0',
        [`fx-align-self-${align}`]: align,
      },
    )}>
      {children}
    </div>
  );
}

RCFlex.Item = RCFlexItem;
