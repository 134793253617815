import type { DeployEnvironment, DeployEnvSettings } from './types';

interface EnvSettings {
  DEPLOY_ENV: DeployEnvironment;
  overrides?: Partial<DeployEnvSettings>;
  /** How did the env settings get resolved? */
  source: 'envvar' | 'document' | 'build';
}

export const envSettingsID = 'renv.settings';

/**
 * HTML to be rendered server-side to inject envvars into the document.
 * This is kept separate from app components because app code relies on this HTML being present before executing.
 */
export function envHTML(envData: EnvSettings) {
  return `<script id="${envSettingsID}" type="application/json">${JSON.stringify(envData)}</script>`;
}

/**
 * Safely access process.env.DEPLOY_ENV even in browser contexts where `process` is undefined.
 * This is constructed to not be affected by webpack.EnvironmentPlugin, which only subsitutes
 * the exact string 'process.env.DEPLOY_ENV'.
 */
function readFromRuntimeEnvvar(): EnvSettings {
  if (typeof process !== 'object' || typeof process.env !== 'object') {
    return null;
  }

  return {
    DEPLOY_ENV: Object.getOwnPropertyDescriptor(process.env, 'DEPLOY_ENV')?.value as DeployEnvironment,
    source: 'envvar',
  };
}

function readFromDocument(): EnvSettings {
  if (typeof document !== 'object' || typeof document.getElementById !== 'function') {
    return null;
  }

  const tag = document.getElementById(envSettingsID) as HTMLScriptElement;
  if (!tag) {
    return null;
  }

  try {
    const settings = JSON.parse(tag.text);
    return {
      ...settings,
      source: 'document',
    };
  } catch (e) {
    console.error(e);
    return null;
  }
}


// Allows envvars on rre server process to be used in frontend code
const ENV_OVERRIDE_PREFIX = 'FRONTEND_OVERRIDE__';
export function serverEnvOverrides(serverEnv: Record<string, string>): Record<string, string> {
  if (!serverEnv) {
    return {};
  }

  const tuples = Object.keys(serverEnv)
    .filter(key => key.startsWith(ENV_OVERRIDE_PREFIX))
    .map(key => [key.substring(ENV_OVERRIDE_PREFIX.length), serverEnv[key]]);

  return Object.fromEntries(tuples);
}

/**
 * Determines DEPLOY_ENV and any overrides in the given context.
 * For server runtimes this should be set via DEPLOY_ENV envvar.
 * For browsers this should be injected into the HTML server-side via the above envHTML function.
 * It's also possible to set DEPLOY_ENV at build time using webpack.EnvironmentPlugin.
 * It's not recommented to mix runtime and build-time configurations for the same app;
 * there's no guarantee about which value takes precedence.
 */
export function resolveRuntimeEnv(): EnvSettings {
  const runtimeEnvSettings = readFromRuntimeEnvvar();
  if (runtimeEnvSettings?.DEPLOY_ENV) {
    const overrides = serverEnvOverrides(process?.env);

    return { ...runtimeEnvSettings, overrides };
  }

  const documentEnvSettings = readFromDocument();
  if (documentEnvSettings?.DEPLOY_ENV) {
    return documentEnvSettings;
  }

  // Fall back to value substituted by build time EnvironmentPlugin
  return {
    DEPLOY_ENV: process.env.DEPLOY_ENV as DeployEnvironment,
    source: 'build',
  };
}
