import React from 'react';
import { RCIcon } from '../RCIcon/RCIcon';
import { CheckIcon } from '../../icons/react';

export interface RCComboboxOptionData {
  value: string;
  label: string;
  disabled?: boolean;
}

interface RCComboboxOptionProps extends RCComboboxOptionData {
  selected?: boolean;
  onClick?: () => void;
}

export const RCComboboxOption = React.forwardRef<
  HTMLLIElement, RCComboboxOptionProps
>(({
  label,
  value,
  disabled = false,
  selected = false,
  ...passthroughProps
}, ref) => {
  return (
    <li
      className="rc-combobox-option"
      ref={ref}
      id={value}
      key={value}
      role="option"
      aria-selected={selected}
      aria-disabled={disabled}
      {...passthroughProps}
    >
      {label}
      {selected &&
        <span className="rc-combobox-option__icon">
          <RCIcon svgComponent={CheckIcon} />
        </span>
      }
    </li>
  );
});

RCComboboxOption.displayName = 'RCComboboxOption';
