// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import {
  NudgeDownloadIcon,
} from '@reverbdotcom/cadence/icons/react';
import { RCNudgeProps, ShippingPromoProps, RCPriceBlockProps, RCImage } from '@reverbdotcom/cadence/components';
import {
  core_apimessages_Listing_ListingType as ListingType,
  core_apimessages_Country as Country,
  ListingForBuyerFieldsFragment,
  ListingForBuyerShippingFieldsFragment as ListingForBuyerShippingFieldsFragmentType,
  core_apimessages_ShippingMethod as IShippingMethod,
  core_apimessages_ShippingPrice as IShippingPrice,
  core_apimessages_Money as IMoney,
  core_apimessages_Address as Address,
  InOtherCartsCardDataFragment,
  core_apimessages_Ribbon_Reason,
  User,
  Shop,
} from './gql/graphql';
import { LISTING_STATES } from './constants';
import { conditionDisplayText } from './components/condition_display';
import { ListingGreatValueFragment, isGreatValue } from './listing_great_value';
import { shouldShowBumpTag } from './components/bump_tag';
import { BRAND_NEW } from './condition_helpers';
import shippingBoxTinyNudgeIcon from '@reverbdotcom/cadence/images/nudge/shipping-box--tiny.svg';
import shippingBoxTinyDarkNudgeIcon from '@reverbdotcom/cadence/images/nudge/shipping-box-tiny-dark.svg';
import gemTinyNudgeIcon from '@reverbdotcom/cadence/images/nudge/gem--tiny.svg';
import gemTinyDarkNudgeIcon from '@reverbdotcom/cadence/images/nudge/gem-tiny-dark.svg';
import fastTruckTinyNudgeIcon from '@reverbdotcom/cadence/images/nudge/fast-truck-tiny.svg';
import fastTruckTinyDarkNudgeIcon from '@reverbdotcom/cadence/images/nudge/fast-truck-tiny-dark.svg';
import locationPinTinyNudgeIcon from '@reverbdotcom/cadence/images/nudge/location-pin--tiny.svg';
import locationPinTinyDarkNudgeIcon from '@reverbdotcom/cadence/images/nudge/location-pin-tiny-dark.svg';
import cartTinyNudgeIcon from '@reverbdotcom/cadence/images/nudge/cart--tiny.svg';
import cartTinyDarkNudgeIcon from '@reverbdotcom/cadence/images/nudge/cart-tiny-dark.svg';
import sparklesIcon from '@reverbdotcom/cadence/images/nudge/sparkles.svg';
import sparklesTinyDarkIcon from '@reverbdotcom/cadence/images/nudge/sparkles-tiny-dark.svg';
import { isMarketplacePage, isUsingProximityFeatures } from './url_helpers';
import { IUser } from './components/user_context_provider';
import { isNewListing } from './newListingNudge';
import { Location } from 'history';
import { useUser } from '@reverbdotcom/commons/src/user_hooks';
import { isNonUSUser } from './user_context_helpers';
import { ListingCardCPOSignal, ListingCardCPOFragment } from './components/listing_card_cpo_signal';
import { RCListingCardProps } from '@reverbdotcom/cadence/components/RCListingCard/RCListingCard';

export enum PriceNudge {
  DIGITAL_DOWNLOAD = 'digitalDownload',
  FREE_EXPEDITED_SHIPPING = 'freeExpeditedShipping',
  FREE_SHIPPING = 'freeShipping',
}

export interface CardNudges {
  listing: ListingForBuyerFieldsFragment & InOtherCartsCardDataFragment;
  decoratedListing: ListingForBuyer;
  shop: Shop;
  displayGreatValue: boolean;
  showFlag: boolean;
  location: Location;
  minimalNudges?: boolean;
  user: User;
}

interface NudgeConfig {
  returnPolicyDays: number;
  inOtherCartsCount: number;
  displayMultiple?: boolean;
  greatValue?: boolean;
  localPickupOnly?: boolean;
  localPickupAvailable?: boolean;
  newListing?: boolean;
  country?: Country;
  minimalNudges?: boolean;
}

interface PriceNudgeConfig {
  freeExpeditedShippingAvailable?: boolean;
  freeStandardShippingAvailable?: boolean;
  digitalDownload?: boolean;
}

type ListingForBuyerFieldsFragment_Address = ListingForBuyerFieldsFragment['shop']['address'];
type ListingForBuyerShippingFieldsFragment_ShippingPrices = ListingForBuyerShippingFieldsFragmentType['shipping']['shippingPrices'][0];

export function getEyebrow(listing: ListingForBuyerFieldsFragment, showBump = true): RCListingCardProps['eyebrow'] | undefined {
  if (shouldShowBumpTag(listing) && showBump) {
    return { text: I18n.t('commons.listingCardEyebrows.bump'), color: 'rc-color-text-accent' };
  }

  const onSale = listing?.pricing?.ribbon?.reason === core_apimessages_Ribbon_Reason.SALE;
  if (onSale) {
    return { text: I18n.t('commons.listingCardEyebrows.onSale'), color: 'rc-color-text-discount' };
  }

  const priceDrop = !!listing?.pricing?.originalPrice;
  if (priceDrop) {
    return { text: I18n.t('commons.listingCardEyebrows.priceDrop'), color: 'rc-color-text-discount' };
  }
}

export function getReturnPolicyDays(listing: ListingForBuyerFieldsFragment): number {
  if (!listing?.shop) return 0;
  if (listing?.soldAsIs || !listing?.condition?.conditionSlug) return 0;

  const returnPolicyDays = listing?.condition?.conditionSlug === BRAND_NEW
    ? listing?.shop.returnPolicy?.newReturnWindowDays
    : listing?.shop.returnPolicy?.usedReturnWindowDays;

  return (returnPolicyDays || 0);
}

export const getListingCardNudgesWithLabels = (
  inOtherCartsCount = 0,
  returnPolicyDays = 0,
  country: Country = {},
): { [k: string]: RCNudgeProps } => ({
  digitalDownload: {
    label: I18n.t('commons.listingCardNudge.digitalDownload'),
    icon: () => <NudgeDownloadIcon />,
  },
  freeExpeditedShipping: {
    label: I18n.t('commons.listingCardNudge.freeExpeditedShipping'),
    icon: () => <RCImage src={fastTruckTinyNudgeIcon} darkModeSrc={fastTruckTinyDarkNudgeIcon} alt="" />,
  },
  freeShipping: {
    label: I18n.t('commons.listingCardNudge.freeShipping'),
    icon: () => <RCImage src={fastTruckTinyNudgeIcon} darkModeSrc={fastTruckTinyDarkNudgeIcon} alt="" />,
  },
  greatValue: {
    label: I18n.t('commons.listingCardNudge.greatValue'),
    icon: () => <RCImage src={gemTinyNudgeIcon} darkModeSrc={gemTinyDarkNudgeIcon} alt="" />,
  },
  inOtherCarts: {
    label: I18n.t('commons.listingCardNudge.inOtherCarts', { count: (inOtherCartsCount) }),
    icon: () => <RCImage src={cartTinyNudgeIcon} darkModeSrc={cartTinyDarkNudgeIcon} alt="" />,
  },
  localPickup: {
    label: I18n.t('commons.listingCardNudge.localPickup'),
    icon: () => <RCImage src={locationPinTinyNudgeIcon} darkModeSrc={locationPinTinyDarkNudgeIcon} alt="" />,
  },
  localPickupAvailable: {
    label: I18n.t('commons.listingCardNudge.localPickupAvailable'),
    icon: () => <RCImage src={locationPinTinyNudgeIcon} darkModeSrc={locationPinTinyDarkNudgeIcon} alt="" />,
  },
  returnPolicy: {
    label: I18n.t('commons.listingCardNudge.returnPolicy', { returnPolicyDays: (returnPolicyDays) }),
    icon: () => <RCImage src={shippingBoxTinyNudgeIcon} darkModeSrc={shippingBoxTinyDarkNudgeIcon} alt="" />,
  },
  newListing: {
    label: I18n.t('commons.listingCardNudge.newListing'),
    icon: () => <RCImage src={sparklesIcon} darkModeSrc={sparklesTinyDarkIcon} alt="" />,
  },
  countryFlag: {
    label: country.name,
    icon: () => (
      <span className="listing-shop-country-nudge">
        <span className={`flag-icon flag-icon-${country?.countryCode.toLowerCase()}`} />
      </span>
    ),
  },
});

export function buildPrice(
  decoratedListing: ListingForBuyer,
  combinedShippingDiscount: boolean,
  showShippingDisplay: boolean,
): RCPriceBlockProps {
  const showDiscountPrice = !combinedShippingDiscount && decoratedListing.isLive;

  return {
    priceDisplay: decoratedListing.primaryPriceDisplay,
    discountPriceDescription: decoratedListing.discountPriceDescription,
    priceNudge: getPriceNudgeProps(decoratedListing.priceNudge),
    shippingPromo: decoratedListing.shippingPromo,
    a11yPriceDescription: getA11yPriceDescription(decoratedListing),
    discountPriceDisplay: showDiscountPrice ? decoratedListing.discountedPriceDisplay : undefined,
    shippingDisplay: (showShippingDisplay || combinedShippingDiscount) ? decoratedListing.primaryShippingDisplay : undefined,
    shippingStrike: !!combinedShippingDiscount,
  };
}

export function getA11yPriceDescription(decoratedListing: ListingForBuyer): string {
  const { primaryPriceDisplay, discountedPriceDisplay, discountPriceDescription } = decoratedListing;

  if (discountedPriceDisplay) {
    const args = { originalPrice: discountedPriceDisplay, buyerPrice: primaryPriceDisplay };
    if (discountPriceDescription) {
      return I18n.t('commons.a11yPriceDescription.withRibbon', { ...args, ribbon: discountPriceDescription });
    } else {
      return I18n.t('commons.a11yPriceDescription.withoutRibbon', args);
    }
  }

  return primaryPriceDisplay;
}

export function showFlagForMarketplace(user: Partial<IUser>, location: Location) {
  return user.countryCode !== 'US' && optionalPathnameIncludes(location, '/marketplace');
}

export function useShowCountryFlags() {
  const user = useUser();

  return isNonUSUser(user);
}

export function optionalPathnameIncludes(location: Location, path: string): boolean {
  if (!location?.pathname) {
    return false;
  }

  return location?.pathname?.includes(path);
}

export function showProximityContent(shopAddress: Address, user: Partial<IUser>, location: Location) {
  return isUsingProximityFeatures(location) &&
    shopAddress?.country?.countryCode === 'US' &&
    user.countryCode === 'US';
}

export function showLocalPickupNudge(decoratedListing, shop, user, location) {
  const localPickupAvailable = decoratedListing.localPickupAvailable && !decoratedListing.localPickupOnly;

  return localPickupAvailable && showProximityContent(shop?.address, user, location);
}

export function getRCListingCardNudges({
  listing,
  decoratedListing,
  shop,
  displayGreatValue,
  user,
  showFlag,
  location,
  minimalNudges,
}: CardNudges): RCNudgeProps[] {
  const includeFlagNudge = showFlag || showFlagForMarketplace(user, location);

  const config = {
    displayMultiple: true,
    greatValue: displayGreatValue && isGreatValue(listing, user),
    inOtherCartsCount: listing.otherBuyersWithListingInCartCounts,
    localPickupOnly: decoratedListing.localPickupOnly,
    localPickupAvailable: showLocalPickupNudge(decoratedListing, shop, user, location),
    returnPolicyDays: decoratedListing?.returnPolicyDays,
    newListing: isNewListing(listing) && isMarketplacePage(location),
    country: includeFlagNudge ? shop.address.country : undefined,
    minimalNudges,
  };

  return getNudges(config);
}

export function getNudges(config: NudgeConfig): RCNudgeProps[] {
  const listingCardNudges = getListingCardNudgesWithLabels(config.inOtherCartsCount, config.returnPolicyDays, config.country);
  const applicableNudges = [];
  const requiredNudges = [];

  if (config.minimalNudges) {
    if (config.greatValue) { applicableNudges.push(listingCardNudges.greatValue); }
  } else {
    if (config.country) { requiredNudges.push(listingCardNudges.countryFlag); }
    if (config.returnPolicyDays >= 14) { applicableNudges.push(listingCardNudges.returnPolicy); }
    if (config.inOtherCartsCount > 0) { applicableNudges.push(listingCardNudges.inOtherCarts); }
    if (config.localPickupOnly) { applicableNudges.push(listingCardNudges.localPickup); }
    if (config.localPickupAvailable) { applicableNudges.push(listingCardNudges.localPickupAvailable); }
    if (config.greatValue) { applicableNudges.push(listingCardNudges.greatValue); }
    if (config.newListing) { applicableNudges.push(listingCardNudges.newListing); }
  }

  const slicedApplicableNudges = applicableNudges.slice(0, config.displayMultiple ? 3 : 1);

  return [...requiredNudges, ...slicedApplicableNudges];
}

function getPriceNudge(config: PriceNudgeConfig): PriceNudge {
  if (config.digitalDownload) { return PriceNudge.DIGITAL_DOWNLOAD; }
  if (config.freeExpeditedShippingAvailable) { return PriceNudge.FREE_EXPEDITED_SHIPPING; }
  if (config.freeStandardShippingAvailable) { return PriceNudge.FREE_SHIPPING; }

  return null;
}

export function getPriceNudgeProps(nudge: PriceNudge): RCNudgeProps {
  const listingCardNudges = getListingCardNudgesWithLabels();

  switch (nudge) {
    case PriceNudge.DIGITAL_DOWNLOAD:
      return listingCardNudges.digitalDownload;
    case PriceNudge.FREE_EXPEDITED_SHIPPING:
      return listingCardNudges.freeExpeditedShipping;
    case PriceNudge.FREE_SHIPPING:
      return listingCardNudges.freeShipping;
    default:
      return undefined;
  }
}

export function shippingPriceDataForMethod(
  listing: ListingForBuyerFieldsFragment,
  shippingMethod: IShippingMethod,
): ListingForBuyerShippingFieldsFragment_ShippingPrices {
  const shippingMethodConfigs = listing?.shipping?.shippingPrices || [];

  return shippingMethodConfigs.find((price: IShippingPrice) => {
    return price.shippingMethod === shippingMethod;
  });
}

export function getStandardShippingPrice(listing: ListingForBuyerFieldsFragment): IMoney | undefined {
  return shippingPriceDataForMethod(listing, IShippingMethod.SHIPPED)?.rate;
}

export function getCombinedShippingPrice(listing: ListingForBuyerFieldsFragment): IMoney | undefined {
  return shippingPriceDataForMethod(listing, IShippingMethod.COMBINED_SHIPPING)?.rate;
}

export function getExpeditedShippingPrice(listing: ListingForBuyerFieldsFragment): IMoney | undefined {
  return shippingPriceDataForMethod(listing, IShippingMethod.EXPEDITED_SHIPPING)?.rate;
}

export function isDigital(listing: ListingForBuyerFieldsFragment): boolean {
  return listing?.listingType === ListingType.DIGITAL;
}

export function getConditionLabel(listing: ListingForBuyerFieldsFragment): string | React.ReactElement {
  const certifiedPreOwned = listing?.certifiedPreOwned;

  if (certifiedPreOwned) {
    return (
      <ListingCardCPOSignal listing={listing} />
    );
  }

  if (!isDigital(listing) && listing?.condition) {
    return conditionDisplayText(listing?.condition?.conditionSlug, listing?.condition?.displayName);
  }
}

export interface ListingForBuyer {
  isLive: boolean,
  conditionLabel: string | React.ReactElement,
  isDigital: boolean,
  isAuction: boolean,

  // Pricing
  primaryPriceDisplay: string;
  discountedPriceDisplay: string;
  discountPriceDescription: string;
  priceNudge: PriceNudge;

  // Shipping
  localPickupOnly: boolean;
  listingShipsToBuyerRegion: boolean;
  freeExpeditedShippingAvailable: boolean;
  freeStandardShippingAvailable: boolean;
  shippingThresholdMet: boolean;
  standardShippingData: IShippingPrice | undefined;
  standardShippingPrice: IMoney | undefined;
  expeditedShippingPrice: IMoney | undefined;
  combinedShippingPrice: IMoney | undefined;
  shippingDependsOnPostalCode: boolean;
  localPickupAvailable: boolean;
  shippingPromo: ShippingPromoProps | undefined;
  primaryShippingDisplay: string;
  returnPolicyDays: number;

  // Shop
  shopLocationDisplay: string;
  shopName: string;
  shopAddress: ListingForBuyerFieldsFragment_Address;
}

export function buildListingForBuyer(
  listing: ListingForBuyerFieldsFragment & InOtherCartsCardDataFragment,
  options: {
    combinedDiscountActive?: boolean,
    shippingThresholdMet?: boolean,
  } = {},
): ListingForBuyer {
  const isDigital = listing?.listingType === ListingType.DIGITAL;
  const isAuction = listing?.listingType === ListingType.AUCTION;

  // Shipping
  const standardShippingData = shippingPriceDataForMethod(listing, IShippingMethod.SHIPPED);
  const expeditedShippingData = shippingPriceDataForMethod(listing, IShippingMethod.EXPEDITED_SHIPPING);

  const { localPickupOnly, localPickup } = listing?.shipping ?? {};
  const listingShipsToBuyerRegion = !!standardShippingData;
  const freeExpeditedShippingAvailable = !!listing?.shipping?.freeExpeditedShipping;
  const freeStandardShippingAvailable = getStandardShippingPrice(listing)?.amountCents === 0;
  const freeShippingAvailable = freeExpeditedShippingAvailable || freeStandardShippingAvailable;
  const canShippingBeDiscounted = !freeShippingAvailable && !localPickupOnly;
  const shippingDependsOnPostalCode = standardShippingData?.carrierCalculated || standardShippingData?.regional || !!expeditedShippingData?.regional;

  let shippingPromo;
  if (options.shippingThresholdMet) {
    shippingPromo = {
      label: I18n.t('commons.freeShipping'),
      tooltipText: I18n.t('commons.freeBecauseThreshold'),
      type: 'plain',
    };
  } else if (canShippingBeDiscounted && options.combinedDiscountActive && !!getCombinedShippingPrice(listing)) {
    shippingPromo = {
      label: I18n.t('commons.whenYouCombineShippingLabel', { displayPrice: getCombinedShippingPrice(listing).display }),
      tooltipText: I18n.t('commons.howToCombinedShippingTooltip'),
      type: 'highlight',
    };
  }

  let primaryShippingDisplay = I18n.t('commons.plusShipping');
  if (localPickupOnly) {
    primaryShippingDisplay = null;
  } else if (freeExpeditedShippingAvailable) {
    primaryShippingDisplay = I18n.t('commons.freeExpeditedShipping');
  } else if (freeStandardShippingAvailable) {
    primaryShippingDisplay = I18n.t('commons.freeShipping');
  } else if (getStandardShippingPrice(listing)) {
    primaryShippingDisplay = I18n.t('commons.plusShippingPrice', { displayPrice: getStandardShippingPrice(listing).display });
  }

  return {
    isLive: listing?.state === LISTING_STATES.LIVE,
    isDigital,
    isAuction,
    conditionLabel: getConditionLabel(listing),
    // Pricing
    primaryPriceDisplay: listing?.pricing?.buyerPrice?.display,
    discountedPriceDisplay: listing?.pricing?.originalPrice?.display,
    discountPriceDescription: listing?.pricing?.ribbon?.display,
    priceNudge: getPriceNudge({ freeExpeditedShippingAvailable, freeStandardShippingAvailable, digitalDownload: isDigital }),
    returnPolicyDays: getReturnPolicyDays(listing),
    // Shipping
    localPickupOnly,
    listingShipsToBuyerRegion,
    freeExpeditedShippingAvailable,
    freeStandardShippingAvailable,
    shippingThresholdMet: !!options.shippingThresholdMet,
    standardShippingData,
    standardShippingPrice: getStandardShippingPrice(listing),
    expeditedShippingPrice: getExpeditedShippingPrice(listing),
    combinedShippingPrice: getCombinedShippingPrice(listing),
    shippingDependsOnPostalCode,
    localPickupAvailable: localPickup,
    primaryShippingDisplay,
    shippingPromo,
    // Shop
    shopLocationDisplay: listing?.shop?.address?.displayLocation,
    shopAddress: listing?.shop?.address,
    shopName: listing?.shop?.name,
  };
}

export function isListingDomesticToBuyer(sellerCountryCode?: string, buyerCountryCode?: string): boolean {
  if (!(sellerCountryCode && buyerCountryCode)) { return false; }
  return sellerCountryCode === buyerCountryCode;
}

export const ListingForBuyerShippingFieldsFragment = gql`
  fragment ListingForBuyerShippingFields on Listing {
    _id
    shipping {
      freeExpeditedShipping
      localPickupOnly
      shippingPrices {
        _id
        shippingMethod
        carrierCalculated
        regional
        destinationPostalCodeNeeded
        postalCode
        rate {
          amount
          amountCents
          currency
          display
        }
      }
    }
  }
`;

export const ListingForBuyerFragment = gql`
  fragment ListingForBuyerFields on Listing {
    _id
    id
    title
    slug
    listingType
    make
    model
    upc
    state
    stateDescription
    bumped
    watching
    soldAsIs
    usOutlet
    publishedAt {
      seconds
    }
    condition {
      displayName
      conditionSlug
      conditionUuid
    }
    pricing {
      buyerPrice {
        display
        currency
        amount
        amountCents
      }
      originalPrice {
        display
      }
      ribbon {
        display
        reason
      }
    }
    images(input: {
      transform: "card_square",
      count: 3,
      scope: "photos",
      type: "Product"
    }) {
      source
    }
    shipping {
      shippingPrices {
        _id
        shippingMethod
        carrierCalculated
        destinationPostalCodeNeeded
        rate {
          amount
          amountCents
          currency
          display
        }
      }
      freeExpeditedShipping
      localPickupOnly
      localPickup
    }
    shop {
      _id
      name
      returnPolicy {
        usedReturnWindowDays
        newReturnWindowDays
      }
      address {
        _id
        locality
        region
        country {
          _id
          countryCode
          name
        }
        displayLocation
      }
    }
    ...ListingForBuyerShippingFields
    ...ListingGreatValueData
    ...ListingCardCPOData
  }
  ${ListingGreatValueFragment}
  ${ListingForBuyerShippingFieldsFragment}
  ${ListingCardCPOFragment}
`;
