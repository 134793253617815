import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgExternalLink = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M22 14.5v5c0 1.24-.44 2.3-1.32 3.18-.88.88-1.94 1.32-3.18 1.32h-13c-1.24 0-2.3-.44-3.18-1.32C.44 21.8 0 20.74 0 19.5v-13c0-1.24.44-2.3 1.32-3.18C2.2 2.44 3.26 2 4.5 2h11a.49.49 0 0 1 .36.14.49.49 0 0 1 .14.36v1a.487.487 0 0 1-.14.36.487.487 0 0 1-.36.14h-11a2.41 2.41 0 0 0-1.766.734A2.407 2.407 0 0 0 2 6.5v13c0 .688.245 1.276.734 1.766.49.49 1.079.734 1.766.734h13a2.41 2.41 0 0 0 1.766-.734c.49-.49.734-1.078.734-1.766v-5a.49.49 0 0 1 .14-.36.487.487 0 0 1 .36-.14h1a.49.49 0 0 1 .36.14.49.49 0 0 1 .14.36zM28 1v8c0 .27-.099.505-.297.703A.961.961 0 0 1 27 10a.961.961 0 0 1-.703-.297l-2.75-2.75-10.188 10.188a.494.494 0 0 1-.718 0l-1.782-1.782a.494.494 0 0 1 0-.718L21.047 4.453l-2.75-2.75A.961.961 0 0 1 18 1c0-.27.099-.505.297-.703A.961.961 0 0 1 19 0h8c.27 0 .505.099.703.297A.961.961 0 0 1 28 1z" />
  </svg>
);
export default SvgExternalLink;
