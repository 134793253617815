import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgMoon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M14.095 2.927c.225.4.195.897-.078 1.266a7 7 0 0 0 9.79 9.79 1.167 1.167 0 0 1 1.855 1.046A11.668 11.668 0 1 1 12.97 2.339c.458-.043.898.188 1.124.588ZM10.869 5.18a9.332 9.332 0 1 0 11.952 11.95A9.332 9.332 0 0 1 10.87 5.179Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMoon;
