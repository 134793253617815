import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgShare = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21 16c1.385 0 2.565.487 3.54 1.46.973.975 1.46 2.155 1.46 3.54 0 1.385-.487 2.565-1.46 3.54-.975.973-2.155 1.46-3.54 1.46-1.385 0-2.565-.487-3.54-1.46C16.488 23.564 16 22.384 16 21c0-.125.01-.302.031-.531l-5.625-2.813C9.448 18.552 8.312 19 7 19c-1.385 0-2.565-.487-3.54-1.46C2.488 16.564 2 15.384 2 14c0-1.385.487-2.565 1.46-3.54C4.436 9.488 5.616 9 7 9c1.313 0 2.448.448 3.406 1.344l5.625-2.813A6.302 6.302 0 0 1 16 7c0-1.385.487-2.565 1.46-3.54C18.436 2.488 19.616 2 21 2c1.385 0 2.565.487 3.54 1.46C25.512 4.436 26 5.616 26 7c0 1.385-.487 2.565-1.46 3.54-.975.973-2.155 1.46-3.54 1.46-1.313 0-2.448-.448-3.406-1.344l-5.625 2.813c.02.229.031.406.031.531a6.3 6.3 0 0 1-.031.531l5.625 2.813C18.552 16.448 19.687 16 21 16z" />
  </svg>
);
export default SvgShare;
