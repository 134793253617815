import qs from 'qs';
import window from './window_wrapper';

const GCLID = 'GCLID';
const GCLID_DATE = 'GCLID Date';

export interface PaidAdData {
  [GCLID]?: string;
  [GCLID_DATE]?: string;
}

export function getPaidAdData(): PaidAdData {
  const data: PaidAdData = {};

  const query = qs.parse(window.location.search.replace(/^\?/, ''));

  const gclid = query.gclid;

  if (gclid) {
    Object.assign(data, {
      [GCLID]: gclid,
      [GCLID_DATE]: Date.now(),
    });
  }

  return data;
}
