import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgReverbGives = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 17 17"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M7.75.24C3.61.24.25 3.6.25 7.74s3.36 7.5 7.5 7.5c4.14 0 7.5-3.36 7.5-7.5S11.89.24 7.75.24zm2.82 10.07c-.44.48-1 .67-1.65.58-.19-.03-.39-.09-.56-.19-.56-.31-.63-.97-.18-1.45.53-.57 1.2-.72 1.95-.51.03.01.07.02.12.04V6.29c0-.29-.27-.51-.55-.44l-3.18.73c-.2.05-.35.23-.35.43 0 .71-.01 2.3.01 3.16.01.57-.24.95-.7 1.21-.52.29-1.06.37-1.62.14-.45-.16-.68-.52-.64-.93.02-.33.2-.59.46-.79.54-.42 1.15-.53 1.81-.32.01.01.04.01.07.02V5.28c0-.21.15-.39.35-.44l4.38-1.01c.28-.07.55.14.55.43 0 1.16 0 3.83.01 5.3 0 .3-.09.55-.28.75z" />
  </svg>
);
export default SvgReverbGives;
