import React from 'react';

import { CommonInputProps, CustomTagProps } from '../../types';
import { RCFormGroupAlertText, RCTag } from '../../';

export function labelID(id = undefined): string | undefined {
  if (!id) return undefined;
  return `${id}--label`;
}

export function sublabelID(id = undefined): string | undefined {
  if (!id) return undefined;
  return `${id}--sublabel`;
}

export function errorID(id = undefined): string | undefined {
  if (!id) return undefined;
  return `${id}--error`;
}

/** props that form elements typically pass through */
export interface CheckableLabelProps extends Omit<CommonInputProps, 'tag' | 'name' | 'id'> {
  /** Additional line of text below main label; sentence-case without ending punctuation. */
  sublabel?: React.ReactNode;

  htmlFor?: string;
  customTag?: CustomTagProps;
  /** The ID of the input element. Used to generate label, sublabel, and error text IDs */
  inputId?: string;

  // used by RCCheckbox when it's rendering a link instead of a form element
  renderAsDiv?: boolean;
}

export function RCCheckableLabel({
  label,
  htmlFor = undefined,
  errorText = undefined,
  sublabel = undefined,
  customTag = undefined,
  inputId = undefined,
  renderAsDiv = undefined,
}: CheckableLabelProps) {
  const Tag = renderAsDiv ? 'div' : 'label';

  return (
    <div className="rc-checkable-label">
      <Tag
        className="rc-checkable-label__label"
        htmlFor={renderAsDiv ? undefined : htmlFor}
        id={inputId ? `${inputId}--label` : undefined}
      >
        {label}
        {customTag && (
          <>
            <span className="mr-2" />
            <RCTag
              variant={customTag.variant || 'highlight'}
            >
              {customTag.label}
            </RCTag>
          </>
        )}
      </Tag>
      {sublabel && (
        <span className="rc-checkable-label__sublabel" id={sublabelID(inputId)}>
          {sublabel}
        </span>
      )}
      {errorText && (
        <RCFormGroupAlertText id={errorID(inputId)} errorText={errorText} />
      )}
    </div>
  );
}
