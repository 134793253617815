// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';

import { BRAND_NEW } from './condition_helpers';
import { US_CON } from './constants';
import { ListingGreatValueDataFragment } from './gql/graphql';
import { IUser } from './components/user_context_provider';

type ListingGreatValueDataFragment_BuyerPrice = ListingGreatValueDataFragment['pricing']['buyerPrice'];
type ListingGreatValueDataFragment_Condition = ListingGreatValueDataFragment['condition'];
type ListingGreatValueDataFragment_Shop = ListingGreatValueDataFragment['shop'];

export function isGreatValue(listing: ListingGreatValueDataFragment, user: Partial<IUser>) {
  const { pricing, priceRecommendation, condition, shop, currency } = listing;
  const priceEstimate = priceRecommendation?.priceMiddle;
  const priceActual = pricing?.buyerPrice;

  if (showGreatValueUK(user, currency, priceActual) || showGreatValueUS(user, currency, priceActual, condition, shop)) {
    return priceEstimate &&
      priceActual &&
      priceEstimate.currency === priceActual.currency &&
      priceEstimate.amountCents >= priceActual.amountCents;
  }

  return false;
}

function showGreatValueUS(
  user: Partial<IUser>,
  currency: string,
  priceActual: ListingGreatValueDataFragment_BuyerPrice,
  condition: ListingGreatValueDataFragment_Condition,
  shop: ListingGreatValueDataFragment_Shop,
): boolean {
  if (!condition?.conditionSlug) return false;
  if (user?.shippingRegionCode !== US_CON) return false;
  if (currency !== 'USD' && currency) return false; // the listing must be listed in USD
  if (priceActual?.currency !== 'USD') return false; // and the buyer must also be using USD
  if (condition.conditionSlug === BRAND_NEW && shop.address?.country?.countryCode === 'US') {
    return false; // We have an issue with representing MAP-priced items as great values, so we've added this hack to fix that
  }
  return true;
}

function showGreatValueUK(user: Partial<IUser>, currency: string, priceActual: ListingGreatValueDataFragment_BuyerPrice): boolean {
  return user?.shippingRegionCode === 'GB' &&
    currency === 'GBP' &&
    priceActual?.currency === 'GBP';
}

export const ListingGreatValueFragment = gql`
  fragment ListingGreatValueData on Listing {
    _id
    pricing {
      buyerPrice {
        currency
        amountCents
      }
    }
    condition {
      conditionSlug
    }
    priceRecommendation {
      priceMiddle {
        amountCents
        currency
      }
    }
    shop {
      _id
      address {
        _id
        country {
          _id
          countryCode
        }
      }
    }
    currency
  }
`;
