import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgRotateRight = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M26 4v7c0 .27-.099.505-.297.703A.961.961 0 0 1 25 12h-7c-.438 0-.745-.208-.922-.625-.177-.406-.104-.766.219-1.078l2.156-2.156C17.912 6.714 16.093 6 14 6c-1.083 0-2.117.21-3.102.633a8.061 8.061 0 0 0-2.554 1.71 8.061 8.061 0 0 0-1.711 2.555A7.793 7.793 0 0 0 6 14c0 1.083.21 2.117.633 3.102a8.06 8.06 0 0 0 1.71 2.554 8.06 8.06 0 0 0 2.555 1.711A7.792 7.792 0 0 0 14 22c1.24 0 2.412-.27 3.516-.813a7.843 7.843 0 0 0 2.797-2.296c.072-.104.192-.167.359-.188.156 0 .286.047.39.14L23.203 21c.094.083.143.19.149.32a.514.514 0 0 1-.118.352 11.73 11.73 0 0 1-4.125 3.195A11.916 11.916 0 0 1 14 26a11.68 11.68 0 0 1-4.656-.953c-1.48-.636-2.755-1.49-3.828-2.563-1.073-1.073-1.927-2.349-2.563-3.828A11.675 11.675 0 0 1 2 14c0-1.625.318-3.177.953-4.656.636-1.48 1.49-2.755 2.563-3.828 1.073-1.073 2.349-1.927 3.828-2.563A11.675 11.675 0 0 1 14 2c1.531 0 3.013.29 4.445.867a12.129 12.129 0 0 1 3.82 2.446l2.032-2.016c.302-.323.666-.396 1.094-.219.406.177.609.485.609.922z" />
  </svg>
);
export default SvgRotateRight;
