import React from 'react';

import { RCComboboxBase } from '../internalComponents/RCComboboxBase/RCComboboxBase';
import { RCAutocompleteItemProps } from './RCAutocompleteItem';

interface RCAutocompleteProps {
  /** Controls visibility of the suggestion panel. */
  isOpen: boolean;

  /** Callback that fires when the suggestion panel opens / closes. */
  onOpenChange: (isOpen: boolean) => void;

  /** Sets the value of the input. */
  inputValue: string;

  /** HTML ID of the input. Should match the `inputId` of the matching `FormGroup` or the `for` property of the matching `<label>`. */
  inputId: string;

  /** Callback function that passes the current input string whenever it changes. */
  onInputChange: (value: string) => void;

  /** Callback function that fires when Search button is pressed. When paired with `preventAutoFocus = true`, also fires when the enter key is pressed and no child is currently focused. */
  onSubmit?: (event) => void;

  /** Set to `true` to render a loading animation inside the input. */
  loading: boolean;

  /** Standard input tag required attribute. */
  required?: boolean;

  /** Standard input tag placeholder text. */
  placeholder?: string;

  /** Standard input tag disabled attribute. */
  disabled?: boolean;

  /** Prevents the default behavior of auto focusing the first child when typing in the input. Can be used to allow the enter key to trigger `onSubmit` when no child is focused. */
  preventAutoFocus?: boolean;

  /** An array of [`RCAutocompleteItem`](https://github.com/reverbdotcom/frontend/blob/main/packages/cadence/components/RCAutocomplete/RCAutocompleteItem.tsx) objects.
   *  For custom styling and layout, wrap RCAutoCompleteItem around your content.
   * */
  children: React.ReactNode;
}

export function RCAutocomplete({
  isOpen,
  onOpenChange,
  placeholder = undefined,
  inputValue,
  onInputChange,
  onSubmit = undefined,
  loading = false,
  required = false,
  disabled = false,
  inputId,
  preventAutoFocus = false,
  children,
}: RCAutocompleteProps) {
  return (
    <div className="rc-autocomplete">
      <RCComboboxBase
        isOpen={isOpen}
        onOpenChange={(v) => onOpenChange(v)}
        type="search"
        inputValue={inputValue}
        placeholder={placeholder}
        isLoading={loading}
        required={required}
        disabled={disabled}
        onInputChange={onInputChange}
        inputId={inputId}
        onSubmit={onSubmit}
        preventAutoFocus={preventAutoFocus}
      >
        {React.Children.toArray(children).map((child) => {
          if (!React.isValidElement(child)) return null;
          const item = child as React.FunctionComponentElement<RCAutocompleteItemProps>;
          if (item.type?.displayName !== 'RCAutocompleteItem') throw new Error(
            'Children should be an array of <RCAutocompleteItem />',
          );
          return item.type?.displayName === 'RCAutocompleteItem' ? child : null;
        })}
      </RCComboboxBase>
    </div>
  );
}
