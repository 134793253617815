import React from 'react';
import { RCThemeContext, Theme, useTheme } from '../';

interface RCThemeOverrideProps {
  /** The theme that this content block should be locked to. Adds `data-theme={theme}` to the child element. */
  theme: Theme;
  /** In rare cases the theme override should be conditional. Defaults to true. */
  enabled?: boolean;
  /** The DOM node to apply the override to. Should be wrapped in `<RCThemeOverride>`. */
  children: React.ReactElement;
}

/**
 * A passthrough component for locking an element and its children to a specific theme.
 * For example, this can allow a DOM node to maintain its light mode appearance in dark mode.
 */
export function RCThemeOverride({
  theme = 'light',
  enabled = true,
  children,
}: RCThemeOverrideProps) {
  const { theme: parentTheme } = useTheme();

  // Does not render a theme provider unless its needed
  if (!enabled || parentTheme === theme) return children;

  const child = React.Children.only(children);

  if (!React.isValidElement(child)) return null;

  if (child.type === React.Fragment) {
    return (
      <RCThemeContext.Provider value={{ theme }}>
        <div data-theme={theme}>
          {children}
        </div>
      </RCThemeContext.Provider>
    );
  }

  return (
    <RCThemeContext.Provider value={{ theme }}>
      {React.cloneElement(child, {
        ...{ 'data-theme': theme },
      })}
    </RCThemeContext.Provider>
  );
}
