import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgStarEmpty = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M21.623 24.517a1.33 1.33 0 0 1-.99.479v-.01c-.23.01-.45-.05-.65-.15l-5.98-3.1-5.98 3.12a1.33 1.33 0 0 1-1.93-1.4l1.1-6.66-4.79-4.75a1.33 1.33 0 0 1 .73-2.27l6.66-1.01 3.02-6.03a1.33 1.33 0 0 1 2.38 0l3.02 6.04 6.66 1a1.33 1.33 0 0 1 .74 2.27l-4.8 4.75 1.1 6.66a1.33 1.33 0 0 1-.29 1.06zm-8.27-4.581c.46-.23 1-.23 1.46 0l5.09 2.64-.93-5.66a1.6 1.6 0 0 1 .45-1.4l4.08-4.04-5.66-.86c-.52-.07-.96-.39-1.19-.85l-2.57-5.12-2.56 5.12c-.24.46-.68.78-1.19.85l-5.66.86 4.07 4.04a1.6 1.6 0 0 1 .45 1.39l-.93 5.67 5.09-2.64z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgStarEmpty;
