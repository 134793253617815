import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

import { RCCloseButton } from '../';
import { AlertType } from '../types';

interface IRCAlertBoxProps {
  /**
   * The nature of the alert.
   * Think about how important the message is to the user in terms of urgency, and whether the message itself is 'negative' (error, warning) or not.
   *
   * We'll be revisiting these soon and likely deprecating `highlight` as redundant with `warning`.
   *
   * - __Success:__ High urgency
   * - __Error:__ High urgency, negative message
   * - __Info:__ Medium urgency
   * - __Warning:__ Medium urgency, negative message
   * - __Plain:__ Low urgency
   *
  */
  type: AlertType;

  /** Reduced font size & padding.
   * Use this for shorter alert messages that need to be displayed within a given block of content rather than above or below it. */
  small?: boolean;

  /** Removes padding altogether. Don't use this, it's on its way toward deprecation.  */
  unpadded?: boolean;

  /** Displays the AlertBox as `inline-block`. Potentially being deprecated. */
  inline?: boolean;

  /** AlertBox message content. Should almost always be 1-2 `<p>` tags. Keep messages concise and written in full sentences (max 3 sentences). */
  children: React.ReactNode;

  /** If you pass a handler here, the AlertBox will render a close button. */
  onDismiss?: React.MouseEventHandler<HTMLButtonElement>;

  /** Will render a larger header with a white background. Use it when your alert content requires more context. Limit this to 1-4 words. */
  headerText?: string | React.ReactNode;
}

/**
 * Use this component to deliver a message directly to the user.
 * Alert Boxes communicate information that is on the page due to a specific condition being met
 * (e.g. the user has an error in a form they need to fix).
 *
 * Make the message dismissible if it isn't able to be dismissed via another means.
 * For example, a form error Alert Box is "dismissed" by the user correcting the error,
 * so it doesn't need its own dismissal action, but a notification of a new feature or sale might need a dismissal action
 * as a way for the user to take action on it once they've read it.
 */
export function RCAlertBox({
  type,
  small = false,
  inline = false,
  unpadded = false,
  children,
  onDismiss = undefined,
  headerText = undefined,
}: IRCAlertBoxProps) {
  const isDismissible = !!onDismiss;

  const classes = classNames(
    'rc-alert-box',
    { 'rc-alert-box--info': type === 'info' },
    { 'rc-alert-box--warning': type === 'warning' },
    { 'rc-alert-box--error': type === 'error' },
    { 'rc-alert-box--success': type === 'success' },
    { 'rc-alert-box--highlight': type === 'highlight' },
    { 'rc-alert-box--plain': type === 'plain' },
    { 'rc-alert-box--small': small },
    { 'rc-alert-box--unpadded': unpadded },
    { 'rc-alert-box--inline': inline },
    { 'rc-alert-box--dismissible': isDismissible },
    { 'rc-alert-box--with-header': !!headerText },
  );

  return (
    <div className={classes}>
      {headerText && (
        <header className="rc-alert-box__header">
          <h3 className="rc-alert-box__header-text">
            {headerText}
          </h3>
        </header>
      )}
      <div className="rc-alert-box__content">
        {children}
      </div>
      {isDismissible &&
        <div className="rc-alert-box__dismiss">
          <RCCloseButton
            onClick={onDismiss}
            aria-label={I18n.t('cadence.RCAlertBox.dismissAriaLabel')}
          />
        </div>
      }
    </div>
  );
}
