/* eslint import/no-extraneous-dependencies: 'off' */
/* TODO: remove this manual vendor file so we don't violate this eslint rule */

import 'core-js';
import 'regenerator-runtime/runtime';
import 'url-api-polyfill';

// by adding this to the very top of the application, we can better catch
// boot errors in code we wrote
import './components/sentry';

import React from 'react';
import 'react-dom';

import 'react-helmet';
import 'react-router';
import 'moment';
import 'jquery';
import 'lodash';

import 'jquery-migrate';

import '@reverbdotcom/commons/src/elog';
import 'jquery-ujs';
import 'alt';
import 'accounting';
import '@apollo/client';
import 'history';
import '@sentry/browser';
import 'react-modal';
import 'react-image-crop';
import 'alt-container';
import 'whatwg-fetch';
import 'js-cookie';
import 'qs';
import 'tslib';
import 'react-datetime';
import 'dompurify/dist/purify';

import 'i18n-js';

import './vendor/jquery-powertip/jquery.powertip';
import '@reverbdotcom/discovery-ui/src/vendor/jquery-fancybox';
import './vendor/jquery.hoverIntent';
import './vendor/temporal';
import './vendor/jquery.sortable';
import './vendor/jquery.ui.widget';
import './vendor/jquery.iframe-transport';
import './vendor/jquery.fileupload';
import './vendor/jquery.cloudinary';

import './vendor/fotorama';
import './vendor/chosen/chosen';

window.__REACT_VERSION__ = React.version;
