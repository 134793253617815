import React from 'react';
import classNames from 'classnames';
/* eslint-disable-next-line no-restricted-imports */
import { motion } from 'framer-motion';

import { ChevronDownIcon, ChevronUpIcon, ArrowRightIcon } from '../../icons/react';
import { RCIcon } from '../';

interface IProps {
  /** The content to be shown / hidden on toggle. */
  children?: React.ReactNode;
  /** The heading above the toggled area. Should be a RCAccordion.Heading, but can also be custom layout code (just make sure the main title string is a `RCAccordion.Heading`).<br><br> Try to keep the heading text to <6 words; it will cleanly wrap to multiple lines if needed but try and minimize the likelihood of wrapping. */
  heading: string | React.ReactNode;
  /** Passed through to the HTML tag; used to auto-scroll/jump to a specific section and for Aria controls. */
  id: string;
  /** Render as open, initially, but still allow toggling.*/
  defaultOpen?: boolean;
  /** Event handler for the toggle. */
  onToggle?: (state: boolean) => void;
  /** Size options. `undefined` renders default. */
  size?: 'small' | 'large' | undefined;
  /** Locks the toggle, effectively displaying it as a heading. Used occasionally when an RCAccordion is in a list with non-toggled items. */
  locked?: boolean;
  /** Behave as a link. Used occasionally when an RCAccordion is in a list with non-toggled items that behave differently */
  asLink?: boolean;
  /** OnClick. Fired in place of onToggle if asLink. */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * Zeros out padding of toggled child container.
   *
   * Only use this if your children are appropriately padded, to keep them flush with the heading text.
   *
   * Use the `rc-space-x-accordion` token for this.
   */
  zeroPadding?: boolean;
}

/**
 * Generic container for hiding / showing content, triggered by clicking on the heading.
 *
 * For most cases, the heading can be a string, but you can pass in custom markup (if you go this route, use `RCAccordion.Heading` to keep the heading typography consistent.)
 */

export function RCAccordion({
  children = undefined,
  defaultOpen = false,
  heading,
  id,
  zeroPadding,
  onToggle = undefined,
  size = undefined,
  locked = undefined,
  asLink = undefined,
  onClick = undefined,
}: IProps) {
  const [collapsed, setCollapsed] = React.useState(!defaultOpen);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  function toggle() {
    if (onToggle) {
      onToggle(collapsed);
    }

    setCollapsed(value => !value);
  }

  function icon() {
    if (asLink) return ArrowRightIcon;
    if (collapsed) return ChevronDownIcon;
    return ChevronUpIcon;
  }

  const classes = classNames(
    'rc-accordion',
    {
      'rc-accordion--collapsed': collapsed,
      'rc-accordion--zero-padding': zeroPadding,
    },
  );

  const headingClasses = classNames(
    'rc-accordion__heading',
    {
      'rc-accordion__heading--locked': locked,
      'rc-accordion__heading--small': size === 'small',
      'rc-accordion__heading--large': size === 'large',
    },
  );

  const innerContent = (
    <div className="rc-accordion__content">
      {children}
    </div>
  );

  return (
    <div
      className={classes}
      id={`rc-accordion__${id}`}
    >
      <h3
        className={headingClasses}
      >
        <button
          type="button"
          onClick={asLink ? onClick : toggle}
          className="rc-accordion__heading__button"
          aria-expanded={!collapsed}
          aria-controls={`rc-accordion__${id}-content`}
          disabled={locked}
        >
          <div className="rc-accordion__heading__content">
            { typeof heading === 'string' ? <AccordionHeading text={heading} /> : heading }
          </div>
          <span className="rc-accordion__heading__icon">
            <RCIcon svgComponent={icon()} />
          </span>
        </button>
      </h3>

      {/** Falls back to non-animated wrapper to prevent errors during SSR */}
      {!loaded ? (
        <div
          id={`rc-accordion__${id}-content`}
          className="rc-accordion__content-wrapper"
          hidden={collapsed}
        >
          {innerContent}
        </div>
      ) : (
        <motion.div
          id={`rc-accordion__${id}-content`}
          className="rc-accordion__content-wrapper"
          hidden={collapsed}
          initial={defaultOpen ? 'open' : 'collapsed'}
          animate={collapsed ? 'collapsed' : 'open'}
          variants={{
            open: {
              opacity: 1,
              height: 'auto',
              display: 'block',
            },
            collapsed: {
              opacity: 0,
              height: 0,
            },
          }}
          transition={{
            ease: [0.4, 0.0, 0.2, 1], // Material's FastOutSlowIn
            duration: .3,
          }}
        >
          {innerContent}
        </motion.div>
      )}
    </div>
  );
}

interface IAccordionHeadingProps {
  text: string;
}

function AccordionHeading({
  text,
}: IAccordionHeadingProps) {
  return (
    <div className="rc-accordion__heading__title">
      {text}
    </div>
  );
}

RCAccordion.Heading = AccordionHeading;
