import { Affirm } from './affirm';
import { MParticleShape } from './elog/mparticle_load';
import { Trustpilot } from './trustpilot';
import { AccertifyBcn } from './accertify';

interface GoogleRecaptcha {
  enterprise: {
    execute: (key: string, options: { action: string }) => Promise<string>;
  }
}

type CommonsWindow = typeof window & {
  mParticle?: Partial<MParticleShape>;
  criteo_q?: PlainObject[];
  grecaptcha?: GoogleRecaptcha;
  affirm: Affirm;
  Trustpilot?: Trustpilot;
  _bcn: AccertifyBcn;
};

export default window as CommonsWindow;
