import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';
import { useId } from '@floating-ui/react';

import { RCButton, useMediaQuery } from '../';
import { RCNonModalDialogBase } from '../internalComponents/RCNonModalDialogBase';
import { preferLightTheme } from '../helpers/themeOverrides';

type FloatingUIPositions = 'bottom' | 'bottom-start' | 'bottom-end';

interface RCGuidePopoverProps {
  /** Open state of the component (controlled by the parent). */
  isOpen: boolean,

  /** Fired when the user clicks the dismissal button or presses escape (on Desktop Web) or taps outside the component (on Mobile Web). */
  onDismiss: Function,

  /** The primary text. Keep this below about 60 characters. On Mobile Web, this will the only text that displays. */
  title: string,

  /** *Desktop Only* - A string or block of HTML that renders in the popover between the header and dismiss button.*/
  content: JSX.Element | string,

  /** The element to which the popover will point & anchor. */
  anchor: JSX.Element,

  /** The popover automatically positions itself,
   * but if you need to ensure it is always flush left or right (e.g. to avoid covering up key UI, use this. */
  position?: FloatingUIPositions,

  /**  If you set this to true, the popover will stay in-viewport even if the user scrolls away from its anchor. */
  sticky?: boolean,

  /** Overrides the default dismissal button text of "Got It". (This button is hidden on Mobile Web.) */
  dismissText?: string | JSX.Element,

  /** `Narrow` sets a reduced max width for the element; use this in areas where you need to minimize content overlap. */
  width?: 'default' | 'narrow',

  /** Prevents the popover and its anchor from stealing focus when it opens. Should be
   * used when RCGuidePopover opens on page load or is not otherwise triggered by a user action. */
  preventAutoFocus?: boolean;
}

function ariaID(id) {
  return {
    label: `${id}-label`,
    description: `${id}-description`,
  };
}

export function RCGuidePopover({
  isOpen,
  onDismiss,
  title,
  position = 'bottom-end',
  sticky = false,
  content,
  anchor,
  dismissText = I18n.t('cadence.RCGuidePopover.gotIt'),
  width = 'default',
  preventAutoFocus = false,
}: RCGuidePopoverProps) {
  const id = useId();
  const isMobile = useMediaQuery('mobile');
  const classes = classNames(
    'rc-guide-popover',
    { 'rc-guide-popover--narrow': width === 'narrow' },
  );

  return (
    <RCNonModalDialogBase
      id={id}
      isOpen={isOpen}
      onOpenChange={(state) => {
        if (state === true) return;
        onDismiss();
      }}
      sticky={sticky}
      position={position}
      anchor={anchor}
      dismissOnOutsidePress={isMobile}
      variant="guide-popover"
      /** When trapFocus is false the popover closes when the the anchor & popover content lose focus */
      trapFocus={true}
      focusOrder={['reference', 'content']}
      initialFocus={preventAutoFocus ? -1 : 1}
    >
      <div className={classes}>
        <p
          id={ariaID(id).label}
          className="rc-guide-popover__title"
        >
          {title}
        </p>
        {!isMobile &&
          <>
            <div
              id={ariaID(id).description}
              className="rc-guide-popover__content"
            >
              {content}
            </div>
            <div className="rc-guide-popover__footer" {...preferLightTheme()}>
              <RCButton
                variant="muted"
                onClick={() => onDismiss()}
                inverted
              >
                {dismissText}
              </RCButton>
            </div>
          </>
        }
      </div>
    </RCNonModalDialogBase>
  );
}
