import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgExclamation = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17.057 21.09c0-.596-.494-1.09-1.09-1.09h-4.364c-.597 0-1.091.494-1.091 1.09v3.82c0 .596.494 1.09 1.09 1.09h4.364c.597 0 1.091-.494 1.091-1.09v-3.82Zm.512-18A1.059 1.059 0 0 0 16.512 2h-5.455A1.06 1.06 0 0 0 10 3.09l.478 13.092a1.14 1.14 0 0 0 1.125 1.09h4.363a1.14 1.14 0 0 0 1.125-1.09L17.57 3.09Z" />
  </svg>
);
export default SvgExclamation;
