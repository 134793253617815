import React from 'react';
import classNames from 'classnames';

import I18n from 'i18n-js';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  arrow,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  Placement,
  useClick,
  safePolygon,
  useMergeRefs,
  useDelayGroup,
  useTransitionStatus,
} from '@floating-ui/react';

import { FLOATING_UI_DOM_ID, PANEL_DEFAULTS } from '../../constants';
import { QuestionCircleIcon } from '../../icons/react';
import { RCIcon } from '../';

interface IProps {
  /**
   * Placement of the tooltip. Renders centered above its triggering element by default.
   * Options currently available here:
  */
  placement?: Placement;
  /**
   * Element that the tooltip will attach to. Optional; if the tooltip type is `icon` it'll render its own info icon & attach itself to that.
   */
  children?: React.ReactNode;
  /**
   * Text to display within the tooltip. Keep tooltips concise & sentence-case; use ending punctuation if it's longer than a few words.
   */
  text: string | JSX.Element | React.ReactNode;
  /**
   * Type of tooltip display:
   *
   * - `icon`: renders an info icon to the right of anchoring text (if any is provided)
   * - `underline`: adds a dashed border underneath the anchoring text
   * - `plain`: doesn't affect the anchoring text at all
   */
  type?: 'icon' | 'underline' | 'plain';
  /**
   * Stops clicks within the tooltip from triggering clicks to the parent container. Use if you're running into unexpected event collisions.
   */
  stopPropagation?: boolean;
  /**
   *  Modify the visual display style of the tooltip.
   *
   * - `popover`: Default styling. Visually matches RCPopover.
   * - `buttonLabel`: Used to provide a condensed label to iconographic button elements.
   */
  style?: 'popover' | 'buttonLabel';
  /**
   *  Use with Caution! Hides the tooltip text from screen readers.
   *  This prop should only be used in cases where the trigger element has an identical aria-label.
   */
  visualOnly?: boolean;

  /** Optional callback that occurs when a state change happens. The callback includes the new state boolean. */
  onOpenChange?: (open: boolean) => void;
}

interface IBaseTriggerElementProps {
  children?: React.ReactNode;
  type?: 'icon' | 'underline' | 'plain';
}
// Default <button> if children is non-existent or a string
function baseTriggerElement({
  children = undefined,
  type = undefined,
}: IBaseTriggerElementProps) {
  const triggerClasses = classNames(
    'rc-tooltip__trigger',
    {
      'rc-tooltip__trigger--icon': type === 'icon',
      'rc-tooltip__trigger--underline': type === 'underline',
    },
  );

  if (children && typeof children !== 'string') {
    return children;
  }

  if (type === 'plain') {
    return (
      <button type="button" className={triggerClasses}>
        {children}
      </button>
    );
  }

  const ariaLabel = children ? undefined : I18n.t('cadence.RCTooltip.ariaLabel');

  return (
    <button
      type="button"
      className={triggerClasses}
      aria-label={ariaLabel}
    >
      {!!children && (
        <span className="rc-tooltip__trigger__inner">
          {children}
        </span>
      )}
      {(type === 'icon') && (
        <RCIcon svgComponent={QuestionCircleIcon} inline />
      )}
    </button>
  );
}

export function RCTooltip({
  children = undefined,
  text,
  placement = 'top',
  type = 'icon',
  stopPropagation = false,
  style = 'popover',
  visualOnly = false,
  onOpenChange = undefined,
}: IProps) {
  const [open, setOpen] = React.useState(false);
  const arrowRef = React.useRef(null);

  const {
    context,
    refs,
    middlewareData,
  } = useFloating({
    open,
    onOpenChange: (state: boolean) => {
      onOpenChange?.(state);
      setOpen(state);
    },
    placement,
    middleware: [
      offset(PANEL_DEFAULTS.anchorOffset),
      flip(),
      shift({
        padding: PANEL_DEFAULTS.windowEdgePadding,
        crossAxis: true,
      }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: (r, f, u) => {
      // We only need to auto update if it's possible to do so
      let cleanup = undefined;
      if (('ResizeObserver' in window)) {
        cleanup = autoUpdate(r, f, u);
      }
      return cleanup;
    },
  });

  // Prefer parent DelayGroup's delay values if available
  const { delay, currentId, isInstantPhase } = useDelayGroup(context);
  const hoverDelay = delay || {
    open: 350,
    close: 100,
  };

  const { isMounted, status } = useTransitionStatus(context, {
    duration: isInstantPhase ? {
      open: 0,
      close: currentId === context.floatingId ? 100 : 0,
    } : hoverDelay,
  });

  // Event listeners to change the open state
  const hover = useHover(context, {
    delay: hoverDelay,
    mouseOnly: false,
    // safePolygon uses `performance.now()` – only use it if available on the window
    handleClose: (global.window && 'performance' in window) ? safePolygon() : null,
  });
  const focus = useFocus(context);
  const click = useClick(context, {
    ignoreMouse: false,
    toggle: true,
    // prevents button labels from overlapping popovers and menus
    enabled: style === 'buttonLabel' ? false : true,
  });
  const dismiss = useDismiss(context, {
    referencePress: style === 'buttonLabel' ? true : false,
    referencePressEvent: 'mousedown',
  });
  const role = useRole(context, {
    enabled: !visualOnly,
    role: 'tooltip',
  });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    click,
    dismiss,
    role,
  ]);

  function onClick(e) {
    if (stopPropagation) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  function arrowSide(panelPlacement) {
    // the side of the anchor that the panel sits on comes from `placement`
    const [ panelSide ] = panelPlacement.split('-');

    // the arrow sits on that opposite side, so we remap it here.
    const arrowSideMapping = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    };

    return arrowSideMapping[panelSide];
  }

  function arrowPositionStyles(arrowData, panelPlacement) {
    return {
      top: arrowData?.y && `${arrowData?.y}px`,
      left: `${arrowData?.x}px`,

      // override defaults above based on position
      [arrowSide(panelPlacement)]: `-${PANEL_DEFAULTS.arrowHeightSmall}px`,
    };
  }

  const triggerEl = baseTriggerElement({ children, type }) as JSX.Element;

  // ensure that if children exists, it's only a single node
  let child;
  if (children && typeof children !== 'string') {
    child = React.Children.only(children) as any;
  }

  return (
    <>
      {React.cloneElement(
        triggerEl,
        getReferenceProps({
          ...triggerEl?.props,
          ...child?.props,
          tabIndex: child?.props?.tabIndex ?? 0,
          ref: useMergeRefs([refs.setReference, child?.ref]),
          onClick: (e) => {
            if (stopPropagation) onClick(e);
            child?.props?.onClick?.(e);
          },
          'data-rc-tooltip-trigger': true,
        }),
      )}
      {isMounted && (
        <FloatingPortal id={FLOATING_UI_DOM_ID}>
          <div
            data-status={status}
            data-placement={placement}
            data-instant={isInstantPhase}
            ref={refs.setFloating}
            className={classNames(
              'rc-tooltip',
              {
                'rc-tooltip--popover': style === 'popover',
                'rc-tooltip--label': style === 'buttonLabel',
              },
            )}
            style={{
              position: context.strategy,
              top: context.y ?? 0,
              left: context.x ?? 0,
              width: 'max-content',
            }}
            {...getFloatingProps()}
          >
            {text}
            <div
              className={`rc-tooltip__arrow rc-tooltip__arrow--${arrowSide(placement)}`}
              ref={arrowRef}
              style={arrowPositionStyles(middlewareData?.arrow, placement)}
            />
          </div>
        </FloatingPortal>
      )}
    </>
  );
}
