import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgMove = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="m25.749 14.605-3.429 3.43a.856.856 0 1 1-1.212-1.213l1.966-1.965h-8.218v8.217l1.966-1.966a.856.856 0 1 1 1.212 1.212l-3.429 3.429a.858.858 0 0 1-1.213 0L9.964 22.32a.856.856 0 1 1 1.212-1.212l1.966 1.966v-8.218H4.925l1.966 1.966a.856.856 0 1 1-1.212 1.212l-3.43-3.429a.861.861 0 0 1 0-1.213l3.43-3.428a.856.856 0 1 1 1.212 1.212l-1.966 1.966h8.217V4.925l-1.965 1.966a.856.856 0 1 1-1.212-1.212l3.428-3.43a.861.861 0 0 1 1.213 0l3.429 3.43a.856.856 0 1 1-1.213 1.212l-1.965-1.966v8.217h8.217l-1.966-1.965a.856.856 0 1 1 1.212-1.212l3.429 3.428a.86.86 0 0 1 0 1.212Z" />
  </svg>
);
export default SvgMove;
