import React from 'react';
import I18n from 'i18n-js';

import { CommonInputProps } from '../../types';
import { RCTextInput } from '../../';
import { isEmailValid } from '../../../components/helpers/isEmailValid';

interface EmailInputProps extends Omit<CommonInputProps, 'type' | 'prefixText' | 'suffixText'> {
  /** Passed through to the input tag. Called when the field loses focus. */
  onBlur?: React.FocusEventHandler<HTMLInputElement>;

  /** Current value of the input. Passed through to the input tag. */
  value: string;

  /** Passed through to the input tag. Text that displays when the field is empty. */
  placeholder?: string;
}

function validationErrorText(inputValue: string) {
  // no need to tell the user their email is invalid if they haven't typed anything
  if (!inputValue) {
    return '';
  }

  if (!isEmailValid(inputValue)) {
    return I18n.t('cadence.RCEmailInput.emailValidationError');
  }
  return '';
}

/**
 * Specialized version of `RCTextInput` with realtime email validation.
 */
export function RCEmailInput({
  errorText = undefined,
  value,
  ...passthroughProps
}: EmailInputProps) {
  return (
    <RCTextInput
      {...passthroughProps}
      errorText={errorText || validationErrorText(value)}
      type="email"
      autoComplete="email"
      inputMode="email"
      value={value}
    />
  );
}
