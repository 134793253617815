import React from 'react';
import I18n from 'i18n-js';

import { RCFormGroup } from '../..';
import { CommonInputProps } from '../../types';

export interface OptionProps {
  label: string;
  value: string;
  disabled?: boolean;
}

/** An individual option within an RCSelect */
function Option({
  disabled = false,
  label,
  value,
}: OptionProps) {
  return (
    <option
      disabled={disabled}
      value={value}
    >
      {label}
    </option>
  );
}

export interface OptionGroupProps {
  label?: string;
  /** Children must be `RCSelect.Option` */
  children?: React.ReactElement<OptionProps> | React.ReactElement<OptionProps>[];
}

/** Grouped options within an RCSelect */
function OptionGroup({
  children = undefined,
  label = undefined,
}: OptionGroupProps) {
  return (
    <optgroup label={label}>
      {children}
    </optgroup>
  );
}

type SelectChild = React.ReactElement<OptionProps> | React.ReactElement<OptionGroupProps>;

interface Props extends Omit<CommonInputProps, 'id' | 'inputId' | 'onChange'> {
  /** Unique ID passed through the the applicable HTML tags */
  id: string;

  /** Passed through to the applicable HTML tag. */
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;

  /** Called when the input element loses focus. */
  onBlur?: React.FocusEventHandler<HTMLSelectElement>;

  /** Current value of the input. Passed through to the `select` tag. */
  value: string;

  /** List of options to display in the `select` tag. Must be `Option` or `OptionGroup` components. */
  children: SelectChild | SelectChild[];

  /**
   * Set this to `true` to prepend a "Select One" option. If `required` is true, the option will be disabled (preventing a user from undoing their selection).
   */
  addEmptyOption?: boolean;

  /** when addEmptyOption is true, sets the empty option's label (defaults to translated 'cadence.RCSelect.selectOne'). */
  emptyOptionLabel?: string;
}

/**
 * Styled dropdown tag. Uses `RCFormGroup` internally to handle label, tag, and so forth.
 */

export function RCSelect({
  addEmptyOption = false,
  children,
  disabled,
  emptyOptionLabel = undefined,
  errorText,
  helpText,
  id,
  label,
  name,
  onBlur = undefined,
  onChange = undefined,
  required,
  tag,
  value,
}: Props) {
  return (
    <RCFormGroup
      inputId={id}
      label={label}
      errorText={errorText}
      helpText={helpText}
      tag={tag}
    >
      <div className="rc-select">
        <select
          disabled={disabled}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          required={required}
          value={value}
        >
          {addEmptyOption &&
            <Option
              disabled={required}
              value=""
              label={emptyOptionLabel || I18n.t('cadence.RCSelect.selectOne')}
            />
          }
          {children}
        </select>
      </div>
    </RCFormGroup>
  );
}

RCSelect.Option = Option;
RCSelect.OptionGroup = OptionGroup;
