import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgX = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M20.326 4.057H23.7l-7.37 8.424L25 23.943h-6.789l-5.317-6.952-6.084 6.952H3.434l7.883-9.01L3 4.057h6.96l4.807 6.354 5.56-6.354Zm-1.184 17.867h1.87L8.945 5.97H6.94l12.203 15.954Z" />
  </svg>
);
export default SvgX;
