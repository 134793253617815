import { IUser } from '@reverbdotcom/commons/src/components/user_context_provider';
import { getMParticleUserInfoCookie } from '@reverbdotcom/commons/src/elog/mparticle';

export const USER_INFO_HEADER = 'X-Reverb-User-Info';

export function reverbUserInfoProperties(user: Partial<IUser>) {
  const mParticleUserInfo = getMParticleUserInfoCookie();

  return {
    mpid: mParticleUserInfo.mpid,
    session_id: mParticleUserInfo.session_id,
    device_id: mParticleUserInfo.device_id,
    user_id: user.id,
    ra: user.isAdmin,
    is_bot: user.isBot,
  };
}

export function reverbUserInfoHeader(user: Partial<IUser>) {
  return {
    [USER_INFO_HEADER]: JSON.stringify(reverbUserInfoProperties(user)),
  };
}
