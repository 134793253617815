import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgSun = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M14 9.333a4.667 4.667 0 1 0 0 9.334 4.667 4.667 0 0 0 0-9.334ZM7 14a7 7 0 1 1 14 0 7 7 0 0 1-14 0Zm7-14c.644 0 1.167.522 1.167 1.167V3.5a1.167 1.167 0 0 1-2.334 0V1.167C12.833.522 13.356 0 14 0Zm0 23.333c.644 0 1.167.523 1.167 1.167v2.333a1.167 1.167 0 0 1-2.334 0V24.5c0-.644.523-1.167 1.167-1.167ZM4.098 4.098a1.167 1.167 0 0 1 1.65 0l1.657 1.657a1.167 1.167 0 0 1-1.65 1.65L4.098 5.748a1.167 1.167 0 0 1 0-1.65Zm16.497 16.497a1.167 1.167 0 0 1 1.65 0l1.657 1.657a1.167 1.167 0 0 1-1.65 1.65l-1.657-1.657a1.167 1.167 0 0 1 0-1.65ZM0 14c0-.644.522-1.167 1.167-1.167H3.5a1.167 1.167 0 0 1 0 2.334H1.167A1.167 1.167 0 0 1 0 14Zm23.333 0c0-.644.523-1.167 1.167-1.167h2.333a1.167 1.167 0 0 1 0 2.334H24.5A1.167 1.167 0 0 1 23.333 14ZM7.405 20.595a1.167 1.167 0 0 1 0 1.65l-1.657 1.657a1.167 1.167 0 0 1-1.65-1.65l1.657-1.657a1.167 1.167 0 0 1 1.65 0ZM23.902 4.098a1.167 1.167 0 0 1 0 1.65l-1.657 1.657a1.167 1.167 0 0 1-1.65-1.65l1.657-1.657a1.167 1.167 0 0 1 1.65 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSun;
