import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgPinterest = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M26 14c0 2.177-.537 4.185-1.61 6.023a11.945 11.945 0 0 1-4.367 4.368C18.185 25.464 16.177 26 14 26c-1.156 0-2.292-.167-3.406-.5.614-.969 1.02-1.823 1.219-2.563.093-.354.374-1.453.843-3.296.209.406.589.757 1.14 1.054a3.703 3.703 0 0 0 1.782.446c1.26 0 2.386-.357 3.375-1.07.99-.714 1.755-1.696 2.297-2.946.542-1.25.813-2.656.813-4.219a6.43 6.43 0 0 0-.93-3.344c-.62-1.041-1.518-1.89-2.695-2.546-1.178-.657-2.506-.985-3.985-.985a10.03 10.03 0 0 0-3.062.453c-.948.302-1.753.704-2.414 1.204a8.229 8.229 0 0 0-1.704 1.726 7.144 7.144 0 0 0-1.046 2.024 6.818 6.818 0 0 0-.336 2.093c0 1.084.208 2.037.625 2.86.416.823 1.026 1.4 1.828 1.734.312.125.51.02.594-.313.02-.072.062-.234.124-.484.063-.25.105-.406.126-.469.062-.24.005-.463-.172-.672-.532-.635-.797-1.421-.797-2.359 0-1.573.544-2.924 1.633-4.055 1.088-1.13 2.513-1.695 4.273-1.695 1.573 0 2.8.427 3.68 1.281.88.854 1.32 1.964 1.32 3.329 0 1.77-.357 3.275-1.07 4.515-.714 1.24-1.628 1.86-2.742 1.86-.636 0-1.146-.227-1.532-.68-.385-.453-.505-.998-.36-1.633.084-.365.222-.852.415-1.46.193-.61.349-1.147.469-1.61.12-.464.18-.857.18-1.18 0-.52-.141-.953-.422-1.297-.282-.344-.683-.515-1.204-.515-.646 0-1.192.296-1.64.89-.448.594-.672 1.334-.672 2.219 0 .76.13 1.396.39 1.906l-1.546 6.531c-.177.73-.245 1.651-.204 2.766-2.145-.948-3.88-2.412-5.203-4.39C2.661 18.63 2 16.426 2 14c0-2.177.536-4.185 1.61-6.023a11.946 11.946 0 0 1 4.367-4.368C9.815 2.536 11.823 2 14 2c2.177 0 4.185.536 6.023 1.61a11.945 11.945 0 0 1 4.368 4.367C25.464 9.815 26 11.823 26 14z" />
  </svg>
);
export default SvgPinterest;
