import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgFacebookAlt = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M20.984.188v4.125h-2.453c-.896 0-1.5.187-1.812.562-.313.375-.469.938-.469 1.688v2.953h4.578l-.61 4.625H16.25V26h-4.781V14.14H7.484V9.517h3.985V6.109c0-1.937.541-3.44 1.625-4.507C14.177.534 15.62 0 17.422 0c1.531 0 2.719.063 3.562.188z" />
  </svg>
);
export default SvgFacebookAlt;
