import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgKeyboards = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M7 4.663A2.337 2.337 0 0 0 4.664 7v14.008A2.337 2.337 0 0 0 7 23.344h14.008a2.337 2.337 0 0 0 2.337-2.336V7a2.337 2.337 0 0 0-2.337-2.337H7zm-.056 1.502a.837.837 0 0 0-.78.835v14.008c0 .462.374.836.836.836h1.444v-7.05h-1.5v-8.63zm2.5 15.68H13.5v-7.052H12v-8.63h-1.056v8.63h-1.5v7.051zM16 6.162v8.63h-1.5v7.051h4.056v-7.05h-1.5v-8.63H16zm5.056.002v8.628h-1.5v7.051h1.452a.837.837 0 0 0 .837-.836V7c0-.446-.35-.81-.79-.835z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgKeyboards;
