import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgApple = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M24.766 18.984c-.407 1.302-1.047 2.604-1.922 3.907-1.344 2.041-2.683 3.062-4.016 3.062-.51 0-1.24-.166-2.187-.5-.896-.333-1.683-.5-2.36-.5-.635 0-1.375.172-2.219.516-.843.354-1.53.531-2.062.531-1.583 0-3.151-1.349-4.703-4.047C3.766 19.234 3 16.615 3 14.093c0-2.374.589-4.322 1.766-5.843C5.943 6.75 7.422 6 9.203 6c.75 0 1.672.156 2.766.469 1.083.312 1.802.468 2.156.468.469 0 1.213-.177 2.234-.53 1.063-.355 1.964-.532 2.703-.532 1.24 0 2.35.339 3.329 1.016.541.375 1.083.895 1.625 1.562-.823.698-1.417 1.313-1.782 1.844a5.564 5.564 0 0 0-1.015 3.234c0 1.292.36 2.453 1.078 3.485.719 1.03 1.541 1.687 2.469 1.968zM18.89.656c0 .636-.151 1.344-.453 2.125-.313.781-.797 1.5-1.454 2.156-.562.563-1.125.938-1.687 1.125-.386.115-.927.204-1.625.266.031-1.552.437-2.89 1.219-4.016C15.66 1.198 16.963.427 18.797 0c.01.031.023.089.039.172.016.083.029.14.039.172 0 .041.003.094.008.156.005.063.008.115.008.156z" />
  </svg>
);
export default SvgApple;
