import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgGridView = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={10} height={10} x={2} y={16} rx={2} />
    <rect width={10} height={10} x={16} y={16} rx={2} />
    <rect width={10} height={10} x={2} y={2} rx={2} />
    <rect width={10} height={10} x={16} y={2} rx={2} />
  </svg>
);
export default SvgGridView;
