import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgShieldCheck = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#a)">
      <path d="M19.387 11.193a1 1 0 1 0-1.441-1.386l-4.892 5.085-1.834-1.901a1 1 0 0 0-1.44 1.388l2.555 2.649a1 1 0 0 0 1.44-.001l5.612-5.834z" />
      <path
        fillRule="evenodd"
        d="M7.957 24.185a29.093 29.093 0 0 0 5.46 3.675c.373.187.793.187 1.166 0a29.095 29.095 0 0 0 5.46-3.675c3.489-2.998 5.624-6.405 5.624-10.185V5.087c0-.525-.339-1.004-.84-1.19L14.455.082a1.227 1.227 0 0 0-.91 0L3.173 3.897c-.501.186-.84.665-.84 1.19V14c0 3.768 2.124 7.187 5.624 10.185zm10.5-1.622a27.313 27.313 0 0 1-3.5 2.544l-.957.56a27.298 27.298 0 0 1-4.457-3.104c-3.068-2.636-4.876-5.53-4.876-8.516V5.775L14 2.333l9.333 3.442v8.272c0 2.986-1.808 5.88-4.876 8.516z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h28v28H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgShieldCheck;
