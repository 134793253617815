import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgMagic = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M19.594 9.078 24.172 4.5 22.5 2.828l-4.578 4.578 1.672 1.672zM26.578 4.5a.954.954 0 0 1-.281.703L6.203 25.297a.954.954 0 0 1-.703.281.954.954 0 0 1-.703-.281l-3.094-3.094a.954.954 0 0 1-.281-.703c0-.281.094-.516.281-.703L21.797.703A.954.954 0 0 1 22.5.422c.281 0 .516.094.703.281l3.094 3.094a.954.954 0 0 1 .281.703zM5.468 1.531 7 2l-1.531.469L5 4l-.469-1.531L3 2l1.531-.469L5 0l.469 1.531zm5.47 2.532L14 5l-3.063.938L10 9l-.938-3.063L6 5l3.063-.938L10 1l.938 3.063zm14.53 7.468L27 12l-1.531.469L25 14l-.469-1.531L23 12l1.531-.469L25 10l.469 1.531zm-10-10L17 2l-1.531.469L15 4l-.469-1.531L13 2l1.531-.469L15 0l.469 1.531z" />
  </svg>
);
export default SvgMagic;
