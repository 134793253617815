import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgPictureO = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={28}
    viewBox="0 0 32 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11 9c0 .833-.292 1.542-.875 2.125A2.893 2.893 0 0 1 8 12a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 5 9c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 8 6c.833 0 1.542.292 2.125.875S11 8.167 11 9zm16 6v7H5v-3l5-5 2.5 2.5 8-8L27 15zm1.5-11h-25a.48.48 0 0 0-.352.148A.48.48 0 0 0 3 4.5v19c0 .135.05.253.148.352A.48.48 0 0 0 3.5 24h25a.48.48 0 0 0 .352-.148A.48.48 0 0 0 29 23.5v-19a.48.48 0 0 0-.148-.352A.48.48 0 0 0 28.5 4zm2.5.5v19a2.41 2.41 0 0 1-.734 1.766c-.49.49-1.078.734-1.766.734h-25a2.407 2.407 0 0 1-1.766-.734A2.407 2.407 0 0 1 1 23.5v-19c0-.688.245-1.276.734-1.766A2.407 2.407 0 0 1 3.5 2h25a2.41 2.41 0 0 1 1.766.734c.49.49.734 1.079.734 1.766z" />
  </svg>
);
export default SvgPictureO;
