import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgNudgeExplore = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m16.45 5.45 1.328-.6a.38.38 0 0 0 0-.692l-1.329-.6-.6-1.336a.38.38 0 0 0-.69 0l-.6 1.329-1.337.6a.38.38 0 0 0 0 .69l1.329.6.6 1.337a.386.386 0 0 0 .698 0l.6-1.329Zm-.948-.332.191-.425.42-.19-.42-.19-.19-.422-.19.417-.422.19.417.188.194.432ZM14.55 14.55l-1.328.6a.38.38 0 0 0 0 .692l1.329.6.6 1.336a.38.38 0 0 0 .69 0l.6-1.329 1.337-.6a.38.38 0 0 0 0-.69l-1.329-.6-.6-1.337a.386.386 0 0 0-.698 0l-.6 1.329Zm.948.332-.191.425-.42.19.42.19.19.422.19-.417.422-.19-.417-.188-.194-.432ZM10.278 12.278l3.189-1.449c.71-.328.71-1.34 0-1.658l-3.19-1.449L8.83 4.533c-.319-.71-1.34-.71-1.658 0l-1.449 3.19L2.533 9.17c-.71.328-.71 1.34 0 1.658l3.19 1.449 1.448 3.189c.328.71 1.34.71 1.658 0l1.449-3.19ZM8.912 8.343c.15.33.415.595.745.745l2.008.912-2.008.912a1.5 1.5 0 0 0-.745.745L8 13.666l-.912-2.007a1.5 1.5 0 0 0-.745-.746L4.334 10l2.009-.912a1.5 1.5 0 0 0 .745-.745L8 6.334l.912 2.009Z"
    />
  </svg>
);
export default SvgNudgeExplore;
