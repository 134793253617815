import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgLock = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10 12h8V9a3.85 3.85 0 0 0-1.172-2.828A3.854 3.854 0 0 0 14 5a3.85 3.85 0 0 0-2.828 1.172A3.854 3.854 0 0 0 10 9v3zm13 1.5v9c0 .417-.146.77-.438 1.063A1.446 1.446 0 0 1 21.5 24h-15c-.417 0-.77-.146-1.063-.438A1.447 1.447 0 0 1 5 22.5v-9c0-.417.146-.77.438-1.063A1.446 1.446 0 0 1 6.5 12H7V9c0-1.917.688-3.563 2.063-4.938C10.437 2.688 12.082 2 14 2c1.917 0 3.563.688 4.938 2.063C20.313 5.438 21 7.082 21 9v3h.5c.417 0 .77.146 1.063.438.291.291.437.645.437 1.062z" />
  </svg>
);
export default SvgLock;
