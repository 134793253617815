import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgBold = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14.488 21.8c.736 0 1.334-.094 1.795-.28.473-.187.84-.429 1.104-.725.264-.297.445-.632.544-1.005a4.42 4.42 0 0 0 .148-1.137c0-.439-.06-.834-.181-1.186a1.877 1.877 0 0 0-.593-.89c-.275-.24-.643-.427-1.104-.56-.461-.131-1.043-.197-1.746-.197h-3.887v5.98h3.92Zm-3.92-15.633v5.93h2.899a9.55 9.55 0 0 0 1.663-.131c.495-.088.912-.242 1.252-.462.352-.22.615-.521.79-.905.188-.385.28-.868.28-1.45 0-.571-.07-1.043-.213-1.417a2.036 2.036 0 0 0-.66-.922c-.296-.23-.674-.395-1.136-.494-.45-.1-.988-.149-1.614-.149h-3.261ZM13.829 2c1.658 0 3.07.154 4.233.461 1.164.308 2.114.741 2.85 1.302.736.56 1.268 1.24 1.598 2.042.34.802.51 1.697.51 2.685 0 .538-.076 1.06-.23 1.565-.154.494-.395.96-.725 1.4-.33.428-.752.824-1.268 1.186s-1.137.675-1.861.939c1.58.384 2.75 1.005 3.508 1.861.758.857 1.137 1.966 1.137 3.328a6.862 6.862 0 0 1-.593 2.85 6.562 6.562 0 0 1-1.746 2.305c-.758.648-1.697 1.16-2.817 1.532-1.12.363-2.4.544-3.838.544H5V2h8.83Z" />
  </svg>
);
export default SvgBold;
