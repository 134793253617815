import React from 'react';
import classNames from 'classnames';

import { GLOBAL_SPACE } from '../../design-tokens/build/rc-tokens-web-typescript';

type GLOBAL_SIZES = typeof GLOBAL_SPACE[number];
const EXCLUDED_SIZES = [ 'rc-space-0', 'rc-space-px', 'rc-space-half', 'rc-space-1'] as const;

type VALID_ICON_SIZES = Exclude<GLOBAL_SIZES, typeof EXCLUDED_SIZES[number]>;
interface SVGTitle {
  title?: string;
}

export interface IRCIconProps {
  /** The rendered size of the icon.
   * - `fullWidth` – Default. Sets the icon width to 100% of its container.
   * - `inline` – Sizes the icon to be relative to the surrounding font size.
   * - `native` – Renders the icon at its native SVG size (28px).
   * - `rc-space-*` – Renders the icon at a fixed width using our global 4px size scale (e.g. `rc-space-5` = 20px)
  */
  size?: 'fullWidth' | 'inline' | 'native' | VALID_ICON_SIZES;
  /** @deprecated use `size = 'inline'` instead. Using this props overrides `size` prop. */
  inline?: boolean;
  /**
   * For accessibility. Leave this blank for decorative icons, but add a descriptive verb for interactive icons.',
  */
  title?: string;
  /** @deprecated use `size = 'native'` instead. Using this props overrides `size` and `inline` props. */
  nativeSize?: boolean;
  /**
   * Import the SVG with `?component` in the import path, and pass it through here.
   */
  // svgComponent: (props: (React.SVGProps<SVGElement> & SVGTitle)) => React.ReactSVGElement; // a specific svg imported from /packages/cadence/icons
  svgComponent: (props: (React.SVGProps<SVGElement> & SVGTitle)) => React.ReactElement;
}

/**
 * Our default icon component. Pass it an SVG, and it handles the SVG output as well as proper ARIA / accessibility markup around it.
*/
export function RCIcon({
  size: iconSize = 'fullWidth',
  inline = false,
  nativeSize = false,
  title = undefined,
  svgComponent,
}: IRCIconProps) {
  const WrappedSVGComponent = svgComponent;

  const size: IRCIconProps['size'] = nativeSize ? 'native' : inline ? 'inline' : iconSize;

  const classes = classNames('rc-icon', {
    'rc-icon--inline': size === 'inline',
    'rc-icon--native-size': size === 'native',
  });

  const styleOverrides = {
    ...fixedSize(size),
  } as React.CSSProperties;

  // if there is no accessible title, then the icon is decorative and should be
  // hidden from screen readers
  const ariaHidden = !title ? true : undefined;

  return (
    <span className={classes} style={styleOverrides}>
      <WrappedSVGComponent title={title} aria-hidden={ariaHidden} />
    </span>
  );
}

function fixedSize(size: IRCIconProps['size']) {
  if (size === 'fullWidth') return {};
  if (size === 'inline') return {};
  if (size === 'native') return {};
  return { '--icon-size': `var(--${size})` };
}
