import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgGlobe = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14.177 1C6.913 1 1 6.913 1 14.177c0 2.98.972 5.789 2.808 8.128a13.077 13.077 0 0 0 10.369 5.048c7.263 0 13.176-5.913 13.176-13.177C27.353 6.914 21.44 1 14.176 1Zm0 24.706c-1.92 0-3.64-2.322-4.588-5.707a22.728 22.728 0 0 1 4.588-.47c1.605 0 3.154.173 4.587.47-.947 3.385-2.669 5.707-4.587 5.707Zm0-7.824c-1.722 0-3.402.19-4.966.52A22.165 22.165 0 0 1 8.84 15h10.665a22.165 22.165 0 0 1-.37 3.401 24.08 24.08 0 0 0-4.959-.519Zm-9.833 2.306A11.44 11.44 0 0 1 2.68 15h4.521c.041 1.334.181 2.61.42 3.805-1.227.362-2.33.832-3.277 1.383Zm9.832-17.54c1.944 0 3.69 2.38 4.629 5.838a22.48 22.48 0 0 1-4.628.478c-1.615 0-3.18-.173-4.62-.478.93-3.459 2.676-5.839 4.62-5.839Zm0 7.963c1.73 0 3.418-.19 4.991-.52.19 1.03.305 2.126.346 3.27H8.84c.041-1.136.156-2.232.346-3.27 1.573.33 3.253.52 4.99.52Zm-6.975 2.742H2.688c.132-1.853.7-3.574 1.606-5.081.947.56 2.059 1.037 3.294 1.408a24.377 24.377 0 0 0-.387 3.673ZM21.151 15h4.514A11.386 11.386 0 0 1 24 20.18c-.939-.552-2.042-1.013-3.261-1.375.23-1.194.37-2.47.412-3.805Zm0-1.647a23.56 23.56 0 0 0-.395-3.681c1.236-.37 2.348-.84 3.295-1.408a11.426 11.426 0 0 1 1.606 5.08h-4.505v.009Zm1.969-6.44c-.782.461-1.705.848-2.718 1.161-.51-1.87-1.251-3.467-2.157-4.67a11.575 11.575 0 0 1 4.875 3.509ZM10.108 3.405c-.906 1.202-1.647 2.8-2.157 4.67-1.013-.314-1.936-.7-2.718-1.162a11.575 11.575 0 0 1 4.875-3.508Zm-4.8 18.117c.773-.444 1.68-.815 2.676-1.12.51 1.829 1.243 3.377 2.133 4.554a11.412 11.412 0 0 1-4.81-3.434Zm12.937 3.426c.88-1.177 1.614-2.726 2.124-4.546.997.305 1.903.676 2.677 1.12a11.41 11.41 0 0 1-4.801 3.426Z" />
  </svg>
);
export default SvgGlobe;
