import { useEffect, useState, useRef, useCallback } from 'react';

type ObserverProps = IntersectionObserverInit;

export function useIntersectionObserver({
  rootMargin = '0px',
  root = null,
  threshold = 0,
}: ObserverProps = {}) {
  const isSupported =
    global?.window &&
    ('IntersectionObserver' in window) &&
    ('IntersectionObserverEntry' in window);

  const [entry, setEntry] = useState<IntersectionObserverEntry>(null);
  const trackedRef = useRef(null);
  const observer = useRef(null);

  const setTrackedRef = useCallback((ref) => {
    trackedRef.current = ref;
  }, []);

  useEffect(() => {
    if (!isSupported) return null;
    if (observer.current) observer.current.disconnect();

    observer.current = new window.IntersectionObserver(
      ([observerEntry]) => setEntry(observerEntry),
      { rootMargin, root, threshold },
    );

    if (trackedRef.current) observer.current.observe(trackedRef.current);

    return () => observer.current.disconnect();
  }, [isSupported, trackedRef, rootMargin, root, threshold]);

  return {
    setTrackedRef,
    entry,
  };
}
