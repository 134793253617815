import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
/*
  README
  This is an automatically generated file and should not be edited manually.
  All changes should be made via 'make build-react-icons'
*/
const SvgCheck = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 28 28"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10.412 18.8 23.404 5.806a.875.875 0 0 1 1.238 0l1.239 1.238a.875.875 0 0 1 0 1.237L11.03 23.13a.875.875 0 0 1-1.238 0l-7.424-7.425a.875.875 0 0 1 0-1.237l1.238-1.237a.875.875 0 0 1 1.237 0l5.568 5.568z" />
  </svg>
);
export default SvgCheck;
